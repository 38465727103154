import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';

//CSS
import './CouponsPopUpRow.css';

//Images
import moment from 'moment';
import { CheckBox } from '@material-ui/icons';

function CouponsPopUpRow(props) {

	return (
		<div className='coupons_popup_table'>
			<div className='coupons_popup_row'>
				<div className='wi-30 coupons_popup_col'>
					{props.type}
				</div>
				<div className='wi-30 coupons_popup_col'>
					{props.code}
				</div>
				<div className='wi-30 coupons_popup_col'>
					{<NumberFormat value={Number(props.value).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />}
				</div>
				<div className='wi-30 coupons_popup_col'>
					{props.description}
				</div>				
			</div>
		</div>
	);
}

export default connect()(CouponsPopUpRow);
