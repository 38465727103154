import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
// import PropTypes from "prop-types";

import DefaultLayout from "../Containers/Layouts/Default/DefaultLayout";
// import { USER_TYPE_ADMIN, USER_TYPE_COACH } from "../../constants";

const AdminPrivateRoute = ({
  component: Component,
  auth,
  // auth: { token, userType, loginPath, isLoggingOut },
  ...rest
}) => {
  // if (token) {
  // 	console.log("Redirecting from auth route to corresponding dashboard as per user");
  // 	switch (userType) {
  // 		case USER_TYPE_ADMIN:
  // 			return <Redirect to={loginPath ? loginPath : "/admin/dashboard"} />;
  // 		case USER_TYPE_COACH:
  // 			return <Redirect to={loginPath ? loginPath : "/coach/events"} />;
  // 		default:
  // 			return <Redirect to="/" />;
  // 	}
  // }

  const handleRoute = (props) => {
    if (auth?.access_token !== null) {
      if (auth?.user?.roles?.length > 0) {
        if (auth?.user?.roles[0].name === 'admin') {
          return (
            <DefaultLayout>
              <Component {...props} />
            </DefaultLayout>
          )
        } else {
          return (
            ''
            // <Redirect to={`/login`} />
          )
        }
      } else {
        return (
          ''
          // <Redirect to={`/login`} />
        )
      }
    } else {
      return (
        <Redirect to={`/login`} />
      )
    }
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        // handleRoute(props)
        auth?.access_token != null ? (
          <DefaultLayout>
            <Component {...props} />
          </DefaultLayout>
        ) : (
            <Redirect to={`/login`} />
          )
      }
    />
  );
};

// AdminPrivateRoute.propTypes = {
// 	auth: PropTypes.object.isRequired,
// };

const mapStateToProps = (state) => ({
  auth: state.register,
});

export default connect(mapStateToProps, {})(AdminPrivateRoute);
