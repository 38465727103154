import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_FAILED,
  LOGIN_SUCCESS,
  AUTH_REQUEST,
  LOGOUT,
  PASSWORD_REST,
} from "../actions/types";
import { removeAuthToken } from "../utils/setAuthToken";
const initialState = {
  access_token: localStorage.getItem("access_token"),
  isAuth: null,
  loading: false,
  user: JSON.parse(localStorage.getItem("UserInfo")),
  password_reset: null,
};

export default function (state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case USER_LOADED:
      let userInformation = {
        first_name: payload.first_name,
        last_name: payload.last_name,
        uuid: payload.uuid,
        mobile_phone: payload.mobile_phone,
        profile_picture: payload.profile_picture,
        email: payload.email,
        street: payload.street,
        suburb: payload.suburb,
        roles: payload.roles,
        id: payload.id,
      }
      localStorage.setItem("UserInfo", JSON.stringify(userInformation))
      return {
        ...state,
        user: payload,
        isAuth: true,
        loading: false,
      };

    case AUTH_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem("access_token", payload.access_token);

      return {
        ...state,
        ...payload,
        isAuth: true,
        loading: false,
      };
    case REGISTER_FAIL:
    case AUTH_FAILED:
      return {
        ...state,
        loading: false,
        isAuth: false,
      }
    case LOGOUT:
      localStorage.removeItem("access_token");
      localStorage.removeItem("portal");
      localStorage.removeItem("prefix");
      localStorage.removeItem("subdomain");
      localStorage.removeItem("UserInfo")
      removeAuthToken();
      return {
        ...state,
        access_token: null,
        isAuth: false,
        loading: false,
        user: null,
      };
    case PASSWORD_REST:
      return {
        ...state,
        password_reset: payload.link,
      };

    default:
      return state;
  }
}
