import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { coachHandle } from "../../../utils/global";
// import moment from 'moment';
import membersAction from "../../../redux/actions/membersAction";

//components
import InvoiceRow from "../../../Components/Members/InvoiceRow";
//images
// import plus from '../../../assets/images/click-plus.svg';
// import whiteX from '../../../assets/images/x-white.svg';
// import Checked from '../../../assets/images/CheckBoxChecked.png';
// import Unchecked from '../../../assets/images/CheckBoxUnchecked.png';
import backArrow from "../../../assets/images/arrow-red-left.svg";

// import leftArrowImg from '../../../assets/images/arrow-left-blue.svg';
// import rightArrowImg from '../../../assets/images/right-arrow-blck.svg';

function MemberInvoices(props) {
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    coachHandle(
      props.user?.roles?.length > 0 ? props.user?.roles[0]?.name : null
    );
    props.dispatch(membersAction.getMemberDetail({ id: params.member_id }));
    props.dispatch(membersAction.getInvoices({ id: params.member_id }));
  }, []);
  console.log(props.membersInvoices);
  return (
    <section>
      <div className="coach_top_div">
        <div className="d-flex align-items-center">
          <div className="go_back_arrow" onClick={() => history.goBack()}>
            <img src={backArrow} alt="Go Back" />
          </div>
          <div className="bold blue font-36 text-upper float-left">
            {console.log(props.memberDetails)}
            {props.memberDetails?.first_name +
              " " +
              props.memberDetails?.last_name +
              " "}
            invoices
          </div>
        </div>
        <div>
          {props?.membersInvoicesLoading && (
            <div className="text-center mx-auto mt-4">
              <CircularProgress color="primary" size={30} />
            </div>
          )}
        </div>
      </div>
      {props.membersInvoicesLoading ? (
        <div className="text-center mx-auto mt-4">
          <CircularProgress color="primary" size={30} />
        </div>
      ) : props?.membersInvoices?.booking_transactions?.length !== 0 ? (
        <div className="coach_list_div">
          <div className="coach_list_top">
            <div className="events_table_header wi-21 text-upper">
              Invoice number
            </div>
            <div className="events_table_header wi-16 text-upper">date</div>
            <div className="events_table_header wi-13 text-upper">
              total paid
            </div>
            <div className="events_table_header wi-21 text-upper">invoice</div>
          </div>
          <div className="coach_list_container">
            {props?.membersInvoices?.booking_transactions?.map((zone, i) => {
              let data = (
                <InvoiceRow
                  key={i}
                  number={zone.invoice_transaction_id}
                  date={zone.transaction_date}
                  due_date={zone.end_date}
                  paid={zone?.total_dollar} //paid={zone.sub_total}
                  due_paid={zone.total_dollar}
                  pdfData={zone}
                />
              );
              return data;
            })}
          </div>
          {/* <div className='coach_list_pagination'>
					{pageOn !== 1 && (
						<div onClick={() => setPageOn(pageOn - 1)} className='pointer'>
							<img src={leftArrowImg} alt='' />
						</div>
					)}
					{numOfPages.map((zone, i) => {
						return (
							<div
								key={i}
								onClick={() => setPageOn(zone)}
								className={
									props.listCoach?.current_page === zone
										? 'coach_list_active_page coachList_pagination'
										: 'coachList_pagination'
								}
							>
								{zone}
							</div>
						);
					})}
					{pageOn !== props.listCoach?.last_page && (
						<div onClick={() => setPageOn(pageOn + 1)} className='pointer'>
							<img src={rightArrowImg} alt='' />
						</div>
					)}
				</div> */}
        </div>
      ) : (
        <div className="text-center mx-auto mt-4">
          <h4 className="blue">This user hasn't booked any event yet</h4>
        </div>
      )}
    </section>
  );
}

const mapStateToProps = (state) => ({
  membersInvoices: state.membersReducers.membersInvoices,
  membersInvoicesLoading: state.membersReducers.membersInvoicesLoading,
  memberDetails: state.membersReducers.detailMember,
  user: state.register.user,
});

export default connect(mapStateToProps)(MemberInvoices);
