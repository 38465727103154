import React from 'react';

import '../Events/EventPurchaserRow.css';

import checkedBox from '../../assets/images/CheckBoxChecked.png';
import uncheckedBox from '../../assets/images/CheckBoxUnchecked.png';

function CoachEventPurchaserRow(props) {
	return (
		<div className='event_purchaser_row'>
			<div className='select_user_div wi-16'>
				<img
					onClick={() => { props.handleCheckedUsers(props.userId, props.checked) }}
					src={!props.checked ? uncheckedBox : checkedBox}
					alt='check'
				/>
			</div>
			<div className='event_purchaser_row_col wi-21'>
				{props.first_name} {props.last_name}
			</div>
			<div className='event_purchaser_row_col wi-21'>{props.email}</div>
			<div className='event_purchaser_row_col wi-21'>{props.mobile_phone}</div>
			<div className='event_purchaser_row_col wi-21'>{props.date}</div>
			{/* <div className='event_purchaser_row_col wi-13'>
				{props.status}
			</div> */}
			{/* <div className='event_purchaser_row_col wi-13'>${props.price}</div> */}
		</div>
	);
}

export default CoachEventPurchaserRow;