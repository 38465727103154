import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { coachHandle } from '../../../utils/global';

//IMAGES
import plus from '../../../assets/images/click-plus.svg';
import postOne from '../../../assets/images/post_one.png';

//Components
import Post from '../../../Components/Noticeboard/Post';

//REDUX
import postActions from '../../../redux/actions/postsActions';

//CSS
import './Noticeboard.css';

function Noticeboard(props) {
	useEffect(() => {
		coachHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null);
		props.dispatch(postActions.listPosts({ thisMonth: 6, previous: 12 }));
	}, []);
	return (
		<div>
			<div className='noticeboard_top_div'>
				<div className='bold blue font-36 text-upper float-left'>
					NoticeBoard
				</div>
				<div className='noticeboard_form_div'>
					<div
						onClick={() => {
							props.dispatch(postActions.popupHandler({ type: 'ADD' }));
						}}
						className='noticeboard_add_new bold pointer'
					>
						<img src={plus} alt=''/>
						<span>Add New</span>
					</div>
				</div>
			</div>
			<div style={styles.subHeaderContainer}>
				<span style={styles.subHeader}>THIS MONTH POSTS</span>
				<Link to='/admin/noticeboard/this_month'>
					<span style={styles.seeAll}>SEE ALL</span>
				</Link>
			</div>
			<div className='row ml-1'>
				{props.postsLoading ? (
					<div className='text-center mx-auto mt-4'>
						<CircularProgress color='primary' size={30} />
					</div>
				) : props?.posts?.current_month?.length > 0 ? (
					props?.posts?.current_month.map((zone, i) => {
						if (zone.image !== null && zone.image !== '') {
							return <Post type={1} all={zone} key={i} />;
						} else {
							return <Post type={2} all={zone} key={i} />;
						}
					})
				) : (
							<h2 className='no_item_available'>No posts available</h2>
						)}
			</div>
			<div style={styles.subHeaderContainer}>
				<span style={styles.subHeader}>ALL POSTS</span>
				<Link to='/admin/noticeboard/previous'>
					<span style={styles.seeAll}>SEE ALL</span>
				</Link>
			</div>
			<div className='row ml-1'>
				{props.postsLoading ? (
					<div className='text-center mx-auto mt-4'>
						<CircularProgress color='primary' size={30} />
					</div>
				) : props?.posts?.previous_month?.length > 0 ? (
					props?.posts?.previous_month.map((zone, i) => {
						if (zone.image !== null && zone.image !== '') {
							return <Post image={postOne} type={1} all={zone} key={i} />;
						} else {
							<Post image={postOne} type={2} all={zone} key={i} />;
						}
					})
				) : (
							<h2 className='no_item_available'>No posts available</h2>
						)}
			</div>
		</div>
	);
}

const styles = {
	subHeader: {
		fontSize: '24px',
		color: '#262262',
		fontStyle: 'bold',
	},
	seeAll: {
		fontSize: '14px',
		color: '#262262',
		fontStyle: 'bold',
	},
	subHeaderContainer: {
		marginTop: '40px',
		display: 'flex',
		justifyContent: 'space-between',
	},
};
const mapStateToProps = (state) => ({
	posts: state.postReducer.posts,
	postsLoading: state.postReducer.postsLoading,
	user: state.register.user
});

export default connect(mapStateToProps)(Noticeboard);
