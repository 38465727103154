import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

//CSS
import './EventsPopUpRow.css';

//Images
import moment from 'moment';
import { CheckBox } from '@material-ui/icons';

function EventsPopUpRow(props) {

	return (
		<div className='events_popup_table'>
			<div className='events_popup_row'>
				<div className='wi-30 events_popup_col'>
					{props.title}
				</div>
				<div className='wi-17 events_popup_col'>{props.subtitle}</div>
				<div className='wi-23 events_popup_col event_date_col'>
					<div className='d-flex justify-content-between w-100'>
						<span className='blue bold text-upper'>START</span>
						<span className='mr-2'>{props.start_date ? moment(props.start_date).format("DD/MM/YYYY") : ''}</span>
					</div>
					<div className='d-flex justify-content-between w-100'>
						<span className='blue bold text-upper'>END</span>
						<span className='mr-2'>{props.end_date ? moment(props.end_date).format("DD/MM/YYYY") : ''}</span>
					</div>
					<div className='w-100'>
						<span>
							{props.time_start} - {props.time_end}
						</span>
					</div>
				</div>
				<div className='wi-30 events_popup_col'>{props.location}</div>
			</div>
		</div>
	);
}

export default connect()(EventsPopUpRow);
