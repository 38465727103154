import React , {useEffect} from 'react'
import { connect } from 'react-redux'

import profileActions from '../../../redux/actions/profileActions'

import './schoolStyle.scss'

export const DeleteSchool = (props) => {


    
    const [school, setSchool] = React.useState('')

    useEffect(() => {
        setSchool(props?.selectedPost?.id ?  props?.selectedPost?.id : null)
     }, [props.selectedPost])

    const deleteSchool = () => {
        props.dispatch(profileActions.deleteSchool({ data: school, }));
    }
    

    console.log('selected Post' , props.selectedPost)
    return (
        <div>
        <h3 className='blue bold'>Delete School</h3>
        <span className='font-20 blue mb-5'>Are you sure you want to delete {props.selectedPost.school_name}?</span>
        <div className='white  bold red-bg add_school_btn_popup' 
        onClick={() => deleteSchool()}
            >
                Delete
        </div>
    </div>
    )
}

const mapStateToProps = (state) => ({
    selectedPost: state.postReducer?.selectedPost,

})


export default connect(mapStateToProps)(DeleteSchool)
