import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'


//REDUX
import popupAction from "../../redux/actions/postsActions"
import postsActions from "../../redux/actions/eventsActions"

//css
import './EventProgramCard.css';
//images
import EditImg from '../../assets/images/edit.png';
import DeleteImg from '../../assets/images/delete.png';

function EventProgramCard(props) {
	return (
		<div className='event_program_card m-2' >
			<div className='edit_card_div'>
				{props.type === 1 ?
					<div onClick={() => { props.dispatch(popupAction.popupHandler({ type: "EDIT_EVENT_PROG_EDIT", data: props })) }} to='' className='mr-3 pointer'>
						<svg className="eventProgramCard_icons" data-name="Component 59 – 1" xmlns="http://www.w3.org/2000/svg" width="17.715" height="17.869" viewBox="0 0 17.715 17.869">
							<path id="Path_20017" data-name="Path 20017" d="M15.081,28.465l3.925,3.811,7.561-7.788-3.925-3.809Z" transform="translate(-12.409 -17.015)" />
							<path id="Path_20018" data-name="Path 20018" d="M5.583,74.346,1.657,70.535,0,75.909Z" transform="translate(0 -58.04)" />
							<path id="Path_20019" data-name="Path 20019" d="M0,100.869" transform="translate(0 -83)" />
							<path id="Path_20020" data-name="Path 20020" d="M66.041,0,63.5,2.616l3.925,3.811,2.541-2.617Z" transform="translate(-52.251)" />
						</svg>
					</div>
					:
					<div onClick={() => { props.dispatch(popupAction.popupHandler({ type: "ADDPROG_EDIT", data: props })) }} to='' className='mr-3 pointer'>
						<svg className="eventProgramCard_icons" data-name="Component 59 – 1" xmlns="http://www.w3.org/2000/svg" width="17.715" height="17.869" viewBox="0 0 17.715 17.869">
							<path id="Path_20017" data-name="Path 20017" d="M15.081,28.465l3.925,3.811,7.561-7.788-3.925-3.809Z" transform="translate(-12.409 -17.015)" fill="#686692" />
							<path id="Path_20018" data-name="Path 20018" d="M5.583,74.346,1.657,70.535,0,75.909Z" transform="translate(0 -58.04)" fill="#686692" />
							<path id="Path_20019" data-name="Path 20019" d="M0,100.869" transform="translate(0 -83)" fill="#686692" />
							<path id="Path_20020" data-name="Path 20020" d="M66.041,0,63.5,2.616l3.925,3.811,2.541-2.617Z" transform="translate(-52.251)" fill="#686692" />
						</svg>
					</div>}
				<div onClick={() => { props.dispatch(postsActions.handleDeleteEventProgram({ id: props.deleteId, formData: props.formData, type: props.ptype === 'old' ? 1 : 0 })) }} className="pointer">
					<svg className="eventProgramCard_icons" data-name="Component 60 – 1" xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
						<path id="Path_20022" data-name="Path 20022" d="M6,19a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V8H6ZM19,5V6H5V5H8L9,3h6l1,2ZM11,17H9V12h2Zm4,0H13V12h2Z" transform="translate(-5 -3)" />
					</svg>
				</div>
			</div>
			<div className='card_info'>
				<span className='bold blue font-16 text-break pr-2 pl-2' title={props.title}>
					{props.title.length>60? `${props.title.substring(0,60)} ...` :props.title}
				</span>
				<span className='blue regular font-16'>${props.ticket_price}/CHILD</span>
			</div>
		</div>
	);
}



const mapStateToProps = (state) => ({
	formData: state.eventReducer.formData,
})

export default connect(mapStateToProps)(EventProgramCard)

