import React  from 'react'
import { connect } from 'react-redux'

import shopActions from '../../../redux/actions/shopActions'

import './schoolStyle.scss'

export const deleteProducts = (props) => {

    const deleteProducts = () => {
        props.dispatch(shopActions.deleteProducts(props.selectedPost));
    }
    return (
        <div>
        <h3 className='blue bold'>Delete Product(s)</h3>
        <span className='font-20 blue mb-5'>Are you sure you want to delete the selected product(s)?</span>
        <div className='white  bold red-bg add_school_btn_popup' 
        onClick={() => deleteProducts()}
            >
                Delete
        </div>
    </div>
    )
}

const mapStateToProps = (state) => ({
    selectedPost: state.postReducer?.selectedPost,

})


export default connect(mapStateToProps)(deleteProducts)
