import React, { useEffect, useState } from 'react';
import AddProductComponent from '../../../Components/store/AddProduct';
import { connect } from 'react-redux';
import { coachHandle } from '../../../utils/global';

import {
	fetchListOfCategory,
	fetchListOfRelatedProducts,
	createProduct,
} from '../../../actions/store';
import { getValue } from '../../../utils/app';
import { toast } from 'react-toastify';

const AddProduct = (props) => {
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		coachHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null);
		props.fetchListOfCategory();
		props.fetchListOfRelatedProducts();
		// setCategoryValueData(props.eventDetail.category_id);
	}, []);

	useEffect(() => {
		let data = [];
		if (props.categories) {
			props.categories.map((category) => {
				data.push({ value: category.id, label: category.name });
			});
			setCategories(data);
		}
	}, [getValue(props, 'props.categories')]);

	const onCallApi = (formData) => {
		if (formData.item_id_in_business_center) {
			props.createProduct(formData, getValue(props, 'props.match.params.id'));
		} else {
			toast.error('Item Id In Business Central field is required')
		}
	};

	return (
		<AddProductComponent
			propsData={props}
			categories={categories}
			relatedProducts={props.relatedProducts}
			onCallApi={onCallApi}
		/>
	);
};

const mapStateToProps = (state) => ({
	categories: state.storeReducer.categories,
	relatedProducts: state.storeReducer.relatedProducts,
	user: state.register.user
});

export default connect(mapStateToProps, {
	fetchListOfCategory,
	fetchListOfRelatedProducts,
	createProduct,
})(AddProduct);
