import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import queryString from 'query-string';
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import NumberFormat from 'react-number-format';

//REDUX
import couponActions from '../../../redux/actions/couponsActions';
import postsActions from '../../../redux/actions/postsActions';

//CSS
import './CouponList.css';

//Components
import CouponListRow from '../../../Components/Coupons/CouponsListRow';

//Images
import plus from '../../../assets/images/click-plus.svg';
import excel from "../../../assets/images/excel.svg";
import Checked from "../../../assets/images/CheckBoxChecked.png";
import Unchecked from "../../../assets/images/CheckBoxUnchecked.png";
import whiteX from "../../../assets/images/x-white.svg";

import { getCouponsExcelDoc } from '../../../config/config'


const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			marginTop: theme.spacing(2),
		},
	},
}));

export const CouponList = (props) => {
	let history = useHistory();
	const classes = useStyles();
	const [selectedCategory, setSelectedCategory] = useState('');
	const [couponList, setCouponList] = useState([]);
	const [checkUsed, setCheckUsed] = useState('');
	const [searchQuery, setSearchQuery] = useState({
		value: '',
		current: '',
	})
	const [isSearch, setIsSearch] = useState(false)
	const [disableAvailable, setDisableAvailable] = useState(false)
	const [selected, setSelected] = useState(false);

	useEffect(() => {
		//get the coupons categories
		props.dispatch(couponActions.getCouponCategories());
	}, []);

	useEffect(() => {
		if (selectedCategory !== '') {
			props.dispatch(couponActions.getCoupons({ id: selectedCategory, page: queryString.parse(props.location.search).page, status: checkUsed, query: queryString.parse(props.location.search).query}))
		} else {
			props.dispatch(couponActions.getCoupons({ page: queryString.parse(props.location.search).page, status: checkUsed , query: queryString.parse(props.location.search).query})
		);
	}
	}, [queryString.parse(props.location.search).page, queryString.parse(props.location.search).query, selectedCategory, checkUsed])

	const handleNewPagination = (e, selectedPage) => {
		props.dispatch(couponActions.changePageState(selectedPage));
		if (isSearch) {
			history.push({
				pathname: "/admin/coupons",
				search: `?page=${selectedPage}&&query=${searchQuery.current}`,
			});
		} else {
			history.push({
				pathname: "/admin/coupons",
				search: `?page=${selectedPage}`,
			});
		}
	};

	const handleCategoryChange = (e) => {
		if (e !== '') {
			history.push({
				pathname: '/admin/coupons',
				search: `?page=${1}`,
			});
			props.dispatch(couponActions.changePageState(1));
			setSelectedCategory(e);
		}else{
			setSelectedCategory('');
			handleCancelSearch();
		}
	};

	const handleCheckUsed = (e) => {
		if (e !== '') {
			history.push({
				pathname: '/admin/coupons',
				search: `?page=${1}`,
			});
			props.dispatch(couponActions.changePageState(1));
			setCheckUsed(e);
		}else{
			setCheckUsed('');
			handleCancelSearch();
		}
	};

	const handleSearch = () => {
		setIsSearch(true);
		setSearchQuery({ ...searchQuery, current: searchQuery.value });
		history.push({
			pathname: '/admin/coupons',
			search: `?page=${1}&query=${searchQuery.value}`,
		});
	}
	const handleCancelSearch = () => {
		props.dispatch(couponActions.getCoupons({ page: 1 }));
		setIsSearch(false);
		setSearchQuery({ ...searchQuery,current:'',value:''});
		props.dispatch(couponActions.changePageState(1));
		history.push({
			pathname: '/admin/coupons',
			search: `?page=${1}`,
		});
	}

	const newCouponModalState = () => {
		props.dispatch(postsActions.popupHandler({ type: 'ADD_NEW_COUPON'}))
	}

	const callApi = async () => {
		await getCouponsExcelDoc()
	  }

	const handleChecked = (id) => {
	let finalData = couponList;
	props?.coupons?.data?.map((coupon,i) => {
		if (id === coupon.code)
		{
			if(couponList.find(e => e.id === coupon.id))
			{
				if (couponList.find(e => e.id === coupon.id).checked)
					finalData.splice(couponList.findIndex(e => e.id === coupon.id), 1);	
				else
					finalData.find(e => e.id === coupon.id).checked = true;
			}
			else
			{
				if (!coupon.AKV)
				{
					finalData.push({
						key: i,
						id: coupon.id,
						code: coupon.code,
						type: coupon.is_birhtday_special ? "BIRTHDAY" : ( coupon.is_school_campaign ? "SCHOOL" : (coupon.AKV ? "AKV" : "DISCOUNT")),
						description: coupon.message,
						value: coupon.value,
						checked: true
					});
				}
			}
		}
	});

	setCouponList(finalData);
	setDisableAvailable(finalData.length);
	};

	const handleSelectAll = (e) => {
	let finalData = couponList;
	props?.coupons?.data?.map((coupon,i) => {

		if(finalData.find(e => e.id === coupon.id))
		{
			if (e === "checked")
				finalData.find(e => e.id === coupon.id).checked = true;
			else
				finalData.splice(couponList.findIndex(e => e.id === coupon.id), 1);
		}
		else
		{
			if (!coupon.AKV)
			{
				finalData.push({
					key: i,
					id: coupon.id,
					code: coupon.code,
					type: coupon.is_birhtday_special ? "BIRTHDAY" : ( coupon.is_school_campaign ? "SCHOOL" : (coupon.AKV ? "AKV" : "DISCOUNT")),
					description: coupon.message,
					value: coupon.value,
					checked: e === "checked" ? true : false
				});
			}
		}
	});
	setCouponList(finalData);
	setDisableAvailable(finalData.length);
	setSelected(e === "checked" ? true : false);
	};

	const handleDeactivate = () => {
		if(isSearch){
		  setIsSearch(false)
		  setSearchQuery({
			value: '',
			current: ''
		  })
		}
		props.dispatch(postsActions.popupHandler({type: 'DEACTIVATE_COUPONS', data: couponList, action:() => handleSuccessDeactivate() }))
	  };

	  const handleSuccessDeactivate = () =>{
		setCouponList([]);
		setDisableAvailable(0);
		setSelected(false);
	  };


	return (
		<section>
			<div className='coupons_top_div'>
				<div className='bold blue font-36 text-upper float-left'>coupons</div>
				<div className='coupons_form_div'>
					{isSearch &&
						(<div className="mr-1">
							<div>
								SHOWING RESULTS FOR "{searchQuery.current}"
							</div>
							<div className="search_cancel">
								<span className="pointer" onClick={() => handleCancelSearch()}>
									CANCEL
								</span>
							</div>
						</div>)}
					{props.couponCategoriesLoading && (
						<div className='text-center mx-auto mt-4'>
							<CircularProgress color='primary' size={30} />
						</div>
					)}
					{ <div className='dropDown_bar mr-2'>
						<select
							onChange={(e) => {
								handleCategoryChange(e.target.value);
							}}
							name=''
							id=''
						>
							<option key={'1Base'} value=''>
								Select Type
							</option>
							{props?.couponCategories?.length > 0
								? props?.couponCategories?.map((zone, i) => {
									return (
										<option key={i} value={zone.id}>
											{zone.name}
										</option>
									);
								})
								: null}
						</select>
					</div> }
					{<div className='dropDown_bar_status mr-2'>
						<select
							onChange={(e) => {
								handleCheckUsed(e.target.value);
							}}
							name=''
							id='usedSelect'
						>
							<option key={'AllUsedOption'} value=' '>
								Select Status
							</option>
							<option key={'UsedOption'} value='1'>
								Used
							</option>
							<option key={'NotUsedOption'} value='0'>
								Not Used
							</option>
						</select>
					</div>}
					{ <div className='seach_bar mr-2' style={{width: '200px'}}>
						<input className='search_button' type='search' onClick={() => handleSearch()} />
						<input
							type='text'
							placeholder='Search Coupons..'
							className='search_input'
							value={searchQuery.value}
							onChange={(e) => {
								setSearchQuery({ ...searchQuery, value: e.target.value });
							}}
						/>
					</div> }
					<div onClick={newCouponModalState} className='coupon_add_new bold mr-2' style={{cursor: 'pointer'}}>
						<img className='plus-image' src={plus} alt='' />
                  		<span className="edit-class">Add New</span>
                	</div>
					<div className={couponList.length > 0 ? 'coupon_deactivate_btn' : 'coupon_deactivate_btn_disable'}>
						<img src={whiteX} alt="" className="mr-2" />
						<span onClick={() => { (couponList.length > 0) && handleDeactivate() }} className="white bold text-upper">DEACTIVATE</span>
					</div>


					<div onClick={callApi} className="memberlist_export_btn">
						<img src={excel} alt="" className="mr-2" />
						<span className="white bold text-upper">EXPORT</span>
					</div>
				</div>
			</div>
			<div className='coupons_list_main_body'>
				<div className='coupons_list_top'>
					<div className="coach_table_header wi-5">
						{selected === true ? (
						<img
							onClick={() => {
							handleSelectAll("unchecked");
							}}
							src={Checked}
							alt=""
						/>
						) : (
						<img
							onClick={() => {
							handleSelectAll("checked");
							}}
							src={Unchecked}
							alt=""
						/>
						)}
					</div>
					<div className='coupons_table_header wi-10'>TYPE</div>
					<div className='coupons_table_header wi-10'>CODE</div>
					<div className='coupons_table_header wi-10'>VALUE</div>
					<div className='coupons_table_header wi-16'>DESCRIPTION</div>
					<div className='coupons_table_header wi-10'>MAX USES</div>
					<div className='coupons_table_header wi-10'>USED</div>
					<div className='coupons_table_header wi-10'>TIMES USED</div>
					<div className='coupons_table_header wi-16'>CREATED DATE</div>
					<div className='coupons_table_header wi-16'>EXPIRATION DATE</div>
				</div>
				<div className='coupons_list_container'>
					{props?.couponsLoading === true ? (
						<div className='text-center mx-auto mt-4'>
							<CircularProgress color='primary' size={30} />
						</div>
					) : props?.coupons?.data?.length > 0 ? (
						props?.coupons?.data?.map((coupon, i) => {
							let data = (
							 	<CouponListRow
									key={i}
									id={coupon.id}
									type={coupon.is_birhtday_special ? "BIRTHDAY" : ( coupon.is_school_campaign ? "SCHOOL" : (coupon.AKV ? "AKV" : "DISCOUNT"))}
							 		code={coupon.code}
							 		value={coupon.value}
									message={coupon.message}
							 		expiration_date={coupon.expiration_date}
							 		is_used={coupon.is_used ? "YES" : "NO"}
									max_uses={coupon.max_uses}
									times_used={coupon.times_used}
									created_at={coupon.created_at}
									checked={couponList.length > 0 ? (couponList.find(r => r.id == coupon.id) ? couponList.find(r => r.id == coupon.id).checked  : false) : false}
									handleChecked={handleChecked}
									status={coupon.cancelled? 'canceled' : ''}
									notes={coupon.notes}
							 	/>
							 );

							return data;
						})
					) : (
						<h2 className='no_item_available'>No coupons available</h2>
					)}
				</div>
			</div>
			<div className={classes.root}>
				<Pagination count={Number(props?.coupons?.last_page_url?.split('page=')[1])} defaultPage={ queryString.parse(props.location.search).page? parseInt(queryString.parse(props.location.search).page) : 1 } shape="rounded" onChange={handleNewPagination} key={"paginationCoupons"}/>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => ({
	couponCategories: state.couponReducer.couponCategories,
	couponCategoriesLoading: state.couponReducer.couponCategoriesLoading,
	coupons: state.couponReducer.coupons,
	couponsLoading: state.couponReducer.couponsLoading,
	user: state.register.user,
	selectedPage: state.couponReducer.selectedPage,
});

export default connect(mapStateToProps)(CouponList);
