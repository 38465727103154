import React, { useState, useEffect } from "react";
import { SubmitPassword } from "../../actions/auth";
import { connect } from "react-redux";
import ResetPasswordView from "./ResetPassword.view";
import { useParams } from "react-router-dom"
import "./resetPassword.scss";

const ResetPassword = (props) => {
  const { isAuth, loading } = props.auth;

  const { SubmitPassword } = props;

  const search = props.propsData.location.search; // could be '?foo=bar'
  const params = new URLSearchParams(search);

  const params2 = useParams()
  const [formData, setFormData] = useState({
    new_password: "",
    confirm_password: "",
    signature: "",
  });

  useEffect(() => {
    setFormData({
      ...formData,
      //signature: params.get("signature"),
      signature: params2?.signature?.split('=')[1],
      uuid: params2.uuid,
    });
  }, []);

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    // const newData = { ...formData, ...data };
    // setFormData({
    //   ...formData,
    //   newData,
    // });
    e.preventDefault();
    //setLoading(true);
    await SubmitPassword(formData);
    // setLoading(false);
  };

  console.log(formData)
  return (
    <ResetPasswordView
      onChange={onChange}
      onSubmit={onSubmit}
      formData={formData}
    />
  );
};

const mapStateToProps = (state) => ({
  auth: state.register,
});

export default connect(mapStateToProps, { SubmitPassword })(ResetPassword);
