import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import queryString from 'query-string';
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

//Redux
import ordersActions from "../../../redux/actions/ordersActions";

//Components
import OrderRow from "../../../Components/Orders/OrderRow";


const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function OrdersList(props){
  let history = useHistory();
  const classes = useStyles();


  useEffect(()=>{
    let page = queryString.parse(props.location.search).page ? queryString.parse(props.location.search).page : props.selectedPage;
    props.dispatch(ordersActions.listOrders(page))
  },[props.selectedPage, queryString.parse(props.location.search).page])

  const handleNewPagination = (e, selectedPage) => {
      props.dispatch(ordersActions.handlePagination(selectedPage));
      history.push({
        pathname: "/admin/orders",
        search: `?page=${selectedPage}`,
      });
  }


  return (
    <section>
      <div className="coach_top_div">
        <div className="bold blue font-36 text-upper float-left">Orders</div>
      </div>
      <div className="coach_list_div">
        <div className="coach_list_top">
          <div className="events_table_header wi-13">ORDER #</div>
          <div className="events_table_header wi-15">PURCHASER</div>
          <div className="events_table_header wi-13">PHONE</div>
          <div className="events_table_header wi-21">EMAIL</div>
          <div className="events_table_header wi-21">ADDRESS</div>
          <div className="events_table_header wi-13">AMOUNT</div>
          <div className="events_table_header wi-13">ORDER ITEMS</div>
        </div>
        <div className="coach_list_container">
          {props.ordersLoading? (
            <div className="text-center mx-auto mt-4">
              <CircularProgress color="primary" size={30} />
            </div>
          ) : 
            props?.orders?.data?.map((zone, i) => {
              return(
                <OrderRow
                  key={i}
                  order_number={zone.order_number}
                  first_name={zone.first_name}
                  last_name={zone.last_name}
                  phone={zone.mobile}
                  email={zone.email}
                  street_address={zone.street_address}
                  city={zone.city}
                  suburb={zone.suburb}
                  state={zone.state}
                  postal_code={zone.postal_code}
                  freight={zone.freight_cost}
                  total={zone.total}
                />
              );
            })
          }
        </div>

        <div className={classes.root}>
          <Pagination count={props.orders.last_page} defaultPage={ queryString.parse(props.location.search).page? parseInt(queryString.parse(props.location.search).page) : 1 } shape="rounded" onChange={handleNewPagination} />
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  orders: state.order.orders,
  ordersLoading: state.order.ordersLoading,
  selectedPage: state.order.page,
});


export default connect(mapStateToProps)(OrdersList);
