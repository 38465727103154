import React, { useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { coachHandle } from '../../../utils/global';

//Images
import plus from '../../../assets/images/click-plus.svg';
import backArrow from '../../../assets/images/arrow-red-left.svg';

//COMPONENTS
import Post from '../../../Components/Noticeboard/Post';

//REDUX
import postActions from '../../../redux/actions/postsActions';

function NoticeBoardPosts(props) {
	useEffect(() => {
		coachHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null);
		getData();
	}, []);
	const location = useLocation();
	const GetLocation = () => {
		if (location.pathname === '/admin/noticeboard/this_month') {
			return 'This month posts';
		} else if (location.pathname === '/admin/noticeboard/previous') {
			return 'All posts';
		}
	};

	const getData = () => {
		if (location.pathname === '/admin/noticeboard/this_month') {
			props.dispatch(postActions.listPosts({ thisMonth: 30, previous: 0 }));
		} else if (location.pathname === '/admin/noticeboard/previous') {
			props.dispatch(postActions.listPosts({ thisMonth: 0, previous: 30 }));
		}
	};

	return (
		<div>
			<div className='noticeboard_top_div'>
				<div className='bold blue font-36 text-upper d-flex flex-row'>
					<Link to='/admin/noticeboard'>
						<div className='go_back_arrow'>
							<img src={backArrow} alt='Go Back' />
						</div>
					</Link>
					{GetLocation()}
				</div>
				<div className='noticeboard_form_div'>
					<div
						onClick={() => {
							props.dispatch(postActions.popupHandler({ type: 'ADD' }));
						}}
						className='noticeboard_add_new bold pointer'
					>
						<img src={plus} alt='' />
						<span>Add New</span>
					</div>
				</div>
			</div>

			<div>
				{window.location.pathname === '/admin/noticeboard/this_month' ? (
					<div className='row ml-1'>
						{props.postsLoading ? (
							<div className='text-center mx-auto mt-4'>
								<CircularProgress color='primary' size={30} />
							</div>
						) : props?.posts?.current_month?.length > 0 ? (
							props?.posts?.current_month.map((zone, i) => {
								if (zone.image !== null && zone.image !== '') {
									return <Post type={1} all={zone} key={i} />;
								} else {
									return <Post type={2} all={zone} key={i} />;
								}
							})
						) : (
									<h2 className='no_item_available'>No posts available</h2>
								)}
					</div>
				) : (
						<div className='row ml-1'>
							{props.postsLoading ? (
								<div className='text-center mx-auto mt-4'>
									<CircularProgress color='primary' size={30} />
								</div>
							) : props?.posts?.previous_month?.length > 0 ? (
								props?.posts?.previous_month.map((zone, i) => {
									if (zone.image !== null && zone.image !== '') {
										return <Post type={1} all={zone} key={i} />;
									} else {
										return <Post type={2} all={zone} key={i} />;
									}
								})
							) : (
										<h2 className='no_item_available'>No posts available</h2>
									)}
						</div>
					)}
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	posts: state.postReducer.posts,
	postsLoading: state.postReducer.postsLoading,
	user: state.register.user
});

export default connect(mapStateToProps)(NoticeBoardPosts);
