import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { coachHandle } from '../../utils/global';
import queryString from 'query-string';
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

//images
import plus from '../../assets/images/click-plus.svg';
import whiteX from "../../assets/images/x-white.svg";
import Checked from "../../assets/images/CheckBoxChecked.png";
import Unchecked from "../../assets/images/CheckBoxUnchecked.png";
import backArrow from '../../assets/images/arrow-red-left.svg';


//Redux
import shopActions from "../../redux/actions/shopActions";
import postsActions from "../../redux/actions/postsActions";

import './StoreCategoriesList.css';
import ProductRow from './Rows/ProductRow';



const useStyles = makeStyles((theme) => ({
	root: {
	  "& > *": {
		marginTop: theme.spacing(2),
	  },
	},
  }))

function ProductsList(props) {
	let history = useHistory();
	const params = useParams();
	const classes = useStyles();

	const [products, setProducts] = useState([]);
	const [searchQuery, setSearchQuery] = useState({
		value:'',
		current:'',
	})
	const [isSearch, setIsSearch] = useState(false)
	const [selected, setSelected] = useState(false);
	const [itemSelected, setItemSelectd] = useState(false)



	const handleSelectAll = (e) => {
		let finalData = [];
		products.map((product) => {
		  finalData.push({
			name: product.name,
			meta: product.meta,
			price: product.price,
			image: product.image,
			link: product.link,
			description: product.description,
			checked: e === "checked" ? true : false,
		  });
		});
		setProducts(finalData);
		setItemSelectd(false);
		setSelected(e == "checked" ? true : false);
	  };

	  const handleChecked = (id) => {
		let finalData = [];
		let flagSelected = false
		products.map((zone) => {
		  if (zone.link === id) {
			finalData.push({
				name: zone.name,
				meta: zone.meta,
				price: zone.price,
				image: zone.image,
				link: zone.link,
				description: zone.description,
			  	checked: !zone.checked,
			});
		  } else {
			finalData.push({
				name: zone.name,
				meta: zone.meta,
				price: zone.price,
				image: zone.image,
				link: zone.link,
				description: zone.description,
			  	checked: zone.checked,
			});
		  }
		});
		finalData.map(zone => {
		  if (zone.checked) {
			flagSelected = true
		  }
		})
		setItemSelectd(flagSelected)
		setProducts(finalData);
	  };

	  const handleDelete = () => {
		let products_array = [];
		products.map((zone) => {
		  if (zone.checked === true) {
			products_array.push(zone.link);
		  }
		});
		if(isSearch){
		  setIsSearch(false)
		  setSearchQuery({
			value: '',
			current: ''
		  })
		}
		let selected = {
			product: products_array, 
			id: params.id 
		}
		props.dispatch(postsActions.popupHandler({type: 'DELETE_PRODUCTS', data: selected}))
	  };

	useEffect(() => {
		if(isSearch){
			props.dispatch(shopActions.getProducts({query:queryString.parse(props.location.search).query, page:queryString.parse(props.location.search).page ,id: params.id}));
		}else{
			props.dispatch(shopActions.getProducts({id: params.id}))
		}
		setSelected(false);
		setItemSelectd(false);
	}, [queryString.parse(props.location.search).page, queryString.parse(props.location.search).query, isSearch]);

	useEffect(()=>{
		let products_array = [];
		if(props.productsList?.data?.length>0){
			props.productsList?.data?.map((product)=>{
				let item = {
					name: product.name,
					meta: product.product_meta,
					price: product.price,
					image: product.photos?.length>0 ? product.photos[0].photo: '',
					link: product.id,
					description: product.description,
					checked: false,
				}
				products_array.push(item);
			})
		}
		setProducts(products_array);
	},[props.productsList])

	useEffect(() => {
		coachHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null);
	}, []);

	const handleSearch = (e) => {
		setIsSearch(true);
		setSearchQuery({...searchQuery,current:searchQuery.value});
		
		history.push({
			pathname: `/admin/stores/${params.id}/products`,
			search: `?page=${1}&query=${searchQuery.value}`,
		});
	};

	const handleNewPagination = (e, selectedPage) =>{
		if (isSearch) {
			history.push({
			  pathname: `/admin/stores/${params.id}/products`,
			  search: `?page=${selectedPage}&query=${searchQuery.current}`,
			});
		  } else {
			history.push({
			  pathname: `/admin/stores/${params.id}/products`,
			  search: `?page=${selectedPage}`,
			});
		  }
	  };


	return (
		<section>
			<div className='coach_top_div'>
				
				<div className='bold blue font-36 text-upper float-left'>
					<img onClick={()=> history.push('/admin/stores')} src={backArrow} alt='Go Back' className='go_back_img' />
					{props.category}</div>
				<div className='coach_form_div'>
					{isSearch&&
					(<div className="mr-3">
						<div>
							SHOWING RESULTS FOR "{searchQuery.current}"
						</div>
						<div className="search_cancel">
							<span className="pointer" onClick={() => {
										setIsSearch(false);
										setSearchQuery({...searchQuery, value:''})
									}}>
								CANCEL		
							</span>
						</div>
					</div>)}
					<div className='seach_bar'>
						<input className='search_button' type='search' onClick={()=>handleSearch()} />
						<input
							type='text'
							placeholder='Search for Products...'
							className='search_input'
							value={searchQuery.value}
							onChange={(e) => {
								setSearchQuery({...searchQuery,value:e.target.value});
							}}
						/>
					</div>
					<div style={{cursor: 'pointer'}} onClick={()=>{history.push(`/admin/stores/${params.id}/products/new`)}} className='coach_add_new bold'>
						<img src={plus} alt='' />
						<span>Add New</span>
					</div>
					<div className={selected || itemSelected ? 'member_delete_btn' : 'member_delete_btn_disable'}>
						<img src={whiteX} alt="" className="mr-2" />
						<span onClick={() => { (selected || itemSelected) && handleDelete() }} className="white bold text-upper">DELETE</span>
					</div>
					
				</div>
			</div>
			<div className='coach_list_div'>
			<div className="coach_list_top">
          <div className="coach_table_header wi-5">
            {selected === true ? (
              <img
                onClick={() => {
                  handleSelectAll("unchecked");
                }}
                src={Checked}
                alt=""
              />
            ) : (
              <img
                onClick={() => {
                  handleSelectAll("checked");
                }}
                src={Unchecked}
                alt=""
              />
            )}
          </div>
          <div className="events_table_header wi-10">PHOTO</div>
          <div className="events_table_header wi-13">PRODUCT NAME</div>
          <div className="events_table_header wi-25">DESCRIPTION</div>
          <div className="events_table_header wi-17">SIZE & QTY IN STOCK</div>
          <div className="events_table_header wi-13">PRICE</div>
          <div className="events_table_header wi-4">VIEW/EDIT</div>
        </div>
				<div className='coach_list_container'>
					{props.productsListLoading ? (
						<div className='text-center mx-auto mt-4'>
							<CircularProgress color='primary' size={30} />
						</div>
					) : (
						products.length>0 && products?.map((zone, i) => {
							let data = (
								<ProductRow
									key={i}
									name={zone.name}
									image={zone.image}
									description={zone.description}
									price={zone.price}
									link={zone.link}
									meta={zone.meta}
									checked={zone.checked}
									handleChecked={handleChecked}
								/>
							);
							return data;
						})
					)}
				</div>
				{products?.length > 0 ? null : <p className='font-16 blue'>No Data Available</p>}
				<div className={classes.root}>
          			<Pagination count={props?.productsList?.last_page} defaultPage={ queryString.parse(props.location.search).page? parseInt(queryString.parse(props.location.search).page) : 1 } shape="rounded" onChange={handleNewPagination} />
       			 </div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => ({
	user: state.register.user,
	productsList: state.ShopReducer.productsList,
	productsListLoading: state.ShopReducer.productsListLoading,
	category: state.ShopReducer.category
});

export default connect(mapStateToProps)(ProductsList);
