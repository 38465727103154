import React, { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux'

import AuthRoute from './AuthRoute';
import AdminPrivateRoute from './AdminPrivateRoute';
import CoachPrivateRoute from './CoachPrivateRoute';

import HomePageSetup from '../Containers/Pages/HomePageSetup/HomePageSetup';
import DashboardPage from '../Containers/Pages/Dashboard/DashboardPage';

import LoginPage from '../Containers/Pages/Auth/LoginPage';
import ForgotPasswordPage from '../Containers/Pages/Auth/ForgotPasswordPage';
import ResetPasswordPage from '../Containers/Pages/Auth/ResetPasswordPage';
//Profile
import Profile from '../Containers/Pages/Profile/Profile';
import ProfileEdit from '../Containers/Pages/Profile/ProfileEdit';
//Event
import EventList from '../Containers/Pages/Events/EventList';
import EditEvent from '../Containers/Pages/Events/EditEvent';
import EventDetail from '../Containers/Pages/Events/EventDetail';
import AddEvent from '../Containers/Pages/Events/AddEvent';
import ProgramList from '../Containers/Pages/Events/EventProgramList';
import ProgramDetail from '../Containers/Pages/Events/EventProgramDetail';
//coupons
import CouponsList from '../Containers/Pages/Coupons/CouponList';

//Members
import MembersList from '../Containers/Pages/Members/MembersList';
import MembersDetail from '../Containers/Pages/Members/MembersDetail';

// import AddMember from '../Containers/Pages/Members/AddMember';
import MembersEdit from '../Containers/Pages/Members/MembersEdit';
import KidsList from '../Containers/Pages/Members/KidsList';
import EditKid from '../Containers/Pages/Members/EditKid';
// import NewKid from '../Containers/Pages/Members/NewKid';
import MemberInvoices from '../Containers/Pages/Members/MemberInvoices';
import MemberPurchases from '../Containers/Pages/Members/MemberPurchases';

//Coach
import CoachList from '../Containers/Pages/Coach/CoachList';
import CoachDetail from '../Containers/Pages/Coach/CoachDetail';
import CoachEdit from '../Containers/Pages/Coach/CoachEdit';
import NewCoach from '../Containers/Pages/Coach/NewCoach';

//Store
import ListOfCategory from '../Components/StorePages/StoreCategoriesList';
import ListOfProducts from '../Components/StorePages/ProductsList';
import AddProduct from '../Containers/Pages/Store/AddProduct';
import EditProduct from '../Containers/Pages/Store/EditProduct';

//noticeboard
import NoticeBoard from '../Containers/Pages/Noticeboard/Noticeboard';
import NoticeBoardPosts from '../Containers/Pages/Noticeboard/NoticeBoardPosts';

//COACH PORTAL COMPONENTS
import EventsList from '../Containers/Pages/Coach Portal/Events/EventsList';
import EventsDetail from '../Containers/Pages/Coach Portal/Events/EventsDetail';
import EventsPrograms from '../Containers/Pages/Coach Portal/Events/EventsPrograms';
import EventProgramDetail from '../Containers/Pages/Coach Portal/Events/EventProgramDetail';

import Settings from '../Containers/Pages/Settings/Settings';
import SchoolsList from '../Containers/Pages/Settings/SchoolsList';
import ChildrenList from '../Containers/Pages/Members/ChildrenList';
import EventCategoriesList from '../Containers/Pages/Settings/EventCategoriesList';
import OrdersList from '../Containers/Pages/Orders/OrdersList';

//Admins
import AdminsList from '../Containers/Pages/SuperAdmins/AdminsList';
import NewAdmin from '../Containers/Pages/SuperAdmins/NewAdmin';
import AdminEdit from '../Containers/Pages/SuperAdmins/AdminEdit';

const Routes = (props) => {
	return (
		<Fragment>
			<Switch>
				<AuthRoute exact path='/login' component={LoginPage} />
				<AuthRoute
					exact
					path='/forgot-password'
					component={ForgotPasswordPage}
				/>
				<AuthRoute exact path='/reset-password/:uuid/:signature' component={ResetPasswordPage} />


				<AuthRoute exact path='/confirm-user/:uuid/:signature' component={ResetPasswordPage} />

				<AuthRoute exact path='/confirm-user/:uuid' component={ResetPasswordPage} />

				{/*<AuthRoute exact path='/reset-password' component={ResetPasswordPage} />*/}



				<AdminPrivateRoute
					exact
					path='/admin/homePage'
					component={HomePageSetup}
				/>

				<AdminPrivateRoute
					exact
					path='/admin/dashboard'
					component={DashboardPage}
				/>
				<AdminPrivateRoute
					exact
					path='/admin/events/new'
					component={AddEvent}
				/>

				{/* PROFILE */}
				<AdminPrivateRoute exact path='/admin/profile' component={Profile} />
				<AdminPrivateRoute
					exact
					path='/admin/profile/edit'
					component={ProfileEdit}
				/>

				{/* EVENTS */}
				<AdminPrivateRoute exact path='/admin/events' component={EventList} />
				<AdminPrivateRoute
					exact
					path='/admin/events/:id'
					component={EventDetail}
				/>
				<AdminPrivateRoute
					exact
					path='/admin/events/:id/edit'
					component={EditEvent}
				/>
				<AdminPrivateRoute
					exact
					path='/admin/events/:id/program'
					component={ProgramList}
				/>
				<AdminPrivateRoute
					exact
					path='/admin/events/:id/program/:program_id'
					component={ProgramDetail}
				/>

				{/* COUPONS */}
				<AdminPrivateRoute
					exact
					path='/admin/coupons'
					component={CouponsList}
				/>

				{/* MEMBERS */}
				<AdminPrivateRoute
					exact
					path='/admin/members'
					component={MembersList}
				/>
				<AdminPrivateRoute
					exact
					path='/admin/children'
					component={ChildrenList}
				/>
				<AdminPrivateRoute
					exact
					path='/admin/orders'
					component={OrdersList}
				/>
				<AdminPrivateRoute
					exact
					path='/admin/members/:member_id'
					component={MembersDetail}
				/>
				{/* <AdminPrivateRoute
					exact
					path='/admin/members/new'
					component={AddMember}
				/> */}
				<AdminPrivateRoute
					exact
					path='/admin/members/:member_id/edit-member'
					component={MembersEdit}
				/>
				<AdminPrivateRoute
					exact
					path='/admin/members/:member_id/children'
					component={KidsList}
				/>
				<AdminPrivateRoute
					exact
					path='/admin/members/:member_id/children/:chilren_id/edit'
					component={EditKid}
				/>
				<AdminPrivateRoute
					exact
					path='/admin/members/:member_id/invoices'
					component={MemberInvoices}
				/>

				<AdminPrivateRoute
					exact
					path='/admin/members/:member_id/purchases'
					component={MemberPurchases}
				/>

				{/* COACH */}
				<AdminPrivateRoute exact path='/admin/coaches' component={CoachList} />
				<AdminPrivateRoute
					exact
					path='/admin/coaches/coach-detail/:coachId'
					component={CoachDetail}
				/>
				<AdminPrivateRoute
					exact
					path='/admin/coaches/coach-detail/:coachId/coach-edit'
					component={CoachEdit}
				/>
				<AdminPrivateRoute
					exact
					path='/admin/coaches/new-coach'
					component={NewCoach}
				/>

				<AdminPrivateRoute
					exact
					path='/admin/super-admins/new-admin'
					component={NewAdmin}
				/>
				<AdminPrivateRoute
					exact
					path='/admin/super-admins/admin-details/:adminId'
					component={AdminEdit}
				/>

				{/* STORE */}
				<AdminPrivateRoute
					exact
					path='/admin/stores/:id/products'
					component={ListOfProducts}
				/>

				<AdminPrivateRoute
					exact
					path='/admin/stores/:id/products/edit/:product_id'
					component={EditProduct}
				/>

				<AdminPrivateRoute
					exact
					path='/admin/stores/:id/products/new'
					component={AddProduct}
				/>

				<AdminPrivateRoute
					exact
					path='/admin/stores'
					component={ListOfCategory}
				/>

				<AdminPrivateRoute
					exact
					path='/admin/settings'
					component={Settings}
				/>
				<AdminPrivateRoute
					exact
					path='/admin/schools-list'
					component={SchoolsList}
				/>
				<AdminPrivateRoute
					exact
					path='/admin/events-categories-list'
					component={EventCategoriesList}
				/>

				<AdminPrivateRoute
					exact
					path='/admin/super-admins'
					component={AdminsList}
				/>

				<AdminPrivateRoute
					exact
					path='/admin/noticeboard'
					component={NoticeBoard}
				/>
				<AdminPrivateRoute
					exact
					path='/admin/noticeboard/previous'
					component={NoticeBoardPosts}
				/>
				<AdminPrivateRoute
					exact
					path='/admin/noticeboard/this_month'
					component={NoticeBoardPosts}
				/>

				{/* THIS IS FOR THE COACH ROUTES */}

				<AdminPrivateRoute exact path='/coach/events' component={EventsList} />
				<AdminPrivateRoute
					exact
					path='/coach/events/:event_id'
					component={EventsDetail}
				/>
				<AdminPrivateRoute
					exact
					path='/coach/events/:event_id/programs'
					component={EventsPrograms}
				/>
				<AdminPrivateRoute
					exact
					path='/coach/events/:event_id/programs/:program_id'
					component={EventProgramDetail}
				/>
				<Route>
					<Redirect to='/admin/dashboard' />
				</Route>


			</Switch>
		</Fragment>
	);
};





const mapStateToProps = (state) => ({
	user: state.register,
	loading: state.register.loading
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
