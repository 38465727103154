import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import NumberFormat from 'react-number-format';
//REDUX
// import eventActions from '../../../redux/actions/eventsActions';
import coachPortalActions from "../../../../redux/actions/coachPortalActions"
//CSS
import '../../Events/EventList.css';

//Components
import EventListRow from '../../../../Components/Coach Portal/EventsListRow';

//Images
import plus from '../../../../assets/images/click-plus.svg';
import leftArrowImg from '../../../../assets/images/arrow-left-blue.svg';
import rightArrowImg from '../../../../assets/images/right-arrow-blck.svg';

//globals
import { adminHandle } from '../../../../utils/global';
export const EventList = (props) => {
	const [listOfEvents, setListOfEvents] = useState([])
	useEffect(() => {
		props.dispatch(coachPortalActions.listCoaches({ id: props.user.id, page: 1 }))
	}, []);

	useEffect(() => {
		adminHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null)
	}, [props.user])
	const [pages, setPages] = useState([])
	useEffect(() => {
		let finalData = []
		let firstPage = Number(props?.assignedCoachEvents?.first_page_url?.split("page=")[1])
		let lastPage = Number(props?.assignedCoachEvents?.last_page_url?.split("page=")[1])
		for (let i = firstPage; i <= lastPage; i++) {
			finalData.push({ i })
		}
		setPages(finalData)
		// let eventsData=[];
		// if(props?.assignedCoachEvents?.data?.length>0){
		// 	props?.assignedCoachEvents?.data?.map((zone,i)=>{
		// 		if(i===0){
		// 			eventsData.push(zone)
		// 		}else{
		// 			let exist = false;
		// 			eventsData.map((zone2)=>{
		// 				if(zone2.event_id===zone.event_id){
		// 					exist=true;
		// 				}
		// 			})
		// 			if(!exist){
		// 				eventsData.push(zone);
		// 			}
		// 		}
		// 	})
		// setListOfEvents(eventsData);
		// }
	}, [props.assignedCoachEvents])

	const handlePagination = (currentPage) => {
		let p = pages.map((zone, i) => {
			if (currentPage === zone?.i) {
				return (
					<div key={i} style={{ color: "red", marginLeft: 2, marginRight: 2 }}>
						{zone?.i}
					</div>
				)
			} else {
				return (
					<div key={i} style={{ marginLeft: 2, marginRight: 2 }}
						onClick={() => {
							handlePaginationDispatch(zone?.i);
						}}
					>
						{zone?.i}
					</div>
				)
			}
		})

		let first = null
		let last = null
		if (currentPage !== pages[0]?.i) {
			first = <img
				onClick={() => {
					handlePaginationDispatch(currentPage - 1);
				}}
				src={leftArrowImg}
				alt='back'
			/>
		}
		if (currentPage !== pages[pages.length - 1]?.i) {
			last = <img
				onClick={() => {
					handlePaginationDispatch(currentPage + 1);
				}}
				src={rightArrowImg}
				alt='back'
			/>
		}
		let final = <div style={{ display: "flex" }}>
			{first}{(first || last)?p:null}{last}
		</div>
		return (final)
	}

	const handlePaginationDispatch = (e) => {
		props.dispatch(coachPortalActions.listCoaches({ id: props.user.id, page: e }))
	}
	// useEffect(() => {
	// 	props.dispatch(eventActions.getEventCategories());
	// }, []);
	// const [selectedCategory, setSelectedCategory] = useState('');
	// const handleCategoryChange = (e) => {
	// 	if (e !== '') {
	// 		setSelectedCategory(e);
	// 		props.dispatch(eventActions.getEventsByCategoryId({ id: e, page: 1 }));
	// 	}
	// };
	// const handlePagination = (e) => {
	// 	if (selectedCategory !== '') {
	// 		props.dispatch(
	// 			eventActions.getEventsByCategoryId({ id: selectedCategory, page: e })
	// 		);
	// 	}
	// };
	// useEffect(() => {
	// 	assignPages();
	// }, [props?.evenyByCategoryID]);
	// const [pages, setPages] = useState([]);
	// const assignPages = () => {
	// 	let pagess = [];

	// 	let firstPage = Number(
	// 		props?.evenyByCategoryID?.first_page_url?.split('page=')[1]
	// 	);
	// 	let lastPage = Number(
	// 		props?.evenyByCategoryID?.last_page_url?.split('page=')[1]
	// 	);
	// 	for (let i = firstPage; i < lastPage; i++) {
	// 		pagess.push(i);
	// 	}
	// 	setPages(pagess);
	// };

	// const handleArrowClick = (e) => {
	// 	if (
	// 		e === 'ADD' &&
	// 		props?.evenyByCategoryID?.current_page < pages[pages.length - 1]
	// 	) {
	// 		handlePagination(props?.evenyByCategoryID?.current_page + 1);
	// 	} else if (
	// 		e === 'DED' &&
	// 		props?.evenyByCategoryID?.current_page > pages[0]
	// 	) {
	// 		handlePagination(props?.evenyByCategoryID?.current_page - 1);
	// 	}
	// };
	console.log(props.assignedCoachEvents)
	return (
		<section>
			<div className='events_top_div'>
				<div className='bold blue font-36 text-upper float-left'>events</div>
				<div className='events_form_div'>
					{props.eventCategoriesLoading && (
						<div className='text-center mx-auto mt-4'>
							<CircularProgress color='primary' size={30} />
						</div>
					)}
					<div className='dropDown_bar mr-3'>
						{/* <select
							// onChange={(e) => {
							// 	handleCategoryChange(e.target.value);
							// }}
							name=''
							id=''
						>
							<option key={'1Base'} value={''}>
								Select Event Category
							</option>
							{props?.eventCategories?.length > 0
								? props?.eventCategories?.map((zone, i) => {
									return (
										<option key={i} value={zone.id}>
											{zone.name}
										</option>
									);
								})
								: null}
						</select> */}
					</div>
					<div className='seach_bar'>
						<input className='search_button' type='search' />
						<input
							type='text'
							placeholder='Search for Events...'
							className='search_input'
						// onChange={(e) => {
						// 	handleSearch(e);
						// }}
						/>
					</div>
				</div>
			</div>
			<div className='events_list_main_body'>
				<div className='events_list_top'>
					<div className='events_table_header wi-16'>EVENT TITLE</div>
					<div className='events_table_header wi-10'>AGE</div>
					<div className='events_table_header wi-16'>DATE &amp; TIME</div>
					<div className='events_table_header wi-16'>LOCATION</div>
					<div className='events_table_header wi-10'>
						AVAILABLE
						<br />
						SPACE
					</div>
					<div className='events_table_header wi-8'>VACANT</div>
					<div className='events_table_header wi-8'>OCCUPIED</div>
					<div className='events_table_header wi-8'>STATUS</div>
					<div className='events_table_header wi-8'>VIEW</div>
				</div>
				<div className='events_list_container'>
					{props?.assignedCoachEventsLoading === true ? (
						<div className='text-center mx-auto mt-4'>
							<CircularProgress color='primary' size={30} />
						</div>
					) : props?.assignedCoachEvents?.data?.length > 0 ? (
						// add public event condition
						props?.assignedCoachEvents?.data?.map((zone, i) => {
							let data = (
								<EventListRow
									key={i}
									id={zone.event_id}
									title={zone.title}
									subtitle={zone.subtitle}
									start_date={zone.start_date}
									end_date={zone.end_date}
									time_start={zone.start_time}
									time_end={zone.end_time}
									location={zone.location}
									available_space={<NumberFormat value={zone.available_space} displayType={'text'} thousandSeparator={true}/>}
									vacant={<NumberFormat value={zone.vacant_space} displayType={'text'} thousandSeparator={true}/>}
									occupied={<NumberFormat value={zone?.available_space - zone?.vacant_space} displayType={'text'} thousandSeparator={true}/>}
									status={zone.status}
								/>
							);
							return data;
						})
					) : (
						<h2 className='no_item_available'>No events available</h2>
					)}
					{handlePagination(props?.assignedCoachEvents?.current_page)}
				</div>
			</div>
			{/* {pages.length < 2 ? (
				''
			) : (
				<div style={{ display: 'flex' }}>
					<img
						// onClick={() => {
						// 	handleArrowClick('DED');
						// }}
						src={leftArrowImg}
						alt='back'
					/>
					{pages?.map((zone, i) => {
						if (props?.evenyByCategoryID?.current_page === zone) {
							return (
								<div
									style={{
										marginLeft: '4px',
										marginRight: '4px',
										color: 'red',
									}}
									key={i}
								>
									{zone}
								</div>
							);
						} else {
							return (
								<div
									// onClick={() => {
									// 	handlePagination(zone);
									// }}
									style={{ marginLeft: '4px', marginRight: '4px' }}
									key={i}
								>
									{zone}
								</div>
							);
						}
					})}
					<img
						// onClick={() => {
						// 	handleArrowClick('ADD');
						// }}
						src={rightArrowImg}
						alt='add'
					/>
				</div>
			)} */}
		</section>
	);
};

const mapStateToProps = (state) => ({
	user: state.register.user,
	assignedCoachEvents: state.coachPortal.assignedCoachEvents,
	assignedCoachEventsLoading: state.coachPortal.assignedCoachEventsLoading,
});

export default connect(mapStateToProps)(EventList);
