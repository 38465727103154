import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { coachHandle } from '../../../utils/global';
import moment from 'moment';
import NumberFormat from 'react-number-format';

//redux
import eventActions from '../../../redux/actions/eventsActions';

//images
import backArrow from '../../../assets/images/arrow-red-left.svg';
import leftArrowImg from '../../../assets/images/arrow-left-blue.svg';
import rightArrowImg from '../../../assets/images/right-arrow-blck.svg';

//component
import ProgramChildRow from '../../../Components/Events/ProgramChildRow';

function EventProgramDetail(props) {
	const params = useParams();
	useEffect(() => {
		coachHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null);
		props.dispatch(eventActions.getEventRelatedProgramsBooking({ eventId: params.id, programId: params.program_id, page: 1 }))
	}, []);

	const [pages, setPages] = useState([])
	useEffect(() => {
		let finalData = []
		let firstPage = Number(props?.eventRelatedProgramsBooking?.data?.bookin_details?.first_page_url?.split("page=")[1])
		let lastPage = Number(props?.eventRelatedProgramsBooking?.data?.bookin_details?.last_page_url?.split("page=")[1])
		for (let i = firstPage; i <= lastPage; i++) {
			finalData.push({ i })
		}
		setPages(finalData)
	}, [props.eventRelatedProgramsBooking])

	const handlePagination = (currentPage) => {
		let p = pages.map((zone, i) => {
			if (currentPage === zone?.i) {
				return (
					<div key={i} style={{ color: "red", marginLeft: 2, marginRight: 2 }}>
						{zone?.i}
					</div>
				)
			} else {
				return (
					<div key={i} style={{ marginLeft: 2, marginRight: 2 }}
						onClick={() => {
							handlePaginationDispatch(zone?.i);
						}}
					>
						{zone?.i}
					</div>
				)
			}
		})

		let first = null
		let last = null
		if (currentPage !== pages[0]?.i) {
			first = <img
				onClick={() => {
					handlePaginationDispatch(currentPage - 1);
				}}
				src={leftArrowImg}
				alt='back'
			/>
		}
		if (currentPage !== pages[pages.length - 1]?.i) {
			last = <img
				onClick={() => {
					handlePaginationDispatch(currentPage + 1);
				}}
				src={rightArrowImg}
				alt='back'
			/>
		}
		let final = <div style={{ display: "flex" }}>
			{first}{(first || last)?p:null}{last}
		</div>
		return (final)
	}

	const handlePaginationDispatch = (e) => {
		props.dispatch(eventActions.getEventRelatedProgramsBooking({ eventId: params.id, programId: params.program_id, page: e }))
	}

	console.log(props.eventRelatedProgramsBooking)
	return (
		<section>
			<div className='event_detail_top_div'>
				<div className='left_detail_top'>
					<Link to={`/admin/events/${params.id}/program`}>
						<div className='go_back_arrow'>
							<img src={backArrow} alt='Go Back' />
						</div>
					</Link>
					<div className='bold blue font-36 text-upper float-left'>
						{props.eventRelatedProgramsBooking?.data?.event_details?.title}
					</div>
				</div>
			</div>
			<div>
				{props?.eventRelatedProgramsBookingLoading && (
					<div className='text-center mx-auto mt-4'>
						<CircularProgress color='primary' size={30} />
					</div>
				)}
			</div>
			<div className='event_detail_mainbody'>
				<span className='blue bold text-upper font-20'>event DETAILS</span>
				<div className='event_detail_mainrow'>
					<div className='program_half'>
						{/* <div className='event_program_detail_mainrow_col'>
							<span className='bold blue font-16 text-upper mb-2'>
								{props.eventRelatedProgramsBooking?.data?.program?.title}
							</span>
						</div> */}
						<div className='event_program_detail_mainrow_col'>
							<span className='bold blue font-16 text-upper mb-2'>Date</span>
							<span className='blue font-16 text-upper'>
								{props.eventRelatedProgramsBooking?.data?.event_details?.start_date?moment(props.eventRelatedProgramsBooking?.data?.event_details?.start_date).format('DD/MM/YYYY'):''}
							</span>
						</div>
						<div className='event_program_detail_mainrow_col2'>
							<span className='bold blue font-16 text-upper mb-2'>
								EVENT title
							</span>
							<span className='blue font-16 text-upper'>
								{props.eventRelatedProgramsBooking?.data?.event_details?.title}
							</span>
						</div>
						<div className='event_program_detail_mainrow_col'>
							<span className='bold blue font-16 text-upper mb-2'>
								coach name
							</span>
							<span className='blue font-16 text-upper'>
								{props.eventRelatedProgramsBooking?.data?.event_details?.coach_name}
							</span>
						</div>
					</div>
					<div className='program_half2'>
						<div className='event_program_mainrow_col'>
							<span className='bold blue font-16 text-upper mb-2'>
								TOTAL BOOKING
							</span>
							<div className='detail_total_event_sale'>
								{/* <p className='font-16 blue'>{props.eventRelatedProgramsBooking?.data?.total_bookin_details_count}</p> */}
								<p className='font-16 blue'>
									<NumberFormat value={props?.eventRelatedProgramsBooking?.data?.total_bookin_details_count} displayType={'text'} thousandSeparator={true}/>
									{/*props?.eventRelatedProgramsBooking?.data?.total_bookin_details_count*/}
								</p>{/* this is modified as Tony requested*/}
							</div>
						</div>
						<div className='event_program_mainrow_col'>
							<span className='bold blue font-16 text-upper mb-2'>
								Total Check Ins
							</span>
							<div className='detail_total_event_sale'>
								<p className='font-16 blue'>
									<NumberFormat value={props.eventRelatedProgramsBooking?.data?.total_children_check_in} displayType={'text'} thousandSeparator={true}/>
									{/*props.eventRelatedProgramsBooking?.data?.total_children_check_in*/}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='event_detail_purchase_list'>
					<div className='events_detail_list_top'>
						<div className='events_detail_table_header wi-17'>child name</div>
						<div className='events_detail_table_header wi-17'>purchaser</div>
						<div className='events_detail_table_header wi-17'>phone</div>
						<div className='events_detail_table_header wi-17'>dob</div>
						<div className='events_detail_table_header wi-16'>Age</div>
						<div className='events_detail_table_header wi-16'>action</div>
					</div>
					<div className='event_purchaser_container'>
						{props.eventRelatedProgramsBookingLoading ? (
							<div className='text-center mx-auto mt-4'>
								<CircularProgress color='primary' size={30} />
							</div>
						) : ( 
							props.eventRelatedProgramsBooking?.data?.bookin_details?.data?.length > 0 ?
								props.eventRelatedProgramsBooking?.data?.bookin_details?.data?.map((zone, i) => {
									console.log('child roww' ,zone)
									return (
										<ProgramChildRow
											key={i}
											child_name={zone.child_first_name+" "+zone.child_last_name}
											purchaser={zone.purchaser_first_name+" "+zone.purchaser_last_name}
											mobile_number={zone.purchaser_mobile}
											date={zone.dob}
											status={"paid"}
											eventId={params.id}
											programId={params.program_id}
											bookingId={zone.child_id}
											dob={zone.dob}
											has_children_checked_in={zone.has_children_checked_in}
										/>
									)
								}) :
								<p className='font-16 blue'>No Data Available</p>
						)}
						{handlePagination(props?.eventRelatedProgramsBooking?.data?.bookin_details?.current_page)}

					</div>
				</div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => ({
	eventRelatedPrograms: state.eventReducer.eventRelatedPrograms,
	eventRelatedProgramsBooking: state.eventReducer.eventRelatedProgramsBooking,
	eventRelatedProgramsBookingLoading: state.eventReducer.eventRelatedProgramsBookingLoading,
	user: state.register.user
});

export default connect(mapStateToProps)(EventProgramDetail);
