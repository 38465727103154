import React from 'react'
import { connect } from 'react-redux'
import profileActions from '../../../redux/actions/profileActions'

import './schoolStyle.scss'

export const AddSchool = (props) => {
const [school, setSchool] = React.useState({school_name: '',suburb:''})

const handleDataChange = (e,name) => {


    setSchool({
        ...school,
        [name]: e.target.value
    })
}

const createSchool = (e) => {
    e.preventDefault();
    props.dispatch(profileActions.addSchool({ data: school }));
    
}

    return (
        <div>
            <h3 className='blue bold'>Add School</h3>
                <form onSubmit={createSchool} id="add_school_form">
                    <div className='event_edit_input school_div w-50 position-relative blue regular font-16'>
                        <label className='bold text-upper blue font-14'>
                            Scool Name *
                        </label>
                        <input
                            type='text'
                            placeholder='School'
                            name='school_name'
                            className='school_input'
                            value={school.school_name}
                            required
                            onChange={(e) => {
                                handleDataChange(e,'school_name');
                            }}
                        ></input>
                        <label className='bold text-upper blue font-14'>
                            Suburb *
                        </label>
                        <input
                            type='text'
                            placeholder='Suburb'
                            name='suburb'
                            className='school_input'
                            value={school.suburb}
                            required
                            onChange={(e) => {
                                handleDataChange(e,'suburb');
                            }}
                        ></input>
                    </div>
                    <button className='white  bold blue-bg add_school_btn_popup' 
                    type="submit"
                        >
                            Create School
                    </button>
                </form>
        </div>
    )
}

const mapStateToProps = (state) => ({
    
})



export default connect(mapStateToProps)(AddSchool)
