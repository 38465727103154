import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import coachActions from '../../../redux/actions/coachActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { coachHandle } from '../../../utils/global';
import { AsYouType } from "libphonenumber-js";
import moment from 'moment';
import './NewCoach.scss';
import "react-datepicker/dist/react-datepicker.css";
import insert from '../../../assets/images/insert-photo.png';
import backArrow from '../../../assets/images/arrow-red-left.svg';
import checked from '../../../assets/images/CheckBoxChecked.png';
import unchecked from '../../../assets/images/CheckBoxUnchecked.png';
import camera from '../../../assets/images/camera.svg';
import DatePickerComponent from '../../../Components/DatePickerComponent/DatePickerComponent';
import AddressAuto from '../../../Components/Address/AddressAuto';

function NewCoach(props) {
	const [isOpen, setIsOpen] = useState(false)
	const [coachField, setCoachField] = useState({
		first_name: '',
		last_name: '',
		email: '',
		mobile_phone: '',
		address_1: '',
		address_2: '',
		suburb: '',
		state: '',
		postal_code: '',
		date_of_birth: '',
		qualifications: '',
		equipment: '',
		coach_category_id:'',
		is_active: 0,
		profile_picture: '',
		workingWithChildrenDetails: '',
		workingWithChildrenDate: moment().toDate(),
	});

	const [isChecked, setIsChecked] = useState(false);
	const [pictureError, setPictureError] = useState('');
	const [expDateError, setExpDateError] = useState('');
	const [dobError , setDobError] = useState('');
	//data of coach dispatch action men l zone bte5od data payload: data: data
	const handleChange = (data) => (e) => {
		setCoachField({ ...coachField, [data]: e.target.value });
	};

	const handleChecked = () => {
		setIsChecked(!isChecked);
		setCoachField({ ...coachField, ['is_active']: + !isChecked });
	}

	const handleImageChange = (e) => {
		if(e.target.files[0]){
			setPictureError('');
		}
		createImage(e.target.files[0]);
	};
	const createImage = (file) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			setCoachField({
				...coachField,
				profile_picture: e.target.result,
			});
		};
		reader.readAsDataURL(file);
	};

	const createCoach = (e) => {
		e.preventDefault();
		if(!coachField.profile_picture){
			setPictureError('Profile picture is required');
			return
		}

		if(!coachField.workingWithChildrenDate){
			setExpDateError('Expiration date is required');
			return
		}

		if(!coachField.date_of_birth){
			setDobError('Birth date is required');
			return
		}
		
		setPictureError('');
			let coachData = {
				first_name: coachField.first_name,
				last_name: coachField.last_name,
				email: coachField.email,
				mobile_phone: coachField.mobile_phone,
				street_address1: coachField.address_1,
				street_address2: coachField.address_2,
				suburb: coachField.suburb,
				state: coachField.state,
				postal_code: coachField.postal_code,
				date_of_birth: moment(coachField.date_of_birth).format("YYYY-MM-DD"),
				coach_category_id: coachField.coach_category_id,
				qualifications: coachField.qualifications,
				equipment: coachField.equipment,
				is_active: coachField.is_active,
				profile_picture: coachField.profile_picture,
				coach_children_working_description: coachField.workingWithChildrenDetails,
				coach_children_working_date: moment(coachField.workingWithChildrenDate).format("YYYY-MM-DD"),
			};

			setPictureError('');
			setExpDateError('');
			setDobError('')
			props.dispatch(coachActions.newCoach({ data: coachData }));
		
		
	};
	const handleMobileChange = (e) => {
		let value = e.target.value.split(' ').join('');
		let number;
		if (value.length === 6 && value[1] === '3' && value[0] === '1') {
			number = ''
			for (let i = 0; i < value.length; i++) {
				if (i === 2 || i === 4) {
					number += ` ${value[i]}`
				} else {
					number += `${value[i]}`
				}
			}
		} else {
			number = new AsYouType('AU').input(e.target.value)
		}
		
		setCoachField({ ...coachField, mobile_phone: number });
	}

	useEffect(() => {
		coachHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null);

		props.dispatch(coachActions.getCoachCategories());
	}, []);
	return (
		<section>
			<div className='coach_new_top_div'>
				<div className='left_new_top'>
					<Link to='/admin/coaches'>
						<div className='go_back_arrow'>
							<img src={backArrow} alt='Go Back' />
						</div>
					</Link>
					<div className='bold blue font-36 text-upper float-left'>
						add new coach
					</div>
				</div>
			</div>
			<div className='coach_new_body'>
				<div className='coach_pfp_div'>
					<div className='coach_new_pfp'>
						{coachField.profile_picture === '' ? (
							<img src={insert} alt='coach pfp' />
						) : (
							<img src={coachField.profile_picture} alt='coach pfp' />
						)}
					</div>
					<button className='add_photo_btn'>
						<input
							onChange={(e) => {
								handleImageChange(e);
							}}
							type='file'
							id='file'
							name='file'
							className='custom_file_input'
						/>
						<label
							htmlFor='file'
							className='bold blue text-upper add_photo_label'
						>
							<img src={camera} alt='' />
							upload photo
						</label>
					</button>
					<p className="error">{pictureError}</p>
				</div>
				<div className='coach_new_form'>
					<form onSubmit={createCoach}>
					<div className='new_form_row'>
						<label className='bold blue text-upper font-24 pb-4'>Coach Info:</label>
					</div>
					<div className='new_form_row'>
						<div className='coach_new_input_div font-18 '>
							<label className='bold blue text-upper'>First Name*</label>
							<input
								value={coachField.first_name}
								onChange={handleChange('first_name')}
								name='First Name'
								type='text'
								placeholder='First Name'
								className='coach_new_input'
								required
							/>
						</div>
						<div className='coach_new_input_div font-18 '>
							<label className='bold blue text-upper'>Last Name*</label>
							<input
								value={coachField.last_name}
								onChange={handleChange('last_name')}
								name='Last Name'
								type='text'
								placeholder='Family Name'
								className='coach_new_input'
								required
							/>
						</div>
					</div>
					<div className='new_form_row'>
						<div className='coach_new_input_div font-18 '>
							<label className='bold blue text-upper'>Email address*</label>
							<input
								value={coachField.email}
								onChange={handleChange('email')}
								name='email'
								type='email'
								placeholder='example@gmail.com'
								className='coach_new_input'
								required
							/>
						</div>
						<div className='coach_new_input_div font-18 '>
							<label className='bold blue text-upper'>mobile number*</label>
							{/* <InputMask */}
							<input
								value={coachField.mobile_phone}
								onChange={(e) => handleMobileChange(e)}
								name='phone number'
								type='text'
								// maxLength='12'
								placeholder='ex: 9999 999 999'
								className='coach_new_input number_input'
								required
							/>
							{/* </InputMask> */}
						</div>
					</div>
					<div className='new_form_row'>
						<div className='coach_new_input_div font-18 pr-3'>
							<label className='bold blue text-upper'>Date Of Birth*</label>
							<DatePickerComponent
								required
								placeholder="MM/DD/YYYY"
								containerClass="coach_new_date coach_new_input"
								name='date_of_birth'
								selected={coachField.date_of_birth}
								handleDateChange={
									(e) => {
									if(!e){
										setDobError('Birth date is required');
									}
									else{
										setDobError('');
									}
									setCoachField({ ...coachField, date_of_birth: e })
									}}
								open={isOpen}
								setIsOpenTrue={() => setIsOpen(true)}
								setIsOpenFalse={() => setIsOpen(false)}
							/>
							<p className="error">{dobError}</p>
						</div>
					</div>
					<div className='new_form_row'>
						<label className='bold blue text-upper font-24 pb-4 pt-4'>Address Info:</label>
					</div>
					<AddressAuto
						required
						addressInfo={coachField}
						setAddressInfo={setCoachField}
						styling='member_edit'
						inputStyling='coach_edit_input'
					/>
					<div className='new_form_row'>
						<label className='bold blue text-upper font-24 pb-4 pt-4'>Working with children:</label>
					</div>
					<div className='new_form_row'>
						<div className='coach_new_input_div font-18 '>
							<label className='bold blue text-upper'>Level*</label>
							<select
								value={coachField.category}
								onChange={handleChange('coach_category_id')}
								name='category'
								placeholder='Category'
								className='coach_new_input'
								required
							>
								<option selected value=''>Select a level</option>
								{!props.coachCategoriesLoading && props.coachCategories.map((category)=>{
									return(
									<option value={category.id}>{category.coach_category_name}</option>
									)
								})}
							</select>
						</div>
						<div className='coach_new_input_div font-18 '>
							<label className='bold blue text-upper'>Expiration date*</label>
							{/* <div className='coach_new_date coach_new_input' style={{background:`url("${calendarImg}") no-repeat 90% #fff`}}>
								<DatePicker
									name='expirationDate'
									selected={coachField.workingWithChildrenDate}
									dateFormat="yyyy-MM-dd"
									// minDate={moment().toDate()}
									onChange={(e)=>setCoachField({...coachField,workingWithChildrenDate:e})}
									open={isOpen}
									onCalendarOpen={() => setIsOpen(true)}
									onCalendarClose={() => setIsOpen(false)}
								/>

							</div> */}
							<DatePickerComponent
								required
								containerClass="coach_new_date coach_new_input"
								name='expirationDate'
								selected={coachField.workingWithChildrenDate}
								// minDate={moment().toDate()}
								handleDateChange={
									(e) => {
									if(!e){
										setExpDateError('Expiration date is required');
									}
									else{
										setExpDateError('');
									}
									setCoachField({ ...coachField, workingWithChildrenDate: e })
									}}
								open={isOpen}
								setIsOpenTrue={() => setIsOpen(true)}
								setIsOpenFalse={() => setIsOpen(false)}
							/>
							<p className="error">{expDateError}</p>
						</div>
					</div>
					<div className='new_form_row'>
						<div className='coach_new_input_div font-18 '>
							<label className='bold blue text-upper'>Description*</label>
							<textarea
								value={coachField.workingWithChildrenDetails}
								onChange={handleChange('workingWithChildrenDetails')}
								name='workWithChildrenDetails'
								placeholder='Working with children details goes here ...'
								className='coach_new_input'
								rows="5"
								required
							/>
						</div>
						<div className='coach_new_input_div font-18 '>
							<label className='bold blue text-upper'>Qualifications</label>
							<textarea
								value={coachField.qualifications}
								onChange={handleChange('qualifications')}
								name='qualifications'
								placeholder='Qualifications go here ...'
								className='coach_new_input'
								rows="5"
							/>
						</div>
					</div>
					<div className='new_form_row'>
						<div className='coach_new_input_div font-18 pr-3'>
							<label className='bold blue text-upper'>Provided Equipment</label>
							<textarea
								value={coachField.equipment}
								onChange={handleChange('equipment')}
								name='equipment'
								placeholder='Equipments go here ...'
								className='coach_new_input'
								rows="5"
							/>
						</div>
					</div>
					<div className='new_form_row'>
						<div className='coach_new_input_div font-18' style={{display:'flex', flexDirection:'row', gap: '10px'}}>
							<label className='bold blue text-upper'>Is Active?</label>
							<img src={isChecked? checked : unchecked} onClick={handleChecked} style={{width:'18pt', height: '18pt'}}></img>
						</div>
					</div>
					<div className='edit_button_div'>
						<button
							disabled={
								coachField.first_name === '' ||
									coachField.last_name === '' ||
									coachField.email === '' ||
									coachField.mobile_phone === '' ||
									coachField.street === '' ||
									coachField.suburb === '' ||
									coachField.workingWithChildrenDetails === '' ||
									coachField.coach_category_id === '' ||
									coachField.date_of_birth === ''
									? true
									: false
							}
							type="submit"
							className='white bold text-upper new_save_red_button'
						>
							save
						</button>
						<Link
							to='/admin/coaches'
							className='white bold text-upper cancel_blue_button'
						>
							cancel
						</Link>
						{props?.newCoachLoading === true && (
							<div className='text-center mx-auto mt-4'>
								<CircularProgress color='primary' size={30} />
							</div>
						)}
					</div>
					</form>
				</div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => ({
	newCoach: state.coachreducers.newCoach,
	newCoachLoading: state.coachreducers.newCoachLoading,
	coachCategoriesLoading: state.coachreducers.coach_categories_loading,
	coachCategories: state.coachreducers.coach_categories,
	user: state.register.user
});

export default connect(mapStateToProps)(NewCoach);
