import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { coachHandle } from "../../../utils/global";
import NumberFormat from 'react-number-format';

//REDUX
import eventActions from "../../../redux/actions/eventsActions";
import postActions from "../../../redux/actions/postsActions";
//CSS
import "./EventDetail.css";

//Components
import EventPurchaserRow from "../../../Components/Events/EventPurchaserRow";

//Images
import backArrow from "../../../assets/images/arrow-red-left.svg";
import calendar from "../../../assets/images/calendar.svg";
import editPen from "../../../assets/images/edit-pen.svg";
import plane from "../../../assets/images/paper_plane.svg";
import checkedBox from "../../../assets/images/CheckBoxChecked.png";
import uncheckedBox from "../../../assets/images/CheckBoxUnchecked.png";
import moment from "moment";

import { useHistory } from 'react-router';

function EventDetail(props) {
  const [purchaser, setPurchaser] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const params = useParams();
  useEffect(() => {
    coachHandle(
      props.user?.roles?.length > 0 ? props.user?.roles[0]?.name : null
    );
    props.dispatch(eventActions.getEventDetailsById({ id: params.id }));
  }, []);

  useEffect(() => {
    coachHandle(
      props.user?.roles?.length > 0 ? props.user?.roles[0]?.name : null
    );
    props.dispatch(eventActions.getEventBookings({ id: params.id }));
  }, [params.id]);

  useEffect(() => {
    let finalData = [];
    if (props.eventBookings?.data?.length > 0) {
      props.eventBookings?.data?.map((zone, i) => {
        finalData.push({
          first_name: zone?.first_name,
          last_name: zone?.last_name,
          email: zone?.email,
          mobile_phone: zone?.mobile,
          date: zone?.booking_date,
          status: "Paid",
          price: zone?.total_dollar,
          user_id: zone?.parent_id,
          program_title: zone?.program_title,
          checked: false,
          active_kids_voucher: zone.active_kids_voucher ? zone.active_kids_voucher : null,
          coupon_codes: zone.coupon_codes ? (zone.coupon_codes != "\"\"" ? zone.coupon_codes : null) : null,
        });
      });
    }
    setPurchaser(finalData);
  }, [props.eventBookings]);

  const HandlePublish = () => {
    props.dispatch(eventActions.setPublished({ id: params.id }));
  };

  const handleCheckedUsers = (id, status) => {
    let finalData = [];
    purchaser.map((zone) => {
      if (zone.user_id === id) {
        finalData.push({
          first_name: zone?.first_name,
          last_name: zone?.last_name,
          email: zone?.email,
          mobile_phone: zone?.mobile_phone,
          date: zone?.date,
          status: zone.status,
          price: zone?.price,
          user_id: zone?.user_id,
          program_title: zone?.program_title,
          checked: !status,
        });
      } else {
        finalData.push({
          first_name: zone?.first_name,
          last_name: zone?.last_name,
          email: zone?.email,
          mobile_phone: zone?.mobile_phone,
          date: zone?.date,
          status: zone.status,
          price: zone?.price,
          user_id: zone?.user_id,
          program_title: zone?.program_title,
          checked: zone.checked,
        });
      }
    });
    setPurchaser(finalData);
  };

  const handleSendEmail = () => {
    let members = [];
    purchaser.map((zone) => {
      if (zone.checked === true) {
        members.push(zone);
      }
    });
    props.dispatch(
      postActions.popupHandler({
        type: "CUSTOM_EMAIL_EVENT",
        data: members,
        event_id: params.id,
      })
    );
  };

  const handleSelectAll = (type) => {
    let finalData = [];
    purchaser.map((zone) => {
      if (type === "checked") {
        finalData.push({
          first_name: zone?.first_name,
          last_name: zone?.last_name,
          email: zone?.email,
          mobile_phone: zone?.mobile_phone,
          date: zone?.date,
          status: zone.status,
          price: zone?.price,
          user_id: zone?.user_id,
          checked: true,
        });
      } else {
        finalData.push({
          first_name: zone?.first_name,
          last_name: zone?.last_name,
          email: zone?.email,
          mobile_phone: zone?.mobile_phone,
          date: zone?.date,
          status: zone.status,
          price: zone?.price,
          user_id: zone?.user_id,
          checked: false,
        });
      }
    });
    if (type === "checked") {
      setPurchaser(finalData);
      setSelectAll(true);
    } else {
      setPurchaser(finalData);
      setSelectAll(false);
    }
  };

  const history = useHistory();

  return (
    <section>
      <div className="event_detail_top_div">
        <div className="left_detail_top">
          <div className="go_back_arrow" onClick={() => history.goBack()}>
            <img src={backArrow} alt="Go Back" />
          </div>
          <div className="bold blue font-36 text-upper float-left">
            {props?.eventDetails?.status !== "public"
              ? `${props?.eventDetails?.title} - ${props?.eventDetails?.status}`
              : `${props?.eventDetails?.title}`}
          </div>
        </div>
        <div>
          {props?.eventDetailsLoading && (
            <div className="text-center mx-auto mt-4">
              <CircularProgress color="primary" size={30} />
            </div>
          )}
        </div>
      </div>
      <div className="event_detail_mainbody">
        <div className="d-flex flex-row w-100 justify-content-between">
          <span className="blue bold text-upper font-20">event DETAILS</span>
          <Link
            to={`/admin/events/${params.id}/edit`}
            className="edit_event_detail bold"
          >
            <img src={editPen} alt="" />
            <div className="link_text_div">
              <span>EDIT</span>
            </div>
          </Link>
        </div>
        <div className="event_detail_mainrow">
          <div className="event_detail_mainrow_col">
            <span className="bold blue font-16 text-upper mb-2">
              EVENT VENUE
            </span>
            <span className="blue font-16 text-upper">
              {props?.eventDetails?.location}
            </span>
          </div>
          <div className="event_detail_mainrow_col">
            <span className="bold blue font-16 text-upper mb-2">
              EVENT DATE
            </span>
            <span className="blue font-16 text-upper">
              {props?.eventDetails?.start_date
                ? moment(props.eventDetails.start_date).format("DD/MM/YYYY")
                : ""}
            </span>
            <span className="blue font-16 text-upper">
              {props?.eventDetails?.end_date
                ? moment(props.eventDetails.end_date).format("DD/MM/YYYY")
                : ""}
            </span>
          </div>
          <div className="event_detail_mainrow_col">
            <span className="bold blue font-16 text-upper mb-2">BOOKING</span>
            <span className="blue font-16 text-upper">
              {/* {props?.eventBookings?.total_booking} OUT OF {props?.eventDetails.available_space} */}
              <NumberFormat value={props?.eventBookings?.total_booking} displayType={'text'} thousandSeparator={true}/>
              {/*props?.eventBookings?.total_booking*/}
              {/* need to add all booking */}
              {/* {props?.eventDetails?.} */}
            </span>
          </div>
          <div className="event_detail_mainrow_col">
            <span className="bold blue font-16 text-upper mb-2">
              TOTAL EVENT SALES
            </span>
            <div className="detail_total_event_sale">
              {/* no sale in api */}
              <NumberFormat value={Number(props?.totalSales).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            </div>
          </div>
          <div className="button_main_row_col">
            {props?.eventDetails?.status !== "public" ? (
              props?.eventDetails?.status === "draft" ? (
                <div>
                  <div
                    onClick={() => {
                      HandlePublish();
                    }}
                    className="publish_Event_draft_btn"
                  >
                    <div>
                      <img src={calendar} alt="cal" />
                    </div>
                    <div className="bold blue font-16 publish_button_detail_div">
                      PUBLISH EVENT
                    </div>
                  </div>
                  {props.PublishEventLoading === true ? (
                    <div className="text-center mx-auto mt-4">
                      <CircularProgress color="primary" size={30} />
                    </div>
                  ) : null}
                </div>
              ) : null
            ) : (
              <div className="published_event_btn_div">
                <div className="upper_btn_div">
                  <div
                    onClick={() => handleSendEmail()}
                    className="email_all_btn_detail"
                  >
                    <img src={plane} alt="" />
                    <div>
                      <span className="bold white text-upper">
                        EMAIL SELECTED
                      </span>
                    </div>
                  </div>
                </div>
                <div className="lower_btn_div">
                  {props.SendMailLoading === true ? (
                    <div className="text-center mx-auto mt-4">
                      <CircularProgress color="primary" size={30} />
                    </div>
                  ) : null}
                  <Link
                    to={`/admin/events/${props?.eventDetails?.id}/program`}
                    className="view_programs_btn"
                  >
                    <div className="bold white text-upper">VIEW SESSIONS</div>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
        {props?.eventDetails?.status === "public" ? (
          <div className="event_detail_purchase_list">
            <div className="events_detail_list_top">
              <div
                className="events_detail_table_header wi-8"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  onClick={() => {
                    handleSelectAll(
                      selectAll === true ? "unchecked" : "checked"
                    );
                  }}
                  src={!selectAll ? uncheckedBox : checkedBox}
                  alt="check"
                />
              </div>
              <div className="events_detail_table_header wi-18" style={{ display: "flex", justifyContent: "center" }}>Purchaser</div>
              {/* <div className="events_detail_table_header wi-18">email</div> */}
              <div className="events_detail_table_header wi-13" style={{ display: "flex", justifyContent: "center" }}>phone</div>
              <div className="events_detail_table_header wi-20" style={{ display: "flex", justifyContent: "center" }}>Programs</div>
              <div className="events_detail_table_header wi-13" style={{ display: "flex", justifyContent: "center" }}>booking date</div>
              <div className="events_detail_table_header wi-13" style={{ display: "flex", justifyContent: "center" }}>voucher type</div>
              <div className="events_detail_table_header wi-13" style={{ display: "flex", justifyContent: "center" }}>value</div>
              <div className="events_detail_table_header wi-13" style={{ display: "flex", justifyContent: "center" }}>voucher code</div>
              <div className="events_detail_table_header wi-20" style={{ display: "flex", justifyContent: "center" }}>AKV</div>
              <div className="events_detail_table_header wi-10" style={{ display: "flex", justifyContent: "center" }}>total</div>
            </div>
            <div className="event_purchaser_container">
              {purchaser.length > 0
                ? purchaser.map((zone, i) => {
                    return (
                      <EventPurchaserRow
                        key={i}
                        first_name={zone.first_name}
                        last_name={zone.last_name}
                        email={zone.email}
                        mobile_phone={zone?.mobile_phone}
                        date={zone?.date}
                        // status={zone.status}
                        price={<NumberFormat value={Number(zone?.price).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}
                        userId={zone.user_id}
                        checked={zone.checked}
                        handleCheckedUsers={handleCheckedUsers}
                        program_title={zone?.program_title}
                        active_kids_voucher={zone?.active_kids_voucher}
                        gift_voucher={zone?.coupon_codes? (JSON.parse(zone?.coupon_codes).is_birhtday_special? null : zone?.coupon_codes) : null}
                        birthday_voucher={zone?.coupon_codes? (JSON.parse(zone?.coupon_codes).is_birhtday_special? zone?.coupon_codes : null) : null}
                      />
                    );
                  })
                : null}
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  eventDetails: state.eventReducer.eventDetails,
  eventDetailsLoading: state.eventReducer.eventDetailsLoading,
  totalSales: state.eventReducer.totalSales,
  eventBookings: state.eventReducer.eventBookings,
  eventBookingsLoading: state.eventReducer.eventBookingsLoading,

  PublishEventLoading: state.eventReducer.PublishEventLoading,
  SendMailLoading: state.eventReducer.SendMailLoading,
  user: state.register.user,
});

export default connect(mapStateToProps)(EventDetail);
