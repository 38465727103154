import {
    ADD_CAROUSEL_ITEM_REQUEST,
    ADD_CAROUSEL_ITEM_SUCCESS,
    ADD_CAROUSEL_ITEM_ERROR,
    EDIT_CAROUSEL_ITEM_REQUEST,
    EDIT_CAROUSEL_ITEM_SUCCESS,
    EDIT_CAROUSEL_ITEM_ERROR,
    DELETE_CAROUSEL_ITEM_REQUEST,
    DELETE_CAROUSEL_ITEM_SUCCESS,
    DELETE_CAROUSEL_ITEM_ERROR,
    GET_CAROUSEL_ITEM_REQUEST,
    GET_CAROUSEL_ITEM_SUCCESS,
    GET_CAROUSEL_ITEM_ERROR,

    MASTER_SEARCH_REQUEST,
    MASTER_SEARCH_SUCCESS,
    MASTER_SEARCH_ERROR,
    CLEAR_MASTER_SEARCH,
} from '../actions/homeActions';



const initialState = {
	getCarouselItemsData:[],
    getCarouselItemsLoading:false,

    addCarouselItemLoading:false,
    
    editCarouselItemLoading:false,

    deleteCarouselItemLoading:false,

    masterSearch:[],
    masterSearchLoading:false,
};

function homeReducer(state = initialState, action) {
	switch (action.type) {
        case CLEAR_MASTER_SEARCH:
            return Object.assign({},state,{
                masterSearch:[],
                masterSearchLoading:false,
            });
        case MASTER_SEARCH_REQUEST:
            return Object.assign({}, state, {
                masterSearch:[],
                masterSearchLoading:true,
            });
        case MASTER_SEARCH_SUCCESS:
            console.log("hello", action.payload.data)
            return Object.assign({}, state, {
                masterSearch:action.payload.data,
                masterSearchLoading:false,
            });
        case MASTER_SEARCH_ERROR:
            return Object.assign({}, state, {
                masterSearch:[],
                masterSearchLoading:false,
            });


		case GET_CAROUSEL_ITEM_REQUEST:
			return Object.assign({}, state, {
				getCarouselItemsLoading: true,
				getCarouselItemsData: [],
			});
		case GET_CAROUSEL_ITEM_SUCCESS:
			return Object.assign({}, state, {
				getCarouselItemsLoading: false,
				getCarouselItemsData: action.payload.data,
			});
		case GET_CAROUSEL_ITEM_ERROR:
			return Object.assign({}, state, {
				getCarouselItemsLoading: false,
				getCarouselItemsData: [],
			});
		


        case ADD_CAROUSEL_ITEM_REQUEST:
            return Object.assign({}, state, {
                addCarouselItemLoading: true,
            });
        case ADD_CAROUSEL_ITEM_SUCCESS:
            return Object.assign({}, state, {
                addCarouselItemLoading: false,
            });
        case ADD_CAROUSEL_ITEM_ERROR:
            return Object.assign({}, state, {
                addCarouselItemLoading: false,
            });    
		


        case EDIT_CAROUSEL_ITEM_REQUEST:
            return Object.assign({}, state, {
                editCarouselItemLoading: true,
            });
        case EDIT_CAROUSEL_ITEM_SUCCESS:
            return Object.assign({}, state, {
                editCarouselItemLoading: false,
            });
        case EDIT_CAROUSEL_ITEM_ERROR:
            return Object.assign({}, state, {
                editCarouselItemLoading: false,
            });    
		


        case DELETE_CAROUSEL_ITEM_REQUEST:
            return Object.assign({}, state, {
                deleteCarouselItemLoading: true,
            });
        case DELETE_CAROUSEL_ITEM_SUCCESS:
            return Object.assign({}, state, {
                deleteCarouselItemLoading: false,
            });
        case DELETE_CAROUSEL_ITEM_ERROR:
            return Object.assign({}, state, {
                deleteCarouselItemLoading: false,
            });    


		default:
			return state;
	}
}

export default homeReducer;
