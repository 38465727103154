import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { coachHandle } from '../../../utils/global';

//REDUX
import coachActions from '../../../redux/actions/coachActions';
import eventActions from '../../../redux/actions/eventsActions';
import postsActions from '../../../redux/actions/postsActions'
import { baseURL } from '../../../config/config';

import './CoachDetail.scss';

import backArrow from '../../../assets/images/arrow-red-left.svg';
import editPen from '../../../assets/images/edit-pen.svg';
import deleteIcon from '../../../assets/images/x-white.svg';
import selectArrow from '../../../assets/images/arrow_red_select.svg';
//radio
import radioCheckedLogo from '../../../assets/images/Checked.png';
import radioUncheckedLogo from '../../../assets/images/Unchecked.png';
//checkbox
import checkBoxChecked from '../../../assets/images/CheckBoxChecked.png';
import checkBoxUnchecked from '../../../assets/images/CheckBoxUnchecked.png';

// this are the detail images
import phone from '../../../assets/images/phone.svg';
import email from '../../../assets/images/mail.svg';
import address from '../../../assets/images/pin-location.svg';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';

import { useHistory } from 'react-router';

function CoachDetail(props) {
	const params = useParams();
	const [eventCategoryCheck, setEventCategoryCheck] = useState('');
	const [eventEventCheck, setEventEventCheck] = useState('');
	const [programs, setPrograms] = useState([]);
	// const [selectedPrograms, setSelectedPrograms] = useState([]);

	const handleEventCategorySelection = (e) => {
		if (e !== '') {
			setEventCategoryCheck(e);
			props.dispatch(coachActions.getCoachEventsByID({ id: e }));
		}
	};

	const handleEventSelection = (e) => {
		if (e !== '') {
			setEventEventCheck(e);
		}
	};

	useEffect(() => {
		coachHandle(
			props.user?.roles?.length > 0 ?
				props.user?.roles[0]?.name : null
		);
		props.dispatch(coachActions.getCoachProfileSuper(params.coachId));
	}, []);

	useEffect(() => {
		props.dispatch(eventActions.getEventCategories());
		props.dispatch(coachActions.clearCoachEventsById());
	}, []);

	useEffect(() => {
		if (props.coachDetails?.id)
			props.dispatch(coachActions.getCoachAssignedEvents(props.coachDetails?.id));
	}, [props.coachDetails])

	useEffect(() => {
		props.dispatch(eventActions.getEventDetailsById({ id: eventEventCheck }));
	}, [eventEventCheck])

	const handleCheckedProgram = (id) => {
		let finalData = []
		programs.map((zone) => {
			if (zone.id === id) {
				// finalData.push({
				// 	title: zone.title,
				// 	id: zone.id,
				// 	selected: true,
				// 	assigned_date: zone.assigned_date
				// })
				finalData.push({
					id: zone.id,
					start_date: zone.start_date,
					end_date: zone.end_date,
					selected: true,
				})
			} else {
				// finalData.push({
				// 	title: zone.title,
				// 	id: zone.id,
				// 	selected: zone.selected,
				// 	assigned_date: zone.assigned_date
				// })
				finalData.push({
					id: zone.id,
					start_date: zone.start_date,
					end_date: zone.end_date,
					selected: zone.selected,
				})
			}
		})
		setPrograms(finalData)
	}
	const handleUncheckedProgram = (id) => {
		let finalData = []
		programs.map((zone) => {
			if (zone.id === id) {
				// finalData.push({
				// 	title: zone.title,
				// 	id: zone.id,
				// 	selected: false,
				// 	assigned_date: zone.assigned_date
				// })
				finalData.push({
					id: zone.id,
					start_date: zone.start_date,
					end_date: zone.end_date,
					selected: false,
				})
			} else {
				// finalData.push({
				// 	title: zone.title,
				// 	id: zone.id,
				// 	selected: zone.selected,
				// 	assigned_date: zone.assigned_date
				// })
				finalData.push({
					id: zone.id,
					start_date: zone.start_date,
					end_date: zone.end_date,
					selected: zone.selected,
				})
			}
		})
		setPrograms(finalData)
	}
	useEffect(() => {
		let finalData = []
		props.eventDetails?.recurences?.map((zone) => {
			// finalData.push({
			// 	title: zone.title,
			// 	id: zone.id,
			// 	selected: false,
			// 	assigned_date: props.eventDetails?.start_date,
			// })
			finalData.push({
				id: zone.id,
				start_date: zone.start_date,
				end_date: zone.end_date,
				selected: false,
			})
		})
		setPrograms(finalData)
	}, [props.eventDetails])

	const handleSubmit = () => {
		let events = []
		programs.map((zone) => {
			if (zone.selected) {
				events.push({
					event_id: eventEventCheck,
					event_recurrence_id: zone.id,
					assigned_date: zone.start_date
				})
			}
		})
		let finalData = {
			events: events,
			event_category_id: eventCategoryCheck
		}
		// console.log(finalData)
		props.dispatch(coachActions.assignCoach({ data: finalData, coachId: props.coachDetails.id }))
	}

	const history = useHistory();

	return (
		<section>
			<div className='coach_detail_top_div'>
				<div className='left_detail_top'>
					<div className='go_back_arrow'  onClick={() => history.goBack()}>
						<img src={backArrow} alt='Go Back' />
					</div>
					<div className='bold blue font-36 text-upper float-left'>
						{props?.coachDetails?.first_name}
					</div>
				</div>
				<div>
					{props?.coachDetailsLoading && (
						<div className='text-center mx-auto mt-4'>
							<CircularProgress color='primary' size={30} />
						</div>
					)}
				</div>
				<div className='left_detail_top'>
					<Link
						to={`/admin/coaches/coach-detail/${props?.coachDetails?.uuid}/coach-edit`}
						className='edit_coach bold'
					>
						<img src={editPen} alt='' />
						<div className='link_text_div'>
							<span>EDIT</span>
						</div>
					</Link>
					<div
						onClick={()=>{
							props.dispatch(postsActions.popupHandler({ type: 'DELETE_COACH', data: props.coachDetails }))
						}}
						className='delete_coach bold'
					>
						<img src={deleteIcon} alt='' />
						<div className='link_text_div_delete'>
							<span>DELETE</span>
						</div>
					</div>
				</div>
			</div>
			<div className='coach_detail_body'>
				<Row>
					<Col md={2}>
						<div className='main_coach_info mt-4'>
							<div className='coach_pfp_resize'>
								<img
									src={baseURL + '/image/' + props?.coachDetails?.profile_picture}
									alt=''
								/>
							</div>
						</div>
					</Col>
					<Col md={5}>
						<div className='mt-3'>
							<span className='bold blue font-20'>Coach Info:</span>
							<div className=' align-items-center'>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue address_container '>
										<div>First Name:</div>
									</div>
									<div className='address_container ml-2 row'>
										{props?.coachDetails?.first_name}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue address_container '>
										Last Name:
									</div>
									<div className='ml-2'>
										{props?.coachDetails?.last_name}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue address_container '>
										Mobile Number:
									</div>
									<div className='ml-2'>
										{props?.coachDetails?.mobile_phone}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue address_container '>

										Email:
									</div>
									<div className='ml-2'>
										{props?.coachDetails?.email}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
										<div className='bold blue address_container '>
											<div>Date of Birth:</div>
										</div>
										<div className='address_container ml-2 row'>
											{props?.coachDetails?.date_of_birth ? moment(props?.coachDetails?.date_of_birth).format("DD/MM/YYYY") : "N/A"}
										</div>
									</div>
								{/* <div className='mr-4 d-flex align-items-center'>
										<div className='bold blue address_container '>
											<div>Address:</div>
										</div> 
										<div className='address_container ml-2 row'>
											{props?.coachDetails?.street}, {props?.coachDetails?.suburb}
										</div>
									</div> */}
							</div>

						</div>
					</Col>
					<Col md={5}>
						<div className='mt-3'>
							<span className='bold blue font-20'>Address Info:</span>
							<div className=' align-items-center'>
								<div className='mr-4 d-flex'>
									<div className='bold blue address_container '>
										Street Address:
									</div>
									<div className='ml-2 row'>
										{props?.coachDetails?.street_address1}
									</div>
								</div>
								<div className='mr-4 d-flex'>
									<div className='bold blue address_container '>
										Unit / Apartment no.:
									</div>
									<div className='ml-2'>
										{props?.coachDetails?.street_address2}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue address_container '>
										Suburb:
									</div>
									<div className='ml-2'>
										{props?.coachDetails?.suburb}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue address_container '>
										State:
									</div>
									<div className='ml-2'>
										{props?.coachDetails?.state}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue address_container '>
										Postal Code:
									</div>
									<div className='ml-2'>
										{props?.coachDetails?.postal_code}
									</div>
								</div>
							</div>

						</div>
					</Col>
					</Row>
					<Row>
					<Col md={12}>
						<div>
							<div className='coach_phone_num mt-3'>
								<span className='bold blue font-20'>
									Working With Children:
								</span>
								<div className=' align-items-center'>
									<div className='mr-4 d-flex'>
										<div className='bold blue address_container '>
											<div>Level:</div>
										</div>
										<div className='ml-2 row'>
											{props?.coachDetails?.coachcategory!==null ? props?.coachDetails?.coachcategory?.coach_category_name : "N/A"}
										</div>
									</div>
									<div className='mr-4 d-flex align-items-center'>
										<div className='bold blue address_container '>
											<div>Expiration Date:</div>
										</div>
										<div className='address_container ml-2 row'>
											{props?.coachDetails?.coach_children_working_date ? moment(props?.coachDetails?.coach_children_working_date).format("DD/MM/YYYY") : "N/A"}
										</div>
									</div>
									<div className='mr-4 d-flex'>
										<div className='bold blue address_container '>
											<div>Description:</div>
										</div>
										<div className='ml-2 row'>
											{props?.coachDetails?.coach_children_working_description ? props?.coachDetails?.coach_children_working_description : "N/A"}
										</div>
									</div>
									<div className='mr-4 d-flex'>
										<div className='bold blue address_container '>
											<div>Qualifications:</div>
										</div>
										<div className='ml-2 row'>
											{props?.coachDetails?.qualifications ? props?.coachDetails?.qualifications : "N/A"}
										</div>
									</div>
									<div className='mr-4 d-flex'>
										<div className='bold blue address_container '>
											<div>Equipment:</div>
										</div>
										<div className='ml-2 row'>
											{props?.coachDetails?.equipment ? props?.coachDetails?.equipment : "N/A"}
										</div>
									</div>
									<div className='mr-4 d-flex'>
										<div className='bold blue address_container '>
											<div>Active:</div>
										</div>
										<div className='ml-2 row'>
											{props?.coachDetails?.is_active === 1 ? "Yes" : "No"}
										</div>
									</div>
								</div>
							</div>
						</div>
					</Col>

				</Row>

				<div className='assigned_event_container'>

					<div className='bold blue font-24 text-upper float-left assigned_event_container_title'>
						ASSIGN EVENTS
					</div>
					<div className='coach_event_container'>
						{/* event category */}
						<div className='coach_events_sliders'>
							<div className='event_slider_title'>
								<span className='blue bold'>Event Category</span>
								<img src={selectArrow} alt='' />
							</div>
							<div className='coach_event_main_items'>
								<div className='coach_event_choice'>
									{props?.eventCategories?.length > 0
										? props?.eventCategories?.map((zone, i) => {
											return (
												<div
													className='radio_btn'
													key={i}
													onClick={() =>
														handleEventCategorySelection(zone.id)
													}
												>
													<img
														src={
															eventCategoryCheck === zone.id
																? radioCheckedLogo
																: radioUncheckedLogo
														}
														alt=''
													/>
													<label className='blue'>{zone.name}</label>
												</div>
											);
										})
										: null}
								</div>
							</div>
						</div>
						{/* Events */}
						<div className='coach_events_sliders'>
							<div className='event_slider_title'>
								<span className='blue bold'>Events</span>
								<img src={selectArrow} alt='' />
							</div>
							<div className='coach_event_main_items'>
								{props?.coachEventById === true ? (
									<div>Loading</div>
								) : (
									props?.coachEventById?.map((zone, i) => {
										return (
											<div className='coach_event_choice' key={i}>
												<div
													className='radio_btn'
													onClick={() => handleEventSelection(zone.id)}
												>
													<img
														src={
															eventEventCheck === zone.id
																? radioCheckedLogo
																: radioUncheckedLogo
														}
														alt=''
													/>
													<label className=' blue'>{zone.title}</label>
												</div>
											</div>
										);
									})
								)}
							</div>
						</div>
						{/* Programs */}
						<div className='coach_events_sliders'>
							<div className='event_slider_title'>
								<span className='blue bold'>Sessions</span>
								<img src={selectArrow} alt='' />
							</div>
							<div className='coach_event_main_items'>
								<div className='coach_event_choice'>
									{programs.length > 0 ?
										programs.map((zone, i) => {
											if (zone.selected === true) {
												return (
													<div onClick={() => { handleUncheckedProgram(zone.id) }} className='radio_btn' key={i}>
														<img src={checkBoxChecked} alt='' />
														<label className=' blue'>{zone.start_date === zone.end_date ? moment(zone.start_date).format("DD/MM/YYYY") : `${moment(zone.start_date).format("DD/MM/YYYY")} to ${moment(zone.end_date).format("DD/MM/YYYY")}`}</label>
													</div>)
											} else {
												return (
													<div onClick={() => { handleCheckedProgram(zone.id) }} className='radio_btn' key={i}>
														<img src={checkBoxUnchecked} alt='' />
														<label className=' blue'>{zone.start_date === zone.end_date ? moment(zone.start_date).format("DD/MM/YYYY") : `${moment(zone.start_date).format("DD/MM/YYYY")} to ${moment(zone.end_date).format("DD/MM/YYYY")}`}</label>
													</div>)
											}

										})
										: eventEventCheck ? !props?.eventDetailsLoading && 'No occurrances are set for this event.' : null
									}
									{/* {props.eventDetails?.programs?.length > 0 ?
										props.eventDetails?.programs?.map((zone, i) => {
											console.log(selectedPrograms.find(element => element.id == zone.id))
											

											// console.log(zone)
											// return (
											// 	<div className='radio_btn' key={i}>
											// 		<img src={checkBoxChecked} alt='' />
											// 		<label className=' blue'>{zone.title}</label>
											// 	</div>
											// )
										}) : null} */}

								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='give_access_div'>
					<button onClick={() => { handleSubmit() }} className='access_red_button text-upper white bold'>
						give access
					</button>
					{/* when the programs is done */}
					{/* {props?.popupStateLoading === true && (
						<div className='text-center mx-auto mt-4'>
							<CircularProgress color='primary' size={30} />
						</div>
					)} */}
				</div>
				<div className="my-5">
					<div className='bold blue font-24 text-upper my-3'>ASSIGNED EVENTS</div>
					<Row noGutters={true}>
						<Col xs={3} className='p-3 blue-bg white' style={{ border: '1px solid #ebebeb40' }}>EVENT NAME</Col>
						<Col xs={3} className='p-3 blue-bg white' style={{ border: '1px solid #ebebeb40' }}>EVENT DATE</Col>
						<Col xs={3} className='p-3 blue-bg white' style={{ border: '1px solid #ebebeb40' }}>ASSIGNED DATE</Col>
						<Col xs={3} className='p-3 blue-bg white text-center' style={{ border: '1px solid #ebebeb40' }}>ACTION</Col>
					</Row>
					{console.log(props.coachAssignedEvents?.length)}
					{console.log(props.coachAssignedEvents)}
					{props.coachAssignedEventsLoading ? <div>Loading</div> : (props.coachAssignedEvents?.length > 0 ? props.coachAssignedEvents?.map((zone, i) => {
						return <Row noGutters={true} key={`Assignment-${i}`}>
							<Col xs={3} className='p-3 bg-white' style={{ border: '1px solid #ebebeb80' }}>{zone.title}</Col>
							<Col xs={3} className='p-3 bg-white' style={{ border: '1px solid #ebebeb80' }}>
								{`${zone.start_date ? moment(zone.start_date).format("DD/MM/YYYY") : ''}${zone.end_date ? (" to " + moment(zone.end_date).format("DD/MM/YYYY")) : ''}`}
							</Col>
							<Col xs={3} className='p-3 bg-white' style={{ border: '1px solid #ebebeb80' }}>
								{`${zone.recurrence_start_date ? moment(zone.recurrence_start_date).format("DD/MM/YYYY") : ''}${zone.recurrence_end_date ? (" to " + moment(zone.recurrence_end_date).format("DD/MM/YYYY")) : ''}`}
							</Col>
							<Col xs={3} className='p-3 bg-white' style={{ border: '1px solid #ebebeb80' }}>
								<div className="revoke_access_button mx-auto" onClick={() => { props.dispatch(coachActions.removeCoachAssignment({ id: props.coachDetails?.id, assignmentId: zone.assignment_id })) }}>
									Revoke Access
								</div>
							</Col>
						</Row>
					}) : <div>No Assigned Events</div>)}
				</div>
			</div>

		</section>
	);
}

const mapStateToProps = (state) => ({
	coachDetails: state.coachreducers.coachDetails,
	coachDetailsLoading: state.coachreducers.coachDetailsLoading,

	coachAssignedEvents: state.coachreducers.coachAssignedEvents,
	coachAssignedEventsLoading: state.coachreducers.coachAssignedEventsLoading,

	coachEventById: state.coachreducers.coachEventById,
	coachEventByIdLoading: state.coachreducers.coachEventByIdLoading,
	eventCategories: state.eventReducer.eventCategories,
	eventCategoriesLoading: state.eventReducer.eventCategoriesLoading,
	user: state.register.user,
	eventDetails: state.eventReducer.eventDetails,
	eventDetailsLoading: state.eventReducer.eventDetailsLoading,
});
export default connect(mapStateToProps)(CoachDetail);
