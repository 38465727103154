import React, { useState, useEffect } from 'react';
import { getValue } from '../../utils/app';
import AddProductView from './AddProduct.view';
import imageCompression from 'browser-image-compression';
const AddProductComponent = (props) => {
	const [activeSize, setActiveSize] = useState(true);
	const [errors, setErrors] = useState({
		description: '',
		general: '',
		meta: '',
		discount: ''
	});

	const [formData, setFormData] = useState({
		category_id: getValue(props, 'props.propsData.match.params.id'),
		name: '',
		description: '',
		price: '',
		discount: 0,
		is_active: '1',
		single_quantity_in_stock: '',
		sku: '',
		product_meta: [
			{
				size: '',
				quantity_in_stock: '',
			},
		],
		no_size: !activeSize,
		photos: [],
		item_id_in_business_center: '',
		meta_title: '',
		meta_keywords: '',
		meta_description: '',
		is_freight_applicable:1,
	});

	const [relatedProduct, setRelatedProduct] = useState([]);
	const [categoryValue, setCategoryValue] = useState({});
	useEffect(() => {
		setCategoryValueData(getValue(props, 'props.propsData.match.params.id'));
	}, [getValue(props, 'props.propsData.match.params.id') && props.categories]);

	const setCategoryValueData = (id) => {
		let data = {};
		if (props.categories) {
			props.categories.map((category) => {
				if (id.toString() === category.value.toString())
					data = { value: category.value, label: category.label };
			});
			setCategoryValue(data);
		}
	};

	const setRelatedProductValueData = (id) => {
		let data = [];
		if (props.relatedProduct) {
			props.relatedProduct.map((category) => {
				if (id.toString() === category.value.toString())
					data = { value: category.value, label: category.label };
			});
			setRelatedProduct(data);
		}
	};

	const onChangeCategory = (selectedValue) => {
		setFormData({
			...formData,
			category_id: selectedValue.value,
		});
		setCategoryValue(selectedValue);
	};

	const handleOnChangeRelatedProduct = (selectedValue) => {
		setRelatedProduct(selectedValue);
	};

	const createImage = (file, index) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			let photos = formData.photos;
			if (index || index === 0) {
				photos[index] = e.target.result;
			} else {
				photos.push(e.target.result);
			}

			setFormData({
				...formData,
				photos: photos,
			});
		};
		reader.readAsDataURL(file);
	};

	const handleOnChangeImage = (e, index) => {
		compressImage(e.target.files[0], index);
	};

	const options = {
		maxSizeMB: 0.2,
		useWebWorker: true
	};
	const compressImage =  (file, index) =>{
		 imageCompression(file, options).then((compresssedFile)=>{
			createImage(compresssedFile, index)
		})
	}

	const handleOnChange = (e) => {
		if (e.target.name === 'price' && categoryValue.label === 'Gift Vouchers') {
			let productMeta = formData.product_meta;
			setFormData({
				...formData,
				product_meta: productMeta[0]['size'] = e?.target?.value
			})
		}

		if(e.target.name==='description' && !e.target.value){
			setErrors({
				...errors,
				description: 'Description is required'
			})
		}

		if(e.target.name==='description' && e.target.value){
			setErrors({
				...errors,
				description: ''
			})
		}
			setFormData({
				...formData,
				[e.target.name]: e.target.value,
			});
		
	};

	const handleProductMetaChanges = (index, e, type) => {
		let productMeta = formData.product_meta;

		if (formData.category_id === 22) {
			productMeta[index]['size'] = 1
		}
		if (type === 1) {
			productMeta[index][e.type ? e.type : 'size'] = e.value
		} else if (type !== 2) {
			if (e?.target?.name !== 'undefined') {
				productMeta[index][e?.target?.name] = e?.target?.value
			}
		}
		setFormData({
			...formData,
			product_meta: productMeta,
		});
	};

	const AddProductMeta = () => {
		let productMeta = formData.product_meta;
		productMeta.push({
			size: '',
			quantity_in_stock: '',
		});
		setFormData({
			...formData,
			product_meta: productMeta,
		});
	};

	const handleRemoveSingleSize = (index) => {
		let newData = []
		formData.product_meta.map((zone, i) => {
			if (i !== index) newData.push(zone)
		})
		setFormData({ ...formData, product_meta: newData })
	}

	const handleSizeSelection = () => {
		setActiveSize(!activeSize)
		if (activeSize === true) {
			setFormData({
				...formData,
				product_meta: [],
				no_size: activeSize,
				single_quantity_in_stock: '',
			})
		} else {
			setFormData({
				...formData,
				product_meta: [{ size: '', quantity_in_stock: '' }],
				single_quantity_in_stock: null,
				no_size: activeSize,
			})
		}
	}

	const handleSingleQuantity = (e) => {
		setFormData({
			...formData,
			single_quantity_in_stock: e.target.value,
			product_meta: [{ size: 'fixed', quantity_in_stock: e.target.value }],
		})
	}

	const onSubmit = () => {

			if (!formData['description']){
				setErrors({
					...errors,
					description: "This field is required",
				});
				return
			}

			if (!formData.item_id_in_business_center || !formData.name || !formData.price){
				setErrors({
					...errors,
					general: "Please fill all required fields (*)",
				});
				return
			}

			if(!formData.discount){
				formData.discount = 0;
			  }
			
			if(Number(formData.discount)>Number(formData.price)){
				setErrors({
					...errors,
					discount: "Discount should be less than or equal to price",
				});
				return
			}

			if(activeSize){
				if(!formData.product_meta[0]['size'] || !formData.product_meta[0]['quantity_in_stock']){
					setErrors({
						...errors,
						meta: "Size and quantity in stock are required",
					});
					return
				}
			}



			setErrors({
				meta: '',
				discount: '',
				general: '',
				description: '',
			})
			let data = { ...formData };
			data.related_products = relatedProduct
				.map((product) => product.value)
				.join(',');
			props.onCallApi(data);
			
			
		};

	return (
		<AddProductView
			formData={formData}
			errors = {errors}
			categories={props.categories}
			relatedProducts={props.relatedProducts}
			relatedProduct={relatedProduct}
			categoryValue={categoryValue}
			onChangeCategory={onChangeCategory}
			handleOnChange={handleOnChange}
			handleProductMetaChanges={handleProductMetaChanges}
			AddProductMeta={AddProductMeta}
			handleRemoveSingleSize={handleRemoveSingleSize}
			handleOnChangeImage={handleOnChangeImage}
			handleOnChangeRelatedProduct={handleOnChangeRelatedProduct}
			onSubmit={onSubmit}
			activeSize={activeSize}
			handleSizeSelection={handleSizeSelection}
			handleSingleQuantity={handleSingleQuantity}
		/>
	);
};
export default AddProductComponent;
