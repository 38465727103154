import React , {useEffect} from 'react'
import { connect } from 'react-redux'

import './schoolStyle.scss'

import OrderItemRow from '../../Orders/OrderItemRow';
import actions from '../../../redux/actions/ordersActions';

export const OrderItems = (props) => {

    useEffect(()=>{
      props.getOrderItems(props.selectedPost)
    },[props.selectedPost])

    return (
        <div>
        <h3 className='blue bold'>Order Items</h3>
        <div className="coach_list_top">
          <div className="events_table_header wi-25">ITEM</div>
          <div className="events_table_header wi-17">SIZE</div>
          <div className="events_table_header wi-13">QUANTITY</div>
          <div className="events_table_header wi-21">UNIT PRICE</div>
          <div className="events_table_header wi-21">SUBTOTAL</div>
        </div>
        {props?.selectedOrder?.map((zone, i) => {
              return(
                <OrderItemRow
                  key={i}
                  name={zone.name}
                  size={zone.size}
                  quantity={zone.quantity}
                  price={zone.price}
                  total={zone.total}
                />
              );
            })
          }
    </div>
    )
}

const mapStateToProps = (state) => ({
	selectedPost: state.postReducer?.selectedPost,
  selectedOrder: state.order.selectedOrder
})

const mapDispatchToProps = (dispatch) => ({
  getOrderItems : (order_number) => dispatch(actions.orderItems(order_number)),
})
export default connect(mapStateToProps, mapDispatchToProps)(OrderItems)
