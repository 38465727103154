import React from 'react'
import ReactDatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import calendarImg from '../../assets/images/calendar.svg';
import './DatePickerComponent.css'

export const DatePickerComponent = (props) => {
    return (
        
        <div className={`${props.containerClass?props.containerClass:'reactDatePicker_component'}`} style={{background:`url("${calendarImg}") no-repeat 90% #00000000`}}>
            <ReactDatePicker
                readOnly={props.disabled || false}
                placeholderText={props.placeholder || 'DD/MM/YYYY'}
                name={props.name}
                selected={props.selected}
                onChange={props.handleDateChange}
                minDate={props.minDate?props.minDate:''}
                maxDate={props.maxDate?props.maxDate:''}
                dateFormatCalendar={"MMM yyyy"}
                dateFormat="dd/MM/yyyy"
                open={props.isOpen}
                onCalendarOpen={props.setIsOpenTrue}
                onCalendarClose={props.setIsOpenFalse}
                showYearDropdown
                showMonthDropdown
                closeOnScroll={props.closeOnScroll}
                popperPlacement={props.popperPlacement? props.popperPlacement : 'bottom'}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = (dispatch)=>({
    
})

export default connect(mapStateToProps, mapDispatchToProps)(DatePickerComponent)
