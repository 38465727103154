import {
    EDIT_PROFILE_REQUEST,
    EDIT_PROFILE_SUCCESS,
    EDIT_PROFILE_ERROR,
    LOAD_PROFILE_DATA,

    GET_SETTINGS_REQUEST,
    GET_SETTINGS_SUCCESS,
    GET_SETTINGS_ERROR,

    GET_SCHOOLS_REQUEST,
    GET_SCHOOLS_SUCCESS,
    GET_SCHOOLS_ERROR,

    
    GET_SUPER_PROFILE_REQUEST,
    GET_SUPER_PROFILE_SUCCESS,
    GET_SUPER_PROFILE_ERROR,

} from '../actions/profileActions';

const initialState = {
    editProfileLoading: false,
    editProfileData: [],
    settings: [],
    settingsLoading: false,
    schools: [],
    schoolsLoading: false,
    userProfileData: [],
    userProfileDataLoading: false,
};

function profile(state = initialState, action) {
    switch (action.type) {
        case EDIT_PROFILE_REQUEST:
            return Object.assign({}, state, {
                editProfileLoading: true,
                editProfileData: [],
            });
        case EDIT_PROFILE_SUCCESS:
            let userInformation = {
                first_name: action.payload.data.first_name,
                last_name: action.payload.data.last_name,
                uuid: action.payload.data.uuid,
                mobile_phone: action.payload.data.mobile_phone,
                profile_picture: action.payload.data.profile_picture,
                email: action.payload.data.email,
                street: action.payload.data.street,
                suburb: action.payload.data.suburb,
                roles: action.payload.data.roles,
            }
            // localStorage.setItem("UserInfo", JSON.stringify(userInformation))
            return Object.assign({}, state, {
                editProfileLoading: false,
                editProfileData: action.payload.data
            });
        case EDIT_PROFILE_ERROR:
            return Object.assign({}, state, {
                editProfileLoading: false,
            });

        case LOAD_PROFILE_DATA:
            return Object.assign({}, state, {
                editProfileData: action.payload.data
            })

        case GET_SETTINGS_REQUEST:
            return Object.assign({}, state, {
                settings: [],
                settingsLoading: true,
            })

        case GET_SETTINGS_SUCCESS:
            return Object.assign({}, state, {
                settings: action.payload,
                settingsLoading: false,
            })

        case GET_SETTINGS_ERROR:
            return Object.assign({}, state, {
                settingsLoading: false,
            })

        case GET_SCHOOLS_REQUEST:
            return Object.assign({}, state, {
                schoolsLoading: true
            })
        case GET_SCHOOLS_SUCCESS:
            return Object.assign({}, state, {
                schools: action.payload,
                schoolsLoading: false
            })
        case GET_SCHOOLS_ERROR:
            return Object.assign({}, state, {
                schoolsLoading: false
            })
        case GET_SUPER_PROFILE_REQUEST:
            return Object.assign({}, state, {
                userProfileDataLoading: true
            })
        case GET_SUPER_PROFILE_SUCCESS:
            return Object.assign({}, state, {
                userProfileData: action.payload.data,
                userProfileDataLoading: false
            })
        case GET_SUPER_PROFILE_ERROR:
            return Object.assign({}, state, {
                userProfileDataLoading: false
            })

        default:
            return state;
    }
}

export default profile;
