import React, { Fragment, useState } from "react";

import "./defaultLayout.scss";
import Header from "./Header";
// import Sidebar from "./Sidebar";
import CoachSidebar from "./CoachSidebar"

const CoachLayout = (props) => {
    const [isSidebarMenuOpen, setIsSidebarMenuOpen] = useState(false);

    const toggleSidebarMenu = () => {
        setIsSidebarMenuOpen(!isSidebarMenuOpen);
    };

    return (
        <Fragment>
            <div className="wrapper">
                <div className="default-layout">
                    <Header toggleSidebarMenu />
                    <CoachSidebar isSidebarMenuOpen={isSidebarMenuOpen} {...props} />

                    <div className="main-content">{props.children}</div>
                </div>
            </div>
        </Fragment>
    );
};

export default CoachLayout;
