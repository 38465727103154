import React, { useState } from 'react'
import { connect } from 'react-redux'
import homeActions from '../../../redux/actions/homeActions'
import CircularProgress from '@material-ui/core/CircularProgress';
import { baseURL } from '../../../config/config';

//IMAGES
import camera from '../../../assets/images/camera.svg';
import LineRedImg from '../../../assets/images/Line-red.svg';

//CSS
import './popup.css';

import imageCompression from 'browser-image-compression';

export const AddHomeCarouselItem = (props) => {

    const [formData, setFormData] = useState({
        headline:props?.popupState==="EDIT_CAROUSEL_ITEM"?props?.selectedData?.headline : '',
        title:props?.popupState==="EDIT_CAROUSEL_ITEM"?props?.selectedData?.title :'',
        subtitle:props?.popupState==="EDIT_CAROUSEL_ITEM"?props?.selectedData?.subtitle :'',
        buttonText:props?.popupState==="EDIT_CAROUSEL_ITEM"?props?.selectedData?.button_text :'',
        buttonURL:props?.popupState==="EDIT_CAROUSEL_ITEM"?props?.selectedData?.button_url :'',
        bannerImage:'',
        bannerImageExisting:props?.popupState==="EDIT_CAROUSEL_ITEM"?props?.selectedData?.image :'',
    })
    
	const [errorMessage, setErrorMessage] = useState('')
	const [imageError, setImageError] = useState('')
	const [isError,setIsError] = useState({})

    const handleChange = (name)=>(e)=>{
		setFormData({...formData,[name]:e.target.value})
		setIsError({...isError,[name]:false})
		setErrorMessage('')
    }

	const handleOnChangeImage = (e) => {
		if(e.target.files[0]){
			checkImageDimensions(e.target.files[0]);
		}
	};
	const options = {
		maxSizeMB: 0.2,
		useWebWorker: true
	};
	
	const checkImageDimensions = (file) =>{
		var image = new Image();
		let reader = new FileReader();
		reader.onload = (e) => {
			image.src = e.target.result;
			image.onload = ()=>{
				setImageError('');
				setIsError({
					...isError,
					bannerImageExisting:false,
					bannerImage:false,
				})
				compressImage(file)
			}
		};
		reader.readAsDataURL(file);
		
	}
	const compressImage =  (file) =>{
		imageCompression(file, options).then((compresssedFile)=>{
			createImage(compresssedFile)
		})
	}
	const createImage = (file) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			setFormData({
				...formData,
				bannerImageExisting:'',
				bannerImage: e.target.result,
			});
			
		};
		reader.readAsDataURL(file);
	};

    const createItem =()=>{
		let pattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/ig
		let errorCount=0;
		let errorMessage='';
		let data={};
		for(let item in formData){
			if(formData[item] ===''){
				data[item]=true
				errorCount++;
			}
		}
		if(!pattern.test(formData['buttonURL'])){
			data['buttonURL']=true;
			errorCount++;
			errorMessage='URL provided is not valid';
		}
		if(!data.bannerImage&&data.bannerImageExisting){
			data['bannerImageExisting']=false;
			errorCount--;
		}
		if(props?.popupState==="EDIT_CAROUSEL_ITEM"){
			if(!data.bannerImageExisting&&data.bannerImage){
				data['bannerImage']=false;
				errorCount--;
			}
			if(errorCount===0){
				let finalData={
					headline:formData.headline,
					title:formData.title,
					subtitle:formData.subtitle,
					button_text:formData.buttonText,
					button_url:formData.buttonURL,
					type:'homepage',
				}
				formData.bannerImage?finalData.image=formData.bannerImage:finalData.existing_image=formData.bannerImageExisting;
				props.editCarouselItem(finalData,props?.selectedData?.id)
				// console.log(`%cData To Be Sent, EDIT CAROUSEL ITEM WHERE ID=${props?.selectedData?.id}`,"color:blue;text-decoration:underline",finalData)
			}else{
				(errorMessage&&errorCount===1)?setErrorMessage(errorMessage):(errorMessage&&errorCount>1&&formData.buttonURL!=='')?setErrorMessage('All fields are required, URL provided is not valid'):setErrorMessage('All fields are required')
				console.log('%cData To Be Sent',"color:green;text-decoration:underline",formData)
				console.log(`%cError Data, ADD CAROUSEL ITEM`,`color:red;text-decoration:underline`,data)
			}
		}else{
			if(errorCount===0){
				let finalData={
					headline:formData.headline,
					title:formData.title,
					subtitle:formData.subtitle,
					button_text:formData.buttonText,
					button_url:formData.buttonURL,
					image:formData.bannerImage,
					type:'homepage',
				}
				props.addCarouselItem(finalData)
				// console.log('%cData To Be Sent, ADD CAROUSEL ITEM REMOVE WHEN YOU FINISH',"color:green;text-decoration:underline",finalData)
			}else{
				(errorMessage&&errorCount===1)?setErrorMessage(errorMessage):(errorMessage&&errorCount>1&&formData.buttonURL!=='')?setErrorMessage('All fields are required, URL provided is not valid'):setErrorMessage('All fields are required')
			}
		}
		setIsError(data);
    }
    return (
        <div>
            <div style={styles.headerContainer}>
				<img src={LineRedImg} alt='line Red' style={styles.lineRed} />
				<span style={styles.title}>{props?.popupState==="EDIT_CAROUSEL_ITEM"?"EDIT":"ADD"} CAROUSEL ITEM</span>
			</div>
			<label className='labels'>
				Headline
			</label>
			<input
				style={styles.inputTitle}
				placeholder={'Carousel Headline'}
				className={`popup_Input_Carousel ${isError?.headline?'popup_Input_CarouselError':''}`}
				value={formData.headline}
				onChange={handleChange('headline')}
			/>
			<label className='labels'>
				Title
			</label>
			<input
				style={styles.inputTitle}
				placeholder={'Carousel Title'}
				className={`popup_Input_Carousel ${isError?.title?'popup_Input_CarouselError':''}`}
				value={formData.title}
				onChange={handleChange('title')}
			/>
			<label className='labels'>
				Subtitle
			</label>
			<input
				style={styles.inputTitle}
				placeholder={'Carousel Subtitle'}
				className={`popup_Input_Carousel ${isError?.subtitle?'popup_Input_CarouselError':''}`}
				value={formData.subtitle}
				onChange={handleChange('subtitle')}
			/>
			<label className='labels'>
				Button Text
			</label>
			<input
				style={styles.inputTitle}
				placeholder={'Carousel Button Text'}
				className={`popup_Input_Carousel ${isError?.buttonText?'popup_Input_CarouselError':''}`}
				value={formData.buttonText}
				onChange={handleChange('buttonText')}
			/>
			<label className='labels'>
				Button URL
			</label>
			<input
				style={styles.inputTitle}
				placeholder={'Carousel Button URL'}
				className={`popup_Input_Carousel ${isError?.buttonURL?'popup_Input_CarouselError':''}`}
				value={formData.buttonURL}
				onChange={handleChange('buttonURL')}
			/>
			<label className='labels'>
				Image
			</label>
            <div style={styles.mediaContainer}>
				{(formData.bannerImageExisting||formData.bannerImage) ? (
					<div style={styles.photoContainer}>
						<img	
							src={formData.bannerImageExisting?`${baseURL}/image/${formData.bannerImageExisting}`:formData.bannerImage}
							style={{
								width: '225px',
								objectFit: 'contain',
								position: 'relative',
							}}
							alt=''
						/>
						{/* <img
							src={removePhoto}
							alt=''
							style={styles.photoClose}
							onClick={() => {
								setFormData({ ...formData, bannerImage: '', bannerImageExisting: '' });
							}}
						/> */}
					</div>
				) : null}
				<div>
					<button
						className='add_photo_btn'
						style={{ float: 'left', marginTop: '10px' }}
					>
						<input
							onChange={(e) => {
								handleOnChangeImage(e);
							}}
							type='file'
							id='file'
							name='file'
							className='custom_file_input'
						/>
						<label
							htmlFor='file'
							className={`bold ${(isError?.bannerImage||isError?.bannerImageExisting)?'red':'blue'} text-upper add_photo_label`}
							style={{ fontSize: '16px' }}
						>
							<img src={camera} alt='' />
							Add Media
						</label>
					</button>
				</div>
				
                <div className='d-flex justify-content-between red'>
					<button
						className='popup_button'
						onClick={() => {
							createItem();
						}}
					>
						{props?.popupState==="EDIT_CAROUSEL_ITEM"
							?(props?.editCarouselItemLoading?
								<div className='text-center mx-auto mt-4'>
									<CircularProgress color='primary' size={30} />
								</div>
								:"EDIT ITEM")
							:(props?.addCarouselItemLoading?
								<div className='text-center mx-auto mt-4'>
									<CircularProgress color='primary' size={30} />
								</div>
								:"CREATE ITEM")}
						{/* {props?.popupState==="EDIT_CAROUSEL_ITEM"?"EDIT":"CREATE"} ITEM */}
					</button>
					<div className="font-18">
						{errorMessage}
						<br/>
						{imageError}
						</div>
				</div>
            </div>
        </div>
    )
}

const styles = {
	title: {
		color: '#262262',
		fontSize: '36px',
		fontStyle: 'bold',
		marginTop: '5px',
		fontWeight: 'bold',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: '10px'
	},
	lineRed: {
		width: '88px',
	},
	inputTitle: {
		width: '100%',
		fontSize: '14px',
		marginBottom: '5px',
	},
	textArea: {
		width: '100%',
		height: '600px',
		fontSize: '16px',
	},
	textArea2: {
		width: '100%',
		height: '100px',
		fontSize: '16px',
	},
	mediaContainer: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
	},
	addMedia: {
		position: 'absolute',
		left: 5,
		top: 5,
	},
	photoContainer: {
		position: 'relative',
		width: '225px',
	},
	photoClose: {
		position: 'absolute',
		top: 25,
		right: 5,
	},
};

const mapStateToProps = (state) => ({
	popupState:state.postReducer.popupState,
    selectedData: state.postReducer.selectedPost,
	addCarouselItemLoading: state.homeReducer.addCarouselItemLoading,
	editCarouselItemLoading:state.homeReducer.editCarouselItemLoading,
})

const mapDispatchToProps = (dispatch) =>({
	addCarouselItem:(data)=>dispatch(homeActions.addCarouselItem(data)),
	editCarouselItem:(data,id)=>dispatch(homeActions.editCarouselItem(data,id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddHomeCarouselItem)
