import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

//REDUX
import ProfileActions from '../../../redux/actions/profileActions'

//CSS
import './Settings.scss'

export const Setings = (props) => {
    const history = useHistory()
    const [activeVoucher, setActiveVoucher] = useState(false)
    const [buisnessCentralSync, setBuisnessCentralSync] = useState(false)

    useEffect(() => {
        props.dispatch(ProfileActions.getSettings())
        props.dispatch(ProfileActions.loadProfileData(JSON.parse(localStorage.getItem("UserInfo"))))
    }, [])

    const handleChangeSettings = (type) => {

        props.settings.map((zone) => {
            if (zone.type === type) {
                let finalObject = {
                    key: zone.settings_id,
                    value: zone.value === 0 ? 1 : 0,
                    type: zone.type
                }
                props.dispatch(ProfileActions.editSettings(finalObject, props.editProfileData.uuid))
            }
        })

    }

    useEffect(() => {
        if (props.settings.length > 0) {
            props.settings.map((zone) => {
                if (zone.type === 'voucher') {
                    setActiveVoucher(zone.value === 0 ? false : true)
                }
                else if (zone.type === 'sync') {
                    setBuisnessCentralSync(zone.value === 0 ? false : true)
                }
            })
        }
    }, [props.settings])

    console.log(props.settings)
    return (
        <div>

            {/* <h3>Active Kids Voucher </h3>
            <div className="profileCheckBox">
                <input id='sunday' type="checkbox" className="switch" checked={activeVoucher} onClick={() => { handleChangeSettings('voucher') }} />
            </div> */}

            <h3>Business Central Sync</h3>
            <div className="profileCheckBox">
                <input id='sunday' type="checkbox" className="switch" checked={buisnessCentralSync} onClick={() => { handleChangeSettings('sync') }} />
            </div>

            <div className='white bold blue-bg add_school_btn mb-2' onClick={() => history.push('/admin/schools-list')}>
                <span>SCHOOLS LIST</span>
            </div>

            <div className='white bold blue-bg add_school_btn mb-2' onClick={() => history.push('/admin/events-categories-list')}>
                <span>EVENT CATEGORIES LIST</span>
            </div>

            <div className='white bold blue-bg add_school_btn mb-2' onClick={() => history.push('/admin/super-admins')}>
                <span>Super Admins</span>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    settings: state.profileReducer.settings,
    editProfileData: state.profileReducer.editProfileData,
})


export default connect(mapStateToProps)(Setings)
