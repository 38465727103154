import React from 'react';

//CSS
import './BookedRow.css';


const BookedItemRow = (props) => {


	return (
		<div className='coach_row_table' style={{height:'90px'}}>
			<div className='coach_row_row'>
				<div className='booked_row_col wi-25'>{props?.date}</div>
				<div className='booked_row_col wi-17'>{props.booked}</div>
			</div>
		</div>
	);
}

export default BookedItemRow;
