import React from 'react';
import { connect } from 'react-redux';

//redux
import postActions from '../../../redux/actions/postsActions';

//Components
import AddPost from './AddPost';
import DeletePost from './DeletePost';
import EditPost from './EditPost';
import CustomRec from './CustomRec';
import ConfirmCancelEvent from './ConfirmCancelEvent';
import Program from './Program';
import CustomEmailEvent from './CustomEmailEvent';
import ConfirmCloneEvents from './EventClone';
import Info from './Info';

// SCHOOLS
import AddSchool from './AddSchool';
import EditSchool from './EditSchool';
import DeleteSchool from './DeleteSchool';

import AddVoucher from './AddVoucher'

// CAROUSEL
import AddHomeCarouselItem from './AddHomeCarouselItem';
import DeleteHomeCarouselItem from './DeleteHomeCarouselItem';

//EVENTS
import EventCategory from './EventCategory';
import  EditEventCategory  from './EditEventCategory';

//ORDER ITEMS
import OrderItems from './OrderItems';

//COACH
import  DeleteCoach  from './DeleteCoach';

//IMAGES
import ClosePopupImg from '../../../assets/images/close-popup.svg';
import  StoreCategory  from './StoreCategory';
import DeleteMembers from './DeleteMembers';
import DeleteProducts from './DeleteProducts';

//COUPONS
import NewCoupon from './NewCoupon';
import ConfirmDeactivateCoupons from './CouponsDeactivate';

//EVENTS RECURRENCES
import BookedItems from './BookedItems';




export const Add = (props) => {
	if (props.popupState !== 'CLOSED') {
		return (
			<div
				style={
					props.popupState === 'DELETE' || props.popupState === 'DELETE_MEMBERS' || props.popupState === 'DELETE_COACH' || props.popupState === 'DELETE_PRODUCTS' ? styles.r_popup_delete : styles.r_popup
				}
			>
				<div style={(props.popupState === 'ADD' || props.popupState === 'EDIT' ) ? styles.pop_up_news : styles.landiging}>
					<div
						style={
							props.popupState === 'DELETE'
								? styles.pop_up_image_delete
								: styles.pop_up_image && props.popupState === 'CONFIRMDELEVENT'
									? styles.pop_up_image_cancel
									: styles.pop_up_image && (props.popupState === 'DELETE_SCHOOL' || props.popupState === 'DELETE_COACH' || props.popupState==='DELETE_MEMBERS' || props.popupState === 'DELETE_PRODUCTS') ? styles.pop_up_image_school : styles.pop_up_image 
									
						}
					>
						<img
							onClick={() => {
								props.dispatch(postActions.popupHandler({ type: 'CLOSE' }));
							}}
							src={ClosePopupImg}
							alt='close'
							style={styles.closeButton}
						/>
						<div style={styles.content_pops}>
							{props.popupState === 'ADD' ? <AddPost /> : null}
							{props.popupState === 'EDIT' ? <EditPost /> : null}
							{props.popupState === 'DELETE' ? <DeletePost /> : null}
							{props.popupState === 'DELETE_MEMBERS' ? <DeleteMembers /> : null}
							{props.popupState === 'DELETE_PRODUCTS' ? <DeleteProducts /> : null}
							{props.popupState === 'ADD_SCHOOL' ? <AddSchool /> : null}
							{props.popupState === 'ADD_STORE_CATEGORY' ? <StoreCategory /> : null}
							{props.popupState === 'ADD_EVENT_CATEGORY' ? <EventCategory /> : null}
							{props.popupState === 'EDIT_EVENT_CATEGORY' ? <EditEventCategory /> : null}
							{props.popupState === 'EDIT_SCHOOL' ? <EditSchool /> : null}
							{props.popupState === 'VIEW_ORDER_ITEMS' ? <OrderItems /> : null}
							{props.popupState === 'DELETE_COACH' ? <DeleteCoach /> : null}
							{props.popupState === 'DELETE_SCHOOL' ? <DeleteSchool /> : null}
							{props.popupState === 'CUSTOMREC' ? (
								<CustomRec type={'ADD'} />
							) : null}
							{props.popupState === 'ADDPROG' ? <Program type={'ADD'} /> : null}
							{props.popupState === 'VOUCHER' ? <AddVoucher /> : null}
							{props.popupState === 'CUSTOMREC_EDIT' ? (
								<CustomRec type={'EDIT'} />
								) : null}
							{props.popupState === 'ADDPROG_EDIT' ? (
								<Program type={'EDIT'} />
								) : null}

							{props.popupState === 'EDIT_EVENT_PROG_EDIT' ? (
								<Program type={'EDIT_EVENT_PROG_EDIT'} />
								) : null}

							{props.popupState === 'EDIT_EVENT_PROG_ADD' ? (
								<Program type={'EDIT_EVENT_PROG_ADD'} />
								) : null}

							{props.popupState === 'CONFIRMDELEVENT' ? (
								<ConfirmCancelEvent />
								) : null}
							{props.popupState === 'ADD_CAROUSEL_ITEM' ? <AddHomeCarouselItem /> : null}
							{props.popupState === 'EDIT_CAROUSEL_ITEM' ? <AddHomeCarouselItem /> : null}
							{props.popupState === 'DELETE_CAROUSEL_ITEM' ? <DeleteHomeCarouselItem /> : null}

							{props.popupState === 'CUSTOM_EMAIL_EVENT' ? <CustomEmailEvent /> : null}
							{props.popupState === 'CUSTOM_EMAIL_EVENT_COACH' ? <CustomEmailEvent /> : null}
							{props.popupState === 'CLONE_EVENTS' ? <ConfirmCloneEvents /> : null}
							{props.popupState === 'ADD_NEW_COUPON' ? <NewCoupon /> : null}
							{props.popupState === 'DEACTIVATE_COUPONS' ? <ConfirmDeactivateCoupons /> : null}
							{props.popupState === 'INFO' ? <Info /> : null}
							{props.popupState === 'VIEW_BOOKED_ITEMS' ? <BookedItems /> : null}
						</div>
					</div>
				</div>
			</div>
		);
	}
	return null;
};

const mapStateToProps = (state) => ({
	popupState: state.postReducer.popupState,
});

const styles = {
	r_popup: {
		height: '100%',
		width: '100%',
		background: 'rgba(0, 0, 0, 0.8)',
		zIndex: 9999,
		position: 'fixed',
		// padding: '0% 0%',
		display: 'block',
		top: 0,
	},
	landiging: {
		display: 'block',
		width: '750px',
		position: 'relative',
		top: 'auto',
		right: 'auto',
		left: 'auto',
		background: '0 0',
		margin: 'auto',
		clear: 'both',
		height: '100%',
		overflow: 'hidden',
	},
	pop_up_news: {
		display: 'block',
		width: '80%',
		position: 'relative',
		top: 'auto',
		right: 'auto',
		left: 'auto',
		background: '0 0',
		margin: 'auto',
		clear: 'both',
		height: '100%',
		overflow: 'hidden',
	},
	pop_up_image: {
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		background: '#fff',
		display: 'block',
		width: '100%',
		height: '650px',
		// overflow: 'hidden auto',
		margin: 'auto',
		borderRadius: '0px',
	},
	pop_up_image_school: {
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		overflow: 'hidden',
		background: '#fff',
		display: 'block',
		width: '100%',
		minHeight: '300px',
		borderRadius: '0px',
	},
	pop_up_image_delete: {
		background: '#fff',
		display: 'block',
		width: '100%',
		minHeight: '480px',
		maxHeight: '900px',
		borderRadius: '0px',
	},
	pop_up_image_cancel: {
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		background: '#fff',
		display: 'block',
		width: '100%',
		height: '350px',
		overflow: 'hidden',
		margin: 'auto',
		borderRadius: '0px',
	},
	r_popup_delete: {
		height: '100vh',
		width: '100%',
		background: 'rgba(0, 0, 0, 0.8)',
		zIndex: 9999,
		position: 'fixed',
		paddingTop: '11%',
		display: 'block',
		top: 0,
	},
	content_pops: {
		position: 'absolute',
		top: '45px',
		width: '100%',
		textAlign: 'left',
		fontSize: '30px',
		lineHeight: '38px',
		paddingLeft: '50px',
		paddingRight: '50px',
		paddingBottom: '20px',
		height: '600px',
		overflow: 'hidden auto',
	},
	closeButton: {
		position: 'absolute',
		right: 5,
		top: 5,
		cursor: 'pointer',
	},
};

export default connect(mapStateToProps)(Add);
