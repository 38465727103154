import React , {useEffect} from 'react'
import { connect } from 'react-redux'

import './schoolStyle.scss'

import BookedItemRow from '../../Events/BookedItemRow';

export const BookedItems = (props) => {

    return (
        <div>
        <h3 className='blue bold'>Booked Dates</h3>
        <div className="coach_list_top">
          <div className="events_table_header wi-25">DATE</div>
          <div className="events_table_header wi-17">BOOKED</div>
        </div>
        {props?.selectedPost?.data.map((zone, i) => {
              return(
                <BookedItemRow
                  key={i}
                  date={zone.date}
                  booked={zone.occupied_space}
                />
              );
            })
          }
    </div>
    )
}

const mapStateToProps = (state) => ({
	selectedPost: state.postReducer?.selectedPost,
});

export default connect(mapStateToProps)(BookedItems);