import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Autocomplete from 'react-google-autocomplete';
import NumberFormat from 'react-number-format';
// import Select from 'react-select';
import moment from 'moment';
import { baseURL, editPicture } from '../../../config/config';
import { coachHandle } from '../../../utils/global';
import imageCompression from 'browser-image-compression';

//CSS
import './EditEvent.css';

//Redux
import eventActions from '../../../redux/actions/eventsActions';
import popupAction from '../../../redux/actions/postsActions';

//components
import ProgramCard from '../../../Components/Events/EventProgramCard';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactPlayer from 'react-player'

//Images
import backArrow from '../../../assets/images/arrow-red-left.svg';
import saveIcon from '../../../assets/images/save-icon.svg';
import whitePlus from '../../../assets/images/white-cross.svg';
import radioCheckedLogo from '../../../assets/images/Checked.png';
import radioUncheckedLogo from '../../../assets/images/Unchecked.png';
import ArrowBlueImg from '../../../assets/images/arrow_blue_down.svg';
// import post_one from '../../../assets/images/post_one.png';

import plus from '../../../assets/images/white-cross.svg';
import camera from '../../../assets/images/camera.svg';
import calendarImg from '../../../assets/images/calendar.svg';
import removePhoto from '../../../assets/images/remove_photo_red.svg';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerComponent from '../../../Components/DatePickerComponent/DatePickerComponent';

import { useHistory } from 'react-router';

const EditEvent = (props) => {
	const [videoSrc, setVideoSrc] = useState('');
	const [originalVideo, setOriginalVideo] = useState('');
	const [imageHandle, setImageHandle] = useState({
		isChanged: false,
		existing_banner: '',
	});

	const [errors, setErrors] = useState({
		description: '',
		banner: '',
		end_time: '',
		general: '',
		});

	const [ckEditor, setCkEditor] = useState('');
	const [oldDate, setOldDate] = useState('');
	const params = useParams();
	useEffect(() => {
		coachHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null);
		props.dispatch(eventActions.getEventCategories());
		props.dispatch(eventActions.getEventDetailsById({ id: params.id }));
		return () => {
			props.dispatch(eventActions.clearEventDetails());
		}
	}, []);

	useEffect(() => {

		if (props?.eventDetailsLoading !== true && Array.isArray(props.eventDetails) === false) {

			recurrenceHandle();

			let booked = bookedInfo();
			
			setImageHandle({
				...imageHandle,
				existing_banner: props?.eventDetails?.banner,
			});
			if(props.eventDetails?.thumbnail!==null){
				setOriginalVideo(props.eventDetails?.thumbnail);
				setVideoSrc(`${baseURL}/storage/${props.eventDetails?.thumbnail}`);
			}
			let programs = []
			props?.eventDetails?.programs?.map((zone) => {
				programs.push({
					name: zone.name,
					number_of_days: zone.number_of_days,
					ticket_price: zone.ticket_price,
					is_akv_allowed: zone?.is_akv_allowed,
					title: zone.title,
					id: zone.id,
					event_id: zone.event_id,
					ptype: 'old',
					discount: zone?.discount,
					from: zone?.from,
					to: zone?.to,
				})
			})
			setOldDate(props?.eventDetails?.start_date);
			let final = {
				start_date: props?.eventDetails?.start_date,
				end_date: props?.eventDetails?.end_date,
				start_time: props?.eventDetails?.start_time,
				end_time: props?.eventDetails?.end_time,
				title: props?.eventDetails?.title,
				category_id: props?.eventDetails?.category_id,
				subtitle: props?.eventDetails?.subtitle,
				description: props?.eventDetails?.description,
				location: props?.eventDetails?.location,
				venue: props?.eventDetails?.venue,
				available_space: props?.eventDetails?.available_space,
				occupied_space: props?.eventDetails?.occupied_space,
				// vacant_space: "",
				is_full_day_event: props?.eventDetails?.is_full_day_event,
				is_recurring: props?.eventDetails?.is_recurring,
				is_custom_rec: props?.eventDetails?.is_custom_rec,
				status: props?.eventDetails?.status,
				is_editable: props?.eventDetails?.is_editable,
				recurrence: {
					recurrence_type: props?.eventDetails?.recurrence_type,
					frequency: props?.eventDetails?.frequency,
					interval: props?.eventDetails?.interval,
					after_reccurence: props?.eventDetails?.after_reccurence,
					by_day: props?.eventDetails?.by_day,
					ends_on: props?.eventDetails?.ends_on,
					by_step: props?.eventDetails?.by_step,
				},
				banner: props?.eventDetails?.banner,
				programs: programs,
				// discount_percentage: '',
				discount: {
					discount: props?.eventDetails?.discount?.discount && props?.eventDetails?.discount?.discount != 0 ? (Number(props?.eventDetails?.discount?.discount))?.toFixed(0) : '',
					from: props?.eventDetails?.discount?.from ? moment(props?.eventDetails?.discount?.from).toDate() : '',
					to: props?.eventDetails?.discount?.to ? moment(props?.eventDetails?.discount?.to).toDate() :''
				},
				meta_title: props?.eventDetails?.meta_title,
				meta_description: props?.eventDetails?.meta_description,
				thumbnail: props?.eventDetails?.thumbnail,
				pricing: props?.eventDetails?.pricing,
				meta_keywords: props?.eventDetails?.meta_keywords,
				id_in_business_center: props?.eventDetails?.id_in_business_center,
				bookedInfo: booked,
			};
			props.dispatch(eventActions.assignIncomingData({ formData: final }));
			setRadio(props?.eventDetails?.is_recurring === 1 ? 'recurring' : 'oneDay')
			setCkEditor(props?.eventDetails?.description)
		}
	}, [props?.eventDetails, props.eventDetailsLoading]);


	const handleRecurence = () => {
		props.dispatch(popupAction.popupHandler({ type: 'CUSTOMREC' }));
	};
	const handleDataChange = (e) => {
		if(e.target.name!=='thumbnail'){
			if(e.target.value.length==0){
				setErrors({
					...errors,
					[e.target.name]: "This field is required"
				})
			}
			else{
				setErrors({
					...errors,
					[e.target.name]: ""
				})
			}
		}
		props.dispatch(
			eventActions.handleAddEventFormData({
				name: e.target.name,
				data: e.target.value,
				formData: props.formData,
			})
		);
	};

	const handleDateChange = (data, name) => {
			let date = data===null ? moment().toDate() : moment(data).format('YYYY-MM-DD');
			props.dispatch(
				eventActions.handleAddEventFormData({
					name: name,
					data: date,
					formData: props.formData,
				})
			);
	}

	const handleOnChangeImage = (e) => {
		if(e.target.files[0]){
			setErrors({
				...errors,
				banner: ''
			});
		}
		checkImageDimensions(e.target.files[0]);
	};
	const options = {
		maxSizeMB: 0.2,
		useWebWorker: true
	};

	const checkImageDimensions = (file) =>{
		var image = new Image();
		let reader = new FileReader();
		reader.onload = (e) => {
			image.src = e.target.result;
			image.onload = ()=>{
				compressImage(file)
			}
		};
		reader.readAsDataURL(file);
		
	}
	const compressImage =  (file) =>{
		imageCompression(file, options).then((compresssedFile)=>{
			createImage(compresssedFile)
		})
	}
	const createImage = (file) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			let j = {
				target: {
					value: e.target.result,
					name: 'banner',
				},
			};
			handleDataChange(j);
			
		};
		reader.readAsDataURL(file);
	};

	// this is only allowed for specific emails in order to change directly an image on the server
	const handleOnlyImage = (e) => {
		let newImage = e.target.files[0]
		let reader = new FileReader();
		reader.onload = async (e) => {
			let value = e.target.result
			await editPicture({banner: value}, params.id)
		};
		reader.readAsDataURL(newImage);
	}

	const recurrenceHandle = () =>{
		if(props.eventDetails.is_recurring===1){
			let dates = [];
			props.eventDetails.recurences.map( rec => {
				dates.push(rec.start_date);
			})
			props.dispatch(eventActions.handleNewRecData({
				count: props.eventDetails.recurrence_count,
				dates: dates,
				text: '',
			}))
		}
	}

	const HandlePublish = () => {
		props.dispatch(eventActions.setPublished({ id: params.id }));
	};

	const handleDataChangeType = (e) => {
		props.dispatch(
			eventActions.handleAddEventRecurenceData({
				name: 'recurrence',
				data: {},
				formData: props.formData,
				name2: 'is_recurring',
				data2: 0,
				data3: 1,
				name3: 'is_full_day_event',
				name4: 'is_custom_rec',
				data4: 0,
			})
		);
	};
	const [handleRadio, setRadio] = useState(props?.eventDetails?.is_recurring === 1 ? 'recurring' : 'oneDay');

	const handleEventType = () => {
		handleDataChangeType();
		setRadio('oneDay');
	};

	const handleInvitation = () => {
		props.dispatch(eventActions.sendBulkInvitationToAllMembers({ id: params.id }));
	};

	const [isOpen, setIsOpen] = useState(false);

	const handleOpenCalendar = () => {
		setIsOpen(!isOpen)
	}

	const handleSubmit = (e) => {
		let formValid = true;
		let status = props?.formData?.status;
		if (e === 1) {
			status = 'draft';
		}
		else if(e === 2){
			status = 'public';
		}
		let finalData;
		let old_programs = []
		let new_programs = []
		props?.formData?.programs?.map((zone) => {
			if (zone.ptype === 'old') {
				old_programs.push({
					id: zone.id,
					title: zone.title,
					name: zone.name,
					event_id: zone.event_id,
					ticket_price: zone.ticket_price,
					number_of_days: props.rec_count===1 ? props.rec_count : zone.number_of_days,
					is_akv_allowed: zone?.is_akv_allowed,
					discount: zone?.discount ? zone?.discount : null,
					from: zone?.from ? moment(zone?.from).format('YYYY-MM-DD') : null,
					to: zone?.to ? moment(zone?.to).format('YYYY-MM-DD') : null ,
				})
			} else {
				new_programs.push({
					title: zone.title,
					name: zone.name,
					event_id: zone.event_id,
					ticket_price: zone.ticket_price,
					number_of_days:  props.rec_count===1 ? props.rec_count : zone.number_of_days,
					is_akv_allowed: zone?.is_akv_allowed,
					discount: zone?.discount ? zone?.discount : null,
					from: zone?.from ? moment(zone?.from).format('YYYY-MM-DD') : null,
					to: zone?.to ? moment(zone?.to).format('YYYY-MM-DD') : null ,
				})
			}
		})
		if (imageHandle.isChanged === true) {
			
			finalData = {
				start_date: props?.formData?.start_date !== oldDate ? props?.formData?.start_date : oldDate,
				// end_date: props?.formData?.end_date,
				start_time: props?.formData?.start_time,
				end_time: props?.formData?.end_time,
				title: props?.formData?.title,
				category_id: props?.formData?.category_id,
				subtitle: props?.formData?.subtitle,
				description: ckEditor,
				location: props?.formData?.location,
				venue: props?.formData?.venue,
				available_space: props?.formData?.available_space,
				occupied_space: props?.formData?.occupied_space,
				is_full_day_event: props?.formData?.is_full_day_event,
				is_recurring: props?.formData?.is_recurring,
				status: status,
				recurrence: props?.formData?.recurrence,
				banner: props?.formData?.banner,
				programs: old_programs,
				['new-program']: new_programs,
				pricing: props?.formData.pricing,
				meta_title: props.formData?.meta_title,
				meta_keywords: props.formData?.meta_keywords,
				meta_description: props.formData?.meta_description,
				is_custom_rec: props?.formData?.is_custom_rec,
				thumbnail: props.formData.thumbnail? props.formData.thumbnail : null,
				// discount_percentage: props?.formData?.discount_percentage,
				discount: props?.formData?.discount,
				id_in_business_center: props?.formData?.id_in_business_center
			};
			if(!finalData['banner']){
				setErrors({
					...errors,
					['banner']: "Banner is required"
				});
				formValid = false;
			}
		} else {
			finalData = {
				start_date: props?.formData?.start_date !== oldDate ? props?.formData?.start_date : oldDate,
				// end_date: props?.formData?.end_date,
				start_time: props?.formData?.start_time,
				end_time: props?.formData?.end_time,
				title: props?.formData?.title,
				category_id: props?.formData?.category_id,
				subtitle: props?.formData?.subtitle,
				description: ckEditor,
				location: props?.formData?.location,
				venue: props?.formData?.venue,
				available_space: props?.formData?.available_space,
				occupied_space: props?.formData?.occupied_space,
				is_full_day_event: props?.formData?.is_full_day_event,
				is_recurring: props?.formData?.is_recurring,
				status: status,
				is_custom_rec: props?.formData?.is_custom_rec,
				recurrence: props?.formData?.recurrence,
				existing_banner: imageHandle.existing_banner,
				programs: old_programs,
				['new-program']: new_programs,
				meta_title: props.formData?.meta_title,
				meta_keywords: props.formData?.meta_keywords,
				meta_description: props.formData?.meta_description,
				thumbnail: props.formData?.thumbnail,
				// discount_percentage: props?.formData?.discount_percentage,
				discount: props?.formData?.discount,
				pricing: props?.formData.pricing,
				id_in_business_center: props?.formData?.id_in_business_center
			};
			if(!finalData['existing_banner']){
				setErrors({
					...errors,
					['banner']: "Banner is required"
				});
				formValid = false;
			}
		}

		if(!finalData['description']){
			setErrors({
				...errors,
				['description']: "Description is required"
			});
			formValid = false;
		}

		if(!finalData.start_time || !finalData.end_time || !finalData.title || !finalData.subtitle || !finalData.location || !finalData.available_space || !finalData.category_id || !finalData.venue || !finalData.pricing || !finalData.meta_description || !finalData.meta_keywords || !finalData.meta_title){
			setErrors({
				...errors,
				general : "Please fill all required fields (*)"
			})
			formValid = false;
		}

		if(finalData.start_time>finalData.end_time){
			setErrors({
				...errors,
				end_time : "End time must be greater than start time"
			})
			formValid = false;
		}


			
		//  }
		// if (props?.formData?.discount) {
		// 	finalData = { ...finalData, discount: props?.formData?.discount }
		// }
		if (finalData.discount?.discount && finalData.discount?.from && finalData?.discount?.to) {
			let finalDiscount = {
				from: moment(finalData.discount.from).format("YYYY-MM-DD"),
				to: moment(finalData.discount.to).format("YYYY-MM-DD"),
				discount: finalData.discount.discount
			}
			finalData.discount = finalDiscount
		} else {
			delete finalData.discount
		}
		
		if(formValid){
			setErrors({
				banner: '',
				description: '',
				end_time: '',
				general: '',
			})
			props.dispatch(eventActions.editEvent({ id: params.id, data: finalData }));
		}
		
	};

	const history = useHistory();


	const handleVideoChange = (e) => {
		let file = e.target.files[0];
		var url = URL.createObjectURL(file);
		setVideoSrc(url);
		createVideo(file)
	};

	const createVideo = (file) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			let j = {
				target: {
					value: e.target.result,
					name: 'thumbnail',
				},
			};
			handleDataChange(j);
		};
		reader.readAsDataURL(file);
	};

	const bookedInfo = () => {
		let finalDates = [];
		
		if(props.eventDetails.recurences.length > 0){
			props.eventDetails.recurences.map( rec => {
				finalDates.push({
					date: rec.start_date,
					occupied_space: rec.booked,
				});
			});
		}
		else
		{
			finalDates.push({
				date: props.eventDetails.start_date,
				occupied_space: props.eventDetails?.occupied_space,
			});			
		}

		return finalDates;
	};

	return (
		<section>
			{/* {console.log("%cDescription", "background-color:black;color:white;", props?.eventDetails.description)} */}
			<div className='event_edit_detail_top_div'>
				<div className='left_detail_top'>
					<div className='go_back_arrow' onClick={() => history.goBack()}>
					<img src={backArrow} alt='Go Back' />
				</div>

					<div className='bold blue font-36 text-upper float-left'>
						EDIT EVENT
					</div>
				</div>

				<div className='left_detail_top'>
					<div style={{ height: '37px' }} className='text-center mx-auto mt-4'>
						{props.editEventLoading === true ? (
							<CircularProgress color='primary' size={30} />
						) : null}
					</div>
					<div
						onClick={() => {
							if (!props.editEventLoading) {
								handleSubmit(2)
							}
						}}
						to=''
						className={`edit_event_save ${props.editEventLoading ? 'disabledButton' : ''} bold`}
					>
						<img src={saveIcon} alt='' />

						<div className='edit_link_text_div'>
							<span>SAVE</span>
						</div>
					</div>
					
				</div>
			</div>
			<div className='edit_event_mainbody'>
				{props.formData.is_editable === true ?
					<div className='recurence_div'>
						<div className='recurence_choices'>
							<span className='blue bold text-upper font-18'>event type</span>
							<div className='blue font-16 event_recurence_radio'>
								<div
									onClick={() => {
										handleEventType();
										props.dispatch(eventActions.handleNewRecData({
											count: 1,
											dates: [],
											text: ''
										}))
									}}
									className='mr-1'
								>
									<img
										src={
											handleRadio === 'oneDay'
												? radioCheckedLogo
												: radioUncheckedLogo
										}
										alt=''
									/>
								</div>
								<span>One Day</span>
							</div>
							<div className='blue font-16 event_recurence_radio'>
								<div
									onClick={() => {
										setRadio('recurring');
										recurrenceHandle()
									}}
									className='mr-1'
								>
									<img
										src={
											handleRadio === 'recurring'
												? radioCheckedLogo
												: radioUncheckedLogo
										}
										alt=''
									/>
								</div>
								<span>Recurring</span>
							</div>
						</div>
						{handleRadio === 'recurring' ? (
							<div
								onClick={() => {
									handleRecurence();
								}}
								className='custom_recurence white bold text-upper'
							>
								<img src={plus} alt='' className='mr-2' />
								CUSTOM RECURRENCE
							</div>
						) : null}
					</div>
					: null}
				{/* <div className='recurence_div'>
					<div className='recurence_choices'>
						<span className='blue bold text-upper font-18'>event type</span>
						<div className='blue font-16 event_recurence_radio'>
							<div
								onClick={() => {
									handleEventType();
								}}
								className='mr-1'
							>
								<img
									src={
										handleRadio === 'oneDay'
											? radioCheckedLogo
											: radioUncheckedLogo
									}
									alt=''
								/>
							</div>
							<span>One Day Event</span>
						</div>
						<div className='blue font-16 event_recurence_radio'>
							<div
								onClick={() => {
									setRadio('weekly');
								}}
								className='mr-1'
							>
								<img
									src={
										handleRadio === 'weekly'
											? radioCheckedLogo
											: radioUncheckedLogo
									}
									alt=''
								/>
							</div>
							<span>Weekly</span>
						</div>
					</div>
					{handleRadio === 'weekly' ? (
						<div
							onClick={() => {
								handleRecurence();
							}}
							className='custom_recurence white bold text-upper'
						>
							<img src={whitePlus} alt='' className='mr-2' />
							CUSTOM RECURRENCE
						</div>
					) : null}
				</div> */}
				{/* <div className='mt-4'>
					<span className='bold text-upper blue mr-3 font-18'>Event Code:</span>
					<span className='blue font-18 text-upper'>123123</span>
				</div> */}
				<div className=' bold blue font-36 text-upper mt-4'>
					<span>Edit Event Details</span>
				</div>
				<p className="error">{errors['general']}</p>
				<div className='event_edit_form_comtainer'>
					<div className='event_edit_row'>
						<div className='event_edit_input'>
							<label className='blue bold font-14 text-upper'>
								Event Start Date *
							</label>
							{/* <input
								type='date'
								name='start_date'
								value={props.formData?.start_date}
								min={moment().format('YYYY-MM-DD')}
								onChange={(e) => {
									handleDataChange(e);
								}}
							></input> */}
							{/* <div className='event_calendar' style={{background:`url("${calendarImg}") no-repeat 90% #fff`}}>
								<DatePicker
									name='start_date'
									selected={props?.formData?.start_date ? moment(props?.formData?.start_date).toDate() : moment()}
									dateFormat="yyyy-MM-dd"
									// minDate={moment().toDate()}
									onChange={(e) => handleDateChange(e, 'start_date')}
									open={isOpen}
									onCalendarOpen={() => setIsOpen(true)}
									onCalendarClose={()=>setIsOpen(false)}
								/>
							</div> */}
							{/* <img src={calendarImg} alt="calendar" className='calImg' onClick={() => handleOpenCalendar()} /> */}
							<DatePickerComponent
								containerClass="event_calendar"
								name='start_date'
								selected={props?.formData?.start_date ? moment(props?.formData?.start_date).toDate() : moment().toDate()}
								handleDateChange={(e) => 
									handleDateChange(e, 'start_date')}
								open={isOpen}
								setIsOpenTrue={() => setIsOpen(true)}
								setIsOpenFalse={() => setIsOpen(false)}
							/>
						</div>
						{/* <div className='event_edit_input position-relative blue regular font-16'>
							<label className='bold text-upper blue font-14'>
								Event End Date
							</label>
							<input
								type='date'
								placeholder='End Date'
								name='end_date'
								value={props.formData?.end_date}
								min={moment().toDate()}
								onChange={(e) => {
									handleDataChange(e);
								}}
							></input>
							<DatePicker
								name='end_date'
								selected={props?.formData?.end_date ? moment(props?.formData?.end_date).toDate() :
									props.formData?.is_full_day_event === 1 ? moment(props.formData.start_date).toDate() : endDateBasedOnReccurence()}
								dateFormat="yyyy-MM-dd"
								minDate={moment().toDate()}
								disabled
								onChange={(e) => handleDateChange(e, 'end_date')}
							/>
							<img src={calendarImg} alt="calendar" className='calImg' />
						</div> */}
						<div className='event_edit_input position-relative blue regular font-16'>
							<label className='bold text-upper blue font-14'>
								Event start Time*
							</label>
							<input
								type='time'
								placeholder='Time'
								required
								name='start_time'
								value={props.formData?.start_time}
								onChange={(e) => {
									handleDataChange(e);
								}}
							></input>
						</div>
						<div className='event_edit_input position-relative  blue regular font-16'>
							<label className='bold text-upper blue font-14'>
								Event End Time*
							</label>
							<input
								type='time'
								required
								placeholder='Time'
								name='end_time'
								value={props.formData?.end_time}
								onChange={(e) => {
									handleDataChange(e);
								}}
							></input>
							{errors['end_time']!=='' && <p className="error">{errors['end_time']}</p>}
						</div>
					</div>
					<div className='event_edit_row'>
						<div className='event_edit_input position-relative  blue regular font-16'>
							<label className='bold text-upper blue font-14'>
								Event Title*
							</label>
							<input
								type='text'
								placeholder={props.formData?.title}
								name='title'
								onChange={(e) => {
									handleDataChange(e);
								}}
								value={props.formData?.title}
								required
							></input>
						</div>
						<div className='event_edit_input position-relative blue regular font-16'>
							<label className='bold text-upper blue font-14'>
								Age*
							</label>
							<input
								type='text'
								placeholder={props.formData?.subtitle}
								name='subtitle'
								value={props.formData?.subtitle}
								onChange={(e) => {
									handleDataChange(e);
								}}
								required
							></input>
						</div>
						<div className='event_edit_input d-inline-block blue regular font-16'>
							<label className='bold text-upper blue font-14'>
								Event Location*
							</label>
							<Autocomplete
								defaultValue={props.formData?.location}
								required
								onPlaceSelected={(place) => {
									let j = {
										target: {
											value: place.formatted_address,
											name: 'location',
										},
									};
									handleDataChange(j);
								}}

								onChange={(e) => {
									let j = {
										target: {
											value: e.target.value,
											name: 'location',
										},
									}
									handleDataChange(j);
								}}
								options={{
									types: ["(regions)"],
									componentRestrictions: { country: "au" },
								}}
							/>
						</div>
					</div>
					<div className='event_edit_row justify-content-start'>
						<div className='double_col_event_input'>
							<div className='blue regular font-16'>
								<label className='text-upper bold font-14'>
									AVAILABLE SPACE *Day
								</label>
								<input
									type='number'
									required
									placeholder={props.formData?.available_space}
									name='available_space'
									className='number_input'
									value={props.formData?.available_space}
									onChange={(e) => {
										handleDataChange(e);
									}}
								></input>
							</div>
							<div className=' blue regular font-16 half_row'>
								<label className='text-upper bold font-14'>Occupied</label>
								<div
									onClick={() => {
										props.dispatch(popupAction.popupHandler({ type: 'VIEW_BOOKED_ITEMS', data: props.formData?.bookedInfo }));
									}}
									className='see_details_box'
								>
									<span className='see_details_span'>See Details</span>	
								</div>
							</div>
						</div>
						<div className='event_edit_input d-inline-block blue regular font-16 v-top ml-4'>
							<label className='bold text-upper blue font-14'>
								Event Category*
							</label>
							<div className='d-flex'>
								<select
									required
									name=''
									id=''
									className='edit_event_select'
									value={props?.formData?.category_id || ''}
									onChange={(e) => {
										let j = {
											target: {
												value: e.target.value,
												name: 'category_id',
											},
										};
										handleDataChange(j);
									}}
									style={{ background: `url(${ArrowBlueImg}) no-repeat right #fff`, width: '100%' }}
								>
									<option value='' defaultValue>Choose category</option>
									{props?.eventCategories?.length > 0
										? props?.eventCategories?.map((zone, i) => {
											if (props.formData?.category_id === zone.id) {
												return (
													<option key={i} value={zone.id}>
														{zone.name}
													</option>
												);
											} else {
												return (
													<option key={i} value={zone.id}>
														{zone.name}
													</option>
												);
											}
										})
										: null}
								</select>
							</div>
						</div>
						<div className='event_edit_input position-relative blue regular font-16     v-top ml-4'>
							<label className='bold text-upper blue font-14'>
								Venue*
							</label>
							<input
								type='text'
								placeholder='Venue'
								name='venue'
								value={props.formData.venue}
								onChange={(e) => {
									handleDataChange(e);
								}}
								required
							></input>
						</div>
						<div className='event_edit_input d-inline-block blue regular font-16 v-top ml-4'>
							<label className='bold text-upper blue font-14'>
								Id In Business Centeral
							</label>
							<input
								type='text'
								placeholder='Id'
								name='id_in_business_center'
								value={props.formData?.id_in_business_center}
								onChange={(e) => {
									handleDataChange(e);
								}}
							></input>
						</div>
					</div>
					<div className='event_edit_row'>
						<div className='event_edit_input position-relative  blue regular font-16'>
							<label className='bold text-upper blue font-14'>
								Meta Title*
							</label>
							<input
								type='text'
								placeholder='Meta title'
								name='meta_title'
								onChange={(e) => {
									handleDataChange(e);
								}}
								value={props.formData?.meta_title}
							></input>
						</div>
						<div className='event_edit_input position-relative blue regular font-16'>
							<label className='bold text-upper blue font-14'>
								Meta Description*
							</label>
							<input
								type='text'
								placeholder='Meta description'
								name='meta_description'
								value={props.formData?.meta_description}
								onChange={(e) => {
									handleDataChange(e);
								}}
							></input>
						</div>
						<div className='event_edit_input position-relative blue regular font-16'>
							<label className='bold text-upper blue font-14'>
								Meta Keywords*
							</label>
							<input
								type='text'
								placeholder='Meta Keywords  '
								name='meta_keywords'
								value={props.formData?.meta_keywords}
								onChange={(e) => {
									handleDataChange(e);
								}}
							></input>
						</div>
					</div>
				</div>
				<div className='event_edit_banner_img'>
				<div className='event_edit_input position-relative blue regular font-16'>
						<label className='bold text-upper blue font-14'>
							Pricing*
						</label>
						<input
							type='text'
							placeholder='Pricing'
							name='pricing'
							value={props?.formData?.pricing}
							onChange={(e) => {
								handleDataChange(e);
							}}
						></input>
					</div>
					<label className='bold text-upper blue font-14'>Banner image*</label>
					{imageHandle.isChanged === true ? (
						props.formData?.banner !== '' ? (
							<div className='event_banner_image_container'>
								<img
									src={props.formData?.banner}
									alt=''
									className='banner_image'
								/>
								<img
									src={removePhoto}
									alt=''
									className='remove_photo'
									onClick={() => {
										setErrors({
											...errors,
											banner: 'Banner is required'
										});
										let j = {
											target: {
												value: '',
												name: 'banner',
											},
										};
										handleDataChange(j);
										setImageHandle({
											...imageHandle,
											isChanged: true,
											existing_banner: '',
										});
									}}
								/>
							</div>
						) : null
					) : imageHandle.existing_banner !== '' ? (
						<div className='event_banner_image_container'>
							<img
								src={baseURL + '/image/' + imageHandle.existing_banner}
								alt=''
								className='banner_image'
							/>
							<img
								src={removePhoto}
								alt=''
								className='remove_photo'
								onClick={() => {
									let j = {
										target: {
											value: '',
											name: 'banner',
										},
									};
									handleDataChange(j);
									setImageHandle({
										...imageHandle,
										isChanged: true,
										existing_banner: '',
									});
								}}
							/>
						</div>
					) : null}

					<button className='event_add_photo_btn' type="button">
						<input
							type='file'
							id='file'
							name='file'
							className='event_custom_file_input'
							onChange={(e) => {
								setImageHandle({
									...imageHandle,
									isChanged: true,
									existing_banner: '',
								});
								handleOnChangeImage(e);
							}}
						/>
						{
							props.formData?.banner !== '' ? 
								<label
									htmlFor='file'
									className='bold blue text-upper event_add_photo_label'
								>
									<img src={camera} alt='' />
									update photo
								</label>
								:
								<label
									htmlFor='file'
									className='bold blue text-upper event_add_photo_label'
								>
									<img src={camera} alt='' />
									add photo
								</label>
						}
					</button>
					<p className="error">{errors['banner']}</p>
					{props.user?.email === ('rony.ibrahim@adcreatorsmena.com') && <button className='event_add_photo_btn'>
						<input
							type='file'
							id='image'
							name='image'
							className='event_custom_file_input'
							onChange={(e) => {
								handleOnlyImage(e);
							}}
						/>
						<label
							htmlFor='image'
							className='bold blue text-upper event_add_photo_label red'
						>
							<img src={camera} alt='' />
							update New photo
						</label>
					</button>}
				</div>
				<div className='event_edit_banner_img'>
				<label className='bold text-upper blue font-14'>Video</label>
				{videoSrc!==''&& <div className='event_video_container'>
				<ReactPlayer url={videoSrc} controls={true} className="event_video"/>
				<img
								src={removePhoto}
								alt=''
								className='remove_photo'
								onClick={() => {
									setVideoSrc('');
									let j = {
											target: {
												value: null,
												name: 'thumbnail',
											},
										};
									handleDataChange(j);
								}}
							/>
				</div>}
				<button className='event_add_photo_btn' type="button">
						<input
							type='file'
							id='video'
							name='video'
							accept="video/mp4,video/x-m4v,video/*"
							className='event_custom_file_input'
							onChange={(e)=>{
								handleVideoChange(e)
							}
							}
						/>
						{videoSrc !== '' ?
							<label
								htmlFor='video'
								className='bold blue text-upper event_add_photo_label'
							>
								<img src={camera} alt='' />
								UPDATE VIDEO
							</label>
							:
							<label
								htmlFor='video'
								className='bold blue text-upper event_add_photo_label'
							>
								<img src={camera} alt='' />
								ADD VIDEO
							</label>
						}
					</button>
				</div>
				<div className='event_desc_div pt-5 blue regular font-16'>
					<label className='text-upper  bold font-14'>About the EVENT*</label>
					<CKEditor
						editor={ClassicEditor}
						data={ckEditor} //ckEditor.length > 0? : ''
						config={{
							toolbar: [
								'heading',
								'|',
								'bold',
								'italic',
								'link',
								'bulletedList',
								'numberedList',
								'blockQuote',
							],
							heading: {
								options: [
									{
										model: 'paragraph',
										title: 'Paragraph',
										class: 'ck-heading_paragraph',
									},
									{
										model: 'heading1',
										view: 'h1',
										title: 'Heading 1',
										class: 'ck-heading_heading1',
									},
									{
										model: 'heading2',
										view: 'h2',
										title: 'Heading 2',
										class: 'ck-heading_heading2',
									},
									{
										model: 'heading3',
										view: 'h3',
										title: 'Heading 3',
										class: 'ck-heading_heading3',
									},
									{
										model: 'heading4',
										view: 'h4',
										title: 'Heading 4',
										class: 'ck-heading_heading4',
									},
									{
										model: 'heading5',
										view: 'h5',
										title: 'Heading 5',
										class: 'ck-heading_heading5',
									},
									{
										model: 'heading6',
										view: 'h6',
										title: 'Heading 6',
										class: 'ck-heading_heading6',
									},
								],
							},
						}}
						onChange={(event, editor) => {
							const data = editor.getData();
							if(data){
								setErrors({
									...errors,
									description: ''
								});
							}
							else{
								setErrors({
									...errors,
									description: 'Description is required'
								})
							}
							setCkEditor(data)
							// let j = {
							// 	target: {
							// 		value: data,
							// 		name: 'description',
							// 	},
							// };
							// handleDataChange(j);
						}}
					/>
					<p className="error">{errors['description']}</p>
				</div>
				<div className='event_program_edit_section'>
					<div className='header_title_left bold blue font-36 float-left text-upper pt-5'>
						<span className='text-upper'>Add Event Program</span>
					</div>
					<div className='event_programs_container'>
						{props.formData?.programs?.length > 0
							? props.formData?.programs.map((zone, i) => {
								return (
									<ProgramCard
										title={zone.title}
										ticket_price={<NumberFormat value={Number(zone.ticket_price).toFixed(2)} displayType={'text'} thousandSeparator={true} />}
										name={zone.name}
										number_of_days={zone.number_of_days}
										is_akv_allowed={zone?.is_akv_allowed}
										type={1}
										id={zone.id}
										deleteId={i}
										key={i}
										event_id={zone.event_id}
										ptype={zone.ptype}
										discount={zone?.discount}
										from={zone?.from}
										to={zone?.to}
									/>
								);
							})
							: null}
						<div
							onClick={() => {
								props.dispatch(popupAction.popupHandler({ type: 'EDIT_EVENT_PROG_ADD', data: params.id }));
							}}
							className='add_event_program_btn'
						>
							<img src={whitePlus} alt='' />
						</div>
					</div>
				</div>
				{/* <div className='edit_special_offecr_section'>
					<div className='header_title_left bold blue font-36 float-left text-upper pt-5'>
						<span className='text-upper'>Add Special Offer</span>
					</div>
					<div className='offer_edit_container mt-3'>
						<div className='event_edit_input position-relative blue regular font-16'>
							<label>Start date</label>
							<DatePickerComponent
								placeholder='Start Date'
								containerClass="event_calendar"
								selected={props.formData?.discount?.from ? props.formData?.discount?.from : ''}
								minDate={moment().toDate()}
								maxDate={props.formData?.discount?.to ? props.formData?.discount?.to : ''}
								handleDateChange={(e) => {
									let value = {
										discount: props.formData?.discount?.discount,
										from: e,
										to: props.formData?.discount?.to,
									}
									handleDateChange(value, 'discount')
								}
								}
								open={isOpen}
								setIsOpenTrue={() => setIsOpen(true)}
								setIsOpenFalse={() => setIsOpen(false)}
							/>
						</div>
						<div className='event_edit_input position-relative blue regular font-16'>
							<label>End date</label>
							{/* <div className='event_calendar' style={{background:`url("${calendarImg}") no-repeat 90% #fff`}}>
							<DatePicker
								selected={props.formData?.discount?.to ? moment(props.formData?.discount?.to) : ''}
								dateFormat="yyyy-MM-dd"
								onChange={(e) => {
									let value =  {
												discount: props.formData?.discount?.discount,
												from: props.formData?.discount?.from,
												to: moment(e).format("YYYY-MM-DD"),
											}
									handleDateChange(value, 'discount')}
									}
								/>
								</div>
							<DatePickerComponent
								placeholder='End Date'
								containerClass="event_calendar"
								selected={props.formData?.discount?.to ? moment(props.formData?.discount?.to).toDate() : ''}
								minDate={props.formData?.discount?.from ? moment(props.formData?.discount?.from).toDate() : ''}
								handleDateChange={(e) => {
									let value = {
										discount: props.formData?.discount?.discount,
										from: props.formData?.discount?.from,
										to: e,
									}
									handleDateChange(value, 'discount')
								}
								}
								open={isOpen}
								setIsOpenTrue={() => setIsOpen(true)}
								setIsOpenFalse={() => setIsOpen(false)}
							/>
						</div>
						<div className='event_edit_input position-relative blue regular font-16'>
							<label>Amount</label>
							<input
								onChange={(e) => {
									let j = {
										target: {
											name: "discount",
											value: {
												discount: e.target.value,
												from: props.formData?.discount?.from,
												to: props.formData?.discount?.to,
											}
										}
									}
									handleDataChange(j);
								}}
								name='discount'
								type='number'
								placeholder='Amount'
								value={props?.formData?.discount?.discount || ''}
								className='number_input'
							></input>
						</div>

					</div>
				</div> */}

				{handleRadio==='recurring' && 
				<div className='edit_special_offecr_section blue font-14' style={{marginBottom: '20px'}}>
					<div className='header_title_left bold blue font-36 float-left text-upper pt-5'>
						<span className='text-upper'>Recurrence Dates</span>
					</div>
					{props?.rec_dates?.length!==0 &&
					<ol>
					<div className="grid_display_dates">

						{props?.rec_dates?.map(rec => {
							return(
								<li>{moment(rec).format('DD/MM/YYYY') === "Invalid date" ? rec : moment(rec).format('DD/MM/YYYY')}</li>
							)	
						})}

					</div>
					</ol>
					}
				</div>
				}

				<div className='edit_event_button_div'>
					{props.formData.status === 'public' ? null : (
						<div>
							<div
								onClick={() => {
									if (!props.PublicEventLoading) {
										HandlePublish()
									}
								}}
								className={`blue_edit_event_button ${props.PublishEventLoading ? "disabledButton" : ''}`}
							>
								<span className='bold white text-upper font-14'>PUBLISH</span>
							</div>
							<div
								style={{ height: '37px' }}
								className='text-center mx-auto mt-4'
							>
								{props.PublishEventLoading === true ? (
									<CircularProgress color='primary' size={30} />
								) : null}
							</div>
						</div>
					)}

					{props.eventDetails?.status === 'public' ? null :
						<div>
							<div
								onClick={() => {
									if (!props.editEventLoading) {
										handleSubmit(1);
									}
								}}
								className={`blue_edit_event_button ${props.editEventLoading ? "disabledButton" : ''}`}
							>
								<span className='bold white text-upper font-14'>
									SAVE AS DRAFT
								</span>
							</div>
							<div
								style={{ height: '37px' }}
								className='text-center mx-auto mt-4'
							>
								{props.editEventLoading === true ? (
									<CircularProgress color='primary' size={30} />
								) : null}
							</div>
						</div>}


					{/* <Link to={`/admin/events/${params.id}`}> */}
					<div>
						<div
							onClick={() => {
								if (!props.SendMailLoading) {
									handleInvitation();
								}
							}}
							className={`blue_edit_event_button ${props.SendMailLoading ? "disabledButton" : ''}`}
							style={{ width: 'max-content' }}
						>
							<span className='bold white text-upper font-14'>
								Invite All Members To Event
							</span>
						</div>
						<div
							className='text-center mx-auto mt-4'
							style={{ height: '37px' }}
						>
							{props.SendMailLoading === true ? (
								<CircularProgress color='primary' size={30} />
							) : null}
						</div>
					</div>
					{/* }{props.SendMailLoading&& */}
					{/* </Link> */}

					<div>
						<div
							onClick={() => {
								props.dispatch(
									popupAction.popupHandler({
										type: 'CONFIRMDELEVENT',
										data: params.id,
									})
								);
							}}
							className='red_edit_event_button'
						>
							<span className='bold white text-upper font-14'>
								CANCEL EVENT
							</span>
						</div>
						<div
							style={{ height: '37px' }}
							className='text-center mx-auto mt-4'
						></div>
					</div>
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => ({
	eventDetails: state.eventReducer.eventDetails,
	eventDetailsLoading: state.eventReducer.eventDetailsLoading,
	eventCategories: state.eventReducer.eventCategories,
	formData: state.eventReducer.formData,
	editEventLoading: state.eventReducer.editEventLoading,
	cancelEventLoading: state.eventReducer.cancelEventLoading,
	PublishEventLoading: state.eventReducer.PublishEventLoading,
	SendMailLoading: state.eventReducer.SendBulkMailLoading,
	rec_text: state.eventReducer.rec_text,
	rec_count: state.eventReducer.rec_count,
	rec_dates: state.eventReducer.rec_dates,
	user: state.register.user
});

export default connect(mapStateToProps)(EditEvent);
