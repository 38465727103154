import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { baseURL } from '../../../config/config';
import { AsYouType } from "libphonenumber-js";
//css
import '../Coach/CoachEdit.scss';
//components
import CircularProgress from '@material-ui/core/CircularProgress';
//redux
import profileActions from '../../../redux/actions/profileActions';
import coachActions from '../../../redux/actions/coachActions';

//images
import insert from '../../../assets/images/insert-photo.png';
import camera from '../../../assets/images/camera.svg';
import whiteX from '../../../assets/images/x-white.svg';
import { connect } from 'react-redux';
import AddressAuto from '../../../Components/Address/AddressAuto';


function ProfileEdit(props) {

	useEffect(() => {
		if(props?.user?.roles[0]?.id===2){
			props.dispatch(coachActions.getCoachDetails({id:props?.user?.uuid}))
		}else{
			props.dispatch(profileActions.getSuperProfile(props?.user?.uuid))
		}
	},[])

	const [profileData, setProfileData] = useState({
		first_name: '',
		last_name: '',
		email: '',
		mobile_phone: '',
		profile_picture: '',
		password: '',
		address_1:'',
		address_2:'',
		suburb:'',
		state:'',
		postal_code:'',
	});


	const [isChanged, setChanged] = useState(false);

	const handleOnChangeImage = (e) => {
		createImage(e.target.files[0]);
	};

	const createImage = (file) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			setProfileData({
				...profileData,
				profile_picture: e.target.result,
			});
		};
		reader.readAsDataURL(file);
	};

	const handleEdit = () => {
		let finalEditedProfile
		if (isChanged === true) {
			finalEditedProfile = {
				first_name: profileData.first_name,
				last_name: profileData.last_name,
				email: profileData.email,
				mobile_phone: profileData.mobile_phone,
				street_address1:profileData.address_1,
				street_address2:profileData.address_2,
				suburb:profileData.suburb,
				state:profileData.state,
				postal_code:profileData.postal_code,
				profile_picture: profileData.profile_picture,
			};
			if (profileData.password !== '') {
				finalEditedProfile.password = profileData.password
			}
		} else {
			finalEditedProfile = {
				first_name: profileData.first_name,
				last_name: profileData.last_name,
				email: profileData.email,
				mobile_phone: profileData.mobile_phone,
				street_address1:profileData.address_1,
				street_address2:profileData.address_2,
				suburb:profileData.suburb,
				state:profileData.state,
				postal_code:profileData.postal_code,
				existing_profile_image: props?.userProfileData?.profile_picture,
			};
			if (profileData.password !== '') {
				finalEditedProfile.password = profileData.password
			}
		}

			props.dispatch(
				profileActions.editProfile(
					finalEditedProfile,
					props?.user?.uuid,
				)
			);
	
	};
	const handleMobileChange = (e)=>{
		let value=e.target.value.split(' ').join('');
		let number;
		if( value.length===6 &&  value[1]==='3' && value[0]==='1'){
			number=''
			for(let i=0;i<value.length;i++){
				if(i===2 || i===4){
					number+=` ${value[i]}`
				}else{
					number+=`${value[i]}`
				}
			}
		}else{
			 number = new AsYouType('AU').input(e.target.value)
		}
		setProfileData({
			...profileData,
			mobile_phone: number,
		});
	}

	useEffect(() => {
		if(props?.user?.roles[0]?.id===2){
			setProfileData({
				first_name: props?.coachDetails?.first_name
					? props?.coachDetails?.first_name
					: '',
				last_name: props?.coachDetails?.last_name
					? props?.coachDetails?.last_name
					: '',
				email: props?.coachDetails?.email ? props?.coachDetails?.email : '',
				mobile_phone: props?.coachDetails?.mobile_phone
					? props?.coachDetails?.mobile_phone
					: '',
				profile_picture: props?.coachDetails?.profile_picture
					? props?.coachDetails?.profile_picture
					: '',
				password: '',
				address_1:props?.coachDetails?.street_address1 || '',
				address_2:props?.coachDetails?.street_address2 || '',
				suburb:props?.coachDetails?.suburb || '',
				state:props?.coachDetails?.state || '',
				postal_code:props?.coachDetails?.postal_code || '',
			});
		}else{
			setProfileData({
				first_name: props?.userProfileData?.first_name
					? props?.userProfileData?.first_name
					: '',
				last_name: props?.userProfileData?.last_name
					? props?.userProfileData?.last_name
					: '',
				email: props?.userProfileData?.email ? props?.userProfileData?.email : '',
				mobile_phone: props?.userProfileData?.mobile_phone
					? props?.userProfileData?.mobile_phone
					: '',
				profile_picture: props?.userProfileData?.profile_picture
					? props?.userProfileData?.profile_picture
					: '',
				password: '',
				address_1:props?.userProfileData?.street_address1 || '',
				address_2:props?.userProfileData?.street_address2 || '',
				suburb:props?.userProfileData?.suburb || '',
				state:props?.userProfileData?.state || '',
				postal_code:props?.userProfileData?.postal_code || '',
			});
		}
	}, [props.userProfileData,props.coachDetails])

	return (
		<section>
			<div className='coach_edit_top_div'>
				<div className='left_edit_top'>
					<div className='bold blue font-36 text-upper float-left'>
						edit {props?.userProfileData?.first_name}
					</div>
				</div>
			</div>
			<div className='coach_edit_body'>
				<div className='coach_pfp_div'>
					<div className='coach_edit_pfp'>
						<div className='delete_img_btn'>
							<img
								src={whiteX}
								alt=''
								onClick={() => {
									setProfileData({
										...profileData,
										profile_picture: '',
									});
								}}
							/>
						</div>
						{isChanged === true ? (
							<div className="coach_pfp_container">
								<img
									src={
										profileData.profile_picture !== ''
										? profileData.profile_picture
										: insert
									}
									alt='coach pfp'
									/>
							</div>
						) : (
							<div className="coach_pfp_container">
								<img
									src={
										profileData.profile_picture !== ''
											? baseURL + '/image/' + profileData.profile_picture
											: insert
									}
									alt='coach pfp'
								/>
							</div>
							)}
					</div>
					<button className='add_photo_btn'>
						<input
							onChange={(e) => {
								handleOnChangeImage(e);
								setChanged(true);
							}}
							type='file'
							id='file'
							name='file'
							className='custom_file_input'
						/>
						<label
							htmlFor='file'
							className='bold blue text-upper add_photo_label'
						>
							<img src={camera} alt='' />
							update photo
						</label>
					</button>
				</div>
				
				<div className='coach_edit_form'>
				<form onSubmit={handleEdit}>
					<div className='edit_form_row'>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>First Name *</label>
							<input
								value={profileData.first_name}
								required
								onChange={(e) => {
									setProfileData({
										...profileData,
										first_name: e.target.value,
									});
								}}
								name='First Name'
								type='text'
								placeholder={'First Name'}
								className='coach_edit_input'
							/>
						</div>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Last Name *</label>
							<input
								value={profileData.last_name}
								onChange={(e) => {
									setProfileData({
										...profileData,
										last_name: e.target.value,
									});
								}}
								name='Last Name'
								type='text'
								required
								placeholder={'Last Name'}
								className='coach_edit_input'
							/>
						</div>
					</div>
					<div className='edit_form_row'>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Email address *</label>
							<input
								disabled
								value={profileData.email}
								className='coach_edit_input'
							/>
						</div>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Phone number *</label>
							{/* <InputMask */}
								<input
								value={profileData.mobile_phone}
								onChange={(e) => handleMobileChange(e)}
									name='phone number'
									type='text'
									// maxLength='12'
									placeholder={'ex: 9999 999 999'}
									className='coach_edit_input number_input'
									required
									/>
							{/* </InputMask> */}
						</div>
					</div>
					<AddressAuto
						addressInfo={profileData} 
						setAddressInfo={setProfileData}
						styling='member_edit'
						inputStyling='coach_edit_input'
					/>
					<div className='edit_form_row'>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Password</label>
							<input
								value={profileData.password}
								onChange={(e) => {
									setProfileData({
										...profileData,
										password: e.target.value,
									});
								}}
								name='password'
								type='password'
								placeholder='Password'
								className='coach_edit_input number_input'
							/>
						</div>
						{props.userProfileDataLoading && <div className="text-center mx-auto mt-4"><CircularProgress color='primary' size={30} /></div>}
					</div>

					<div className='edit_button_div'>
						<button
							type='submit'
							className='white bold text-upper save_red_button'
						>
							save
						</button>
						<Link
							to='/admin/profile'
							className='white bold text-upper cancel_blue_button'
						>
							cancel
						</Link>
					</div>
					</form>
				</div>
			</div>
		</section>
	);
}
const mapStateToProps = (state) => ({
	user: state.register.user,
	// userProfileData: state.profileReducer.userProfileData,
	// userProfileDataLoading: state.profileReducer.userProfileDataLoading,
	coachDetails: state.coachreducers.coachDetails,
	userProfileData: state.profileReducer.userProfileData,
})

export default connect(mapStateToProps)(ProfileEdit)
