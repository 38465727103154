import {
  listAllCoaches,
  listCoachCatgeories,
  getSuperAdminProfile,
  createCoach,
  editCoach,
  getCoachDetails,
  getCoachEventsByID,
  assignCoachtoEvent,
  programStatusCoach,
  coachSearch,
  deleteCoach,
  getCoachAssignedEvents,
  removeCoachAssignment,
} from "../../config/config";
import { toast } from "react-toastify";

import postsActions from './postsActions';

export const LIST_COACH_REQUEST = "LIST_COACH_REQUEST";
export const LIST_COACH_SUCCESS = "LIST_COACH_SUCCESS";
export const LIST_COACH_ERROR = "LIST_COACH_ERROR";
export const NEW_COACH_REQUEST = "NEW_COACH_REQUEST";
export const NEW_COACH_SUCCESS = "NEW_COACH_SUCCESS";
export const NEW_COACH_ERROR = "NEW_COACH_ERROR";
export const EDIT_COACH_REQUEST = "EDIT_COACH_REQUEST";
export const EDIT_COACH_SUCCESS = "EDIT_COACH_SUCCESS";
export const EDIT_COACH_ERROR = "EDIT_COACH_ERROR";
export const COACH_DETAILS_REQUEST = "COACH_DETAILS_REQUEST";
export const COACH_DETAILS_SUCCESS = "COACH_DETAILS_SUCCESS";
export const COACH_DETAILS_ERROR = "COACH_DETAILS_ERROR";
export const COACH_EVENT_DETAIL_REQUEST = "COACH_EVENT_DETAIL_REQUEST";
export const COACH_EVENT_DETAIL_SUCCESS = "COACH_EVENT_DETAIL_SUCCESS";
export const COACH_EVENT_DETAIL_ERROR = "COACH_EVENT_DETAIL_ERROR";

export const ASSIGN_COACH_REQUEST = "ASSIGN_COACH_REQUEST";
export const ASSIGN_COACH_SUCCESS = "ASSIGN_COACH_SUCCESS";
export const ASSIGN_COACH_ERROR = "ASSIGN_COACH_ERROR";

export const COACH_SEARCH_REQUEST = "COACH_SEARCH_REQUEST";
export const COACH_SEARCH_SUCCESS = "COACH_SEARCH_SUCCESS";
export const COACH_SEARCH_ERROR = "COACH_SEARCH_ERROR";

export const CLEAR_SEARCH = "CLEAR_SEARCH";

export const COACH_DETAILS_SUPER_REQUEST = "COACH_DETAILS_SUPER_REQUEST";
export const COACH_DETAILS_SUPER_SUCCESS = "COACH_DETAILS_SUPER_SUCCESS";
export const COACH_DETAILS_SUPER_ERROR = "COACH_DETAILS_SUPER_ERROR";

export const COACH_CATEGORIES_REQUEST = "COACH_CATEGORIES_REQUEST";
export const COACH_CATEGORIES_SUCCESS = "COACH_CATEGORIES_SUCCESS";
export const COACH_CATEGORIES_ERROR = "COACH_CATEGORIES_ERROR";

export const CLEAR_COACH_EVENT_DETAILS = "CLEAR_COACH_EVENT_DETAILS";

export const GET_ASSIGNED_EVENTS_REQUEST = "GET_ASSIGNED_EVENTS_REQUEST";
export const GET_ASSIGNED_EVENTS_SUCCESS = "GET_ASSIGNED_EVENTS_SUCCESS";
export const GET_ASSIGNED_EVENTS_ERROR = "GET_ASSIGNED_EVENTS_ERROR";

export const REMOVE_COACH_ASSIGNMENT_REQUEST =
  "REMOVE_COACH_ASSIGNMENT_REQUEST";
export const REMOVE_COACH_ASSIGNMENT_SUCCESS =
  "REMOVE_COACH_ASSIGNMENT_SUCCESS";
export const REMOVE_COACH_ASSIGNMENT_ERROR = "REMOVE_COACH_ASSIGNMENT_ERROR";

const actions = {
  getCoachAssignedEvents: (data) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ASSIGNED_EVENTS_REQUEST,
      });
      await getCoachAssignedEvents(data).then((response) => {
        if (response.data.code === 200) {
          dispatch({
            type: GET_ASSIGNED_EVENTS_SUCCESS,
            payload: {
              data: response.data.data.data,
            },
          });
        } else {
          dispatch({
            type: GET_ASSIGNED_EVENTS_ERROR,
          });
          toast.error(response.message);
        }
      });
    } catch (error) {
      dispatch({
        type: GET_ASSIGNED_EVENTS_ERROR,
      });
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error);
      }
    }
  },
  removeCoachAssignment: (data) => async (dispatch) => {
    try {
      dispatch({
        type: REMOVE_COACH_ASSIGNMENT_REQUEST,
      });
      await removeCoachAssignment(data).then((response) => {
        if (response.data.code === 200) {
          dispatch({
            type: REMOVE_COACH_ASSIGNMENT_SUCCESS,
          });
          dispatch(actions.getCoachAssignedEvents(data.id));
          toast.success("Access Revoked Successfully");
        } else {
          dispatch({
            type: REMOVE_COACH_ASSIGNMENT_ERROR,
          });
          toast.error(response.message);
        }
      });
    } catch (error) {
      dispatch({
        type: REMOVE_COACH_ASSIGNMENT_ERROR,
      });
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error);
      }
    }
  },

  listCoaches: (data) => async (dispatch) => {
    try {
      dispatch({
        type: LIST_COACH_REQUEST,
      });
      await listAllCoaches(data).then((response) => {
        if (response.data.code === 200) {
          dispatch({
            type: LIST_COACH_SUCCESS,
            payload: {
              data: response.data.data,
            },
          });
        } else {
          dispatch({
            type: LIST_COACH_ERROR,
            payload: {
              message: response.message,
            },
          });
        }
      });
    } catch (error) {
      dispatch({
        type: LIST_COACH_ERROR,
        payload: {
          message: "Error Loading Event Categories",
        },
      });
    }
  },

  newCoach: (data) => async (dispatch) => {
    try {
      dispatch({
        type: NEW_COACH_REQUEST,
      });
      await createCoach(data).then((response) => {
        console.log('creating coach response', response)
        if (response?.data.code === 200) {
          toast.success("Coach Created");
          dispatch({
            type: NEW_COACH_SUCCESS,
            payload: {
              data: response?.data?.data?.data,
            },
          });
        } else {
          toast.error(` Error : ${response?.data?.errors}`);
          dispatch({
            type: NEW_COACH_ERROR,
          });
        }
      });
    } catch (error) {
      console.log('error creating coach', error.response)
      dispatch({
        type: NEW_COACH_ERROR,
      });
    }
  },

  getCoachCategories: () => async (dispatch) => {
      dispatch({
        type: COACH_CATEGORIES_REQUEST,
      });
      await listCoachCatgeories().then((response) => {
        if (response?.data.code === 200) {
          dispatch({
            type: COACH_CATEGORIES_SUCCESS,
            data: response?.data?.data,
          });
        } else {
          dispatch({
            type: COACH_CATEGORIES_ERROR,
          });
        }
      });
  },

  editSelectedCoach: (data) => async (dispatch) => {
    try {
      dispatch({
        type: EDIT_COACH_REQUEST,
      });
      await editCoach(data).then((response) => {
        if (response?.data?.code === 200) {
          toast.success("Coach edited successfully");
          dispatch({
            type: EDIT_COACH_SUCCESS,
            payload: {
              data: response.data.data.data,
            },
          });
        } else {
          toast.error(response.response?.data?.errors || response.response?.data?.message);
          dispatch({
            type: EDIT_COACH_ERROR,
          });
        }
      });
    } catch (error) {
      toast.error(error?.response?.data?.errors || error?.response?.data?.message);
      dispatch({
        type: EDIT_COACH_ERROR,
      });
    }
  },

  getCoachProfileSuper: (data) => async (dispatch) => {
    try {
      dispatch({
        type: COACH_DETAILS_REQUEST,
      });
      await getSuperAdminProfile(data).then((response) => {
        if (response.data.code === 200) {
          dispatch({
            type: COACH_DETAILS_SUCCESS,
            payload: {
              data: response.data.data,
            },
          });
        } else {
          dispatch({
            type: COACH_DETAILS_ERROR,
            payload: {
              message: response.message,
            },
          });
        }
      });
    } catch (error) {
      dispatch({
        type: COACH_DETAILS_ERROR,
        payload: {
          message: "Error Loading Coach Details",
        },
      });
    }
  },

  getCoachDetails: (data) => async (dispatch) => {
    try {
      dispatch({
        type: COACH_DETAILS_REQUEST,
      });
      await getCoachDetails(data).then((response) => {
        if (response.data.code === 200) {
          dispatch({
            type: COACH_DETAILS_SUCCESS,
            payload: {
              data: response.data.data,
            },
          });
        } else {
          dispatch({
            type: COACH_DETAILS_ERROR,
            payload: {
              message: response.message,
            },
          });
        }
      });
    } catch (error) {
      dispatch({
        type: COACH_DETAILS_ERROR,
        payload: {
          message: "Error Loading Coach Details",
        },
      });
    }
  },

  clearCoachEventsById: () => (dispatch) => {
    dispatch({
      type: CLEAR_COACH_EVENT_DETAILS,
    });
  },

  getCoachEventsByID: (data) => async (dispatch) => {
    try {
      dispatch({
        type: COACH_EVENT_DETAIL_REQUEST,
      });
      await getCoachEventsByID(data).then((response) => {
        console.log(response);
        if (response.data.code === 200) {
          dispatch({
            type: COACH_EVENT_DETAIL_SUCCESS,
            payload: {
              data: response.data.data,
            },
          });
        } else {
          dispatch({
            type: COACH_EVENT_DETAIL_ERROR,
            payload: {
              message: response.message,
            },
          });
        }
      });
    } catch (error) {
      dispatch({
        type: COACH_EVENT_DETAIL_ERROR,
        payload: {
          message: "Error getting coacch events",
        },
      });
    }
  },

  deleteCoach: (data, history) => async (dispatch) => {
    try{
      await deleteCoach(data).then((response) => {
        if (response.data.code === 200) {
          toast.success('Coach deleted');
          dispatch(postsActions.popupHandler({type: 'CLOSE'}))
          history.push('/admin/coaches');
        } else {
          toast.error('Error deleting coach');
        }
      });
    }catch(error){
      toast.error('Error deleting coach: '+error);
    }
  },

  assignCoach: (data) => async (dispatch) => {
    try {
      dispatch({
        type: ASSIGN_COACH_REQUEST,
      });
      await assignCoachtoEvent(data).then((response) => {
        if (response.data.code === 200) {
          dispatch({
            type: ASSIGN_COACH_SUCCESS,
          });
          dispatch(actions.getCoachAssignedEvents(data.coachId));
          toast.success("Event assigned");
        } else {
          dispatch({
            type: ASSIGN_COACH_ERROR,
            payload: {
              message: response.message,
            },
          });
          toast.error("Error: " + response.message);
        }
      });
    } catch (error) {
      dispatch({
        type: ASSIGN_COACH_ERROR,
        payload: {
          message: "Error assigning coach",
        },
      });
    }
  },

  coachSearch: (data) => async (dispatch) => {
    try {
      dispatch({
        type: COACH_SEARCH_REQUEST,
      });
      await coachSearch(data).then((response) => {
        if (response.data.code === 200) {
          dispatch({
            type: COACH_SEARCH_SUCCESS,
            payload: {
              data: response.data.data,
            },
          });
        } else {
          dispatch({
            type: COACH_SEARCH_ERROR,
          });
        }
      });
    } catch (error) {
      dispatch({
        type: COACH_SEARCH_ERROR,
      });
    }
  },

  resetCoachSearch: () => async (dispatch) => {
    dispatch({
      type: CLEAR_SEARCH,
    });
  },
};

export default actions;
