import React , {useEffect} from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom';

import membersAction from "../../../redux/actions/membersAction";

import './schoolStyle.scss'

export const DeleteMembers = (props) => {
    
    const history = useHistory();

    const deleteMembers = () => {
        props.dispatch(membersAction.deleteMembers({ members: props.selectedPost }, history));
        
    }
    
    return (
        <div>
        <h3 className='blue bold'>Delete Member(s)</h3>
        <span className='font-20 blue mb-5'>Are you sure you want to delete the selected member(s)?</span>
        <div className='white  bold red-bg add_school_btn_popup' 
        onClick={() => deleteMembers()}
            >
                Delete
        </div>
    </div>
    )
}

const mapStateToProps = (state) => ({
    selectedPost: state.postReducer?.selectedPost,
})


export default connect(mapStateToProps)(DeleteMembers)
