import React from 'react';
import { connect } from 'react-redux';
//REDUX
import eventActions from "../../../redux/actions/eventsActions"
import popupAction from "../../../redux/actions/postsActions"

//images
import LineRedImg from '../../../assets/images/Line-red.svg';

export const ConfirmCancelEvent = (props) => {

	const handleCancelEvent = () => {
		props.dispatch(eventActions.cancelEvent({ id: props.selectedPost }))
		props.dispatch(popupAction.popupHandler({ type: "CLOSE" }))
	}
	return (
		<div>
			<div className='form_style'>
				<img src={LineRedImg} />
			</div>
			<div className='form_title blue font-36 bold text-upper'>
				Are you sure you want to cancel the event?
			</div>
			<div className='d-flex flex-row mt-5'>
				<div onClick={() => { props.dispatch(popupAction.popupHandler({ type: "CLOSE" })) }} className='white text-upper bold font-18' style={styles.blue_btn}>
					Go back
				</div>
				<div onClick={() => { handleCancelEvent() }} className='white bold text-upper font-18' style={styles.red_btn}>
					PROCEED
				</div>
			</div>
		</div>
	);
};

const styles = {
	red_btn: {
		width: '200px',
		background: '#d91c24',
		height: '70px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	blue_btn: {
		width: '200px',
		marginRight: '20px',
		background: '#262262',
		height: '70px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
};

const mapStateToProps = (state) => ({
	selectedPost: state.postReducer?.selectedPost,
});

export default connect(mapStateToProps)(ConfirmCancelEvent);
