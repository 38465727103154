import React, { useEffect } from 'react';
import { coachHandle } from '../../../utils/global';
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom"


import Dashboard from '../../../Components/Dashboard/Dashboard';

const DashboardPage = (props) => {
	const history = useHistory()
	useEffect(() => {
		coachHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null);
		// if (props.user?.roles[0]?.name !== 'admin') {
		// 	console.log(props.user?.roles[0]?.name)
		// 	history.push("/coach/events")
		// }
	}, []);
	return <Dashboard />;
};




const mapStateToProps = (state) => ({
	user: state.register.user
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage)
