import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import DefaultLayout from '../Containers/Layouts/Default/DefaultLayout';
import CoachLayout from "../Containers/Layouts/Default/CoachLayout"

const CoachPrivateRoute = ({
	component: Component,
	auth,
	// auth: { token, userType, loginPath, isLoggingOut },
	...rest
}) => {
	// const routeBasedOnRoles = (props) => {
	// 	if (auth?.roles?.length > 0) {
	// 		if (auth?.roles[0]?.name === 'admin') {
	// 			return (
	// 				<DefaultLayout>
	// 					<Component {...props} />
	// 				</DefaultLayout>
	// 			)
	// 		} else if (auth?.roles[0]?.name === "coach") {
	// 			return (
	// 				<CoachLayout>
	// 					<Component {...props} />
	// 				</CoachLayout>
	// 			)
	// 		}
	// 	}
	// }
	// if (token) {
	// 	console.log("Redirecting from auth route to corresponding dashboard as per user");
	// 	switch (userType) {
	// 		case USER_TYPE_ADMIN:
	// 			return <Redirect to={loginPath ? loginPath : "/admin/dashboard"} />;
	// 		case USER_TYPE_COACH:
	// 			return <Redirect to={loginPath ? loginPath : "/coach/events"} />;
	// 		default:
	// 			return <Redirect to="/" />;
	// 	}
	// }

	return (
		<Route
			{...rest}
			render={(props) =>
				auth.access_token != null ? (
					<CoachLayout>
						<Component {...props} />
					</CoachLayout>
				) : (
						<Redirect to={`/login`} />
						// <Redirect to="/admin/dashboard" />
					)
			}
		/>
	);
};

const mapStateToProps = (state) => ({
	auth: state.register,
});

export default connect(mapStateToProps, {})(CoachPrivateRoute);
