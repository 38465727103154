import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import homeActions from '../../../redux/actions/homeActions'
import HomeCarouselCard from '../../../Containers/Pages/HomePageSetup/HomeCarouselCard';
import CircularProgress from '@material-ui/core/CircularProgress';

//CSS
import './popup.css';

export const DeleteHomeCarouselItem = (props) => {
    
    const [itemId, setItemId] = useState(props?.selectedData?.id || '')

    useEffect(() => {
        setItemId(props?.selectedData?.id ?  props?.selectedData?.id : null)
        console.log(props?.selectedData)
     }, [props.selectedData])

    const deleteItem=()=>{
        props.deleteCarouselItem(itemId)
        // console.log('%cData To Be Deleted, DELETE CAROUSEL ITEM WHERE ID = ','color:red;text-decoration:underline',itemId)
    }

    return (
        <div>
            <h3 className='blue bold'>DELETE CAROUSEL ITEM</h3>
            <HomeCarouselCard data={props?.selectedData} type="delete" />
            <span className='font-20 blue mb-5'>Are you sure you want to delete this carousel item?</span>
            <div className='white  bold red-bg popup_button d-flex justify-content-center align-items-center pointer mt-3'
            onClick={() => deleteItem()}
                >
                    {props?.deleteCarouselItemLoading?
							<div className='text-center mx-auto mt-4'>
								<CircularProgress color='primary' size={30} />
							</div>
							:"DELETE"}
                    
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedData: state.postReducer.selectedPost,
    deleteCarouselItemLoading:state.homeReducer.deleteCarouselItemLoading,
})

const mapDispatchToProps = (dispatch)=>({
    deleteCarouselItem:(id)=>{dispatch(homeActions.deleteCarouselItem(id))}
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteHomeCarouselItem)
