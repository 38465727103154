import { combineReducers } from 'redux';
import register from '../../reducers/auth';
// import eventReducer from '../../reducers/event';
import storeReducer from '../../reducers/store';
import coachreducers from '../reducers/coachreducer';
import postReducer from "../reducers/postReducer";
import eventReducer from "../reducers/eventReducer";
import profileReducer from "../reducers/profileReducer";
import membersReducers from '../reducers/membersReducers';
import coachPortal from "../reducers/coachPortalReducer";
import dashboard from "../reducers/dashboardReducer"
import homeReducer from './homeReducer';
import order from './ordersReducer';
import adminReducer from './adminReducer';
import ShopReducer from './ShopReducer';
import couponReducer from '../reducers/couponReducer'

export default combineReducers({
	register,
	eventReducer,
	storeReducer,
	coachreducers,
	postReducer,
	profileReducer,
	membersReducers,
	adminReducer,
	order,
	coachPortal,
	dashboard,
	homeReducer,
	ShopReducer,
	couponReducer
});
