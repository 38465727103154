import {
    getOrders,
    getOrderItems
  } from "../../config/config";

export const SET_SELECTED_ORDER_NULL = "SET_SELECTED_ORDER_NULL";

export const LIST_ORDERS_REQUEST = "LIST_ORDERS_REQUEST";
export const LIST_ORDERS_SUCCESS = "LIST_ORDERS_SUCCESS";
export const LIST_ORDERS_ERROR = "LIST_ORDERS_ERROR";

export const ORDER_ITEMS_REQUEST = "ORDER_ITEMS_REQUEST";
export const ORDER_ITEMS_SUCCESS = "ORDER_ITEMS_SUCCESS";
export const ORDER_ITEMS_ERROR = "ORDER_ITEMS_ERROR";

export const HANDLE_PAGINATION = "HANDLE_PAGINATION";

const actions = {
    setSelectedOrderNull: () => async (dispatch) => {
      dispatch({
        type: SET_SELECTED_ORDER_NULL,
      });
    },
    listOrders: (data) => async (dispatch) => {
      try {
        dispatch({
          type: LIST_ORDERS_REQUEST,
        });
        await getOrders(data, "Bearer " + localStorage.access_token).then( 
          (response) => {
            if (response.data.code === 200) {
              dispatch({
                type: LIST_ORDERS_SUCCESS,
                payload: {
                  data: response.data.data,
                },
              });
            } else {
              dispatch({
                type: LIST_ORDERS_ERROR,
                payload: {
                  message: response.message,
                },
              });
            }
          }
        );
      } catch (error) {
        dispatch({
          type: LIST_ORDERS_ERROR,
          payload: {
            message: "Error Loading Orders",
          },
        });
      }
    },
    orderItems: (data) => async (dispatch) => {
      try {
        dispatch({
          type: ORDER_ITEMS_REQUEST,
        });
        await getOrderItems(data, "Bearer " + localStorage.access_token).then( 
          (response) => {
            if (response.data.code === 200) {
              dispatch({
                type: ORDER_ITEMS_SUCCESS,
                payload: {
                  data: response.data.data,
                },
              });
            } else {
              dispatch({
                type: ORDER_ITEMS_ERROR,
                payload: {
                  message: response.message,
                },
              });
            }
          }
        );
      } catch (error) {
        dispatch({
          type: LIST_ORDERS_ERROR,
          payload: {
            message: "Error Loading Orders",
          },
        });
      }
    },
    handlePagination: (data) => async (dispatch) => {
        dispatch({
            type: HANDLE_PAGINATION,
            payload: {
                data: data,
            }
        })
    }
}
  
  export default actions;