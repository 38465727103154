import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import logo from '../../../assets/images/logo.svg';
import { getValue } from '../../../utils/app';

const CoachSidebar = (props) => {
    return (
        <div
            className={`left-panel blue-bg ${props.isSidebarMenuOpen ? '' : 'hide-1024'
                }`}
        >

            <div className='logo'>
                <Link to="/coach/events">
                    <img src={logo} alt='logo' />
                </Link>
            </div>

            <div className='panel-menu'>

                <ul className='list-unstyled'>
                    {/* THIS IS FOR THE COACH PORTAL */}
                    <NavLink to="/coach/events" className="menu_item" activeClassName="menu_item_active">
                        <span className='menu-icon'>
                            <svg
                                id='Layer_1'
                                data-name='Layer 1'
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 16 16'
                            >
                                <defs>
                                    <clipPath id='clip-path' transform='translate(0 0)'>
                                        <rect width='16' height='16' style={{ fill: 'none' }} />
                                    </clipPath>
                                </defs>
                                <title>calendar</title>
                                <g style={{ clipPath: 'url(#clip-path)' }}>
                                    <g id='calendar'>
                                        <rect
                                            id='Rectangle_557'
                                            data-name='Rectangle 557'
                                            width='16'
                                            height='16'
                                            style={{ fill: 'none' }}
                                        />
                                        <path
                                            id='Path_149'
                                            data-name='Path 149'
                                            d='M2,5v9H14V5ZM13,2h2a1,1,0,0,1,1,.89V15a1,1,0,0,1-.89,1H1a1,1,0,0,1-1-.89V3A1,1,0,0,1,.89,2H3V1a1,1,0,0,1,.89-1H4A1,1,0,0,1,5,.89V2h6V1a1,1,0,0,1,2,0ZM12,12H10V10h2ZM9,12H7V10H9Zm3-3H10V7h2ZM9,9H7V7H9ZM6,12H4V10H6Z'
                                            transform='translate(0 0)'
                                            style={{ fill: '#686692', fillRule: 'evenodd' }}
                                        />
                                    </g>
                                </g>
                            </svg>
                        </span>
                        Events
                    </NavLink>
                </ul>

            </div>
        </div>
    );
};

export default CoachSidebar;
