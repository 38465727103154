import {
    GET_ASSIGNED_COACH_EVENTS_REQUEST, GET_ASSIGNED_COACH_EVENTS_SUCCESS, GET_ASSIGNED_COACH_EVENTS_ERROR,
    GET_ASSIGNED_COACH_EVENTS_DETAILS_REQUEST, GET_ASSIGNED_COACH_EVENTS_DETAILS_SUCCESS, GET_ASSIGNED_COACH_EVENTS_DETAILS_ERROR,
    GET_ASSIGNED_COACH_PROGRAMS_REQUEST, GET_ASSIGNED_COACH_PROGRAMS_SUCCESS, GET_ASSIGNED_COACH_PROGRAMS_ERROR,
    GET_ASSIGNED_COACH_PROGRAMS_DETAILS_REQUEST, GET_ASSIGNED_COACH_PROGRAMS_DETAILS_SUCCESS, GET_ASSIGNED_COACH_PROGRAMS_DETAILS_ERROR,
    SEND_MAIL_COACH_REQUEST, SEND_MAIL_COACH_SUCCESS, SEND_MAIL_COACH_ERROR

} from '../actions/coachPortalActions';


const initialState = {
    assignedCoachEvents: [],
    assignedCoachEventsLoading: false,

    assignedCoachEventsDetails: [],
    assignedCoachEventsDetailsLoading: false,

    assignedCoachPrograms: [],
    assignedCoachProgramsLoading: false,

    assignedCoachProgramsDetails: [],
    assignedCoachProgramsDetailsLoading: false,

    sendMailCoachLoading: false,

};


function coach(state = initialState, action) {
    switch (action.type) {
        case GET_ASSIGNED_COACH_EVENTS_REQUEST:
            return Object.assign({}, state, {
                assignedCoachEventsLoading: true,
                assignedCoachEvents: [],
            });
        case GET_ASSIGNED_COACH_EVENTS_SUCCESS:
            return Object.assign({}, state, {
                assignedCoachEventsLoading: false,
                assignedCoachEvents: action.payload.data,
            });
        case GET_ASSIGNED_COACH_EVENTS_ERROR:
            return Object.assign({}, state, {
                assignedCoachEventsLoading: false,
                assignedCoachEvents: [],
            });

        case GET_ASSIGNED_COACH_EVENTS_DETAILS_REQUEST:
            return Object.assign({}, state, {
                assignedCoachEventsDetailsLoading: true,
                assignedCoachEventsDetails: [],
            });
        case GET_ASSIGNED_COACH_EVENTS_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                assignedCoachEventsDetailsLoading: false,
                assignedCoachEventsDetails: action.payload.data,
            });
        case GET_ASSIGNED_COACH_EVENTS_DETAILS_ERROR:
            return Object.assign({}, state, {
                assignedCoachEventsDetailsLoading: false,
                assignedCoachEventsDetails: [],
            });

        case GET_ASSIGNED_COACH_PROGRAMS_REQUEST:
            return Object.assign({}, state, {
                assignedCoachProgramsLoading: true,
                assignedCoachPrograms: [],
            });
        case GET_ASSIGNED_COACH_PROGRAMS_SUCCESS:
            return Object.assign({}, state, {
                assignedCoachProgramsLoading: false,
                assignedCoachPrograms: action.payload.data,
            });
        case GET_ASSIGNED_COACH_PROGRAMS_ERROR:
            return Object.assign({}, state, {
                assignedCoachProgramsLoading: false,
                assignedCoachPrograms: [],
            });


        case GET_ASSIGNED_COACH_PROGRAMS_DETAILS_REQUEST:
            return Object.assign({}, state, {
                assignedCoachProgramsDetailsLoading: true,
                assignedCoachProgramsDetails: [],
            });
        case GET_ASSIGNED_COACH_PROGRAMS_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                assignedCoachProgramsDetailsLoading: false,
                assignedCoachProgramsDetails: action.payload.data,
            });
        case GET_ASSIGNED_COACH_PROGRAMS_DETAILS_ERROR:
            return Object.assign({}, state, {
                assignedCoachProgramsDetailsLoading: false,
                assignedCoachProgramsDetails: [],
            });


        case SEND_MAIL_COACH_REQUEST:
            return Object.assign({}, state, {
                sendMailCoachLoading: true,
            });
        case SEND_MAIL_COACH_SUCCESS:
            return Object.assign({}, state, {
                sendMailCoachLoading: false,
            });
        case SEND_MAIL_COACH_ERROR:
            return Object.assign({}, state, {
                sendMailCoachLoading: false,
            });

        default:
            return state;
    }
}

export default coach;
