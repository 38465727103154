import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment'
import { calculateAge } from '../../utils/app';

import { baseURL } from '../../config/config';

//Images
import arrow from '../../assets/images/right-arrow-blck.svg';
import Checked from '../../assets/images/CheckBoxChecked.png';
import Unchecked from '../../assets/images/CheckBoxUnchecked.png';
import redVoucher from '../../assets/images/voucher_red.svg';

//redux
import memberActions from '../../redux/actions/membersAction';
import postActions from '../../redux/actions/postsActions';
function KidsRow(props) {
	const handleVoucher = () => {
		props.dispatch(
			memberActions.generateVoucher({
				parent: props.parent_id,
				child: props.id,
			})
		);
	};
	const [selected, setSelected] = useState(false);


	return (
		<div className='coach_row_table'>
			<div className='coach_row_row'>
				<div className='coach_row_col check_col wi-8'>
					<img
						src={!props.checked ? Unchecked : Checked}
						alt=''
						onClick={() => {
							props.handleCheckKid(props.id);
						}}
						className='check_box_member'
					/>
				</div>
				<div className='coach_row_col member_img wi-13'>
					{props.profile_image ? 
					<img
						src={baseURL + '/image/' + props.profile_image}
						alt=''
						className='coach_img'
					/>
					: `N/A`}
				</div>
				<div className='coach_row_col wi-13'>{props.first_name}</div>
				<div className='coach_row_col wi-13'>{props.last_name}</div>
				<div className='coach_row_col wi-13'>{moment(props.birthday_date).format("DD/MM/YYYY")}</div>
				<div className='coach_row_col wi-10'>{calculateAge(props?.birthday_date)}</div>
				{/* <div className='coach_row_col wi-13'>{moment()?.diff(moment(props?.birthday_date),'years')}</div> */}
				<div className='coach_row_col wi-21'>{props.school_name}</div>
				<div className='coach_row_col wi-16 d-flex flex-column align-items-center justify-content-center'>
					{/* <div
						onClick={() => {
							handleVoucher();
						}}
						className='pointer'
					>
						<img src={redVoucher} alt='' className='mr-2' />
						<span className='blue bold font-14 text-upper'>Birthday Special</span>
					</div> */}
					{/* <hr /> */}
					<div className='pointer blue bold mt-2' onClick={() => props.dispatch(
									postActions.popupHandler({ type: 'VOUCHER', data: {parent: props.parent_id,
										child: props.id} })
								)}>
						<img src={redVoucher} alt='' className='mr-2' />	
						<span className='blue bold font-14 text-upper'>CUSTOM VOUCHER</span>
						
					</div>
				</div>
				<div className='coach_row_col coach_detail_link wi-10'>
					<Link to={`/admin/members/${props.parent_id}/children/${props.id}/edit`}>
						<div className='arrow_div'>
							<img src={arrow} alt='view/edit' />
						</div>
					</Link>
				</div>
			</div>
		</div>
	);
}
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(KidsRow);
