import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format';

//Redux
import coachPortalActions from "../../../../redux/actions/coachPortalActions"

//img
import backArrow from '../../../../assets/images/arrow-red-left.svg';
import leftArrowImg from '../../../../assets/images/arrow-left-blue.svg';
import rightArrowImg from '../../../../assets/images/right-arrow-blck.svg';


//Components
import EventProgramRow from '../../../../Components/Coach Portal/EventProgramRow';

//globals
import { adminHandle } from '../../../../utils/global';
import moment from 'moment';

function EventsPrograms(props) {
	const params = useParams();
	useEffect(() => {
		props.dispatch(coachPortalActions.getPrograms({ coachId: props.user.id, eventId: params.event_id, page: 1 }))
		adminHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null)
	}, [])

	const [pages, setPages] = useState([])
	useEffect(() => {
		let finalData = []
		let firstPage = Number(props?.assignedCoachPrograms?.program?.first_page_url?.split("page=")[1])
		let lastPage = Number(props?.assignedCoachPrograms?.program?.last_page_url?.split("page=")[1])
		for (let i = firstPage; i <= lastPage; i++) {
			finalData.push({ i })
		}
		setPages(finalData)
	}, [props.assignedCoachPrograms])

	const handlePagination = (currentPage) => {
		let p = pages.map((zone, i) => {
			if (currentPage === zone?.i) {
				return (
					<div key={i} style={{ color: "red", marginLeft: 2, marginRight: 2 }}>
						{zone?.i}
					</div>
				)
			} else {
				return (
					<div key={i} style={{ marginLeft: 2, marginRight: 2 }}
						onClick={() => {
							handlePaginationDispatch(zone?.i);
						}}
					>
						{zone?.i}
					</div>
				)
			}
		})

		let first = null
		let last = null
		if (currentPage !== pages[0]?.i) {
			first = <img
				onClick={() => {
					handlePaginationDispatch(currentPage - 1);
				}}
				src={leftArrowImg}
				alt='back'
			/>
		}
		if (currentPage !== pages[pages.length - 1]?.i) {
			last = <img
				onClick={() => {
					handlePaginationDispatch(currentPage + 1);
				}}
				src={rightArrowImg}
				alt='back'
			/>
		}
		let final = <div style={{ display: "flex" }}>
			{first}{(first || last)?p:null}{last}
		</div>
		return (final)
	}

	const handlePaginationDispatch = (e) => {
		props.dispatch(coachPortalActions.getPrograms({ coachId: props.user.id, eventId: params.event_id, page: e }))
	}
	console.log(props.assignedCoachPrograms)
	return (
		<section>
			<div className='event_detail_top_div'>
				<div className='left_detail_top'>
					{console.log(props.user.id,params.event_id)}
					<Link to={`/coach/events/${params.event_id}`}>
						<div className='go_back_arrow'>
							<img src={backArrow} alt='Go Back' />
						</div>
					</Link>
					<div className='bold blue font-36 text-upper float-left'>
						{props?.assignedCoachPrograms?.event?.title}
					</div>
				</div>
				<div>
					{props?.eventDetailsLoading && (
						<div className='text-center mx-auto mt-4'>
							<CircularProgress color='primary' size={30} />
						</div>
					)}
				</div>
			</div>
			<div className='event_detail_mainbody'>
				<span className='blue bold text-upper font-20'>event DETAILS</span>
				<div className='event_detail_mainrow'>
					<div className='program_half'>
						<div className='event_program_mainrow_col'>
							<span className='bold blue font-16 text-upper mb-2'>
								EVENT VENUE
							</span>
							<span className='blue font-16 text-upper'>
								{props?.assignedCoachPrograms?.event?.location}
							</span>
						</div>
						<div className='event_program_mainrow_col'>
							<span className='bold blue font-16 text-upper mb-2'>
								EVENT DATE
							</span>
							<span className='blue font-16 text-upper'>
								{props?.assignedCoachPrograms?.event?.start_date?moment(props?.assignedCoachPrograms?.event?.start_date).format("DD/MM/YYYYY"):''}
							</span>
							<span className='blue font-16 text-upper'>
								{props?.assignedCoachPrograms?.event?.end_date?moment(props?.assignedCoachPrograms?.event?.end_date).format("DD/MM/YYYY"):''}
							</span>
						</div>
					</div>
					<div className='program_half'>
						<div className='event_program_mainrow_col'>
							<span className='bold blue font-16 text-upper mb-2'>
								TOTAL BOOKING
							</span>
							<div className='detail_total_event_sale'>
								<p className='font-16 blue'>
									<NumberFormat value={props?.assignedCoachPrograms?.total_event_booking} displayType={'text'} thousandSeparator={true}/>
									{/*props?.assignedCoachPrograms?.total_event_booking*/}
								</p>
							</div>
						</div>
						<div className='event_program_mainrow_col'>
							<span className='bold blue font-16 text-upper mb-2'>
								Total Check Ins
							</span>
							<div className='detail_total_event_sale'>
								{/* to add in api */}
								<p className='font-16 blue'>
									<NumberFormat value={props?.assignedCoachPrograms?.total_event_booking} displayType={'text'} thousandSeparator={true}/>
									{/*props?.assignedCoachPrograms?.total_children_check_in*/}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='event_detail_purchase_list'>
					<div className='events_detail_list_top'>
						<div className='events_detail_table_header wi-13'>
							Event Programs
						</div>
						<div className='events_detail_table_header wi-13'>date</div>
						<div className='events_detail_table_header wi-21'>event title</div>
						{/* <div className='events_detail_table_header wi-17'>coach name</div> */}
						<div className='events_detail_table_header wi-10'>bookings</div>
						<div className='events_detail_table_header wi-16'>attendance</div>
						<div className='events_detail_table_header wi-16'>action</div>
					</div>
					<div className='event_purchaser_container'>
						{props.assignedCoachProgramsLoading ? (
							<div className='text-center mx-auto mt-4'>
								<CircularProgress color='primary' size={30} />
							</div>
						) : (
							props.assignedCoachPrograms?.program?.data?.map((zone, i) => {
								console.log('bookings' , zone)
								let data = (
									<EventProgramRow
										key={i}
										id={zone?.recurrence_id}
										start_date={zone?.recurrence_start_date?moment(zone?.recurrence_start_date).format("DD/MM/YYYY"):''}
										end_date={zone?.recurrence_end_date?moment(zone?.recurrence_end_date).format("DD/MM/YYYY"):''}
										title={props?.assignedCoachPrograms?.event?.title}
										coach_name={zone?.coach_name}
										booking_count={zone?.total_booking}
										attended={zone?.total_Attendees}
										status={zone?.status}
										program={props?.assignedCoachPrograms?.event?.title}
									/>
								);
								return data;
							})
						)}
						{handlePagination(props?.assignedCoachPrograms?.program?.current_page)}

					</div>
				</div>
			</div>
		</section>
	);
}




const mapStateToProps = (state) => ({
	user: state.register.user,
	assignedCoachPrograms: state.coachPortal.assignedCoachPrograms,
	assignedCoachProgramsLoading: state.coachPortal.assignedCoachProgramsLoading,
})


export default connect(mapStateToProps)(EventsPrograms)
