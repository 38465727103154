import React from 'react';
import { Link } from 'react-router-dom';

//REDUX
import { baseURL } from '../../../config/config';

import './Profile.scss';
import editPen from '../../../assets/images/edit-pen.svg';

// this are the detail images
import phone from '../../../assets/images/phone.svg';
import email from '../../../assets/images/mail.svg';
import address from '../../../assets/images/pin-location.svg';
import { connect } from 'react-redux'
import profileActions from '../../../redux/actions/profileActions';
import coachActions from '../../../redux/actions/coachActions';



function Profile(props) {
	
	const [isCoach,setIsCoach] = React.useState(false)
	React.useEffect(() => {
		if(props?.user?.roles[0]?.id===2){
			props.dispatch(coachActions.getCoachDetails({id:props?.user?.uuid}))
			setIsCoach(true)
		}else{
			props.dispatch(profileActions.getSuperProfile(props?.user?.uuid))
		}
	},[])


	return (
		<section>
			<div className='profile_detail_top_div'>
				<div className='left_detail_top'>
					<div className='bold blue font-36 text-upper float-left'>
						{`Hello ${isCoach?props?.coachDetails.first_name:props?.userProfileData.first_name}`}
					</div>
				</div>
				<div className='left_detail_top'>
					<Link
						to="/admin/profile/edit"
						className='edit_profile bold'
					>
						<img src={editPen} alt='' />
						<div className='link_text_div'>
							<span>EDIT</span>
						</div>
					</Link>
				</div>
			</div>
			<div className='profile_detail_body'>
				<div className='main_profile_info'>
					<div className='profile_pfp'>
						<img
							src={baseURL + '/image/' + (isCoach?props?.coachDetails.profile_picture:props?.userProfileData?.profile_picture)}
							alt=''
						/>
					</div>
				</div>
				<div className='main_profile_info'>
					<div className='profile_phone_num'>
						<img src={phone} alt='phone' />
						{isCoach?props?.coachDetails.mobile_phone:props?.userProfileData.mobile_phone}
					</div>
					<div className='profile_email'>
						<img src={email} alt='email' />
						{isCoach?props?.coachDetails.email:props?.userProfileData.email}
					</div>
					{props?.coachDetails?.street_address1 || props?.userProfileData.street_address1 ? 
					<div className='profile_address'>
						<img src={address} alt='address' />
						{isCoach?props?.coachDetails.street_address1:props?.userProfileData.street_address1}, {isCoach?props?.coachDetails.street_address2:props?.userProfileData.street_address2}, {isCoach?props?.coachDetails.suburb:props?.userProfileData.suburb}
					</div>
					: null }
				</div>
			</div>
		</section>
	);
}




const mapStateToProps = (state) => ({
	user: state.register.user,
	userProfileData: state.profileReducer.userProfileData,
	coachDetails: state.coachreducers.coachDetails,
})


export default connect(mapStateToProps)(Profile)
