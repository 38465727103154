import {
    listAllAdmins,
    getAdminDetails,
    newAdmin,
    editAdmin,
    deleteAdmin
  } from "../../config/config";
  import { toast } from "react-toastify";
  
  export const LIST_ADMINS_REQUEST = "LIST_ADMINS_REQUEST";
  export const LIST_ADMINS_SUCCESS = "LIST_ADMINS_SUCCESS";
  export const LIST_ADMINS_ERROR = "LIST_ADMINS_ERROR";

  export const ADMIN_DETAILS_REQUEST = "ADMIN_DETAILS_REQUEST";
  export const ADMIN_DETAILS_SUCCESS = "ADMIN_DETAILS_SUCCESS";
  export const ADMIN_DETAILS_ERROR = "ADMIN_DETAILS_ERROR";

  export const ADMIN_PAGE_STATE = "ADMIN_PAGE_STATE";

  
  const actions = {
    listAllAdmins: (data) => async (dispatch) => {
      try {
        dispatch({
          type: LIST_ADMINS_REQUEST,
        });
        await listAllAdmins(data).then((response) => {
          if (response.data.code === 200) {
            dispatch({
              type: LIST_ADMINS_SUCCESS,
              payload: {
                data: response.data.data,
              },
            });
          } else {
            dispatch({
              type: LIST_ADMINS_ERROR,
            });
          }
        });
      } catch (error) {
        dispatch({
          type: LIST_ADMINS_ERROR,
        });
      }
    },
  
    newAdmin: (data) => async (dispatch) => {
      try {
        await newAdmin(data).then((response) => {
          if (response?.data.data !== 'User already exists with this email') {
            toast.success("Admin Created");
          } else {
            toast.error("User already exists with this email");
          }
        });
      } catch (error) {
        toast.error(` Error : ${error}`);
      }
    },
  
    editAdmin: (data) => async (dispatch) => {
      try {
        await editAdmin(data).then((response) => {
          if (response?.data?.code === 200) {
            toast.success("Admin edited successfully");
          } else {
            toast.error(response.response?.data?.errors || response.response?.data?.message);
          }
        });
      } catch (error) {
        toast.error(error);
      }
    },

    deleteAdmin: (data, history) => async (dispatch) => {
        try {
          await deleteAdmin(data).then((response) => {
            if (response?.data?.code === 200) {
              toast.success("Admin deleted successfully");
              history.push('/admin/super-admins');
            } else {
              toast.error(response.response?.data?.errors || response.response?.data?.message);
            }
          });
        } catch (error) {
          toast.error(error);
        }
      },
  

  
    getAdminDetails: (data) => async (dispatch) => {
      try {
        dispatch({
          type: ADMIN_DETAILS_REQUEST,
        });
        await getAdminDetails(data).then((response) => {
          if (response.data.code === 200) {
            dispatch({
              type: ADMIN_DETAILS_SUCCESS,
              payload: {
                data: response.data.data[0],
              },
            });
          } else {
            dispatch({
              type: ADMIN_DETAILS_ERROR,
            });
          }
        });
      } catch (error) {
        dispatch({
          type: ADMIN_DETAILS_ERROR,
        });
      }
    },

    changePageState: (data) => (dispatch) => {
      dispatch({
        type: ADMIN_PAGE_STATE,
        page: data,
      })
    },
  
  
  };
  
  export default actions;
  