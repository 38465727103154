export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const AUTH_FAILED = "AUTH_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const AUTH_REQUEST = "AUTH_REQUEST";
export const LOGOUT = "LOGOUT";
export const USER_LOADED = "USER_LOADED";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const RESET_PASS_LINK = "RESET_PASS_LINK";
export const PASSWORD_REST = "PASSWORD_REST";

export const FETCH_STUDENTS = "FETCH_STUDENTS";
export const ADD_EVENT = "ADD_EVENT";
export const EVENT_DETAIL = "EVENT_DETAIL";
export const EVENT_LIST_SUCCESS = "EVENT_LIST_SUCCESS";
export const EVENT_LIST_LOADING = "EVENT_LIST_LOADING";
export const EVENT_LIST_FAILURE = "EVENT_LIST_FAILURE";

export const PROGRAM_LIST_EVENT_SUCCESS = "PROGRAM_LIST_EVENT_SUCCESS";
export const PROGRAM_LIST_EVENT_LOADING = "PROGRAM_LIST_EVENT_LOADING";
export const PROGRAM_LIST_EVENT_FAILURE = "PROGRAM_LIST_EVENT_FAILURE";

export const PROGRAM_DETAIL_EVENT_SUCCESS = "PROGRAM_DETAIL_EVENT_SUCCESS";
export const PROGRAM_DETAIL_EVENT_LOADING = "PROGRAM_DETAIL_EVENT_LOADING";
export const PROGRAM_DETAIL_EVENT_FAILURE = "PROGRAM_DETAIL_EVENT_FAILURE";
export const EVENT_CATEGORIES_LIST_SUCCESS = "EVENT_CATEGORIES_LIST_SUCCESS";

export const EVENT_CANCELED_SUCCESS = "EVENT_CANCELED_SUCCESS";

export const STORE_CATEGORY_LIST_SUCCESS = "STORE_CATEGORY_LIST_SUCCESS";
export const STORE_CATEGORY_LIST_LOADING = "STORE_CATEGORY_LIST_LOADING";
export const STORE_CATEGORY_LIST_FAILURE = "STORE_CATEGORY_LIST_FAILURE";

export const STORE_PRODUCTS_LIST_SUCCESS = "STORE_PRODUCTS_LIST_SUCCESS";
export const STORE_PRODUCTS_LIST_LOADING = "STORE_PRODUCTS_LIST_LOADING";
export const STORE_PRODUCTS_LIST_FAILURE = "STORE_PRODUCTS_LIST_FAILURE";

export const STORE_PRODUCTS_ADD_SUCCESS = "STORE_PRODUCTS_ADD_SUCCESS";
export const STORE_PRODUCTS_ADD_LOADING = "STORE_PRODUCTS_ADD_LOADING";
export const STORE_PRODUCTS_ADD_FAILURE = "STORE_PRODUCTS_ADD_FAILURE";

export const STORE_PRODUCTS_EDIT_SUCCESS = "STORE_PRODUCTS_EDIT_SUCCESS";
export const STORE_PRODUCTS_EDIT_LOADING = "STORE_PRODUCTS_EDIT_LOADING";
export const STORE_PRODUCTS_EDIT_FAILURE = "STORE_PRODUCTS_EDIT_FAILURE";

export const STORE_PRODUCTS_DELETE_SUCCESS = "STORE_PRODUCTS_DELETE_SUCCESS";
export const STORE_PRODUCTS_DELETE_LOADING = "STORE_PRODUCTS_DELETE_LOADING";
export const STORE_PRODUCTS_DELETE_FAILURE = "STORE_PRODUCTS_DELETE_FAILURE";



export const CREATE_CATEGORIES_SUCCESS = "CREATE_CATEGORIES_SUCCESS";

export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_LOADING = "CATEGORY_LIST_LOADING";
export const CATEGORY_LIST_FAILURE = "CATEGORY_LIST_FAILURE";

export const RELATED_PRODUCT_LIST_SUCCESS = "RELATED_PRODUCT_LIST_SUCCESS";
export const RELATED_PRODUCT_LIST_LOADING = "RELATED_PRODUCT_LIST_LOADING";
export const RELATED_PRODUCT_LIST_FAILURE = "RELATED_PRODUCT_LIST_FAILURE";

export const STORE_PRODUCTS_DETAIL_SUCCESS = "STORE_PRODUCTS_DETAIL_SUCCESS";
export const STORE_PRODUCTS_DETAIL_LOADING = "STORE_PRODUCTS_DETAIL_LOADING";
export const STORE_PRODUCTS_DETAIL_FAILURE = "STORE_PRODUCTS_DETAIL_FAILURE";

export const CATEGORY_DETAIL = "CATEGORY_DETAIL";

export const COUPON_DETAIL = "COUPON_DETAIL";
export const COUPON_LIST_SUCCESS = "COUPON_LIST_SUCCESS";
export const COUPON_LIST_LOADING = "COUPON_LIST_LOADING";
export const COUPON_LIST_FAILURE = "COUPON_LIST_FAILURE";
export const COUPON_CATEGORIES_LIST_SUCCESS = "COUPON_CATEGORIES_LIST_SUCCESS";
export const COUPON_CANCELED_SUCCESS = "COUPON_CANCELED_SUCCESS";
