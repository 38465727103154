import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import eventActions from '../../../redux/actions/eventsActions';

//Components
import {SortableContainer, SortableElement} from 'react-sortable-hoc';


//CSS
import './Settings.scss'

import postsActions from '../../../redux/actions/postsActions';


export const EventCategoriesList = (props) => {
    const [selected, setSelected] = useState('');

    useEffect(() => {
        props.dispatch(eventActions.getEventCategories())
    }, [])

    const SortableItem = SortableElement(
        ({element}) => 
        <div className='events_row_table' id={element.id}>
        <div className='w-50 events_row_col p-2'>
            {element.title}
        </div>
        <div className='w-50 events_row_col p-2'>
            {element.name}
        </div>
        <div className='w-25 events_row_col p-2'>
            <button className='white bold blue-bg' onClick={() => {
                 props.dispatch(
                postsActions.popupHandler({ type: 'EDIT_EVENT_CATEGORY', data: element })
                );
            }}>EDIT</button>    
        </div>
        </div>
    );
    
    const SortableList = SortableContainer(({items}) => {
      return (
        <div>
          {items.map((value, index) => (
            <SortableItem key={value.id} index={index} element={value}/>
          ))}
        </div>
      );
    });


    const onSortStart = ({node})=>{
        setSelected(node.id);
    }

    const onSortEnd = ({oldIndex, newIndex}) => {
        const itemsCopy = props.eventCategories.slice();
        const oldIndexBackup = itemsCopy[oldIndex];
        itemsCopy[oldIndex] = itemsCopy[newIndex];
        itemsCopy[newIndex] = oldIndexBackup;
        props.dispatch(eventActions.editCategoryOrder({'old_position_category_id': selected, 'new_position_category_id' : itemsCopy[oldIndex].id , 'new_position': newIndex, 'old_position': oldIndex}));
    };

    return (
        <section>
            <div className="d-flex justify-content-end">
                <div className='white bold blue-bg add_school_btn mb-2' onClick={() => {
                    props.dispatch(postsActions.popupHandler({ type: 'ADD_EVENT_CATEGORY'}))}}>
                        New Category
                </div>
                
            </div>
            <div className='school_list_main_body'>
                <div className='events_list_top'>
                    <div className='events_table_header w-50'>CATEGORY TITLE</div>
                    <div className='events_table_header w-50'>CATEGORY NAME</div>
                    <div className='events_table_header w-50'>OPTIONS</div>
                </div>
                <div className='events_list_container'>
                {props.eventCategories?.length > 0 ?
                <SortableList items={props.eventCategories} onSortStart={onSortStart} onSortEnd={onSortEnd} lockAxis='y'/>
                :
                props?.eventCategoriesLoading?
                <div className='text-center mx-auto mt-4'>
                    <CircularProgress color='primary' size={30} />
                </div>:
                <div>No data Available</div>
                }
                    {/* {props?.evenyByCategoryIDLoading === true ? (
                        <div className='text-center mx-auto mt-4'>
                            <CircularProgress color='primary' size={30} />
                        </div>
                    ) : props?.evenyByCategoryID?.data?.length > 0 ? (
                        props?.evenyByCategoryID?.data?.map((zone, i) => {
                            let data = (
                                <div className='events_row_table'>
                                    <div className='w-75 events_row_col'>SCHOOOL</div>
                                    <div className='w-25 events_row_col'>
                                        <span onClick={() => console.log('open edit popup')}>EDIT</span>    
                                    </div>
                                </div>
                                );
                                return data;
                            })
                            ) : (
                                <h2 className='no_item_available'>No Schools Available</h2>
                                )} */}
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (state) => ({
    eventCategories:state.eventReducer.eventCategories,
    eventCategoriesLoading:state.eventReducer.eventCategoriesLoading,
})

export default connect(mapStateToProps)(EventCategoriesList)
