import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

//Config
import { baseURL } from '../../config/config';

//CSS
import './MembersRow.css';

//Images
import arrow from '../../assets/images/right-arrow-blck.svg';
import brokenIMG from '../../assets/images/brokenIMG.png';

function ChildRow(props) {

	const changeImageToDefault = (e) => {
		e.target.src = brokenIMG
	}


	return (
		<div className='coach_row_table'>
			<div className='coach_row_row'>
				<div className='coach_row_col member_img wi-13'>
					<img
						src={baseURL + '/image/' + props?.profile_img}
						alt=''
						className='coach_img'
						onError={changeImageToDefault}
					/>
				</div>
				<div className='coach_row_col wi-13'>{props?.first_name}</div>
				<div className='coach_row_col wi-13'>{props.last_name}</div>
				<div className='coach_row_col wi-21'>
					<div>
						<p>{props.medical_details ? props.medical_details :`N/A`}</p>
					</div>
				</div>
				<div className='coach_row_col wi-13'>
					{props.school}
				</div>
				<div className='coach_row_col wi-13'>
					{moment(props.date_of_birth).format('DD/MM/YYYY')}
				</div>
				<div className='coach_row_col coach_detail_link wi-10'>
					<Link to={`/admin/members/${props.parent_id}/children/${props.link}/edit`}>
						<div className='arrow_div'>
							<img src={arrow} alt='view/edit' />
						</div>
					</Link>
				</div>
			</div>
		</div>
	);
}

export default ChildRow;
