import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { adminHandle } from '../../../../utils/global';
import NumberFormat from 'react-number-format';

//redux
// import eventActions from '../../../redux/actions/eventsActions';
import coachPortalActions from "../../../../redux/actions/coachPortalActions"


//images
import backArrow from '../../../../assets/images/arrow-red-left.svg';
import leftArrowImg from '../../../../assets/images/arrow-left-blue.svg';
import rightArrowImg from '../../../../assets/images/right-arrow-blck.svg';

//component
import ProgramChildRow from '../../../../Components/Events/ProgramChildRow';
import moment from 'moment';

function EventProgramDetail(props) {
	const params = useParams();
	useEffect(() => {
		adminHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null);
		props.dispatch(coachPortalActions.getProgramsDetails({ programId: params.program_id, eventId: params.event_id, page: 1 }))

	}, []);

	const [pages, setPages] = useState([])
	useEffect(() => {
		let finalData = []
		let firstPage = Number(props.assignedCoachProgramsDetails?.bookin_details?.first_page_url?.split("page=")[1])
		let lastPage = Number(props.assignedCoachProgramsDetails?.bookin_details?.last_page_url?.split("page=")[1])
		for (let i = firstPage; i <= lastPage; i++) {
			finalData.push({ i })
		}
		setPages(finalData)
	}, [props.assignedCoachProgramsDetails])

	const handlePagination = (currentPage) => {
		let p = pages.map((zone, i) => {
			if (currentPage === zone?.i) {
				return (
					<div key={i} style={{ color: "red", marginLeft: 2, marginRight: 2 }}>
						{zone?.i}
					</div>
				)
			} else {
				return (
					<div key={i} style={{ marginLeft: 2, marginRight: 2 }}
						onClick={() => {
							handlePaginationDispatch(zone?.i);
						}}
					>
						{zone?.i}
					</div>
				)
			}
		})

		let first = null
		let last = null
		if (currentPage !== pages[0]?.i) {
			first = <img
				onClick={() => {
					handlePaginationDispatch(currentPage - 1);
				}}
				src={leftArrowImg}
				alt='back'
			/>
		}
		if (currentPage !== pages[pages.length - 1]?.i) {
			last = <img
				onClick={() => {
					handlePaginationDispatch(currentPage + 1);
				}}
				src={rightArrowImg}
				alt='back'
			/>
		}
		let final = <div style={{ display: "flex" }}>
			{first}{(first || last)?p:null}{last}
		</div>
		return (final)
	}

	const handlePaginationDispatch = (e) => {
		// props.dispatch(coachPortalActions.listCoaches({ id: props.user.id, page: e }))
		props.dispatch(coachPortalActions.getProgramsDetails({ programId: params.program_id, eventId: params.event_id, page: e }))

	}
	console.log(props?.assignedCoachProgramsDetails)

	return (
		<section>
			<div className='event_detail_top_div'>
				<div className='left_detail_top'>
					<Link to={`/coach/events/${params.event_id}/programs`}>
						<div className='go_back_arrow'>
							<img src={backArrow} alt='Go Back' />
						</div>
					</Link>
					<div className='bold blue font-36 text-upper float-left'>
						{props?.assignedCoachProgramsDetails?.event_details?.title}
					</div>
				</div>
			</div>
			<div>
				{props?.eventDetailsLoading && (
					<div className='text-center mx-auto mt-4'>
						<CircularProgress color='primary' size={30} />
					</div>
				)}
			</div>
			<div className='event_detail_mainbody'>
				<span className='blue bold text-upper font-20'>event DETAILS</span>
				<div className='event_detail_mainrow'>
					<div className='program_half'>
						<div className='event_program_detail_mainrow_col'>
							<span className='bold blue font-16 text-upper mb-2'>
								{props?.assignedCoachProgramsDetails?.program?.title}
							</span>
						</div>
						<div className='event_program_detail_mainrow_col'>
							<span className='bold blue font-16 text-upper mb-2'>Date</span>
							<span className='blue font-16 text-upper'>
								{props?.assignedCoachProgramsDetails?.event_details?.start_date?moment(props?.assignedCoachProgramsDetails?.event_details?.start_date).format('DD/MM/YYYY'):''}
							</span>
						</div>
						<div className='event_program_detail_mainrow_col'>
							<span className='bold blue font-16 text-upper mb-2'>
								EVENT title
							</span>
							<span className='blue font-16 text-upper'>
								{props?.assignedCoachProgramsDetails?.event_details?.title}
							</span>
						</div>
						<div className='event_program_detail_mainrow_col'>
							<span className='bold blue font-16 text-upper mb-2'>
								coach name
							</span>
							<span className='blue font-16 text-upper'>
								{props?.assignedCoachProgramsDetails?.event_details?.coach_name}
							</span>
						</div>
					</div>
					<div className='program_half2'>
						<div className='event_program_mainrow_col'>
							<span className='bold blue font-16 text-upper mb-2'>
								TOTAL BOOKING
							</span>
							<div className='detail_total_event_sale'>
								<p className='font-16 blue'><NumberFormat value={props?.assignedCoachProgramsDetails?.total_event_booking} displayType={'text'} thousandSeparator={true}/></p>
								{/*<p className='font-16 blue'>{props?.assignedCoachProgramsDetails?.total_event_booking}</p>*/}
							</div>
						</div>
						<div className='event_program_mainrow_col'>
							<span className='bold blue font-16 text-upper mb-2'>
								Total Check Ins
							</span>
							<div className='detail_total_event_sale'>
								<p className='font-16 blue'><NumberFormat value={props?.assignedCoachProgramsDetails?.total_children_check_in} displayType={'text'} thousandSeparator={true}/></p>
								{/*<p className='font-16 blue'>{props?.assignedCoachProgramsDetails?.total_children_check_in}</p>*/}
							</div>
						</div>
					</div>
				</div>
				<div className='event_detail_purchase_list'>
					<div className='events_detail_list_top'>
						<div className='events_detail_table_header wi-17'>child name</div>
						<div className='events_detail_table_header wi-17'>purchaser</div>
						<div className='events_detail_table_header wi-17'>phone</div>
						<div className='events_detail_table_header wi-17'>dob</div>
						{/* <div className='events_detail_table_header wi-16'>status</div> */}
						<div className='events_detail_table_header wi-16'>action</div>
					</div>
					<div className='event_purchaser_container'>
						{/* <ProgramChildRow
							child_name='mickel'
							purchaser='dad'
							mobile_number='123'
							date='12/1/21'
							status={true}
						/>
						<ProgramChildRow
							child_name='mickel'
							purchaser='dad'
							mobile_number='123'
							date='12/1/21'
							status={false}
						/> */}
						{props.eventDetailsLoading ? (
							<div className='text-center mx-auto mt-4'>
								<CircularProgress color='primary' size={30} />
							</div>
						) : (
							props?.assignedCoachProgramsDetails?.bookin_details?.data?.map((zone, i) => {
								// console.log('child row111' ,zone)
								let data = (
									<ProgramChildRow
										key={i}
										child_name={zone.child_name}
										purchaser={zone.purchaser_first_name}
										mobile_number={zone.purchaser_mobile}
										date={zone.dob}
										has_children_checked_in={zone.has_children_checked_in}
										action={zone.action}
										eventId={params.event_id}
										programId={params.program_id}
										bookingId={zone.child_id}
									/>
								);
								return data;
							})
						)}
						{handlePagination(props.assignedCoachProgramsDetails?.bookin_details?.current_page)}
					</div>
				</div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => ({
	eventDetails: state.eventReducer.eventDetails,
	eventDetailsLoading: state.eventReducer.eventDetailsLoading,
	user: state.register.user,

	assignedCoachProgramsDetails: state.coachPortal.assignedCoachProgramsDetails,
	assignedCoachProgramsDetailsLoading: state.coachPortal.assignedCoachProgramsDetailsLoading,
});

export default connect(mapStateToProps)(EventProgramDetail);
