import { LIST_ADMINS_ERROR, LIST_ADMINS_REQUEST, LIST_ADMINS_SUCCESS, ADMIN_DETAILS_REQUEST, ADMIN_DETAILS_SUCCESS ,ADMIN_DETAILS_ERROR, ADMIN_PAGE_STATE } from '../actions/adminActions';

const initialState = {
	listAdmins: [],
	listAdminsLoading: false,

	adminDetails: [],
	adminDetailsLoading: false,

	page: 1,

};

function admin(state = initialState, action) {
	switch (action.type) {
		case LIST_ADMINS_REQUEST:
			return Object.assign({}, state, {
				listAdminsLoading: true,
				listAdmins: [],
			});
		case LIST_ADMINS_SUCCESS:
			return Object.assign({}, state, {
				listAdminsLoading: false,
				listAdmins: action.payload.data,
			});
		case LIST_ADMINS_ERROR:
			return Object.assign({}, state, {
				listAdminsLoading: false,
				listAdmins: [],
			});
		
		case ADMIN_DETAILS_REQUEST:
			return Object.assign({}, state, {
				adminDetails: [],
				adminDetailsLoading: true,
			});
		case ADMIN_DETAILS_SUCCESS:
			return Object.assign({}, state, {
				adminDetails: action.payload.data,
				adminDetailsLoading: false,
			});
		case ADMIN_DETAILS_ERROR:
			return Object.assign({}, state, {
				adminDetails: [],
				adminDetailsLoading: false,
			});

		case ADMIN_PAGE_STATE:
			return Object.assign({}, state, {
				page: action.page
			});
		
		default:
			return state;
		}
	}
			
export default admin;
