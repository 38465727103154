import React, { useEffect } from 'react';
import { Link, useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { coachHandle } from '../../../utils/global';
import moment from 'moment'
import NumberFormat from 'react-number-format';

//redux
import { baseURL } from '../../../config/config';
import membersAction from '../../../redux/actions/membersAction';
import postsActions from '../../../redux/actions/postsActions';

//Css
import './MembersDetail.css';

//Images
import backArrow from '../../../assets/images/arrow-red-left.svg';
import editPen from '../../../assets/images/edit-pen.svg';
// import selectArrow from '../../../assets/images/arrow_red_select.svg';
import whiteX from '../../../assets/images/x-white.svg';
import { Col, Row } from 'react-bootstrap';
// import whitePlus from '../../../assets/images/white-cross.svg';
import { useHistory } from 'react-router';

function MembersDetail(props) {
	const date = props?.detailMember?.created_at ? moment(props.detailMember.created_at).format("DD/MM/YYYY") : 'N/A';
	const dateBooking = props?.detailMember?.booking_date ? moment(props.detailMember.booking_date).format('DD/MM/YYYY') : 'N/A';
	const params = useParams();

	useEffect(() => {
		coachHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null);
		props.dispatch(membersAction.getMemberDetail({ id: params.member_id }));
		props.dispatch(membersAction.getMemberTransactions({ id: params.member_id }));
	}, []);


	const handleDelete = () => {
		let members = []
		members.push(params.member_id)
		props.dispatch(postsActions.popupHandler({type: 'DELETE_MEMBERS', data: members}))
	}

	const history = useHistory();
	return (
		<section>
			<div className='coach_detail_top_div'>
				<div className='left_detail_top'>
					<div className='go_back_arrow' onClick={() => history.goBack()} >
							<img src={backArrow} alt='Go Back' />
					</div>
					<div className='bold blue font-36 text-upper float-left'>
						{props?.detailMember?.first_name}
					</div>
					<div>
						{props?.detailMemberLoading && (
							<div className='text-center mx-auto mt-4'>
								<CircularProgress color='primary' size={30} />
							</div>
						)}
					</div>
				</div>
				<div className='left_detail_top'>
					<Link
						to={`/admin/members/${props?.detailMember?.id}/edit-member`}
						className='coach_add_new bold'
					>
						<img src={editPen} alt='' />
						<span>EDIT</span>
					</Link>
					<div className='member_delete_btn' onClick={handleDelete}>
						<img src={whiteX} alt='' className='mr-2' />
						<span className='white bold  text-upper'>DELETE</span>
					</div>
				</div>
			</div>
			<div className='coach_detail_body'>
				<Row>
					<Col xs={3}>
						<div className='main_coach_info'>
							<div className='member_pfp'>
								<img
									src={baseURL + '/image/' + props?.detailMember?.photo}
									alt=''
									className='w-100'
								/>
							</div>
						</div>
					</Col>
					<Col xs={9}>
						<div>
							<div className='member_emergency mt-3'>
								<span className='bold blue font-20'>
									Emergency Contact:
								</span>
								<div className=' align-items-center'>

									<div className='mr-4 d-flex align-items-center'>
										<div className='bold blue address_container '>
											<div>Emergency Name:</div>
										</div>
										<div className='address_container ml-2 row'>
											{props?.detailMember?.emergency_contact_name}
										</div>
									</div>
									<div className='mr-4 d-flex align-items-center'>
										<div className='bold blue address_container '>
											<div>Emergency Number:</div>
										</div>
										<div className='address_container ml-2 row'>
											{props?.detailMember?.emergency_contact_phone}
										</div>
									</div>
								</div>

							</div>
						</div>
						{/* Emergency Contact: {props?.detailMember?.emergency_contact_name} {props?.detailMember?.emergency_contact_phone}
					<div className='coach_email'>
						<img src={email} alt='email' />
						{props?.detailMember?.email}
					</div>
					<div className='coach_address'>
						<img src={address} alt='address' />
						{props?.detailMember?.street_address},{' '}
						{props?.detailMember?.suburb}
					</div> */}
					</Col>
				</Row>

				<Row>
					<Col xs={6}>
						<div className='mt-3'>
							<span className='bold blue font-20'>Account Info:</span>
							<div className=' align-items-center'>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue address_container '>
										<div>First Name:</div>
									</div>
									<div className='address_container ml-2 row'>
										{props?.detailMember?.first_name}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue address_container '>
										Last Name:
									</div>
									<div className='ml-2'>
										{props?.detailMember?.last_name}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue address_container '>
										Mobile Number:
									</div>
									<div className='ml-2'>
										{props?.detailMember?.mobile}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue address_container '>
										Email:
									</div>
									<div className='ml-2'>
										{props?.detailMember?.email}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue address_container '>
										<div>Second Parent:</div>
									</div>
									<div className='address_container ml-2 row'>
										{props?.detailMember?.second_parent_name}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue address_container '>
										Second Parent Number:
									</div>
									<div className='ml-2'>
										{props?.detailMember?.second_parent_phone}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue'>
										Street Address:
									</div>
									<div className='address_container ml-2'>
										{props?.detailMember?.street_address1}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue address_container '>
										Unit / Apartment no.
									</div>
									<div className='ml-2'>
										{props?.detailMember?.street_address2}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue'>
										Suburb:
									</div>
									<div className='address_container ml-2'>
										{props?.detailMember?.suburb}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue'>
										State:
									</div>
									<div className='address_container ml-2'>
										{props?.detailMember?.state}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue address_container '>
										Postal Code:
									</div>
									<div className='ml-2'>
										{props?.detailMember?.postal_code}
									</div>
								</div>
							</div>

						</div>
					</Col>
					<Col xs={4}>
						<div className='mt-3'>
							<span className='bold blue font-20'>Shipping Info:</span>
							<div className=' align-items-center'>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue address_container '>
										<div>First Name:</div>
									</div>
									<div className='address_container ml-2 row'>
										{props?.detailMember?.shipping_address?.first_name ? props?.detailMember?.shipping_address?.first_name : props?.detailMember?.first_name}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue address_container '>
										Last Name:
									</div>
									<div className='ml-2'>
										{props?.detailMember?.shipping_address?.last_name ? props?.detailMember?.shipping_address?.last_name : props?.detailMember?.last_name}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue address_container '>
										Mobile Number:
									</div>
									<div className='ml-2'>
										{props?.detailMember?.shipping_address?.mobile ? props?.detailMember?.shipping_address?.mobile : props?.detailMember?.mobile}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue address_container '>
										Email:
									</div>
									<div className='ml-2'>
										{props?.detailMember?.shipping_address?.email ? props?.detailMember?.shipping_address?.email : props?.detailMember?.email}
									</div>
								</div>
								<div className='mr-4 d-flex '>
									<div className='bold blue address_container'>
										Street Address:
									</div>
									<div className='address_container ml-2'>
										{props?.detailMember?.shipping_address?.street_address ? props?.detailMember?.shipping_address?.street_address : props?.detailMember?.street_address1}
									</div>
								</div>
								<div className='mr-4 d-flex '>
									<div className='bold blue address_container '>
										Unit / Apartment no. :
									</div>
									<div className='ml-2 address_container'>
										{props?.detailMember?.shipping_address?.city}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue'>
										Suburb:
									</div>
									<div className='address_container ml-2'>
										{props?.detailMember?.shipping_address?.suburb ? props?.detailMember?.shipping_address?.suburb : props?.detailMember?.suburb}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue'>
										State:
									</div>
									<div className='address_container ml-2'>
										{props?.detailMember?.shipping_address?.state ? props?.detailMember?.shipping_address?.state : props?.detailMember?.state}
									</div>
								</div>
								<div className='mr-4 d-flex align-items-center'>
									<div className='bold blue address_container '>
										Postal Code:
									</div>
									<div className='ml-2'>
										{props?.detailMember?.shipping_address?.postal_code ? props?.detailMember?.shipping_address?.postal_code : props?.detailMember?.postal_code}
									</div>
								</div>
							</div>
						</div>
					</Col>


				</Row>
				<div className='member_main_details'>
					<div className='member_account_container'>
						<div className='bold blue font-24 text-upper float-left member_container_title'>
							Events Bookings
						</div>
						<div className='member_detail_container'>
							<div className='left_account_div'>
								<div className='member_detail_div'>
									<span className='bold font-18 text-upper blue'>
										Total Invoices
									</span>
									<span className='blue font-16'>{props.last_boking_transaction?.transaction_count}</span>
								</div>
								{/* <div className='member_detail_div'>
									<span className='bold font-18 text-upper blue'>
										Past Due Invoices
									</span>
									<span className='blue font-16'>No Record Founds</span>
								</div> */}
							</div>
							<div className='right_account_div'>
								<div className='member_detail_div'>
									<span className='bold font-18 text-upper blue'>
										Total Purchases
									</span>
									<span className='blue font-16'><NumberFormat value={Number(props.last_boking_transaction?.total_income).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
								</div>
								<Link
									to={`/admin/members/${props?.detailMember?.id}/invoices`}
									className='blue_link_btn white text-upper bold font-16'

								>
									View All Invoices
								</Link>
							</div>
						</div>
					</div>
					<div className='member_account_container'>
						<div className='bold blue font-24 text-upper float-left member_container_title'>
							Store Purchases
						</div>
						<div className='member_detail_container'>
							<div className='left_account_div'>
								<div className='member_detail_div'>
									<span className='bold font-18 text-upper blue'>
										Total Invoices
									</span>
									<span className='blue font-16'>{props?.transactions_count}</span>
								</div>
							</div>
							<div className='right_account_div'>
								<div className='member_detail_div'>
									<span className='bold font-18 text-upper blue'>
										Total Purchases
									</span>
									<span className='blue font-16'><NumberFormat value={Number(props?.transactions_total).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
								</div>
								<Link
									to={`/admin/members/${props?.detailMember?.id}/purchases`}
									className='blue_link_btn white text-upper bold font-16'

								>
									View All Invoices
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className='member_main_details'>
				<div className='member_register_detail_container'>
						<div className='bold blue font-24 text-upper justify-content-between member_container_title'>
							Register Details
							{/* <div className='d-flex align-items-center'>
								<Link
									to='/admin/members/details/kids/new'
									className='add_child_btn'
								>
									<img src={whitePlus} alt='' />
								</Link>
								<span className='blue bold font-16 text-upper'>ADD CHILD</span>
							</div> */}
						</div>
						<div className='members_info_container'>
							<div className='register_detail_top_row'>
								<div className='member_detail_div'>
									<span className='bold font-18 text-upper blue'>
										Register Date
									</span>
									<span className='blue font-16'>
										{date}
									</span>
								</div>
								<div className='member_detail_div'>
									<span className='bold font-18 text-upper blue'>
										Last Booking
									</span>
									<span className='blue font-16'>
										{dateBooking}
									</span>
								</div>
								<div className='member_detail_div'>
									<span className='bold font-18 text-upper blue'>
										Nb of Children
									</span>
									<span className='blue font-16'>
										{props?.detailMember?.childrens?.length}
									</span>
								</div>
							</div>
							<div className='kids_detail_row'>
								<Link
									to={`/admin/members/${props?.detailMember?.id}/children`}
									className='blue_kids_link_btn white text-upper bold font-16'
								>
									View Kids details
								</Link>
							</div>
						</div>
				</div>
				</div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => ({
	detailMember: state.membersReducers.detailMember,
	detailMemberLoading: state.membersReducers.detailMemberLoading,
	last_boking_transaction: state.membersReducers.last_boking_transaction,
	transactions_count: state.membersReducers.transactions_count,
	transactions_total: state.membersReducers.transactions_total,
	user: state.register.user
});

export default withRouter(connect(mapStateToProps)(MembersDetail));
