import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
// import PropTypes from "prop-types";

import AuthLayout from "./../Containers/Layouts/Auth/AuthLayout";
// import { USER_TYPE_ADMIN, USER_TYPE_COACH } from "../../constants";

const AuthRoute = ({
  component: Component,
  auth,
  // auth: { token, userType, loginPath, isLoggingOut },
  ...rest
}) => {
  // if (auth?.roles?.length > 0) {
  //   console.log(auth)
  // }
  const routeBasedOnRoles = (props) => {
    if (auth?.user?.roles?.length > 0) {
      if (auth?.user?.roles[0]?.name === 'admin') {
        return (
          <Redirect to="/admin/dashboard" />
        )
      } else if (auth?.user?.roles[0]?.name === "coach") {
        return (
          <Redirect to="/coach/events" />
        )
      }
    } else {
      return (
        <AuthLayout>
          <Component {...props} />
        </AuthLayout>
      )
    }
  }
  // if (token) {
  // 	console.log("Redirecting from auth route to corresponding dashboard as per user");
  // 	switch (userType) {
  // 		case USER_TYPE_ADMIN:
  // 			return <Redirect to={loginPath ? loginPath : "/admin/dashboard"} />;
  // 		case USER_TYPE_COACH:
  // 			return <Redirect to={loginPath ? loginPath : "/coach/events"} />;
  // 		default:
  // 			return <Redirect to="/" />;
  // 	}
  // }
  // console.log("auth", auth);
  return (
    <Route
      {...rest}
      render={(props) =>
        auth?.access_token != null ? (
          routeBasedOnRoles(props)
        ) : (
            <AuthLayout>
              <Component {...props} />
            </AuthLayout>
          )
      }
    //   {...rest}
    //   render={(props) => (
    //     <AuthLayout>
    //       <Component {...props} />
    //     </AuthLayout>
    //   )}
    />
  );
};

// AuthRoute.propTypes = {
// 	auth: PropTypes.object.isRequired,
// };

const mapStateToProps = (state) => ({
  auth: state.register,
});

export default connect(mapStateToProps, {})(AuthRoute);
// export default AuthRoute;
