import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { coachHandle } from "../../../utils/global";
import membersAction from "../../../redux/actions/membersAction";

//components
import PurchaseRow from "../../../Components/Members/PurchaseRow";
import CircularProgress from "@material-ui/core/CircularProgress";

import backArrow from "../../../assets/images/arrow-red-left.svg";

function MemberInvoices(props) {
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    coachHandle(
      props.user?.roles?.length > 0 ? props.user?.roles[0]?.name : null
    );
    props.dispatch(membersAction.getMemberDetail({ id: params.member_id }));
    props.dispatch(
      membersAction.getMemberTransactions({ id: params.member_id })
    );
  }, []);
  return (
    <section>
      <div className="coach_top_div">
        <div className="d-flex align-items-center">
          <div className="go_back_arrow" onClick={() => history.goBack()}>
            <img src={backArrow} alt="Go Back" />
          </div>
          <div className="bold blue font-36 text-upper float-left">
            {props.memberDetails?.first_name +
              " " +
              props.memberDetails?.last_name +
              " "}
            purchases
          </div>
        </div>
      </div>
      {props.transactionsLoading === true ? (
        <div className="text-center mx-auto mt-4">
          <CircularProgress color="primary" size={30} />
        </div>
      ) : props.transactionsLoading === false &&
        props.member_purchases.length !== 0 ? (
        <div className="coach_list_div">
          <div className="coach_list_top">
            <div className="events_table_header wi-21 text-upper">
              Invoice number
            </div>
            <div className="events_table_header wi-16 text-upper">date</div>
            <div className="events_table_header wi-13 text-upper">
              total paid
            </div>
            <div className="events_table_header wi-21 text-upper">invoice</div>
          </div>
          <div className="coach_list_container">
            {props?.member_purchases?.map((zone, i) => {
              let data = (
                <PurchaseRow
                  key={i}
                  number={zone.order_id}
                  date={zone.date}
                  paid={zone.total}
                  pdfData={zone}
                />
              );
              return data;
            })}
          </div>
        </div>
      ) : (
        <div className="text-center mx-auto mt-4">
          <h4 className="blue">This member hasn't purchased anything yet</h4>
        </div>
      )}
    </section>
  );
}

const mapStateToProps = (state) => ({
  member_purchases: state.membersReducers.transactions,
  transactionsLoading: state.membersReducers.transactionsLoading,
  memberDetails: state.membersReducers.detailMember,
  user: state.register.user,
});

export default connect(mapStateToProps)(MemberInvoices);
