import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
//CSS
import './OrderRow.css';

//Images
import arrow from '../../assets/images/right-arrow-blck.svg';

//ACTIONS
import postsActions from '../../redux/actions/postsActions';


export const OrderRow = (props) => {


	const viewOrderDetails = ()=>{
		props.popupHandler(props.order_number.substring(1))
	}

	return (
		<div className='coach_row_table'>
			<div className='coach_row_row'>
				<div className='coach_row_col wi-13'>{props?.order_number}</div>
				<div className='coach_row_col wi-15'>{props.first_name+' '+props.last_name}</div>
				<div className='coach_row_col wi-13'>
					{props.phone}
				</div>
				<div className='coach_row_col wi-21'>
					{props.email}
				</div>
				<div className='coach_row_col wi-21'>
					Street Address: {props.street_address}
					<br/>
					Unit / Apartment no.: {props.city}
					<br/>
					Suburb: {props.suburb}
					<br/>
					State: {props.state}
					<br/>
					Postal Code: {props.postal_code}
				</div>
				<div className='coach_row_col wi-13 d-flex flex-column justify-content-center align-items-start'>
					<div className='d-flex flex-column'>
						Freight: 
						<NumberFormat value={Number(props.freight).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
					</div>
					<div className='d-flex flex-column'>
						Total:
						<NumberFormat value={Number(props.total).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
					</div>
				</div>
				<div className='coach_row_col wi-13'>
					<div className='arrow_div' onClick={viewOrderDetails}>
						<img src={arrow} alt='view/edit' />
					</div>
				</div>
			</div>
		</div>
	);
}

const mapDispatchToProps = (dispatch)=>({
	popupHandler: (order) => dispatch(postsActions.popupHandler({ type: 'VIEW_ORDER_ITEMS', data: order })),
})

export default connect(null, mapDispatchToProps)(OrderRow);
