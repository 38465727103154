import axios from 'axios'
import apiService from '../services/apiService'
import { toast } from 'react-toastify'
import moment from 'moment';
var qs = require('qs')
const SERVER_ENV = process.env.REACT_APP_ENVIRONMENT
const baseURL = SERVER_ENV === 'production' ? 'https://api.no1draftpick.com' : 'https://api.staging.no1draftpick.com'

const getBearer = () => {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  let headersdata = {
    ...headers,
    Authorization: 'Bearer ' + localStorage.access_token,
  }
  return headersdata
}

const errorCatch = (error) => {
  if (
    error.response.data.code === 400 &&
    error.response.data.message === 'Token has expired'
  ) {
    apiService.logout()
  } else if (
    error.response.data.code === 401 &&
    error.response.data.message === 'Unauthorized'
  ) {
    apiService.logout()
  }

  if (
    error.response.data.code === 400 &&
    error.response.data.error === 'Token has expired'
  ) {
    apiService.logout()
  } else if (
    error.response.data.code === 401 &&
    error.response.data.error === 'Unauthorized'
  ) {
    apiService.logout()
  }
  if (error.response?.data?.message) {
    return toast.error(
      Array.isArray(error.response?.data?.message)
        ? error.response?.data?.message.join(',')
        : error.response?.data?.message
    )
  }

  if (error.response?.data?.errors) {
    return toast.error(
      Array.isArray(error.response?.data?.errors)
        ? error.response?.data?.errors.join(',')
        : error.response?.data?.errors
    )
  }

  return error.response
}

async function listAllCoaches(data) {
  const url = `${baseURL}/api/admin/coaches?item_per_page=4&page=${data}`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function listCoachCatgeories() {
  const url = `${baseURL}/api/admin/coach_categories`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function listAllAdmins(data) {
  const url = `${baseURL}/api/admin/super-admins?item_per_page=10&page=${data.page}${data.query? '&query='+data.query: ''}`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getAdminDetails(data) {
  const url = `${baseURL}/api/admin/super-admins/${data}`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function newAdmin(data) {
  const url = `${baseURL}/api/admin/super-admins/addAdmin`
  return await axios
    .post(url, data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function editAdmin(data) {
  const url = `${baseURL}/api/admin/super-admins/${data.id}`
  return await axios
    .post(url, data.data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function deleteAdmin(data) {
  const url = `${baseURL}/api/admin/super-admins/delete/${data.id}`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getCoachDetails(data) {
  const url = `${baseURL}/api/coach/${data.id}`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function createCoach(data) {
  const url = `${baseURL}/api/admin/coaches`
  return await axios
    .post(url, data.data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => errorCatch(error))
}

async function editCoach(data) {
  const url = `${baseURL}/api/user/${data.id}/edit-profile`
  return await axios
    .post(url, data.data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getPosts(data, access_token) {
  const url = `${baseURL}/api/admin/posts?item_perpage_this_month=${data.thisMonth}&item_perpage_previous_month=${data.previous}`
  return await axios
    .get(url, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function createPost(data) {
  const url = `${baseURL}/api/admin/posts`
  return await axios
    .post(url, data.data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function editPost(data, access_token) {
  const url = `${baseURL}/api/admin/posts/${data.id}`
  return await axios
    .post(url, data.data, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function deletePost(data) {
  const url = `${baseURL}/api/admin/posts/${data.id}/cancel`
  return await axios
    .patch(url, null, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getEventCategories() {
  const url = `${baseURL}/api/admin/events-categories`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getEventByCategoryId(data) {
  const url = `${baseURL}/api/admin/events?status=${data.status}&item_per_page=4&category_id=${data.id}&page=${data.page}`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getCoachEventsByID(data) {
  const url = `${baseURL}/api/admin/future-events?item_per_page=1000&category_id=${data.id}`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function createEvent(data) {
  const url = `${baseURL}/api/admin/events`
  return await axios
    .post(url, data.data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}

async function cloneEvent(data) {
  const url = `${baseURL}/api/admin/clone-events`
  return await axios
    .post(url, data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}

async function getEventDetails(data) {
  const url = `${baseURL}/api/admin/events/${data.id}`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function cancelEvent(data) {
  const url = `${baseURL}/api/admin/events/${data.id}/canceled`
  return await axios
    .patch(url, null, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function publishEvent(data) {
  const url = `${baseURL}/api/admin/events/${data.id}/publish`
  return await axios
    .patch(url, null, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function editEvent(data) {
  const url = `${baseURL}/api/admin/events/${data.id}`
  return await axios
    .post(url, data.data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}

async function editPicture(data, id) {
  const url = `${baseURL}/api/admin/events/${id}/edit-image-event`
  return await axios
    .post(url, data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}

async function sendInvitationToAllMembers(data) {
  const url = `${baseURL}/api/admin/events/${data.id}/members/send-invitation`
  return await axios
    .post(url, data.data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function sendBulkInvitationToAllMembers(data) {
  const url = `${baseURL}/api/admin/events/${data.id}/members/send-bulk-invitation`
  return await axios
    .post(url, {}, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getSuperAdminProfile(uuid) {
  const url = `${baseURL}/api/admin/users/${uuid}`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function editProfile(data, uuid, access_token) {
  const url = `${baseURL}/api/user/${uuid}/edit-profile`
  return await axios
    .post(url, data, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getMembersList(data) {
  const url = `${baseURL}/api/admin/members?item_per_page=10&page=${data}`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getChildrenList(data) {
  const url = `${baseURL}/api/admin/childrens/?item_per_page=10&page=${data}`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getMemberDetail(data) {
  const url = `${baseURL}/api/admin/members/${data.id}`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getMemberTransactions(data) {
  const url = `${baseURL}/api/admin/members/${data.id}/store-transactions`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getKidDetail(data) {
  const url = `${baseURL}/api/admin/members/${data.parentId}/${data.childrenId}`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getAllSchools() {
  const url = `${baseURL}/web/schools`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function editKidDetail(data) {
  const url = `${baseURL}/api/admin/members/${data.parent_id}/children/${data.id}`
  return await axios
    .put(url, data, {
      headers: getBearer(),
      'Content-Type': 'application/x-www-form-urlencoded',
    })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function editMemberDetail(data) {
  const url = `${baseURL}/api/admin/members/${data.id}`
  return await axios
    .put(url, data, {
      headers: getBearer(),
      'Content-Type': 'application/x-www-form-urlencoded',
    })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}

async function generateVoucher(data) {
  const url = `${baseURL}/api/admin/members/${data.parent}/children/${data.child}/send-coupon`
  return await axios
    .post(url, data.data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function assignCoachtoEvent(data) {
  const url = `${baseURL}/api/admin/coaches/${data.coachId}/assign-programs`
  return await axios
    .post(url, data.data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function deleteCoach(data) {
  const url = `${baseURL}/api/admin/coaches/delete/${data}`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function setProgramCompleted(data) {
  const url = `${baseURL}/api/admin/events/${data.eventId}/programs/${data.programId}/set-status?status=completed`
  return await axios
    .patch(url, null, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getAssignedEventsToCoach(data, access_token) {
  const url = `${baseURL}/api/coach/${data.id}/assigned-events?item_per_page=5&page=${data.page}`
  return await axios
    .get(url, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function viewEventDetailsCoach(data, access_token) {
  const url = `${baseURL}/api/coach/${data.coachId}/assigned-events/${data.eventId}`
  return await axios
    .get(url, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function viewAssignedEventProgramsCoach(data, access_token) {
  const url = `${baseURL}/api/coach/${data.coachId}/events/${data.eventId}/assigned-events-programs?item_per_page=5&page=${data.page}`
  return await axios
    .get(url, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function viewEventBookingDetailsCoach(data, access_token) {
  const url = `${baseURL}/api/coach/events/${data.eventId}/programs/${data.programId}/booking-details?item_per_page=5&page=${data.page}`
  return await axios
    .get(url, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function eventProgramAttendanceCoach(data) {
  const url = `${baseURL}/api/coach/children/${data.childId}/set-event-program-attendance`
  return await axios
    .post(url, data.data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function programStatusCoach(data, access_token) {
  const url = `${baseURL}/api/coach/${data.coachId}/event-program-assignment`
  return await axios
    .put(url, data.data, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function sendMailCoach(data, access_token) {
  const url = `${baseURL}/api/coach/events/${data.id}/members/send-invitation`
  return await axios
    .post(url, data.data, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getMembersDashboard(data, access_token) {
  const url = `${baseURL}/api/admin/analytics/members?filter[period]=${data.filter}`
  return await axios
    .get(url, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getEventsBookingRevenue(data, access_token) {
  const url = `${baseURL}/api/admin/analytics/event-revenue?filter[period]=${data.filter}`
  return await axios
    .get(url, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getShopRevenue(data, access_token) {
  const url = `${baseURL}/api/admin/analytics/event-metrics?filter[period]=${data.data.period}${data.data.program.length>0?'&program='+data.data.program:''}${data.data.location.length>0?'&location='+data.data.location: ''}`
  return await axios
    .get(url, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getProductsSalesbyQuarters(data, access_token) {
  const url = `${baseURL}/api/admin/analytics/shop-revenue?filter[period]=${data.filter}`
  return await axios
    .get(url, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getPrograms(access_token) {
  const url = `${baseURL}/api/admin/event-programs`
  return await axios
    .get(url, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getEventBookingsAdmin(data, access_token) {
  const url = `${baseURL}/api/admin/events/${data.id}/booking`
  return await axios
    .get(url, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getEventRelatedProgramsAdmin(data, access_token) {
  const url = `${baseURL}/api/admin/events/${data.id}/programs-details?item_per_page=5&page=${data.page}`
  return await axios
    .get(url, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getEventRelatedProgramsBookingAdmin(data, access_token) {
  const url = `${baseURL}/api/admin/events/${data.eventId}/programs/${data.programId}/booking-details?item_per_page=5&page=${data.page}`
  return await axios
    .get(url, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function setChildrenAttendanceAdmin(data, access_token) {
  const url = `${baseURL}/api/admin/events/${data.eventId}/set-children-attendance`
  return await axios
    .post(url, data.data, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}

async function setProgramCompletedAdmin(data, access_token) {
  const url = `${baseURL}/api/admin/events/${data.eventId}/programs/${data.programId}/status`
  return await axios
    .patch(url, data.data, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}

async function setChildrenAttendanceCoach(data, access_token) {
  const url = `${baseURL}/api/coach/events/${data.eventId}/set-children-attendance`
  return await axios
    .post(url, data.data, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}

async function deleteMembers(data, headers) {
  const url = `${baseURL}/api/admin/members/20`
  var datass = qs.stringify({
    members: data.members,
  })

  return await axios
    .delete(url, { headers: headers, data: datass })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getMembersInvoices(data, access_token) {
  const url = `${baseURL}/api/admin/members/${data.id}/booking-transactions`
  return await axios
    .get(url, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function deleteChildrens(data, headers) {
  const url = `${baseURL}/api/admin/members/${data.parent_id}/multiple-childrens`
  var datass = qs.stringify({
    childrens: data.kids,
  })
  return await axios
    .delete(url, { headers: headers, data: datass })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function deleteProducts(data, headers) {
  const url = `${baseURL}/api/admin/store/products`
  var datass = qs.stringify({
    product: data.product,
  })
  return await axios
    .delete(url, { headers: headers, data: datass })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getStoreCategories(data){
  const url = `${baseURL}/api/admin/store/categories?item_per_page=10&page=${data.page}${data.query? '&name='+data.query : ''}`
  return await axios
    .get(url, { headers: getBearer()})
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function addCategory(data){
  const url = `${baseURL}/api/admin/store/categories`
  return await axios
    .post(url, data, { headers: getBearer()})
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getProducts(data){
  const url = `${baseURL}/api/admin/store/categories/${data.id}/products${data.page && data.query? `?&page=${data.page}&name=${data.query}`: ''}`
  return await axios
    .get(url, { headers: getBearer()})
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function deleteProgram(data, access_token) {
  const url = `${baseURL}/api/admin/events/${data.eventId}/programs/${data.programId}`
  return await axios
    .delete(url, { headers: { Authorization: access_token } }, null)
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function eventSearch(data, categorie_id) {
  const url = `${baseURL}/api/admin/events?status=${data.status}&searh_query=${data.query
    }&item_per_page=5&page=${data.page}${categorie_id ? `&categorie_id=${categorie_id}` : ''
    }`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getEvents(data) {
  const url = `${baseURL}/api/admin/events?status=${data.status}&item_per_page=5&page=${data.page}`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getSettings(access_token) {
  const url = `${baseURL}/settings`
  return await axios
    .get(url, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function setSettings(data, uuid, token) {
  const url = `${baseURL}/api/admin/${uuid}/settings`

  var datass = qs.stringify({
    key: data.key,
    value: data.value,
    type: data.type,
  })

  return await axios
    .put(url, datass, { headers: token, data: datass })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getSetingsById(data) {
  const url = `${baseURL}/settings/check-settings?settings_id=${data.settings_id}&type=${data.type}`
  return await axios
    .get(url, data)
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getSchools() {
  const url = `${baseURL}/web/schools`
  return await axios
    .get(url)
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function addSchool(data) {
  const url = `${baseURL}/api/admin/schools`
  return await axios
    .post(url, data.data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => error.response)
}

async function editSchool(data, token) {
  const url = `${baseURL}/api/admin/schools/${data?.data?.id}`

  var datass = qs.stringify({
    school_name: data?.data?.school_name,
    street: '',
    town: '',
    sector: '',
    postal_code: '',
    longitude: '',
    latitude: '',
    state: '',
    suburb: data?.data?.suburb ? data?.data?.suburb : '',
  })

  return await axios
    .put(url, datass, { headers: token, data: datass })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}

async function deleteSchool(data) {
  const url = `${baseURL}/api/admin/schools/${data?.data}`
  return await axios
    .delete(url, { headers: getBearer() }, null)
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}

async function getCarouselItems() {
  const url = `${baseURL}/api/admin/banners`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}

async function addCarouselItem(data) {
  const url = `${baseURL}/api/admin/banners`
  return await axios
    .post(url, data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}

async function editCarouselItem(data, id) {
  const url = `${baseURL}/api/admin/banners/${id}`
  return await axios
    .put(url, data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}

async function deleteCarouselItem(id) {
  const url = `${baseURL}/api/admin/banners/${id}`
  return await axios
    .delete(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}

async function membersSearch(data) {
  const url = `${baseURL}/api/admin/members/search?query=${data.query}&item_per_page=4&page=${data.page}`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}

async function childrenSearch(data) {
  const url = `${baseURL}/api/admin/childrens?filter[first_name]=${data.query}&item_per_page=4&page=${data.page}`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}

async function coachSearch(data) {
  const url = `${baseURL}/api/admin/coaches/search?query=${data.query}&item_per_page=4&page=${data.page}`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}


async function getCoachAssignedEvents(data) {
  const url = `${baseURL}/api/admin/coaches/${data}/assigned-events`
  return axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}

async function removeCoachAssignment(data) {
  const url = `${baseURL}/api/admin/coaches/${data.id}/assigments/${data.assignmentId}`
  return axios
    .delete(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}

async function masterSearch(data) {
  const url = `${baseURL}/api/admin/search/all?keyword=${data}&limit=10`
  return axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}

async function deleteEventCanceled(id, headers) {
  const url = `${baseURL}/api/admin/events/${id}/canceled`
  return axios
    .delete(url, { headers })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function addEventCategory(data) {
  const url = `${baseURL}/api/admin/events-categories`
  return await axios
    .post(url, data.data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => error.response)
}

async function editEventCategory(data) {
  const url = `${baseURL}/api/admin/events-categories/${data.data.id}`
  return await axios
    .put(url, data.data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => error.response)
}

async function updateEventCategoryOrder(data) {
  const url = `${baseURL}/api/admin/events-categories-order`
  return await axios
    .post(url, data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => error.response)
}

async function getExcelDoc() {
  const url = `${baseURL}/api/admin/members/list/export`
  return axios
    .get(url, { headers: getBearer(), responseType: 'blob' })
    .then((response) => {
      const filename = `member_list_${moment(new Date()).format('YY-MM-DD-HH-mm-ss')}.xlsx`;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      return link.click();
    })
    .catch((error) => {
      return errorCatch(error)
    })
}

async function getChildrenExcelDoc() {
  const url = `${baseURL}/api/admin/childrens/export`
  return axios
    .get(url, { headers: getBearer(), responseType: 'blob' })
    .then((response) => {
      const filename = `children_list_${moment(new Date()).format('YY-MM-DD-HH-mm-ss')}.xlsx`;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      return link.click();
    })
    .catch((error) => {
      return errorCatch(error)
    })
}

async function getOrders(data, access_token) {
  const url = `${baseURL}/api/admin/orders?page=${data}`
  return await axios
    .get(url, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getOrderItems(data, access_token) {
  const url = `${baseURL}/api/admin/orders/${data}`
  return await axios
    .get(url, { headers: { Authorization: access_token } })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getCouponCategories() {

  var categories =[{
    id: 1,
    name: 'AKV'
  },
  {id: 2,
    name: 'BIRTHDAY'},
  {id: 3,
    name: 'DISCOUNT',},
  {id: 4,
    name: 'SCHOOL',}];

  return categories

  // const url = `${baseURL}/api/admin/coupons`
  // return await axios
  //   .get(url, { headers: getBearer() })
  //   .then((response) => response)
  //   .catch((error) => {
  //     errorCatch(error)
  //   })
}

async function getCoupons(data) {

  let url = `${baseURL}/api/admin/coupons?item_per_page=10`;

  if(data.page)
     url += `&page=${data.page}`

  if(data.status && data.status.trim() != "")
     url += `&status=${data.status}`

  if(data.id)
     url += `&type=${data.id}`

  if(data.query && data.query.trim() != "")
     url += `&query=${data.query}`

  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getCouponDetails(data) {
  const url = `${baseURL}/api/admin/coupons/${data.id}`
  return await axios
    .get(url, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function createCoupon(data) {
  const url = `${baseURL}/api/admin/coupons`
  return await axios
    .post(url, data.data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}

async function cancelCoupon(data) {
  const url = `${baseURL}/api/admin/coupons/${data.id}/canceled`
  return await axios
    .patch(url, null, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function editCoupon(data) {
  const url = `${baseURL}/api/admin/coupons/${data.id}`
  return await axios
    .post(url, data.data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}

async function deleteCouponCanceled(id, headers) {
  const url = `${baseURL}/api/admin/coupons/${id}/canceled`
  return axios
    .delete(url, { headers })
    .then((response) => response)
    .catch((error) => {
      errorCatch(error)
    })
}

async function getCouponsExcelDoc() {
  const url = `${baseURL}/api/admin/coupons/list/export`
  return axios
    .get(url, { headers: getBearer(), responseType: 'blob' })
    .then((response) => {
      const filename = `coupons_list_${moment(new Date()).format('YY-MM-DD-HH-mm-ss')}.xlsx`;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      return link.click();
    })
    .catch((error) => {
      return errorCatch(error)
    })
}

async function deactivateCoupons(data) {
  const url = `${baseURL}/api/admin/coupons/deactivate`
  return await axios
    .post(url, data, { headers: getBearer() })
    .then((response) => response)
    .catch((error) => {
      return errorCatch(error)
    })
}


export {
  baseURL,
  getExcelDoc,
  getChildrenExcelDoc,
  listAllCoaches,
  createCoach,
  editCoach,
  getPosts,
  createPost,
  editPost,
  getSuperAdminProfile,
  deletePost,
  getCoachDetails,
  getEventCategories,
  getEventByCategoryId,
  getCoachEventsByID,
  createEvent,
  cloneEvent,
  getEventDetails,
  getMembersList,
  getMemberDetail,
  getKidDetail,
  getAllSchools,
  editKidDetail,
  editMemberDetail,
  cancelEvent,
  publishEvent,
  editEvent,
  editPicture,
  sendInvitationToAllMembers,
  editProfile,
  generateVoucher,
  assignCoachtoEvent,
  getAssignedEventsToCoach,
  viewEventDetailsCoach,
  viewAssignedEventProgramsCoach,
  viewEventBookingDetailsCoach,
  getEventsBookingRevenue,
  getMembersDashboard,
  getShopRevenue,
  getProductsSalesbyQuarters,
  getPrograms,
  sendMailCoach,
  getEventBookingsAdmin,
  getEventRelatedProgramsAdmin,
  getEventRelatedProgramsBookingAdmin,
  setChildrenAttendanceAdmin,
  setProgramCompletedAdmin,
  programStatusCoach,
  setChildrenAttendanceCoach,
  deleteMembers,
  getMembersInvoices,
  deleteChildrens,
  deleteProducts,
  deleteProgram,
  getEvents,
  getSettings,
  setSettings,
  getSetingsById,
  getSchools,
  addSchool,
  editSchool,
  deleteSchool,
  getCarouselItems,
  addCarouselItem,
  editCarouselItem,
  deleteCarouselItem,
  membersSearch,
  coachSearch,
  deleteCoach,
  eventSearch,
  getCoachAssignedEvents,
  removeCoachAssignment,
  sendBulkInvitationToAllMembers,
  masterSearch,
  deleteEventCanceled,
  getChildrenList,
  childrenSearch,
  addEventCategory,
  editEventCategory,
  updateEventCategoryOrder,
  getOrders,
  getOrderItems,
  getMemberTransactions,
  listAllAdmins,
  getAdminDetails,
  newAdmin,
  editAdmin,
  deleteAdmin,
  getStoreCategories,
  addCategory,
  getProducts,
  listCoachCatgeories,
  getCouponCategories,
  getCoupons,
  getCouponDetails,
  createCoupon,
  cancelCoupon,
  editCoupon,
  deleteCouponCanceled,
  getCouponsExcelDoc,
  deactivateCoupons
}
