import {
	getCouponCategories,
	createCoupon,
	getCouponDetails,
	cancelCoupon,
	editCoupon,
	getCoupons,
	deleteCouponCanceled,
	deactivateCoupons
} from '../../config/config';
import postsActions from './postsActions'

import { toast } from 'react-toastify';

export const GET_COUPON_CATEGORIES_REQUEST = 'GET_COUPON_CATEGORIES_REQUEST';
export const GET_COUPON_CATEGORIES_SUCCESS = 'GET_COUPON_CATEGORIES_SUCCESS';
export const GET_COUPON_CATEGORIES_ERROR = 'GET_COUPON_CATEGORIES_ERROR';

export const GET_COUPONS_REQUEST = 'GET_COUPONS_REQUEST';
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
export const GET_COUPONS_ERROR = 'GET_COUPONS_ERROR';

export const HANDLE_ADD_COUPON = 'HANDLE_ADD_COUPON';

export const CREATE_COUPON_REQUEST = 'CREATE_COUPON_REQUEST';
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS';
export const CREATE_COUPON_ERROR = 'CREATE_COUPON_ERROR';

export const GET_COUPON_DETAILS_REQUEST = 'GET_COUPON_DETAILS_REQUEST';
export const GET_COUPON_DETAILS_SUCCESS = 'GET_COUPON_DETAILS_SUCCESS';
export const GET_COUPON_DETAILS_ERROR = 'GET_COUPON_DETAILS_ERROR';

export const CANCEL_COUPON_REQUEST = 'CANCEL_COUPON_REQUEST';
export const CANCEL_COUPON_SUCCESS = 'CANCEL_COUPON_SUCCESS';
export const CANCEL_COUPON_ERROR = 'CANCEL_COUPON_ERROR';

export const EDIT_COUPON_REQUEST = 'EDIT_COUPON_REQUEST';
export const EDIT_COUPON_SUCCESS = 'EDIT_COUPON_SUCCESS';
export const EDIT_COUPON_ERROR = 'EDIT_COUPON_ERROR';

export const DEACTIVATE_COUPONS_REQUEST = 'DEACTIVATE_COUPONS_REQUEST';
export const DEACTIVATE_COUPONS_SUCCESS = 'DEACTIVATE_COUPONS_SUCCESS';
export const DEACTIVATE_COUPONS_ERROR = 'DEACTIVATE_COUPONS_ERROR';

export const COUPON_PAGE_STATE = 'COUPON_PAGE_STATE';

const actions = {
	getCouponCategories: (data) => async (dispatch) => {
		try {
			dispatch({
				type: GET_COUPON_CATEGORIES_REQUEST,
			});
			await getCouponCategories().then((response) => {
				//if (response.data.code === 200) {
					dispatch({
						type: GET_COUPON_CATEGORIES_SUCCESS,
						payload: {
							data: response,
							//data: response.data.data,
						},
					});
				// } else {
				// 	dispatch({
				// 		type: GET_COUPON_CATEGORIES_ERROR,
				// 		payload: {
				// 			message: response.message,
				// 		},
				// 	});
				// }
			});
		} catch (error) {
			dispatch({
				type: GET_COUPON_CATEGORIES_ERROR,
				payload: {
					message: 'Error Loading Posts',
				},
			});
		}
	},

	getCoupons: (data) => async (dispatch) => {
		try {
			dispatch({
				type: GET_COUPONS_REQUEST,
			});

			await getCoupons(data).then((response) => {
				if (response.data.code === 200) {
					dispatch({
						type: GET_COUPONS_SUCCESS,
						payload: {
							data: response.data.data,
						},
					});
				} else {
					dispatch({
						type: GET_COUPONS_ERROR,
						payload: {
							message: response.message,
						},
					});
				}
			});

		} catch (error) {
			dispatch({
				type: GET_COUPONS_ERROR,
				payload: {
					message: 'Error Loading Posts',
				},
			});
		}
	},

	handleAddCouponFormData: (data) => async (dispatch) => {
		let formData = data.formData;
		let finalData = {
			...formData,
			[data.name]: data.data,
		}
		dispatch({
			type: HANDLE_ADD_COUPON,
			payload: finalData,
		});
	},

	createCoupon: (data) => async (dispatch) => {
		try {
			dispatch({
				type: CREATE_COUPON_REQUEST,
			});
			await createCoupon(data).then((response) => {
				if (response.data.code === 200) {
					toast.success('COUPON Created');
					dispatch({
						type: CREATE_COUPON_SUCCESS,
						payload: {
							data: response.data.data.data,
						},
					});
				} else {
					toast.error(response.data.message + ' ' + response.data.errors);
					dispatch({
						type: CREATE_COUPON_ERROR,
					});
				}
			});
		} catch (error) {
			toast.error(error.message);
			dispatch({
				type: CREATE_COUPON_ERROR,
			});
		}
	},

	getCouponDetailsById: (data) => async (dispatch) => {
		try {
			dispatch({
				type: GET_COUPON_DETAILS_REQUEST,
			});
			await getCouponDetails(data).then((response) => {
				if (response.data.code === 200) {
					dispatch({
						type: GET_COUPON_DETAILS_SUCCESS,
						payload: {
							data: response.data.data,
						},
					});
				} else {
					dispatch({
						type: GET_COUPON_DETAILS_ERROR,
						payload: {
							message: response.message,
						},
					});
				}
			});
		} catch (error) {
			dispatch({
				type: GET_COUPON_DETAILS_ERROR,
				payload: {
					message: 'Error Loading Posts',
				},
			});
		}
	},

	cancelCoupon: (data) => async (dispatch) => {
		try {
			dispatch({
				type: CANCEL_COUPON_REQUEST,
			});
			await cancelCoupon(data).then((response) => {

				if (response.data.code === 200) {
					toast.success('COUPON Canceled');
					dispatch({
						type: CANCEL_COUPON_SUCCESS,
						payload: {
							data: response?.data?.data?.data,
						},
					});
				} else {
					toast.error(response.message);
					dispatch({
						type: CANCEL_COUPON_ERROR,
					});
				}
			});
		} catch (error) {
			toast.error(error.message);
			dispatch({
				type: CANCEL_COUPON_ERROR,
			});
		}
	},

	editCoupon: (data) => async (dispatch) => {
		try {
			dispatch({
				type: EDIT_COUPON_REQUEST,
			});
			await editCoupon(data).then((response) => {
				if (response.data.code === 200) {
					toast.success('COUPON Edited');
					dispatch({
						type: EDIT_COUPON_SUCCESS,
						payload: {
							data: response.data.data.data,
						},
					});
				} else {
					toast.error("ERROR: " + response?.data?.message);
					dispatch({
						type: EDIT_COUPON_ERROR,
					});
				}
			});
		} catch (error) {
			toast.error(error.message);
			dispatch({
				type: EDIT_COUPON_ERROR,
			});
		}
	},

	deleteCoupon: (id,history) => async (dispatch) => {
		try {
			let headers = {
				'Authorization': 'Bearer ' + localStorage.access_token,
				'Content-type': 'application/x-www-form-urlencoded',
			}
			await deleteCouponCanceled(id, headers).then((response) => {
				console.log('RESPONSE', response)
				history.push('/admin/coupons')
			});
		} catch (error) {
			console.log('ERROR delete coupon', error)
		}
	},

	deactivateCoupons: (data) => async (dispatch) => {
		try {
			dispatch({
				type: DEACTIVATE_COUPONS_REQUEST,
			});
			await deactivateCoupons(data).then((response) => {
				if (response.data.code === 200) {
					toast.success('COUPONS Deactivated');
					dispatch({
						type: DEACTIVATE_COUPONS_SUCCESS,
						payload: {
							data: response.data.data.data,
						},
					});
				} else {
					toast.error("ERROR: " + response?.data?.message);
					dispatch({
						type: DEACTIVATE_COUPONS_ERROR,
					});
				}
			});
		} catch (error) {
			toast.error(error.message);
			dispatch({
				type: DEACTIVATE_COUPONS_ERROR,
			});
		}
	},

	changePageState: (pageOn) => (dispatch) => {
		dispatch({
		  type: COUPON_PAGE_STATE,
		  pageOn: pageOn,
		})
	},

	editMaxUses: (data) => async (dispatch) => {
		try {
			dispatch({
				type: EDIT_COUPON_REQUEST,
			});
			await editCoupon(data).then((response) => {
				if (response.data.code === 200) {
					toast.success('COUPON Edited');
					dispatch({
						type: EDIT_COUPON_SUCCESS,
						payload: {
							data: response.data.data.data,
						},
					});
				} else {
					toast.error("ERROR: " + response?.data?.message);
					dispatch({
						type: EDIT_COUPON_ERROR,
					});
				}
			});
		} catch (error) {
			toast.error(error.message);
			dispatch({
				type: EDIT_COUPON_ERROR,
			});
		}
	},
};

export default actions;
