import React, { useState } from 'react';
import { connect } from 'react-redux';
import camera from '../../../assets/images/camera.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Row, Col } from 'react-bootstrap';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import imageCompression from 'browser-image-compression';

//Redux
import postActions from '../../../redux/actions/postsActions';

//IMAGES
import LineRedImg from '../../../assets/images/Line-red.svg';
import removePhoto from '../../../assets/images/remove_photo_red.svg';
//CSS
import './popup.css';
function AddPost(props) {
	const [formData, setFormData] = useState({
		title: '',
		content: '',
		image: '',
		sub_title: '',
		meta_title: '',
		meta_description: '',
		meta_keywords: ''
	});


	const[contentError, setContentError] = useState('');
	const[imageError, setImageError] = useState('');


	const handleOnChangeImage = (e) => {
		checkImageDimensions(e.target.files[0]);
	};
	const options = {
		maxSizeMB: 0.2,
		useWebWorker: true
	};

	const checkImageDimensions = (file) =>{
		var image = new Image();
		let reader = new FileReader();
		reader.onload = (e) => {
			image.src = e.target.result;
			image.onload = ()=>{
				if(image.width <1000 || image.height > 700){
					setImageError('Image dimensions should be 1000X700');
					setFormData({
						...formData,
						image: '',
					});
				}
				else{
					setImageError('');
					compressImage(file)
				}
			}
		};
		reader.readAsDataURL(file);
		
	}
	const compressImage =  (file) =>{
		imageCompression(file, options).then((compresssedFile)=>{
			createImage(compresssedFile)
		})
	}
	const createImage = (file) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			setFormData({
				...formData,
				image: e.target.result,
			});
			
		};
		reader.readAsDataURL(file);
	};

	const createPost = (e) => {
		e.preventDefault();
		if(!formData['content']){
			setContentError('This field is required');
			return
		}
		if(!formData['image']){
			setImageError('Image is required');
			return
		}
		setContentError('');
		setImageError('');
		props.dispatch(postActions.createPost({ data: formData }));
		
	};
	return (
		<div>
			<div style={styles.headerContainer}>
				<img src={LineRedImg} alt='line Red' style={styles.lineRed} />
				<span style={styles.title}>ADD NEW POST</span>
			</div>
			<form onSubmit={createPost}>
			<label className='labels'>
				Post Title
			</label>
			<input
				style={styles.inputTitle}
				placeholder={'Title'}
				className='popup_Input'
				value={formData.title}
				onChange={(e) => {
					setFormData({ ...formData, title: e.target.value });
				}}
				required
			></input>
			<label className='labels'>
				Post Subtitle
			</label>
			<textarea
				style={styles.textArea2}
				placeholder={'Subtitle'}
				className='popup_Input'
				value={formData.sub_title}
				onChange={(e) => {
					setFormData({ ...formData, sub_title: e.target.value });
				}}
			></textarea>
			<label className='labels'>
				Post Content
			</label>
			<CKEditor
				style={styles.textArea}
				className='popup_Input'
				editor={ClassicEditor}
				data={formData.content}
				config={{
					toolbar: [
						'heading',
						'|',
						'bold',
						'italic',
						'link',
						'bulletedList',
						'numberedList',
						'blockQuote',
					],
					heading: {
						options: [
							{
								model: 'paragraph',
								title: 'Paragraph',
								class: 'ck-heading_paragraph',
							},
							{
								model: 'heading1',
								view: 'h1',
								title: 'Heading 1',
								class: 'ck-heading_heading1',
							},
							{
								model: 'heading2',
								view: 'h2',
								title: 'Heading 2',
								class: 'ck-heading_heading2',
							},
							{
								model: 'heading3',
								view: 'h3',
								title: 'Heading 3',
								class: 'ck-heading_heading3',
							},
							{
								model: 'heading4',
								view: 'h4',
								title: 'Heading 4',
								class: 'ck-heading_heading4',
							},
							{
								model: 'heading5',
								view: 'h5',
								title: 'Heading 5',
								class: 'ck-heading_heading5',
							},
							{
								model: 'heading6',
								view: 'h6',
								title: 'Heading 6',
								class: 'ck-heading_heading6',
							},
						],
					},
				}}
				onChange={(event, editor) => {
					const data = editor.getData();
					if(!data){
						setContentError('This field is required');
					}
					else{
						setContentError('');
					}
					setFormData({ ...formData, content: data });
				}}
			/>
			<p className="error">{contentError}</p>
			<Row>
				<Col>
					<label className='labels'>
						Meta Title
					</label>
					<input
						style={styles.inputTitle}
						placeholder={'Meta Title'}
						className='popup_Input'
						value={formData.meta_title}
						onChange={(e) => {
							setFormData({ ...formData, meta_title: e.target.value });
						}}
					></input>
				</Col>
				<Col>
					<label className='labels'>
						Meta description
					</label>
					<input
						style={styles.inputTitle}
						placeholder={'Meta Description'}
						className='popup_Input'
						value={formData.meta_description}
						onChange={(e) => {
							setFormData({ ...formData, meta_description: e.target.value });
						}}
					></input>
				</Col>
				<Col>
					<label className='labels'>
						Meta Keywords
					</label>
					<input
						style={styles.inputTitle}
						placeholder={'Meta Keywords'}
						className='popup_Input'
						value={formData.meta_keywords}
						onChange={(e) => {
							setFormData({ ...formData, meta_keywords: e.target.value });
						}}
					></input>
				</Col>
			</Row>
			<div style={styles.mediaContainer}>
				<label className='labels'>
					Image
				</label>
				{formData.image ? (
					<div style={styles.photoContainer}>
						<img
							src={formData.image}
							style={{
								width: '225px',
								objectFit: 'contain',
								marginTop: '23px',
								position: 'relative',
							}}
							alt=''
						/>
						<img
							src={removePhoto}
							alt=''
							style={styles.photoClose}
							onClick={() => {
								setImageError('Image is required')
								setFormData({ ...formData, image: '' });
							}}
						/>
					</div>
				) : null}
				<div>
					<button
						className='add_photo_btn'
						type='button'
						style={{ float: 'left', marginTop: '25px' }}
					>
						<input
							onChange={(e) => {
								handleOnChangeImage(e);
							}}
							type='file'
							id='file'
							name='file'
							className='custom_file_input'
						/>
						<label
							htmlFor='file'
							className='bold blue text-upper add_photo_label'
							style={{ fontSize: '16px' }}
						>
							<img src={camera} alt='' />
							Add Media 1000X700
						</label>
					</button>
				</div>
				{imageError!=='' && <p className='error'>{imageError}</p>}
				<button
					className='popup_button'
					type="submit"
				>
					Create Post{' '}
				</button>
				{props?.popupStateLoading === true && (
					<div className='text-center mx-auto mt-4'>
						<CircularProgress color='primary' size={30} />
					</div>
				)}
			</div>
			</form>
		</div>
	);
}

const mapStateToProps = (state) => ({
	popupStateLoading: state.postReducer.popupStateLoading,
});

const styles = {
	title: {
		color: '#262262',
		fontSize: '36px',
		fontStyle: 'bold',
		marginTop: '5px',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	lineRed: {
		width: '88px',
	},
	inputTitle: {
		width: '100%',
		fontSize: '16px',
	},
	textArea: {
		width: '100%',
		height: '600px',
		fontSize: '16px',
	},
	textArea2: {
		width: '100%',
		height: '100px',
		fontSize: '16px',
	},
	mediaContainer: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
	},
	addMedia: {
		position: 'absolute',
		left: 5,
		top: 5,
	},
	photoContainer: {
		position: 'relative',
		width: '225px',
	},
	photoClose: {
		position: 'absolute',
		top: 25,
		right: 5,
	},
};

export default connect(mapStateToProps)(AddPost);
