import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Autocomplete from 'react-google-autocomplete';
import imageCompression from 'browser-image-compression';
import NumberFormat from 'react-number-format';
// import Select from 'react-select';
import moment from 'moment';
//REDUX
import eventActions from '../../../redux/actions/eventsActions';
import popupAction from '../../../redux/actions/postsActions';

//CSS
import './EditEvent.css';

//components
import ProgramCard from '../../../Components/Events/EventProgramCard';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactPlayer from 'react-player'

//Images
import backArrow from '../../../assets/images/arrow-red-left.svg';
// import saveIcon from '../../../assets/images/save-icon.svg';
import plus from '../../../assets/images/white-cross.svg';
import radioCheckedLogo from '../../../assets/images/Checked.png';
import radioUncheckedLogo from '../../../assets/images/Unchecked.png';
import ArrowBlueImg from '../../../assets/images/arrow_blue_down.svg';
// import post_one from '../../../assets/images/post_one.png';
import camera from '../../../assets/images/camera.svg';
import removePhoto from '../../../assets/images/remove_photo_red.svg';

import { coachHandle } from '../../../utils/global';

import "react-datepicker/dist/react-datepicker.css";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DatePickerComponent from '../../../Components/DatePickerComponent/DatePickerComponent';

const AddEvent = (props) => {

	const [errors, setErrors] = useState({
		description: '',
		banner: '',
		end_time: '',
		});

	const [type, setType] = useState('');
	const [videoSrc, setVideoSrc] = useState('');
	useEffect(() => {
		coachHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null);
		props.dispatch(eventActions.clearForm());
		props.dispatch(eventActions.getEventCategories());
		return () => {
			props.dispatch(eventActions.clearForm());
		}
	}, []);
	const handleRecurence = () => {
		props.dispatch(popupAction.popupHandler({ type: 'CUSTOMREC' }));
	};
	const handleDataChange = (e) => {
		props.dispatch(
			eventActions.handleAddEventFormData({
				name: e.target.name,
				data: e.target.value,
				formData: props.formData,
			})
		);
	};

	const handleDateChange = (data, name) => {
		props.dispatch(
			eventActions.handleAddEventFormData({
				name: name,
				data: data,
				formData: props.formData,
			})
		);
	}

	const handleDataChangeType = (e) => {
		props.dispatch(
			eventActions.handleAddEventRecurenceData({
				name: 'recurrence',
				data: {},
				formData: props.formData,
				name2: 'is_recurring',
				data2: 0,
				data3: 1,
				name3: 'is_full_day_event',
				name4: 'is_custom_rec',
				data4: 0,
			})
		);
	};

	const handleOnChangeImage = (e) => {
		if(e.target.files[0]){
			setErrors({
				...errors,
				banner: ''
			});
		}
		checkImageDimensions(e.target.files[0]);
	};
	const options = {
		maxSizeMB: 0.2,
		useWebWorker: true
	};

	const checkImageDimensions = (file) =>{
		var image = new Image();
		let reader = new FileReader();
		reader.onload = (e) => {
			image.src = e.target.result;
			image.onload = ()=>{
				compressImage(file)
			}
		};
		reader.readAsDataURL(file);
		
	}
	const compressImage =  (file) =>{
		imageCompression(file, options).then((compresssedFile)=>{
			createImage(compresssedFile)
		})
	}
	const createImage = (file) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			let j = {
				target: {
					value: e.target.result,
					name: 'banner',
				},
			};
			handleDataChange(j);
			
		};
		reader.readAsDataURL(file);
	};

	const createVideo = (file) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			let j = {
				target: {
					value: e.target.result,
					name: 'thumbnail',
				},
			};
			handleDataChange(j);
		};
		reader.readAsDataURL(file);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let status;
		if (type === 'Publish') {
			status = 'public';
		} else {
			status = 'draft';
		}
		const finalStartDate = props.formData.start_date.toISOString().split('T')
		let programs_updated = [];
		props.formData.programs.map((program)=>{
			programs_updated.push({
				title: program?.title,
				name: program?.name,
				event_id: program?.event_id,
				ticket_price: program?.ticket_price,
				number_of_days: props?.rec_dates?.length === 1 ? 1 : program?.number_of_days,
				is_akv_allowed: program?.is_akv_allowed,
				discount: program?.discount ? program?.discount : null,
				from: program?.from ? moment(program?.from).format('YYYY-MM-DD') : null,
				to: program?.to ? moment(program?.to).format('YYYY-MM-DD') : null ,
			})
		})
		let finalData = {
			start_date: finalStartDate[0],
			// end_date: props.formData.is_full_day_event == 1 ? props.formData.start_date : endDateBasedOnReccurence(),
			start_time: props.formData.start_time,
			end_time: props.formData.end_time,
			title: props.formData.title,
			category_id: props.formData.category_id,
			subtitle: props.formData.subtitle,
			description: props.formData.description,
			location: props.formData.location,
			venue: props.formData.venue,
			available_space: props.formData.available_space,
			occupied_space: 0,
			is_full_day_event: props.formData.is_full_day_event,
			is_recurring: props.formData.is_recurring,
			is_custom_rec: props?.formData?.is_custom_rec,
			status: status,
			recurrence: props.formData.recurrence,
			banner: props.formData.banner,
			programs: programs_updated,
			meta_title: props.formData.meta_title,
			meta_keywords: props.formData.meta_keywords,
			meta_description: props.formData.meta_description,
			thumbnail: props.formData.thumbnail? props.formData.thumbnail : null,
			// discount_percentage: props.formData.discount_percentage,
			// discount: props.formData.discount,
			pricing: props?.formData?.pricing,
			id_in_business_center: props.formData.id_in_business_center
		};

		if(!finalData['banner']){
			setErrors({
				...errors,
				banner: "Banner is required",
			})
			return
		}

		if(!finalData['description']){
			setErrors({
				...errors,
				description: "Description is required",
			})
			return
		}


		if(finalData.start_time>finalData.end_time){
			setErrors({
				...errors,
				end_time: "End time must be greater than start time",
			})
			return
		}

		setErrors({
			banner: '',
			description: '',
			end_time: '',
		})

		// if (props.formData.discount?.discount && props.formData.discount?.from && props.formData.discount?.to) {
		// 	let finalDiscount = {
		// 		from: moment(props.formData.discount.from).format("YYYY-MM-DD"),
		// 		to: moment(props.formData.discount.to).format("YYYY-MM-DD"),
		// 		discount: props.formData.discount.discount
		// 	}
		// 	finalData['discount'] = finalDiscount
		// }

		
		props.dispatch(eventActions.createEvent({ data: finalData }));
		
		
		
	};

	const [handleRadio, setRadio] = useState('oneDay');

	const handleEventType = () => {
		handleDataChangeType();
		setRadio('oneDay');

	};

	const endDateBasedOnReccurence = () => {
		if (props.formData.recurrence.after_reccurence) {
			return (moment(props.formData.start_date).add(props.formData.recurrence.after_reccurence, props.formData.recurrence.frequency === 'weekly' ? 'weeks' : 'days').toDate())
		} else if (props.formData.recurrence.ends_on) {
			return (moment(props.formData.recurrence.ends_on).toDate())
		}
	}

	const [isOpen, setIsOpen] = useState(false);

	const handleOpenCalendar = () => {
		setIsOpen(!isOpen)
	}

	const handleVideoChange = (e) => {
		let file = e.target.files[0];
		var url = URL.createObjectURL(file);
		setVideoSrc(url);
		createVideo(file)
	};

	return (
		<section>
			<div className='event_edit_detail_top_div'>
				<div className='left_detail_top'>
					<Link to='/admin/events'>
						<div className='go_back_arrow'>
							<img src={backArrow} alt='Go Back' />
						</div>
					</Link>
					<div className='bold blue font-36 text-upper float-left'>
						ADD NEW EVENT
					</div>
				</div>

				{/* <div className='left_detail_top'>
                    <Link to='' className='edit_event_save bold'>
                        <img src={saveIcon} alt='' />

                        <div className='edit_link_text_div'>
                            <span>SAVE</span>
                        </div>
                    </Link>
                </div> */}
			</div>
			<div className='edit_event_mainbody'>
				<div className='recurence_div'>
					<div className='recurence_choices'>
						<span className='blue bold text-upper font-18'>event type</span>
						<div className='blue font-16 event_recurence_radio'>
							<div
								onClick={() => {
									handleEventType();
								}}
								className='mr-1'
							>
								<img
									src={
										handleRadio === 'oneDay'
											? radioCheckedLogo
											: radioUncheckedLogo
									}
									alt=''
								/>
							</div>
							<span>One Day</span>
						</div>
						<div className='blue font-16 event_recurence_radio'>
							<div
								onClick={() => {
									setRadio('recurring');
									props.dispatch(eventActions.handleNewRecData({
										count: 1,
										dates: [],
										text: ''
									}))
								}}
								className='mr-1'
							>
								<img
									src={
										handleRadio === 'recurring'
											? radioCheckedLogo
											: radioUncheckedLogo
									}
									alt=''
								/>
							</div>
							<span>Recurring</span>
						</div>
					</div>
					{handleRadio === 'recurring' ? (
						<div
							onClick={() => {
								handleRecurence();
							}}
							className='custom_recurence white bold text-upper'
						>
							<img src={plus} alt='' className='mr-2' />
							CUSTOM RECURRENCE
						</div>
					) : null}
				</div>
				<div className=' bold blue font-36 text-upper mt-4'>
					<span>Add Event Details</span>
				</div>
				<form onSubmit={handleSubmit}>
				<div className='event_edit_form_comtainer'>
					<div className='event_edit_row'>
						<div className='event_edit_input'>
							<label className='blue bold font-14 text-upper'>
								Event Start Date *
							</label>
							<DatePickerComponent
								containerClass="event_calendar"
								name='start_date'
								selected={props?.formData?.start_date ? moment(props?.formData?.start_date).toDate() : moment().toDate()}
								// minDate={moment().toDate()}
								handleDateChange={(e) => handleDateChange(e, 'start_date')}
								open={isOpen}
								setIsOpenTrue={() => setIsOpen(true)}
								setIsOpenFalse={() => setIsOpen(false)}
							/>
						</div>
						<div className='event_edit_input position-relative blue regular font-16'>
							<label className='bold text-upper blue font-14'>
								Event start Time*
							</label>
							<input
								type='time'
								required
								placeholder='Time'
								name='start_time'
								value={props.formData.start_time}
								onChange={(e) => {
									handleDataChange(e);
								}}
							></input>
						</div>
						<div className='event_edit_input position-relative  blue regular font-16'>
							<label className='bold text-upper blue font-14'>
								Event End Time*
							</label>
							<input
								type='time'
								placeholder='Time'
								name='end_time'
								value={props.formData.end_time}
								onChange={(e) => {
									handleDataChange(e);
								}}
								required
							></input>
							{errors['end_time']!=='' && <p className="error">{errors['end_time']}</p>}
						</div>
					</div>
					<div className='event_edit_row'>
						<div className='event_edit_input position-relative  blue regular font-16'>
							<label className='bold text-upper blue font-14'>
								Event Title*
							</label>
							<input
								type='text'
								placeholder='Event Ttile'
								name='title'
								onChange={(e) => {
									handleDataChange(e);
								}}
								value={props.formData.title}
								required
							></input>
						</div>
						<div className='event_edit_input position-relative blue regular font-16'>
							<label className='bold text-upper blue font-14'>
								Age*
							</label>
							<input
								type='text'
								placeholder='Age'
								name='subtitle'
								value={props.formData.subtitle}
								onChange={(e) => {
									handleDataChange(e);
								}}
								required
							></input>
						</div>
						<div className='event_edit_input d-inline-block blue regular font-16'>
							<label className='bold text-upper blue font-14'>
								Event Location*
							</label>
							<Autocomplete
								value={props.formData.location}
								onPlaceSelected={(place) => {
									let j = {
										target: {
											value: place.formatted_address,
											name: 'location',
										},
									};
									handleDataChange(j);
								}}
								onChange={(e) => {
									let j = {
										target: {
											value: e.target.value,
											name: 'location',
										},
									}
									handleDataChange(j);
								}}
								options={{
									types: ["(regions)"],
									componentRestrictions: { country: "au" },
								}}
								required
							/>
						</div>
					</div>
					<div className='event_edit_row justify-content-start'>
						<div className='double_col_event_input'>
							<div className='blue regular font-16'>
								<label className='text-upper bold font-14'>
									AVAILABLE SPACE *Day
								</label>
								<input
									type='number'
									placeholder='00'
									name='available_space'
									className='number_input'
									value={props.formData.available_space}
									onChange={(e) => {
										handleDataChange(e);
									}}
									required
								></input>
							</div>
						</div>
						<div className='event_edit_input d-inline-block blue regular font-16 v-top ml-4'>
							<label className='bold text-upper blue font-14'>
								Event Category*
							</label>
							<div className='d-flex'>
								<select
									required
									onChange={(e) => {
										let j = {
											target: {
												value: e.target.value,
												name: 'category_id',
											},
										};
										handleDataChange(j);
									}}
									name='category_id'
									id=''
									className='edit_event_select'
									style={{ background: `url(${ArrowBlueImg}) no-repeat right #fff`, width: '100%' }}
								>
									<option key={'1Base'} value={''}>
										Choose Event Category
									</option>
									{props?.eventCategories?.length > 0
										? props?.eventCategories?.map((zone, i) => {
											return (
												<option key={i} value={zone.id}>
													{zone.name}
												</option>
											);
										})
										: null}
								</select>
							</div>
						</div>
						<div className='event_edit_input d-inline-block blue regular font-16 v-top ml-4'>
							<label className='bold text-upper blue font-14'>
								Venue*
							</label>
							<input
								type='text'
								placeholder='Venue'
								name='venue'
								value={props.formData.venue}
								onChange={(e) => {
									handleDataChange(e);
								}}
								required
							></input>
						</div>
						<div className='event_edit_input d-inline-block blue regular font-16 v-top ml-4'>
							<label className='bold text-upper blue font-14'>
								Id In Business Central
							</label>
							<input
								type='text'
								placeholder='Id'
								name='id_in_business_center'
								value={props.formData?.id_in_business_center}
								onChange={(e) => {
									handleDataChange(e);
								}}
							></input>
						</div>
					</div>
				</div>
				<div className='event_edit_row'>
					<div className='event_edit_input position-relative  blue regular font-16'>
						<label className='bold text-upper blue font-14'>
							Meta Title*
						</label>
						<input
							type='text'
							required
							placeholder='Meta title'
							name='meta_title'
							onChange={(e) => {
								handleDataChange(e);
							}}
							value={props.formData.meta_title}
						></input>
					</div>
					<div className='event_edit_input position-relative blue regular font-16'>
						<label className='bold text-upper blue font-14'>
							Meta Description*
						</label>
						<input
							type='text'
							required
							placeholder='Meta description'
							name='meta_description'
							value={props.formData.meta_description}
							onChange={(e) => {
								handleDataChange(e);
							}}
						></input>
					</div>
					<div className='event_edit_input position-relative blue regular font-16'>
						<label className='bold text-upper blue font-14'>
							Meta Keywords*
						</label>
						<input
							type='text'
							required
							placeholder='Meta Keywords  '
							name='meta_keywords'
							value={props.formData.meta_keywords}
							onChange={(e) => {
								handleDataChange(e);
							}}
						></input>
					</div>
					
					
				</div>
				<div className='event_edit_banner_img'>
				<div className='event_edit_input position-relative blue regular font-16'>
						<label className='bold text-upper blue font-14'>
							Pricing*
						</label>
						<input
							type='text'
							placeholder='Pricing'
							name='pricing'
							required
							value={props.formData.pricing}
							onChange={(e) => {
								handleDataChange(e);
							}}
						></input>
					</div>
					<label className='bold text-upper blue font-14'>Banner image*</label>
					{props.formData.banner !== '' ? (
						<div className='event_banner_image_container'>
							<img src={props.formData.banner} alt='' />
							<img
								src={removePhoto}
								alt=''
								className='remove_photo'
								onClick={() => {
									setErrors({
										...errors,
										banner: 'Banner is required'
									});
									let j = {
										target: {
											value: '',
											name: 'banner',
										},
									};
									handleDataChange(j);
								}}
							/>
						</div>
					) : null}

					<button className='event_add_photo_btn' type="button">
						<input
							type='file'
							id='file'
							name='file'
							className='event_custom_file_input'
							onChange={(e) => {
								handleOnChangeImage(e);
							}}
						/>
						<label
							htmlFor='file'
							className='bold blue text-upper event_add_photo_label'
						>
							<img src={camera} alt='' />
							ADD PHOTO
						</label>
					</button>
					<p className="error">{errors['banner']}</p>
				</div>
				<div className='event_edit_banner_img'>
				<label className='bold text-upper blue font-14'>Video</label>
				{videoSrc!==''&& <div className='event_video_container'>
				<ReactPlayer url={videoSrc} controls={true} className="event_video"/>
				<img
								src={removePhoto}
								alt=''
								className='remove_photo'
								onClick={() => {
									let j = {
										target: {
											value: null,
											name: 'thumbnail',
										},
									};
									handleDataChange(j);
									setVideoSrc('');
								}}
							/>
				</div>}
				<button className='event_add_photo_btn' type="button">
						<input
							type='file'
							id='video'
							name='video'
							accept="video/mp4,video/x-m4v,video/*"
							className='event_custom_file_input'
							onChange={(e)=>{
								handleVideoChange(e)
							}
							}
						/>
						<label
							htmlFor='video'
							className='bold blue text-upper event_add_photo_label'
						>
							<img src={camera} alt='' />
							ADD VIDEO
						</label>
					</button>
				</div>
				<div className='event_desc_div pt-5 blue regular font-16'>
					<label className='text-upper  bold font-14'>About the EVENT*</label>
					<CKEditor
						editor={ClassicEditor}
						value={props.formData.description}
						config={{
							toolbar: [
								'heading',
								'|',
								'bold',
								'italic',
								'link',
								'bulletedList',
								'numberedList',
								'blockQuote',
							],
							heading: {
								options: [
									{
										model: 'paragraph',
										title: 'Paragraph',
										class: 'ck-heading_paragraph',
									},
									{
										model: 'heading1',
										view: 'h1',
										title: 'Heading 1',
										class: 'ck-heading_heading1',
									},
									{
										model: 'heading2',
										view: 'h2',
										title: 'Heading 2',
										class: 'ck-heading_heading2',
									},
									{
										model: 'heading3',
										view: 'h3',
										title: 'Heading 3',
										class: 'ck-heading_heading3',
									},
									{
										model: 'heading4',
										view: 'h4',
										title: 'Heading 4',
										class: 'ck-heading_heading4',
									},
									{
										model: 'heading5',
										view: 'h5',
										title: 'Heading 5',
										class: 'ck-heading_heading5',
									},
									{
										model: 'heading6',
										view: 'h6',
										title: 'Heading 6',
										class: 'ck-heading_heading6',
									},
								],
							},
						}}
						onChange={(event, editor) => {
							const data = editor.getData();
							if(data){
								setErrors({
									...errors,
									description: ''
								});
							}
							else{
								setErrors({
									...errors,
									description: 'Description is required'
								})
							}
							let j = {
								target: {
									value: data,
									name: 'description',
								},
							};
							handleDataChange(j);
						}}
					/>
					<p className="error">{errors['description']}</p>
				</div>
				<div className='event_program_edit_section'>
					<div className='header_title_left bold blue font-36 float-left text-upper pt-5'>
						<span className='text-upper'>Add Event Program</span>
					</div>
					<div className='event_programs_container'>
						{props.formData.programs.length > 0
							? props.formData.programs.map((zone, i) => {
								return (
									<ProgramCard
										title={zone.title}
										ticket_price={<NumberFormat value={Number(zone.ticket_price).toFixed(2)} displayType={'text'} thousandSeparator={true} />}
										name={zone.name}
										number_of_days={zone.number_of_days}
										is_akv_allowed={zone?.is_akv_allowed}
										id={i}
										deleteId={i}
										key={i}
									/>
								);
							})
							: null}
						<div
							onClick={() => {
								props.dispatch(popupAction.popupHandler({ type: 'ADDPROG' }));
							}}
							className='add_event_program_btn'
						>
							<img src={plus} alt='' />
						</div>
					</div>
				</div>
				{/* <div className='edit_special_offecr_section'>
					<div className='header_title_left bold blue font-36 float-left text-upper pt-5'>
						<span className='text-upper'>Add Special Offer</span>
					</div>
					<div className='offer_edit_container mt-3'>
						<div className='event_edit_input position-relative blue regular font-16'>
							<label>Start date</label>
							<DatePickerComponent
								containerClass="event_calendar"
								placeholder='Start Date'
								selected={props.formData?.discount?.from ? moment(props.formData?.discount?.from).toDate() : ''}
								minDate={new Date()}
								maxDate={props.formData?.discount?.to ? moment(props.formData?.discount?.to).toDate() : ''}
								handleDateChange={(e) => {
									let value = {
										discount: props.formData?.discount?.discount,
										from: e,
										to: props.formData?.discount?.to,
									}
									handleDateChange(value, 'discount')
								}}
								open={isOpen}
								setIsOpenTrue={() => setIsOpen(true)}
								setIsOpenFalse={() => setIsOpen(false)}
							/>
						</div>
						<div className='event_edit_input position-relative blue regular font-16'>
							<label>End date</label>
							<DatePickerComponent
								containerClass="event_calendar"
								placeholder='End Date'
								selected={props.formData?.discount?.to ? moment(props.formData?.discount?.to).toDate() : ''}
								minDate={props.formData?.discount?.from ? moment(props.formData?.discount?.from).toDate() : ''}
								handleDateChange={(e) => {
									let value = {
										discount: props.formData?.discount?.discount,
										from: props.formData?.discount?.from,
										to: e,
									}
									handleDateChange(value, 'discount')
								}}
								open={isOpen}
								setIsOpenTrue={() => setIsOpen(true)}
								setIsOpenFalse={() => setIsOpen(false)}
							/>
						</div>
						<div className='event_edit_input position-relative blue regular font-16'>
							<label>Amount</label>
							<input
								onChange={(e) => {
									let j = {
										target: {
											name: "discount",
											value: {
												discount: e.target.value,
												from: props.formData?.discount?.from,
												to: props.formData?.discount?.to,
											}
										}
									}
									handleDataChange(j);
								}}
								name='discount'
								type='number'
								placeholder='Amount'
								value={props?.formData?.discount?.discount || ''}
								className='number_input'
							></input>
						</div>
					</div>
				</div> */}


				{handleRadio==='recurring' && 
				<div className='edit_special_offecr_section blue font-14' style={{marginBottom: '20px'}}>
					<div className='header_title_left bold blue font-36 float-left text-upper pt-5'>
						<span className='text-upper'>Recurrence Dates</span>
					</div>
					{props.rec_dates.length!==0 && 
					<ol>
					<div className="grid_display_dates">

						{props?.rec_dates?.map(rec => {
							return(
								<li>{moment(rec).format('DD/MM/YYYY') === "Invalid date" ? rec : moment(rec).format('DD/MM/YYYY')}</li>
							)	
						})}

					</div>
					</ol>
					}
				</div>
				}

				<div className='edit_event_button_div'>
					<div>
						<button
							type='submit'
							onClick={() => {
								if (!props.addEventLoading) {
									setType('Publish');
									// handleSubmit('Publish')
								}
							}}
							className={`blue_edit_event_button ${props.addEventLoading ? "disabledButton" : ''}`}
						>
							<span className='bold white text-upper font-14'>PUBLISH</span>
						</button>
						<div
							style={{ height: '37px' }}
							className='text-center mx-auto mt-4'
						>
							{props.addEventLoading === true ? (
								<CircularProgress color='primary' size={30} />
							) : null}
						</div>
					</div>

					<div>
						<button
							type='submit'
							onClick={() => {
								if (!props.addEventLoading) {
									setType('draft');
									// handleSubmit('draft')
								}
							}}
							className={`blue_edit_event_button ${props.addEventLoading ? "disabledButton" : ''}`}
						>
							<span className='bold white text-upper font-14'>
								SAVE AS DRAFT
							</span>
						</button>
						<div
							style={{ height: '37px' }}
							className='text-center mx-auto mt-4'
						></div>
					</div>
				</div>
				</form>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => ({
	eventCategories: state.eventReducer.eventCategories,
	formData: state.eventReducer.formData,
	addEventLoading: state.eventReducer.addEventLoading,
	rec_text: state.eventReducer.rec_text,
	rec_dates: state.eventReducer.rec_dates,
	user: state.register.user
});

export default connect(mapStateToProps)(AddEvent);
