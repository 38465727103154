import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { coachHandle } from '../../utils/global';
import queryString from 'query-string';
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

//images
import plus from '../../assets/images/click-plus.svg';

//components
import CategoryRow from './Rows/CategoryRow';

//Redux
import postsActions from "../../redux/actions/postsActions";
import shopActions from "../../redux/actions/shopActions";

import './StoreCategoriesList.css';



const useStyles = makeStyles((theme) => ({
	root: {
	  "& > *": {
		marginTop: theme.spacing(2),
	  },
	},
  }))

function StoreCategoriesList(props) {
	let history = useHistory();
	const classes = useStyles();

	const [searchQuery, setSearchQuery] = useState({
		value:'',
		current:'',
	})
	const [isSearch, setIsSearch] = useState(false)


	useEffect(() => {
		if(isSearch){
			props.dispatch(shopActions.getStoreCategories({query:queryString.parse(props.location.search).query, page:queryString.parse(props.location.search).page}));
		}else{
			props.dispatch(shopActions.getStoreCategories({page:queryString.parse(props.location.search).page}))
		}
	}, [queryString.parse(props.location.search).page, queryString.parse(props.location.search).query, isSearch]);

	useEffect(() => {
		coachHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null);
	}, []);

	const handleSearch = (e) => {
		setIsSearch(true);
		setSearchQuery({...searchQuery,current:searchQuery.value});
		
		history.push({
			pathname: '/admin/stores',
			search: `?page=${1}&query=${searchQuery.value}`,
		});
	};

	const handleNewPagination = (e, selectedPage) =>{
		if (isSearch) {
			history.push({
			  pathname: "/admin/stores",
			  search: `?page=${selectedPage}&query=${searchQuery.current}`,
			});
		  } else {
			history.push({
			  pathname: "/admin/stores",
			  search: `?page=${selectedPage}`,
			});
		  }
	  };


	return (
		<section>
			<div className='coach_top_div'>
				<div className='bold blue font-36 text-upper float-left'>Store Categories</div>
				<div className='coach_form_div'>
					{isSearch&&
					(<div className="mr-3">
						<div>
							SHOWING RESULTS FOR "{searchQuery.current}"
						</div>
						<div className="search_cancel">
							<span className="pointer" onClick={() => {
										setIsSearch(false);
										setSearchQuery({...searchQuery, value:''})
									}}>
								CANCEL		
							</span>
						</div>
					</div>)}
					<div className='seach_bar'>
						<input className='search_button' type='search' onClick={()=>handleSearch()} />
						<input
							type='text'
							placeholder='Search for Categories...'
							className='search_input'
							value={searchQuery.value}
							onChange={(e) => {
								setSearchQuery({...searchQuery,value:e.target.value});
							}}
						/>
					</div>
					<div style={{cursor: 'pointer'}} onClick={()=>{props.dispatch(postsActions.popupHandler({type: 'ADD_STORE_CATEGORY'}))}} className='coach_add_new bold'>
						<img src={plus} alt='' />
						<span>Add New</span>
					</div>
				</div>
			</div>
			<div className='coach_list_div'>
				<div className='coach_list_top'>
					<div className='coach_table_header col-4'>Category Name</div>
					<div className='coach_table_header col-4'>Products Total</div>
					<div className='coach_table_header col-4'>View/Edit Products</div>
				</div>
				<div className='coach_list_container'>
					{props.listCategoriesLoading ? (
						<div className='text-center mx-auto mt-4'>
							<CircularProgress color='primary' size={30} />
						</div>
					) : (
						props.listCategories.data?.map((zone, i) => {
							let data = (
								<CategoryRow
									key={i}
									zone={zone}
									name={zone.name}
									countProducts = {zone?.products ? zone?.products?.length : 0}
								/>
							);
							return data;
						})
					)}
				</div>
				<div className={classes.root}>
          			<Pagination count={props?.listCategories?.last_page} defaultPage={ queryString.parse(props.location.search).page? parseInt(queryString.parse(props.location.search).page) : 1 } shape="rounded" onChange={handleNewPagination} />
       			 </div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => ({
	user: state.register.user,
	listCategories: state.ShopReducer.categoriesList,
	listCategoriesLoading: state.ShopReducer.categoriesListLoading
});

export default connect(mapStateToProps)(StoreCategoriesList);
