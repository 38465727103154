import React, { useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { coachHandle } from '../../../utils/global';

//redux
import membersAction from '../../../redux/actions/membersAction';

//Components
import KidsRow from '../../../Components/Members/KidsRow';

//images
// import plus from '../../../assets/images/click-plus.svg';
import whiteX from '../../../assets/images/x-white.svg';
import Checked from '../../../assets/images/CheckBoxChecked.png';
import Unchecked from '../../../assets/images/CheckBoxUnchecked.png';
import backArrow from '../../../assets/images/arrow-red-left.svg';

function KidsList(props) {
	const params = useParams();
	const history = useHistory();

	const [kids, setKids] = useState([])
	const [selected, setSelected] = useState(false)

	useEffect(() => {
		coachHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null);
		props.dispatch(membersAction.getMemberDetail({ id: params.member_id }));
	}, []);

	useEffect(() => {
		let finalData = []
		props.detailMember?.childrens?.map(zone => {
			finalData.push({
				id: zone.id,
				first_name: zone.first_name,
				last_name: zone.last_name,
				school_name: zone.school ? (zone.school?.school_name==='Other'? `Other - ${zone.school_name}` :(zone.school?.school_name + `${zone.school?.suburb ? ', ' + zone.school?.suburb : ''}`)) : '',
				profile_image: zone.photo,
				birthday_date: zone.dob,
				parent_id: zone.parent_id,
				checked: false
			})
		})
		setKids(finalData)
	}, [props.detailMember])

	const handleCheckKid = (id) => {
		let finalData = []
		kids.map(zone => {
			if (id === zone.id) {
				finalData.push({
					id: zone.id,
					first_name: zone.first_name,
					last_name: zone.last_name,
					school_name: zone.school_name,
					profile_image: zone.profile_image,
					birthday_date: zone.birthday_date,
					parent_id: zone.parent_id,
					checked: !zone.checked,
				})
			} else {
				finalData.push({
					id: zone.id,
					first_name: zone.first_name,
					last_name: zone.last_name,
					school_name: zone.school_name,
					profile_image: zone.profile_image,
					birthday_date: zone.birthday_date,
					parent_id: zone.parent_id,
					checked: zone.checked,
				})
			}
		})
		setKids(finalData)
	}

	const selectAll = (type) => {
		let finalData = []
		if (type === "checked") {
			kids.map(zone => {
				finalData.push({
					id: zone.id,
					first_name: zone.first_name,
					last_name: zone.last_name,
					school_name: zone.school_name,
					profile_image: zone.profile_image,
					birthday_date: zone.birthday_date,
					parent_id: zone.parent_id,
					checked: true,
				})
			})
		} else {
			kids.map(zone => {
				finalData.push({
					id: zone.id,
					first_name: zone.first_name,
					last_name: zone.last_name,
					school_name: zone.school_name,
					profile_image: zone.profile_image,
					birthday_date: zone.birthday_date,
					parent_id: zone.parent_id,
					checked: false,
				})
			})
		}
		if (type === "checked") {
			setKids(finalData)
			setSelected(true)
		} else {
			setKids(finalData)
			setSelected(false)
		}
	}


	const handleDelete = () => {
		let finalData = []
		let parentId
		kids.map(zone => {
			if (zone.checked === true) {
				finalData.push(zone.id)
				parentId = zone.parent_id
			}
		})
		// console.log(finalData, parentId)
		props.dispatch(membersAction.deleteChildrens({ kids: finalData, parent_id: parentId }))
	}
	return (
		<section>
			<div className='coach_top_div'>
				<div className='d-flex align-items-center'>
					<div className='go_back_arrow' onClick={()=> history.goBack()}>
						<img src={backArrow} alt='Go Back' />
					</div>
					<div className='bold blue font-36 text-upper float-left'>
						Kids details
					</div>
				</div>
				{/* <div className='coach_form_div'>
					<Link
						to='/admin/members/details/kids/new'
						className='coach_add_new bold'
					>
						<img src={plus} alt='' />
						<span>Add New</span>
	</Link>*/}
				<div className='member_delete_btn' onClick={() => { handleDelete() }}>
					<img src={whiteX} alt='' className='mr-2' />
					<span className='white bold  text-upper'>DELETE</span>
				</div>
				{/* </div>  */}
			</div>
			<div className='coach_list_div'>
				<div className='coach_list_top'>
					<div className='coach_table_header wi-8'>
						{selected === false ? <img src={Unchecked} alt='' onClick={() => { selectAll("checked") }} /> : <img src={Checked} alt='' onClick={() => { selectAll("unchecked") }} />}
					</div>
					<div className='events_table_header wi-13'>PHOTO</div>
					<div className='events_table_header wi-13'>NAME</div>
					<div className='events_table_header wi-13'>FAMILY NAME</div>
					<div className='events_table_header wi-13'>DATE OF BIRTH</div>
					<div className='events_table_header wi-10'>AGE</div>
					<div className='events_table_header wi-21'>SCHOOL NAME</div>
					<div className='events_table_header wi-16'>VOUCHER</div>
					<div className='events_table_header wi-10'>VIEW/ EDIT</div>
				</div>
				<div className='coach_list_container'>
					{/* <KidsRow
						first_name='mickel'
						last_name='soooo'
						school_name='5452143214'
						profile_image='dasdsad'
						birthday_date='12/12/12'
					/> */}
					{props.detailMemberLoading ? (
						<div className='text-center mx-auto mt-4'>
							<CircularProgress color='primary' size={30} />
						</div>
					) : (
						kids?.map((zone, i) => {
							// console.log('kid detailss',zone)
							let data = (
								<KidsRow
									key={i}
									id={zone.id}
									first_name={zone.first_name}
									last_name={zone.last_name}
									school_name={zone.school_name}
									profile_image={zone.profile_image}
									birthday_date={zone.birthday_date ? zone.birthday_date : 'N/A'}
									parent_id={zone.parent_id}
									checked={zone.checked}
									handleCheckKid={handleCheckKid}
								/>
							);
							return data;
						}) 
					)}
					{kids?.length > 0 ? null : <p className='font-16 blue'>No Data Available</p>}
				</div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => ({
	detailMember: state.membersReducers.detailMember,
	detailMemberLoading: state.membersReducers.detailMemberLoading,
	user: state.register.user
});

export default connect(mapStateToProps)(KidsList);
