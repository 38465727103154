import React, { useState } from 'react';
import { connect } from 'react-redux';
//REDUX
import couponsActions from "../../../redux/actions/couponsActions"
import popupAction from "../../../redux/actions/postsActions"

//images
import LineRedImg from '../../../assets/images/Line-red.svg';

//Components
import CouponsPopUpRow from '../../../Components/Coupons/CouponsPopUpRow';

export const CouponsDeactivate = (props) => {

	const [cancelNotes, setCancelNotes] = useState('');

	const handleConfirmDeactivate = () => {
		let couponsToDeactivate = props.selectedPost.data.map(e => e.id);
		let data = {coupons: couponsToDeactivate, notes: cancelNotes};
		props.dispatch(couponsActions.deactivateCoupons(data))
		props.dispatch(popupAction.popupHandler({ type: "CLOSE" }))
		props.selectedPost.action();
	}

	return (
		<div>
			<div className='form_style'>
				<img src={LineRedImg} />
			</div>
			<div className='form_title blue font-18 bold text-upper'>
				Are you sure you want to deactivate this { props.selectedPost.data.length } coupons?
			</div>
			<div className='coupons_list_main_body' hidden={cancelNotes!=''}>
				<div className="coupons_list_top">
					<div className="coupons_table_header wi-30">TYPE</div>
					<div className="coupons_table_header wi-30">CODE</div>
					<div className="coupons_table_header wi-30">VALUE</div>
					<div className="coupons_table_header wi-30">DESCRIPTION</div>
				</div>
				<div className='coupons_list_container'>
					{props?.selectedPost?.data?.map((zone, i) => {
						return(
							<CouponsPopUpRow
							key={i}
							type={zone.type}
							code={zone.code}
							value={zone.value}
							description={zone.description}
							/>
						);
						})
					}
				</div>
			</div>
			<textarea
				style={styles.textArea2}
				placeholder={'Cancel Notes'}
				className='popup_Input'
				value={cancelNotes}
				onChange={(e) => {
					setCancelNotes(e.target.value);
				}}
				hidden={cancelNotes==''}
			></textarea>

			<div className='d-flex flex-row mt-5'>
				<div onClick={() => { props.dispatch(popupAction.popupHandler({ type: "CLOSE" })) }} className='white text-upper bold font-18' style={styles.blue_btn}>
					Go back
				</div>
				<div onClick={() => { setCancelNotes(' ') }} className='white bold text-upper font-18' style={styles.red_btn} hidden={cancelNotes!=''}>
					PROCEED
				</div>
				<div onClick={() => { handleConfirmDeactivate() }} className='white bold text-upper font-18' style={styles.red_btn} hidden={cancelNotes==''}>
					CONFIRM
				</div>
			</div>
		</div>
	);
};

const styles = {
	red_btn: {
		width: '200px',
		background: '#d91c24',
		height: '70px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	blue_btn: {
		width: '200px',
		marginRight: '20px',
		background: '#262262',
		height: '70px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	textArea2: {
		width: '100%',
		height: '300px',
		fontSize: '16px',
	},
};

const mapStateToProps = (state) => ({
	selectedPost: state.postReducer?.selectedPost,
});

export default connect(mapStateToProps)(CouponsDeactivate);
