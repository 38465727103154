import React, { useState, useEffect } from 'react';
import { Link, useParams, withRouter, useHistory } from 'react-router-dom';
import { baseURL } from '../../../config/config';
import { connect } from 'react-redux';
import { coachHandle } from '../../../utils/global';

//redux
import membersAction from '../../../redux/actions/membersAction';

import insert from '../../../assets/images/insert-photo.png';
import backArrow from '../../../assets/images/arrow-red-left.svg';
import camera from '../../../assets/images/camera.svg';
import whiteX from '../../../assets/images/x-white.svg';
import ArrowBlueImg from '../../../assets/images/arrow_blue_down.svg';
import { DatePickerComponent } from '../../../Components/DatePickerComponent/DatePickerComponent';
import moment from 'moment';
import "../../Pages/Coach/CoachEdit.scss";

function EditKid(props) {
	const params = useParams();
	const history = useHistory();
	const [isChanged, setChanged] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const [errors, setErrors] = useState({
        first_name: '',
        last_name: '',
        dob: '',
    })

	const handleImageChange = (e) => {
		createImage(e.target.files[0]);
	};

	const createImage = (file) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			let item = {
				...props.detailKid,
				photo: e.target.result
			}
			props.dispatch(membersAction.setKidDetails(item));
			setChanged(true)
		};
		reader.readAsDataURL(file);
	};

	const deleteImage = () => {
		let item = {
			...props.detailKid,
			photo: ''
		}
		props.dispatch(membersAction.setKidDetails(item));
		setChanged(false)
	}

	const editChild = () => {
			let data
			if (isChanged === true) {
				data = props.detailKid
			} else {
				data = {
					first_name: props.detailKid.first_name,
					last_name: props.detailKid.last_name,
					dob: moment(props.detailKid.dob).format("YYYY-MM-DD"),
					school_id: props.detailKid.school || props.detailKid.school_id,
					existing_photo: props.detailKid.photo,
					email: props.detailKid.email,
					id: props.detailKid.id,
					message: props.detailKid.message,
					medical_details: props.detailKid.medical_details,
					parent_id: props.detailKid.parent_id,
				}
			}
			if(!data.first_name){
                setErrors({
                    first_name: 'This field is required',
                    last_name: '',
                    dob: '',
                })
                return
            }
            if(!data.last_name){
                setErrors({
                    first_name: '',
                    last_name: 'This field is required',
                    dob: '',
                })
                return
            }
            if(!props.detailKid.dob){
                setErrors({
                    first_name: '',
                    last_name: '',
                    dob: 'This field is required',
                })
                return
			}
            setErrors({
                first_name: '',
                last_name: '',
                dob: '',
            })
			
			props.dispatch(membersAction.editKid(data, props.history));
		
	}

	useEffect(() => {
		coachHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null);
		props.dispatch(membersAction.getKidDetails({ parentId: params.member_id, childrenId: params.chilren_id }));
		props.dispatch(membersAction.getSchools());
	}, []);


	const handleChange = (name) => (e) => {
		let item = {
			...props.detailKid,
			[name]: e.target.value
		}
		props.dispatch(membersAction.setKidDetails(item));
	};

	const handleDobChange = (e) => {
		if(!e){
			setErrors({
                ...errors,
                dob: 'This field is required'
            })
		}
		else{
			setErrors({
                ...errors,
                dob: ''
            })
		}
		let item = {
			...props.detailKid,
			dob: e
		}
		props.dispatch(membersAction.setKidDetails(item))
	}

	return (
		<section>
			<div className='coach_edit_top_div'>
				<div className='left_edit_top'>
					{/* change the back btn */}
					<div className='go_back_arrow' onClick={() => history.goBack()}>
						<img src={backArrow} alt='Go Back' />
					</div>
					<div className='bold blue font-36 text-upper float-left'>
						edit child: {props.detailKid?.first_name}
					</div>
				</div>
			</div>
			<div className='coach_edit_body'>
				<div className='coach_pfp_div'>
					<div className='coach_edit_pfp'>
						{props.detailKid?.photo ?
							<div className='delete_img_btn'>
								<img
									src={whiteX}
									alt=''
									onClick={() => deleteImage()}
								/>
							</div>
							: null}
						{isChanged === true ? (
							<img
								src={
									props.detailKid.photo ? props.detailKid.photo : insert
								}
								alt='kid'
							/>
						) : (
							<img
								src={
									props.detailKid.photo ? baseURL + '/image/' + props.detailKid.photo : insert
								}
								alt='kid'
							/>
						)}
					</div>
					<button className='add_photo_btn'>
						<input
							onChange={(e) => {
								handleImageChange(e);
							}}
							type='file'
							id='file'
							name='file'
							className='custom_file_input'
						/>
						<label
							htmlFor='file'
							className='bold blue text-upper add_photo_label'
						>
							<img src={camera} alt='' />
							upload photo
						</label>
					</button>
				</div>
				<div className='coach_edit_form'>
					<form>
					<div className='edit_form_row'>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>First Name</label>
							<input
								value={props.detailKid.first_name}
								onChange={handleChange('first_name')}
								name='First Name'
								type='text'
								placeholder='First Name'
								className='coach_edit_input'
								required
							/>
							<p className="error">{errors['first_name']}</p>
						</div>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Family Name</label>
							<input
								value={props.detailKid.last_name}
								onChange={handleChange('last_name')}
								name='Last Name'
								type='text'
								placeholder='Family Name'
								className='coach_edit_input'
								required
							/>
							<p className="error">{errors['last_name']}</p>
						</div>
					</div>
					<div className='edit_form_row'>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>date of birth</label>
							<DatePickerComponent
								containerClass="coach_edit_input_date"
								name='dob'
								required
								selected={props.detailKid.dob ? moment(props.detailKid.dob).toDate() : ''}
								handleDateChange={handleDobChange}
								isOpen={isOpen}
								setIsOpenTrue={() => setIsOpen(true)}
								setIsOpenFalse={() => setIsOpen(false)}
							/>
							<p className="error">{errors['dob']}</p>
							{/* <input
								value={props.detailKid.dob}
								onChange={handleChange('dob')}
								name='dob'
								type='text'
								placeholder='YYYY-MM-DD'
								className='coach_edit_input'
							/> */}
						</div>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>school name</label>
							{/* <input
								value ={props.detailKid.school}
								onChange={handleChange('school')}
								name='school'
								type='text'
								className='coach_edit_input number_input'
							/> */}
							<div className='d-flex'>
								<select
									required
									name=''
									id=''
									className='edit_event_select'
									onChange={handleChange('school')}
									style={{ background: `url(${ArrowBlueImg}) no-repeat right #fff`, width: '100%' }}
								>
									{props?.schools?.length > 0
										? props?.schools?.map((zone, i) => {
											if (Number(props.detailKid?.school_id) === zone.id) {
												return (
													<option key={i} value={zone.id} selected={true} defaultValue>
														{zone.school_name + `${zone.suburb ? ', ' + zone.suburb : ''}`}
													</option>
												);
											} else {
												return (
													<option key={i} value={zone.id}>
														{zone.school_name + `${zone.suburb ? ', ' + zone.suburb : ''}`}
													</option>
												);
											}
										})
										: null}
								</select>
							</div>
						</div>
					</div>
					<div className='edit_form_row'>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Notes</label>
							<textarea
								rows='8'
								value={props.detailKid.message ? props.detailKid.message : ''}
								onChange={handleChange('message')}
								name='message'
								type='text'
								placeholder='Message'
								className='coach_edit_input'
							/>
						</div>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Medical Details</label>
							<textarea
								rows='8'
								value={props.detailKid.medical_details ? props.detailKid.medical_details : ''}
								onChange={handleChange('medical_details')}
								name='medical_details'
								type='text'
								placeholder='Medical Details'
								className='coach_edit_input'
							/>
						</div>

					</div>
					<div className='edit_button_div'>
						<Link
						// to={`/admin/members/${params.member_id}/children`}

						>
							<button
								type="submit"
								onClick={() => {
									editChild();
								}}
								className='white bold text-upper save_red_button'
							>

								save

							</button>
						</Link>
						<Link
							to={`/admin/members/${props.detailKid.parent_id}/children`}
							className='white bold text-upper cancel_blue_button'
						>
							cancel
						</Link>
					</div>
					</form>
				</div>
			</div>
		</section>
	);
}




const mapStateToProps = (state) => ({
	detailKid: state.membersReducers.detailKid,
	detailKidLoading: state.membersReducers.detailKidLoading,
	schools: state.membersReducers.schools,
	user: state.register.user
})


export default withRouter(connect(mapStateToProps)(EditKid))
