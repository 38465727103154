import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { baseURL } from '../../../config/config';
import { useParams } from 'react-router-dom';
//CSS
import './CategoryRow.css';

import NumberFormat from 'react-number-format';

//IMAGES
import arrow from '../../../assets/images/right-arrow-blck.svg';
import Checked from '../../../assets/images/CheckBoxChecked.png';
import Unchecked from '../../../assets/images/CheckBoxUnchecked.png';

function ProductRow(props) {
	const params = useParams();
	return (
		<div className='coach_row_table'>
			<div className='coach_row_row'>
				<div className='coach_row_col check_col wi-5'>
					<img
						src={!props.checked ? Unchecked : Checked}
						alt=''
						onClick={() => {
							props.handleChecked(props.link);
						}}
						className='check_box_member'
					/>
				</div>
				<div className='coach_row_col member_img wi-10'>
					<img
						src={baseURL +'/'+ props?.image}
						alt=''
						className='coach_img'
					/>
				</div>
				<div className='coach_row_col wi-13'>{props?.name}</div>
				<div className='coach_row_col wi-25'>{props.description}</div>
				<div className='coach_row_col wi-17'>
					<p>
						{props.meta.map((meta)=>{
							return (<p>{meta.size+ ' - ' +meta.quantity_in_stock}</p>)
						})}
					</p>
				</div>
				<div className='coach_row_col wi-13'>
					<NumberFormat value={Number(props.price).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
				</div>
				<div className='coach_row_col coach_detail_link wi-4'>
					<Link to={`/admin/stores/${params.id}/products/edit/${props.link}`}>
						<div className='arrow_div'>
							<img src={arrow} alt='view/edit' />
						</div>
					</Link>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(ProductRow);
