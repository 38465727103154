import React, { useState, useEffect } from "react";
import { getValue, toDataURL } from "../../utils/app";
import EditProductView from "./EditProduct.view";
import imageCompression from 'browser-image-compression';
const EditProductComponent = (props) => {
  const [activeSize, setActiveSize] = useState(true);
  const [errors, setErrors] = useState({
		description: '',
    general: '',
    meta: '',
    discount: '',
	});

  const [formData, setFormData] = useState({
    category_id: getValue(props, "props.propsData.match.params.id"),
    name: "",
    description: "",
    price: "",
    discount: 0,
    is_active: "1",
    sku: "",
    product_meta: [
      {
        size: "",
        quantity_in_stock: "",
      },
    ],
    single_quantity_in_stock: "",
    photos: [],
    item_id_in_business_center: "",
    meta_title: '',
    meta_description: '',
    meta_keywords: '',
    is_freight_applicable:"1",
  });

  const [ckEditorData, setCkEditorData] = useState('')
  const [photos, setPhotos] = useState([]);
  const [relatedProduct, setRelatedProduct] = useState([]);
  const [categoryValue, setCategoryValue] = useState({});
  useEffect(() => {
    setCategoryValueData(getValue(props, "props.propsData.match.params.id"));
  }, [getValue(props, "props.propsData.match.params.id") && props.categories]);

  useEffect(() => {
    let data = { ...getValue(props, "props.productDetail"), single_quantity_in_stock: null, no_size: 0 };
    let photosData = [];
    data.photos = [];
    getValue(props, "props.productDetail.photos") &&
      getValue(props, "props.productDetail.photos").map((photo) => {
        photosData.push(photo.photo.replace("image/products/", ""));
      });
    data.photos = photosData;

    if (getValue(props, "props.productDetail.product_meta.length") === 0) {
      data.product_meta = [
        {
          size: "",
          quantity_in_stock: "",
        },
      ];
    }
    setFormData(data);
    setCkEditorData(data.description);
    let ids = getValue(props, "props.productDetail.related_products")
      ? getValue(props, "props.productDetail.related_products").map(
        (product) => product.relate_to_product_id
      )
      : [];

    setRelatedProductValueData(ids);
  }, [
    getValue(props, "props.productDetail"),
    getValue(props, "props.relatedProducts"),
  ]);

  const getPhoto = async (url) => {
    let data = [...photos];
    await toDataURL(url).then((dataUrl) => {
      data.push(dataUrl);
      setPhotos(data);
    });
  };

  const setCategoryValueData = (id) => {
    let data = {};
    if (props.categories) {
      props.categories.map((category) => {
        if (id.toString() === category.value.toString())
          data = { value: category.value, label: category.label };
      });
      setCategoryValue(data);
    }
  };

  const setRelatedProductValueData = (ids) => {
    let data = [];
    if (props.relatedProducts && props.relatedProducts.length > 0) {
      ids &&
        ids.map((id) => {
          props.relatedProducts.map((product) => {
            if (id.toString() === product.value.toString()) data.push(product);
          });
        });

      setRelatedProduct(data);
    }
  };

  const onChangeCategory = (selectedValue) => {
    setFormData({
      ...formData,
      category_id: selectedValue.value,
    });
    setCategoryValue(selectedValue);
  };

  const handleOnChangeRelatedProduct = (selectedValue) => {
    setRelatedProduct(selectedValue);
  };

  const deletePhoto = (index) => {
    let photos = formData.photos;
    photos.splice(index, 1);
    setFormData({
      ...formData,
      photos: photos,
    });
  };

  const createImage = (file, index) => {
    let photosData = photos;
    let reader = new FileReader();
    reader.onload = (e) => {
      let photos = formData.photos || [];
      if (index || index === 0) {
        photosData[index] = e.target.result;
        photos[index] = e.target.result;
      } else {
        photosData.push(e.target.result);
        photos.push(e.target.result);
      }
      setPhotos(photosData);
      setFormData({
        ...formData,
        photos: photos,
      });
    };
    reader.readAsDataURL(file);
  };

  const handleOnChangeImage = (e, index) => {
    compressImage(e.target.files[0], index);
  };

  const options = {
		maxSizeMB: 0.2,
		useWebWorker: true
	};
	const compressImage =  (file, index) =>{
		 imageCompression(file, options).then((compresssedFile)=>{
			createImage(compresssedFile, index)
		})
	}

  const handleOnChange = (e) => {
    if (e.target.name === 'price' && categoryValue.label === 'Gift Vouchers') {
      let productMeta = formData.product_meta;
      setFormData({
        ...formData,
        product_meta: productMeta[0]['size'] = e?.target?.value
      })
    }
 
      
    setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    
  };

  const handleCkEditorData = (e) => {
    if(!e.target.value){
      setErrors({
        ...errors,
        description: 'Description is required'
      })
    }
    else{
      setErrors({
        ...errors,
        description: ''
      })
    }
    setCkEditorData(e.target.value);
  }

  // const handleProductMetaChanges = (index, e) => {
  //   let productMeta = formData.product_meta;
  //   productMeta[index][e.target.name] = e.target.value;
  //   setFormData({
  //     ...formData,
  //     product_meta: productMeta,
  //   });
  // };
  const handleProductMetaChanges = (index, e, type) => {
    let productMeta = formData.product_meta;

    if (formData.category_id === 22) {
      productMeta[index]['size'] = 1
    }
    if (type === 1) {
      productMeta[index][e.type ? e.type : 'size'] = e.value
    } else if (type !== 2) {
      if (e?.target?.name !== 'undefined') {
        productMeta[index][e?.target?.name] = e?.target?.value
      }
    }
    setFormData({
      ...formData,
      product_meta: productMeta,
    });
  };

  const AddProductMeta = () => {
    let productMeta = formData.product_meta || [];
    productMeta.push({
      size: "",
      quantity_in_stock: "",
    });
    setFormData({
      ...formData,
      ["product_meta"]: productMeta,
    });
  };

  const handleSizeSelection = () => {
    setActiveSize(!activeSize)
    if (activeSize === true) {
      setFormData({
        ...formData,
        product_meta: [{
          size: 'fixed',
          quantity_in_stock: '0'
        }],
        single_quantity_in_stock: '',
        no_size: 1
      })
    } else {
      setFormData({
        ...formData,
        product_meta: [{ size: '', quantity_in_stock: '' }],
        single_quantity_in_stock: null,
        no_size: 0
      })
    }
  }

  const handleSingleQuantity = (e) => {
    if (e.target.value === '' || e.target.value < 0) {
      setFormData({
        ...formData, single_quantity_in_stock: 0
      })
    } else {
      setFormData({
        ...formData,
        single_quantity_in_stock: e.target.value,
        product_meta: [{ size: 'fixed', quantity_in_stock: e.target.value }],
      })
    }
  }

  const handleRemoveSingleSize = (index) => {
    let newData = []
    formData.product_meta.map((zone, i) => {
      if (i !== index) newData.push(zone)
    })
    setFormData({ ...formData, product_meta: newData })
  }

  const onSubmit = () => {
    if (!formData['description']){
      setErrors({
        ...errors,
        description: "This field is required",
      });
      return
    }

    if (!formData.item_id_in_business_center || !formData.name || !formData.price){
      setErrors({
        ...errors,
        general: "Please fill all required fields (*)",
      });
      return
    }

    if(!formData.discount){
      formData.discount = 0;
    }

    if(Number(formData.discount)>Number(formData.price)){
      setErrors({
        ...errors,
        discount: "Discount should be less than or equal to price",
      });
      return
    }

    if(activeSize){
      if(!formData.product_meta[0]['size'] || !formData.product_meta[0]['quantity_in_stock']){
        setErrors({
          ...errors,
          meta: "Size and quantity in stock are required",
        });
        return
      }
    }



    setErrors({
      meta: '',
      discount: '',
      general: '',
      description: '',
    })
      let data = { ...formData, description: ckEditorData };
      data.related_products = relatedProduct
        .map((product) => product.value)
        .join(",");
      props.onCallApi(data);
    
    
  };


  return (
    <EditProductView
      formData={formData}
      errors={errors}
      ckEditorData={ckEditorData}
      categories={props.categories}
      relatedProducts={props.relatedProducts}
      relatedProduct={relatedProduct}
      categoryValue={categoryValue}
      onChangeCategory={onChangeCategory}
      handleOnChange={handleOnChange}
      handleProductMetaChanges={handleProductMetaChanges}
      AddProductMeta={AddProductMeta}
      handleOnChangeImage={handleOnChangeImage}
      handleCkEditorData={handleCkEditorData}
      handleOnChangeRelatedProduct={handleOnChangeRelatedProduct}
      onSubmit={onSubmit}
      activeSize={activeSize}
      setActiveSize={setActiveSize}
      handleSizeSelection={handleSizeSelection}
      handleRemoveSingleSize={handleRemoveSingleSize}
      handleSingleQuantity={handleSingleQuantity}
      deletePhoto={deletePhoto}
      setFormData={setFormData}
    />
  );
};
export default EditProductComponent;