import {
	SET_SELECTED_ORDER_NULL,
	LIST_ORDERS_REQUEST,
	LIST_ORDERS_SUCCESS,
	LIST_ORDERS_ERROR,
	ORDER_ITEMS_REQUEST,
	ORDER_ITEMS_SUCCESS,
	ORDER_ITEMS_ERROR,
    HANDLE_PAGINATION,
} from '../actions/ordersActions';

const initialState = {
    orders: [],
    ordersLoading: false,
	selectedOrder: [],
    page: 1,
};

function order(state = initialState, action) {
	switch (action.type) {
		case SET_SELECTED_ORDER_NULL:
			return Object.assign({}, state, {
				selectedOrder: [],
			});
		case LIST_ORDERS_REQUEST:
			return Object.assign({}, state, {
				orders: [],
                ordersLoading: true,
			});
		case LIST_ORDERS_SUCCESS:
			return Object.assign({}, state, {
				orders: action.payload.data,
                ordersLoading: false,
			});
		case LIST_ORDERS_ERROR:
			return Object.assign({}, state, {
				orders: [],
                ordersLoading: false,
			});

		case ORDER_ITEMS_REQUEST:
			return Object.assign({}, state, {
				selectedOrder: [],
			});
		case ORDER_ITEMS_SUCCESS:
			return Object.assign({}, state, {
				selectedOrder: action.payload.data,
			});
		case ORDER_ITEMS_ERROR:
			return Object.assign({}, state, {
				selectedOrder: [],
			});

        case HANDLE_PAGINATION:
			return Object.assign({}, state, {
				page: action.payload.data
			});
		

		default:
			return state;
	}
}

export default order;
