import React, { useState } from 'react'
import { connect } from 'react-redux'
import eventsActions from '../../../redux/actions/eventsActions'
import imageCompression from 'browser-image-compression';

import './popup.css';

//Images
import camera from '../../../assets/images/camera.svg';
import LineRedImg from '../../../assets/images/Line-red.svg';

export const EventCategory = (props) => {
const [category, setCategory] = useState({
    title: '',
    name:'',
    meta_title: '',
    meta_description: '',
    meta_keywords: '',
    description: '',
    age_from: '',
    age_to: '',
    subtitle: '',
    image: '',
    display_order: '',
    });
const [imageError, setImageError] = useState('');

const handleDataChange = (e,name) => {
    setCategory({
        ...category,
        [name]: e.target.value
    })
}

const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) 
    checkImageDimensions(event.target.files[0]);
  }
const options = {
    maxSizeMB: 0.2,
    useWebWorker: true
};

const checkImageDimensions = (file) =>{
    var image = new Image();
    let reader = new FileReader();
    reader.onload = (e) => {
        image.src = e.target.result;
        image.onload = ()=>{
            setImageError('');
            compressImage(file)
        }
    };
    reader.readAsDataURL(file);
    
}
const compressImage =  (file) =>{
    imageCompression(file, options).then((compresssedFile)=>{
        createImage(compresssedFile)
    })
}
const createImage = (file) => {
    let reader = new FileReader();
    reader.onload = (e) => {
        setCategory({
            ...category,
            image: e.target.result,
          });
        
    };
    reader.readAsDataURL(file);
};

const createCategory = (e) => {
    e.preventDefault();
    if(category.image.length===0){
        setImageError('Image is required')
        return
    }
    else{
        setImageError('')
        props.dispatch(eventsActions.createEventCategory({ data: category }))
    }
}

    return (
        <div>
            <div style={styles.headerContainer}>
				<img src={LineRedImg} alt='line Red' style={styles.lineRed} />
				<span style={styles.title}>Add Events Category</span>
			</div>
                <form onSubmit={createCategory}>
                        <label className='labels'>
                            Title *
                        </label>
                        <input
                            type='text'
                            className='popup_Input_Carousel'
                            placeholder='Title'
                            name='tilte'
                            style={styles.inputTitle}
                            value={category.title}
                            required
                            onChange={(e) => {
                                handleDataChange(e,'title');
                            }}
                        ></input>
                        <label className='labels'>
                            Name *
                        </label>
                        <input
                            type='text'
                            placeholder='Name'
                            name='name'
                            className='popup_Input_Carousel'
                            style={styles.inputTitle}
                            value={category.name}
                            required
                            onChange={(e) => {
                                handleDataChange(e,'name');
                            }}
                        ></input>
                        <label className='labels'>
                            Minimum Age *
                        </label>
                        <input
                            type='number'
                            placeholder='Minimum Age'
                            name='age_from'
                            className='popup_Input_Carousel'
                            style={styles.inputTitle}
                            value={category.age_from}
                            required
                            onChange={(e) => {
                                handleDataChange(e,'age_from');
                            }}
                            min={1}
                        ></input>
                        <label className='labels'>
                            Maximum Age *
                        </label>
                        <input
                            type='number'
                            placeholder='Maximum Age'
                            name='age_to'
                            className='popup_Input_Carousel'
                            style={styles.inputTitle}
                            value={category.age_to}
                            required
                            onChange={(e) => {
                                handleDataChange(e,'age_to');
                            }}
                            min={category.age_from}
                        ></input>
                        {category.age_to<category.age_from && <p className='error font-14'>Minimum age cannot exceed the maximum age</p>}
                        <label className='labels'>
                            Meta Title
                        </label>
                        <input
                            type='text'
                            className='popup_Input_Carousel'
                            placeholder='Meta Title'
                            name='meta_title'
                            style={styles.inputTitle}
                            value={category.meta_title}
                            onChange={(e) => {
                                handleDataChange(e,'meta_title');
                            }}
                        ></input>
                        <label className='labels'>
                            Subtitle
                        </label>
                        <input
                            type='text'
                            placeholder='Subtitle'
                            name='subtitle'
                            className='popup_Input_Carousel'
                            style={styles.inputTitle}
                            value={category.subtitle}
                            onChange={(e) => {
                                handleDataChange(e,'subtitle');
                            }}
                        ></input>
                        <label className='labels'>
                            Meta Keywords
                        </label>
                        <input
                            type='text'
                            placeholder='Meta Keywords'
                            name='meta_keywords'
                            className='popup_Input_Carousel'
                            style={styles.inputTitle}
                            value={category.meta_keywords}
                            onChange={(e) => {
                                handleDataChange(e,'meta_keywords');
                            }}
                        ></input>
                        <label className='labels'>
                            Description *
                        </label>
                        <textarea
                            type='text'
                            placeholder='Description'
                            name='description'
                            className='popup_Input_Carousel'
                            style={styles.textArea}
                            value={category.description}
                            required
                            onChange={(e) => {
                                handleDataChange(e,'description');
                            }}
                        ></textarea>
                        <label className='labels'>
                            Meta Description
                        </label>
                        <textarea
                            type='text'
                            placeholder='Meta Description'
                            name='meta_description'
                            className='popup_Input_Carousel'
                            style={styles.textArea}
                            value={category.meta_description}
                            onChange={(e) => {
                                handleDataChange(e,'meta_description');
                            }}
                        ></textarea>
                        <div style={{display: 'flex', flexDirection:'column'}}>
                        <label className='labels'>
                            Image *
                        </label>
                        <img
                         src={category.image}
                         className={category.image.length>0?styles.image_container:''}
                        ></img>
                        </div>
                        <div>
                            <button
                                className='add_photo_btn'
                                type='button'
                            >
                                <input
                                    onChange={(e) => {
                                        onImageChange(e);
                                    }}
                                    type='file'
                                    id='file'
                                    name='file'
                                    className='custom_file_input'
                                />
                                <label
                                    htmlFor='file'
                                    style={{fontSize: '13pt' }}
                                    className='blue text-upper add_photo_label'
                                >
                                    <img src={camera} alt='' />
                                    Add Image
                                </label>
                            </button>
                            {imageError && <p style={{fontSize:'14px',color:'red'}}>{imageError}</p>}
				        </div>
                        <div className='d-flex justify-content-between red'>
                        <button className='popup_button' style={styles.submit}
                        type="submit"
                            >
                                Create Category
                        </button>
                        </div>
                </form>
        </div>
    )
}
const styles = {
	title: {
		color: '#262262',
		fontSize: '36px',
		fontStyle: 'bold',
		marginTop: '5px',
        fontWeight: 'bold',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	lineRed: {
		width: '88px',
	},
	inputTitle: {
		width: '100%',
        marginTop:0,
		fontSize: '14pt',
	},
	textArea: {
		width: '100%',
		height: '200px',
		fontSize: '14pt',
        color: '#262262',
        marginTop:0,
	},
    image_container: {
        width: '70%',
        height:'300px',
        objectFit: 'fill',	
    },
    submit:{
        paddingTop: '5px'
    }
};

const mapStateToProps = (state) => ({
    
})



export default connect(mapStateToProps)(EventCategory)
