import { request } from '../utils/request'
// import {
//   getLocalStorageAsJSON,
//   deleteSessionStorage,
//   deleteLocalStorage,
// } from '../storage'
// import history from '../history'
// import axios from 'axios'
// import { getValue } from '../utils/app'
// import { getSessionStorage } from '../storage'
import store from '../redux/store'
import { logout as logoutAction } from '../actions/auth'
// const CancelToken = axios.CancelToken

const SERVER_ENV = process.env.REACT_APP_ENVIRONMENT
const apiEndPoint = SERVER_ENV === 'production' ? 'https://api.no1draftpick.com/' : 'https://api.staging.no1draftpick.com/'

let headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export function post(url, params) {
  let finalUrl = apiEndPoint + url

  let headersdata = {
    ...headers,
    Authorization: 'Bearer ' + localStorage.access_token,
  }

  return request(finalUrl, 'POST', headersdata, params, false)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error.response
    })
}

export function put(url, params) {
  let finalUrl = apiEndPoint + url

  let headersdata = {
    ...headers,
    Authorization: 'Bearer ' + localStorage.access_token,
  }

  return request(finalUrl, 'PUT', headersdata, params, false)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

export function get(url, params) {
  let finalUrl = apiEndPoint + url
  let headersdata = {
    ...headers,
    Authorization: 'Bearer ' + localStorage.access_token,
  }

  return request(finalUrl, 'GET', headersdata, params, false)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

export function patch(url, params) {
  let finalUrl = apiEndPoint + url
  let headersdata = {
    ...headers,
    Authorization: 'Bearer ' + localStorage.access_token,
  }

  return request(finalUrl, 'PATCH', headersdata, params, false)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

export function deleteCall(url, params) {
  let finalUrl = apiEndPoint + url
  let headersdata = {
    ...headers,
    Authorization: 'Bearer ' + localStorage.access_token,
  }

  return request(finalUrl, 'DELETE', headersdata, params, false)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

export function logout() {
  store.dispatch(logoutAction())
  // logoutAction
  // localStorage.removeItem("access_token");
  // localStorage.removeItem("UserInfo")
  // history.push("/login");
}

const apiService = {
  post,
  get,
  put,
  patch,
  deleteCall,
  logout,
}

export default apiService
