import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import actions from '../../../redux/actions/profileActions'

//CSS
import './Settings.scss'

//Images
import plus from '../../../assets/images/click-plus.svg';

import postsActions from '../../../redux/actions/postsActions';

export const SchoolsList = (props) => {

    useEffect(() => {
        props.dispatch(actions.getSchools())
    }, [])
    const [schoolList, setSchoolList] = useState([])
    const [searchQuery, setSearchQuery] = useState({
		value:'',
		current:'',
	})
	const [isSearch, setIsSearch] = useState(false)

    useEffect(() => {
        if(isSearch){
            handleSearch()
        }else{
            setSchoolList(props.schools)
        }
    }, [props?.schools])

    const handleSearch=()=>{
        if(searchQuery.value!==''){
            setIsSearch(true);
            setSearchQuery({...searchQuery,current:searchQuery.value});
            let data=[]
            let pattern = new RegExp(searchQuery.value,"ig")
            for (let i = 0; i < props.schools.length; i++) {
                const element = props.schools[i];
                if(pattern.test(element.school_name) || pattern.test(element.suburb)){
                    data.push(element)
                }
            }
            setSchoolList(data);
        }
    }
    return (
        <section>
            <div className="d-flex justify-content-end">
                {isSearch&&
                (<div className="mr-3 school_search">
                    <div>
                        SHOWING RESULTS FOR "{searchQuery.current}"
                    </div>
                    <div className="search_cancel">
                        <span className="pointer" onClick={() => {
                                    setIsSearch(false);
                                    setSearchQuery({...searchQuery,current:''})
                                    setSchoolList(props?.schools)
                                }}>
                            CANCEL		
                        </span>
                    </div>
                </div>)}
                <div className='seach_bar school_search mr-3 mb-2'>
                    <input className='search_button' type='search' onClick={()=>handleSearch()} />
                    <input 
                        type='text'
                        placeholder='Search for School...'
                        className='search_input'
                        value={searchQuery.value}
                        onChange={(e) => {
                            setSearchQuery({...searchQuery,value:e.target.value});
                        }}
                    />
                </div>
                <div className='white bold blue-bg add_school_btn mb-2' onClick={() => {
                    props.dispatch(postsActions.popupHandler({ type: 'ADD_SCHOOL'}))}}>
                        <img className='plus-image' src={plus}/>
                        Add New 
                </div>
                
            </div>
            <div className='school_list_main_body'>
                <div className='events_list_top'>
                    <div className='events_table_header w-50'>SCHOOL</div>
                    <div className='events_table_header w-50'>SUBURB</div>
                    <div className='events_table_header w-50'>OPTIONS</div>
                </div>
                <div className='events_list_container'>
                {schoolList?.length > 0 ?
                    schoolList?.map((zone,i)=>{
                        return( 
                         <div className='events_row_table' key={i}>
                         <div className='w-50 events_row_col p-2'>{zone.school_name}</div>
                         <div className='w-50 events_row_col p-2'>{zone.suburb?zone.suburb:'N/A'}</div>
                         <div className='w-25 events_row_col p-2'>
                             <span className='school-options pointer' onClick={() => {
                                 	props.dispatch(
                                        postsActions.popupHandler({ type: 'EDIT_SCHOOL', data: zone })
                                    );
                             }}>EDIT</span>    
                         </div>
                         <div className='w-25 events_row_col p-2'>
                             <span className='school-options pointer' onClick={() => {	props.dispatch(
									postsActions.popupHandler({ type: 'DELETE_SCHOOL', data: zone })
								);}}>Delete</span>    
                         </div>
                     </div>
                     )
                    })
                :
                props?.schoolsLoading?
                <div className='text-center mx-auto mt-4'>
                    <CircularProgress color='primary' size={30} />
                </div>:
                <div className='font-14 blue'>No data Available</div>
                }
                    {/* {props?.evenyByCategoryIDLoading === true ? (
                        <div className='text-center mx-auto mt-4'>
                            <CircularProgress color='primary' size={30} />
                        </div>
                    ) : props?.evenyByCategoryID?.data?.length > 0 ? (
                        props?.evenyByCategoryID?.data?.map((zone, i) => {
                            let data = (
                                <div className='events_row_table'>
                                    <div className='w-75 events_row_col'>SCHOOOL</div>
                                    <div className='w-25 events_row_col'>
                                        <span onClick={() => console.log('open edit popup')}>EDIT</span>    
                                    </div>
                                </div>
                                );
                                return data;
                            })
                            ) : (
                                <h2 className='no_item_available'>No Schools Available</h2>
                                )} */}
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (state) => ({
    schools:state.profileReducer.schools,
    schoolsLoading:state.profileReducer.schoolsLoading,
	selectedPost: state.postReducer?.selectedPost,
})

export default connect(mapStateToProps)(SchoolsList)
