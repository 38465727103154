import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { baseURL } from '../../config/config';
//CSS
import './AdminListRow.css';

//IMAGES
import arrow from '../../assets/images/right-arrow-blck.svg';
import brokenIMG from '../../assets/images/brokenIMG.png';

function AdminListRow(props) {
	return (
		<div className='coach_row_table'>
			<div className='coach_row_row'>
				<div className='coach_row_col photo_head'>
					<img
						src={props.image!== null ? baseURL + '/image/' + props?.image: brokenIMG}
						alt=''
						className='coach_img'
					/>
				</div>
				<div className='coach_row_col name_head'>{props.first_name}</div>
				<div className='coach_row_col last_head'>{props.last_name}</div>
				<div className='coach_row_col detail_head'>{props.mobile_phone}</div>
				<div className='coach_row_col detail_head'>{props.email}</div>
				<div className='coach_row_col coach_detail_link view_head'>
					<Link to={`/admin/super-admins/admin-details/${props.link}`}>
						<div className='arrow_div'>
							<img src={arrow} alt='view/edit' />
						</div>
					</Link>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(AdminListRow);
