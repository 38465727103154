import {
	LIST_MEMBERS_REQUEST,
	LIST_MEMBERS_SUCCESS,
	LIST_MEMBERS_ERROR,
	LIST_CHILDREN_REQUEST,
	LIST_CHILDREN_SUCCESS,
	LIST_CHILDREN_ERROR,
	DETAIL_MEMBERS_REQUEST,
	DETAIL_MEMBERS_SUCCESS,
	DETAIL_MEMBERS_ERROR,
	DETAIL_KID_REQUEST,
	DETAIL_KID_SUCCESS,
	DETAIL_KID_ERROR,
	SET_KID,
	SET_MEMBER,
	MEMBER_INVOICES_REQUEST,
	MEMBER_INVOICES_SUCCESS,
	MEMBER_INVOICES_ERROR,
	SCHOOLS_SUCCESS,
	SEND_VOUCHER_REQUEST,
	SEND_VOUCHER_SUCCESS,
	SEND_VOUCHER_ERROR,
	MEMBER_SEARCH_REQUEST,
	MEMBER_SEARCH_SUCCESS,
	MEMBER_SEARCH_ERROR,
	CHILDREN_SEARCH_REQUEST,
	CHILDREN_SEARCH_SUCCESS,
	CHILDREN_SEARCH_ERROR,
	CLEAR_SEARCH,
	CLEAR_CHILDREN_SEARCH,
	MEMBER_PAGE_STATE,
	MEMBER_TRANSACTIONS_REQUEST,
	MEMBER_TRANSACTIONS_SUCCESS,
	MEMBER_TRANSACTIONS_ERROR
} from '../actions/membersAction';


const initialState = {
	listMembers: [],
	listMembersLoading: false,

	listChildren: [],
	listChildrenLoading: false,

	membersInvoices: [],
	membersInvoicesLoading: false,

	last_boking_transaction: {},

	detailKid: {
		first_name: '',
		last_name: '',
		dob: '',
		school: '',
		note: '',
		photo: '',
		email: null,
		id: 1,
		message: null,
		mobile: null,
		parent_id: '',
	},
	detailKidLoading: false,
	detailMember: {
		childrens: [],
		email: '',
		first_name: '',
		id: null,
		last_name: '',
		mobile: '',
		photo: null,
		street_address: '',
		suburb: '',
		emergency_contact_name: '',
		emergency_contact_phone: '',
	},
	nbrChildren: 0,
	detailMemberLoading: false,
	schools: [],
	customVoucherLoading: false,

	searchMembersData: [],
	searchMembersLoading: false,

	searchChildrenData: [],
	searchChildrenLoading: false,

	selectedPage: 1,

	transactions_count: 0,
	transactions_total: 0,
	transactions: [],
	transactionsLoading : false,
	
};

function member(state = initialState, action) {
	switch (action.type) {

		case SEND_VOUCHER_REQUEST:
			return Object.assign({}, state, {
				customVoucherLoading: true
			});
		case SEND_VOUCHER_SUCCESS:
			return Object.assign({}, state, {
				customVoucherLoading: false
			});
		case SEND_VOUCHER_ERROR:
			return Object.assign({}, state, {
				customVoucherLoading: false
			});
		case SCHOOLS_SUCCESS:
			return Object.assign({}, state, {
				schools: action.payload.data,
			});

		case LIST_MEMBERS_REQUEST:
			return Object.assign({}, state, {
				listMembersLoading: true,
				listMembers: [],
			});
		case LIST_MEMBERS_SUCCESS:
			return Object.assign({}, state, {
				listMembersLoading: false,
				listMembers: action.payload.data,
			});
		case LIST_MEMBERS_ERROR:
			return Object.assign({}, state, {
				listMembersLoading: false,
				listMembers: [],
			});

		case LIST_CHILDREN_REQUEST:
			return Object.assign({}, state, {
				listChildrenLoading: true,
				listChildren: [],
			});
		case LIST_CHILDREN_SUCCESS:
			return Object.assign({}, state, {
				listChildrenLoading: false,
				listChildren: action.payload.data,
			});
		case LIST_CHILDREN_ERROR:
			return Object.assign({}, state, {
				listChildrenLoading: false,
				listChildren: [],
			});

		case DETAIL_MEMBERS_REQUEST:
			return Object.assign({}, state, {
				detailMemberLoading: true,
				detailMember: {
					childrens: [],
					email: '',
					first_name: '',
					id: null,
					last_name: '',
					mobile: '',
					photo: null,
					street_address: '',
					suburb: '',
					emergency_contact_name: '',
					emergency_contact_phone: '',
				},
				nbrChildren: 0,
				last_boking_transaction: {}
			});
		case DETAIL_MEMBERS_SUCCESS:
			return Object.assign({}, state, {
				detailMemberLoading: false,
				detailMember: action.payload.data,
				nbrChildren: action.payload.nbrChildren,
				last_boking_transaction: action.payload.last_boking_transaction,
			});
		case DETAIL_MEMBERS_ERROR:
			return Object.assign({}, state, {
				detailMemberLoading: false,
				detailMember: {
					childrens: [],
					email: '',
					first_name: '',
					id: null,
					last_name: '',
					mobile: '',
					photo: null,
					street_address: '',
					suburb: '',
					emergency_contact_name: '',
					emergency_contact_phone: '',
				},
				nbrChildren: 0,
				last_boking_transaction: {}
			});
		case SET_MEMBER:
			return Object.assign({}, state, {
				detailMember: action.payload.data,
			});

		case DETAIL_KID_REQUEST:
			return Object.assign({}, state, {
				detailKidLoading: true,
				detailKid: {
					first_name: '',
					last_name: '',
					dob: '',
					school: '',
					note: '',
					photo: '',
					email: null,
					id: 1,
					message: null,
					mobile: null,
					parent_id: '',
				},
			});
		case DETAIL_KID_SUCCESS:
			return Object.assign({}, state, {
				detailKidLoading: false,
				detailKid: action.payload.data,
			});
		case DETAIL_KID_ERROR:
			return Object.assign({}, state, {
				detailKidLoading: false,
				detailKid: {
					first_name: '',
					last_name: '',
					dob: '',
					school: '',
					note: '',
					photo: '',
					email: null,
					id: 1,
					message: null,
					mobile: null,
					parent_id: '',
				},
			});
		case SET_KID:
			return Object.assign({}, state, {
				detailKid: action.payload.data,
			});

		case MEMBER_INVOICES_REQUEST:
			return Object.assign({}, state, {
				membersInvoicesLoading: true,
				membersInvoices: [],
			});
		case MEMBER_INVOICES_SUCCESS:
			return Object.assign({}, state, {
				membersInvoicesLoading: false,
				membersInvoices: action.payload.data,
			});
		case MEMBER_INVOICES_ERROR:
			return Object.assign({}, state, {
				membersInvoicesLoading: false,
				membersInvoices: [],
			});

		case MEMBER_SEARCH_REQUEST:
			return Object.assign({}, state, {
				searchMembersLoading: true,
				searchMembersData: [],
			});
		case MEMBER_SEARCH_SUCCESS:
			return Object.assign({}, state, {
				searchMembersLoading: false,
				searchMembersData: action.payload.data,
			});
		case MEMBER_SEARCH_ERROR:
			return Object.assign({}, state, {
				searchMembersLoading: false,
				searchMembersData: [],
			});

		case CHILDREN_SEARCH_REQUEST:
			return Object.assign({}, state, {
				searchChildrenLoading: true,
				searchChildrenData: [],
			});
		case CHILDREN_SEARCH_SUCCESS:
			return Object.assign({}, state, {
				searchChildrenLoading: false,
				searchChildrenData: action.payload.data,
			});
		case CHILDREN_SEARCH_ERROR:
			return Object.assign({}, state, {
				searchChildrenLoading: false,
				searchChildrenData: [],
			});

		case CLEAR_SEARCH:
			return Object.assign({}, state, {
				searchMembersLoading: false,
				searchMembersData: [],
			});

		case CLEAR_CHILDREN_SEARCH:
			return Object.assign({}, state, {
				searchChildrenLoading: false,
				searchChildrenData: [],
			});

		case MEMBER_PAGE_STATE:
    		return Object.assign({}, state, {
    			selectedPage: action.pageOn,
      		});

		case MEMBER_TRANSACTIONS_REQUEST:
    		return Object.assign({}, state, {
				transactionsLoading: true,
				transactions_count: 0,
				transactions_total: 0,
				transactions: [],
      		});

		case MEMBER_TRANSACTIONS_SUCCESS:
    		return Object.assign({}, state, {
				transactionsLoading: false,
    			transactions_count: action.payload.count,
				transactions_total: action.payload.total,
				transactions: action.payload.transactions,
      		});

		case MEMBER_TRANSACTIONS_ERROR:
    		return Object.assign({}, state, {
				transactionsLoading: false,
    			transactions_count: 0,
				transactions_total: 0,
				transactions: [],
      		});

		default:
			return state;
	}
}

export default member;
