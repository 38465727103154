import {
	GET_EVENT_CATEGORIES_REQUEST,
	GET_EVENT_CATEGORIES_SUCCESS,
	GET_EVENT_CATEGORIES_ERROR,
	GET_EVENT_BYCATEGORYID_REQUEST,
	GET_EVENT_BYCATEGORYID_SUCCESS,
	GET_EVENT_BYCATEGORYID_ERROR,
	HANDLE_ADD_EVENT, HANDLE_ADD_EVENT_PROGRAM, HANDLE_DELETE_EVENT_PROGRAM,
	GET_EVENT_DETAILS_REQUEST, GET_EVENT_DETAILS_SUCCESS, GET_EVENT_DETAILS_ERROR,
	ASSIGN_INCOMING_DATA, HANDLE_ADD_EVENT_TYPE,
	CREATE_EVENT_REQUEST, CREATE_EVENT_SUCCESS, CREATE_EVENT_ERROR,
	EDIT_EVENT_REQUEST, EDIT_EVENT_SUCCESS, EDIT_EVENT_ERROR,
	CANCEL_EVENT_REQUEST, CANCEL_EVENT_SUCCESS, CANCEL_EVENT_ERROR,

	PUBLISH_EVENT_REQUEST, PUBLISH_EVENT_SUCCESS, PUBLISH_EVENT_ERROR,
	SEND_EMAIL_TO_MEMBERS_REQUEST, SEND_EMAIL_TO_MEMBERS_SUCCESS, SEND_EMAIL_TO_MEMBERS_ERROR,
	SEND_BULK_EMAIL_TO_MEMBERS_REQUEST, SEND_BULK_EMAIL_TO_MEMBERS_SUCCESS, SEND_BULK_EMAIL_TO_MEMBERS_ERROR,
	CLEAR_FORM, CLEAR_EVENT_DETAILS,

	GET_BOOKINGS_EVENT_REQUEST, GET_BOOKINGS_EVENT_SUCCESS, GET_BOOKINGS_EVENT_ERROR,

	GET_EVENT_RELATED_PROGRAMS_REQUEST, GET_EVENT_RELATED_PROGRAMS_SUCCESS, GET_EVENT_RELATED_PROGRAMS_ERROR,

	GET_EVENT_RELATED_PROGRAMS_BOOKING_REQUEST, GET_EVENT_RELATED_PROGRAMS_BOOKING_SUCCESS, GET_EVENT_RELATED_PROGRAMS_BOOKING_ERROR,

	CHILD_ATTENDANCE_ADMIN_REQUEST, CHILD_ATTENDANCE_ADMIN_SUCCESS, CHILD_ATTENDANCE_ADMIN_ERROR,

	CHANGE_CATEGORIES_ORDER, EVENT_PAGE_STATE, HANDLE_REC_DATA,

} from '../actions/eventsActions';
import actions from '../actions/postsActions';



const initialState = {
	eventCategories: [],
	eventCategoriesLoading: false,
	evenyByCategoryID: [],
	evenyByCategoryIDLoading: false,
	eventDetails: [],
	eventDetailsLoading: false,
	addEventLoading: false,
	editEventLoading: false,
	cancelEventLoading: false,
	PublishEventLoading: false,
	SendMailLoading: false,
	SendBulkMailLoading: false,
	eventBookings: [],
	eventBookingsLoading: false,
	totalSales: 0,

	eventRelatedPrograms: [],
	eventRelatedProgramsLoading: false,

	eventRelatedProgramsBooking: [],
	eventRelatedProgramsBookingLoading: false,

	childAttendanceAdminLoading: false,

	selectedPage: 1,

	rec_dates: [],
	rec_text: '',
	rec_count: 1,

	formData: {
		start_date: new Date(),
		end_date: '',
		start_time: '',
		end_time: '',
		title: '',
		category_id: '',
		subtitle: '',
		description: '',
		location: '',
		venue: '',
		item_id_in_business_center: '',
		available_space: '',
		occupied_space: '',
		// vacant_space: "",
		is_full_day_event: 1,
		is_recurring: 0,
		is_custom_rec: 0,
		is_editable: false,
		status: '',
		meta_description: '',
		meta_title: '',
		meta_keywords: '',
		thumbnail: '',
		recurrence: {
			recurrence_type: '',
			frequency: '',
			interval: '',
			after_reccurence: '',
			by_day: '',
			ends_on: '',
		},
		banner: '',
		pricing: '',
		existing_banner: '',
		programs: [],
		discount: {
			discount: "",
			from: "",
			to: ""
		},
	},
};

function event(state = initialState, action) {
	switch (action.type) {
		case GET_EVENT_CATEGORIES_REQUEST:
			return Object.assign({}, state, {
				eventCategoriesLoading: true,
				eventCategories: [],
			});
		case GET_EVENT_CATEGORIES_SUCCESS:
			return Object.assign({}, state, {
				eventCategoriesLoading: false,
				eventCategories: action.payload.data,
			});
		case GET_EVENT_CATEGORIES_ERROR:
			return Object.assign({}, state, {
				eventCategoriesLoading: false,
				eventCategories: [],
			});

		case GET_EVENT_BYCATEGORYID_REQUEST:
			return Object.assign({}, state, {
				evenyByCategoryIDLoading: true,
				evenyByCategoryID: [],
			});
		case GET_EVENT_BYCATEGORYID_SUCCESS:
			return Object.assign({}, state, {
				evenyByCategoryIDLoading: false,
				evenyByCategoryID: action.payload.data,
			});
		case GET_EVENT_BYCATEGORYID_ERROR:
			return Object.assign({}, state, {
				evenyByCategoryIDLoading: false,
				evenyByCategoryID: [],
			});

		case HANDLE_ADD_EVENT:
			return Object.assign({}, state, {
				formData: action.payload,
			});

		case HANDLE_ADD_EVENT_PROGRAM:
			return Object.assign({}, state, {
				formData: action.payload,
			});
		case HANDLE_DELETE_EVENT_PROGRAM:
			return Object.assign({}, state, {
				formData: action.payload,
			});

		case GET_EVENT_DETAILS_REQUEST:
			return Object.assign({}, state, {
				eventDetailsLoading: true,
				eventDetails: [],
			});
		case GET_EVENT_DETAILS_SUCCESS:
			return Object.assign({}, state, {
				eventDetailsLoading: false,
				eventDetails: action.payload.data,
			});
		case GET_EVENT_DETAILS_ERROR:
			return Object.assign({}, state, {
				eventDetailsLoading: false,
				eventDetails: [],
			});

		case ASSIGN_INCOMING_DATA:
			console.log(action.payload.data)
			return Object.assign({}, state, {
				formData: action.payload.data,
			});

		case HANDLE_ADD_EVENT_TYPE:
			return Object.assign({}, state, {
				formData: action.payload,
			});

		case CREATE_EVENT_REQUEST:
			return Object.assign({}, state, {
				addEventLoading: true,
			});
		case CREATE_EVENT_SUCCESS:
			return Object.assign({}, state, {
				addEventLoading: false,
			});
		case CREATE_EVENT_ERROR:
			return Object.assign({}, state, {
				addEventLoading: false,
			});

		case EDIT_EVENT_REQUEST:
			return Object.assign({}, state, {
				editEventLoading: true,
			});
		case EDIT_EVENT_SUCCESS:
			return Object.assign({}, state, {
				editEventLoading: false,
			});
		case EDIT_EVENT_ERROR:
			return Object.assign({}, state, {
				editEventLoading: false,
			});

		case CANCEL_EVENT_REQUEST:
			return Object.assign({}, state, {
				cancelEventLoading: true,
			});
		case CANCEL_EVENT_SUCCESS:
			return Object.assign({}, state, {
				cancelEventLoading: false,
			});
		case CANCEL_EVENT_ERROR:
			return Object.assign({}, state, {
				cancelEventLoading: false,
			});

		case CANCEL_EVENT_REQUEST:
			return Object.assign({}, state, {
				cancelEventLoading: true,
			});
		case CANCEL_EVENT_SUCCESS:
			return Object.assign({}, state, {
				cancelEventLoading: false,
			});
		case CANCEL_EVENT_ERROR:
			return Object.assign({}, state, {
				cancelEventLoading: false,
			});
		case PUBLISH_EVENT_REQUEST:
			return Object.assign({}, state, {
				PublishEventLoading: true,
			});
		case PUBLISH_EVENT_SUCCESS:
			return Object.assign({}, state, {
				PublishEventLoading: false,
			});
		case PUBLISH_EVENT_ERROR:
			return Object.assign({}, state, {
				PublishEventLoading: false,
			});


		case SEND_EMAIL_TO_MEMBERS_REQUEST:
			return Object.assign({}, state, {
				SendMailLoading: true,
			});
		case SEND_EMAIL_TO_MEMBERS_SUCCESS:
			return Object.assign({}, state, {
				SendMailLoading: false,
			});
		case SEND_EMAIL_TO_MEMBERS_ERROR:
			return Object.assign({}, state, {
				SendMailLoading: false,
			});


		case SEND_BULK_EMAIL_TO_MEMBERS_REQUEST:
			return Object.assign({}, state, {
				SendBulkMailLoading: true,
			});
		case SEND_BULK_EMAIL_TO_MEMBERS_SUCCESS:
			return Object.assign({}, state, {
				SendBulkMailLoading: false,
			});
		case SEND_BULK_EMAIL_TO_MEMBERS_ERROR:
			return Object.assign({}, state, {
				SendBulkMailLoading: false,
			});

		case CLEAR_FORM:
			return Object.assign({}, state, {
				formData: {
					start_date: new Date(),
					end_date: '',
					start_time: '',
					end_time: '',
					title: '',
					category_id: '',
					subtitle: '',
					description: '',
					location: '',
					venue: '',
					item_id_in_business_center: '',
					available_space: '',
					occupied_space: '',
					// vacant_space: "",
					is_full_day_event: 1,
					is_custom_rec: 0,
					is_recurring: 0,
					status: '',
					is_editable: false,
					recurrence: {
						recurrence_type: '',
						frequency: '',
						interval: '',
						after_reccurence: '',
						by_day: '',
						ends_on: '',
					},
					banner: '',
					existing_banner: '',
					programs: [],
					discount_percentage: '',
					discount: {
						discount: "",
						from: "",
						to: ""
					},
				},
			});
		case CLEAR_EVENT_DETAILS:
			return Object.assign({}, state, {
				eventDetails: []
			})


		case GET_BOOKINGS_EVENT_REQUEST:
			return Object.assign({}, state, {
				eventBookings: [],
				eventBookingsLoading: true,
			});
		case GET_BOOKINGS_EVENT_SUCCESS:
			return Object.assign({}, state, {
				eventBookings: action.payload,
				eventBookingsLoading: false,
				totalSales: action.payload.totalSales
			});
		case GET_BOOKINGS_EVENT_ERROR:
			return Object.assign({}, state, {
				eventBookings: [],
				eventBookingsLoading: false,
				totalSales: 0,
			});


		case GET_EVENT_RELATED_PROGRAMS_REQUEST:
			return Object.assign({}, state, {
				eventRelatedPrograms: [],
				eventRelatedProgramsLoading: true,
			});
		case GET_EVENT_RELATED_PROGRAMS_SUCCESS:
			return Object.assign({}, state, {
				eventRelatedPrograms: action.payload,
				eventRelatedProgramsLoading: false,
			});
		case GET_EVENT_RELATED_PROGRAMS_ERROR:
			return Object.assign({}, state, {
				eventRelatedPrograms: [],
				eventRelatedProgramsLoading: false,
			});


		case GET_EVENT_RELATED_PROGRAMS_BOOKING_REQUEST:
			return Object.assign({}, state, {
				eventRelatedProgramsBooking: [],
				eventRelatedProgramsBookingLoading: true,
			});
		case GET_EVENT_RELATED_PROGRAMS_BOOKING_SUCCESS:
			return Object.assign({}, state, {
				eventRelatedProgramsBooking: action.payload,
				eventRelatedProgramsBookingLoading: false,
			});
		case GET_EVENT_RELATED_PROGRAMS_BOOKING_ERROR:
			return Object.assign({}, state, {
				eventRelatedProgramsBooking: [],
				eventRelatedProgramsBookingLoading: false,
			});

		case CHILD_ATTENDANCE_ADMIN_REQUEST:
			return Object.assign({}, state, {
				childAttendanceAdminLoading: true,
			});
		case CHILD_ATTENDANCE_ADMIN_SUCCESS:
			return Object.assign({}, state, {
				childAttendanceAdminLoading: false,
			});
		case CHILD_ATTENDANCE_ADMIN_ERROR:
			return Object.assign({}, state, {
				childAttendanceAdminLoading: false,
			});

		case CHANGE_CATEGORIES_ORDER:
      		return Object.assign({}, state, {
        		eventCategories: action.data,
      		});
			
		case EVENT_PAGE_STATE:
      		return Object.assign({}, state, {
        		selectedPage: action.pageOn,
      		});

		case HANDLE_REC_DATA:
      		return Object.assign({}, state, {
        		rec_count: action.payload.count,
				rec_text: action.payload.text,
				rec_dates: action.payload.dates
      		});
		default:
			return state;
	}
}

export default event;
