import React from 'react';
import history from '../../history';
import ArrowRedImg from '../../assets/images/arrow-red-left.svg';
import ProductFormView from './ProductForm.view';
import './AddProduct.scss';
const AddProductView = ({
	formData,
	errors,
	categoryValue,
	onChangeCategory,
	handleOnChange,
	handleProductMetaChanges,
	AddProductMeta,
	handleRemoveSingleSize,
	categories,
	relatedProducts,
	handleOnChangeImage,
	handleOnChangeRelatedProduct,
	relatedProduct,
	onSubmit,
	activeSize,
	handleSizeSelection,
	handleSingleQuantity,
}) => {
	return (
		<div className='header_top w-100 d-inline-block align-top'>
			<div className='header_top_container  padding-right-20  w-100 d-inline-block'>
				<div className='header_title_left bold blue font-36 float-left text-upper'>
					<span>
						<a
							className='back_to'
							onClick={(e) =>
								history.push(`/admin/stores/${formData.category_id}/products`)
							}
						>
							<img src={ArrowRedImg} />
						</a>
					</span>
					<span className='padding-left-20 text-upper'>Add a New Product</span>
				</div>
			</div>
			<ProductFormView
				formData={formData}
				errors = {errors}
				categoryValue={categoryValue}
				onChangeCategory={onChangeCategory}
				handleOnChange={handleOnChange}
				handleProductMetaChanges={handleProductMetaChanges}
				AddProductMeta={AddProductMeta}
				handleRemoveSingleSize={handleRemoveSingleSize}
				handleOnChangeImage={handleOnChangeImage}
				categories={categories}
				relatedProducts={relatedProducts}
				handleOnChangeRelatedProduct={handleOnChangeRelatedProduct}
				relatedProduct={relatedProduct}
				onSubmit={onSubmit}
				activeSize={activeSize}
				handleSizeSelection={handleSizeSelection}
				handleSingleQuantity={handleSingleQuantity}
			/>
		</div>
	);
};
export default AddProductView;
