import React, { useState, useEffect } from 'react';
import { Link, useParams, withRouter, useHistory } from 'react-router-dom';
import { baseURL } from '../../../config/config';
import { connect } from 'react-redux';
import { coachHandle } from '../../../utils/global';
import membersAction from '../../../redux/actions/membersAction';
import AddressAuto from '../../../Components/Address/AddressAuto';
import '../Coach/CoachEdit.scss';
import { AsYouType } from "libphonenumber-js";
//images
import insert from '../../../assets/images/insert-photo.png';
import backArrow from '../../../assets/images/arrow-red-left.svg';
import camera from '../../../assets/images/camera.svg';
import whiteX from '../../../assets/images/x-white.svg';

function MembersEdit(props) {
	const params = useParams();
	const history = useHistory();

	const [isChanged, setChanged] = useState(false);
	const [accountFields, setAccountFields] = useState({
		address_1: '',
		address_2: '',
		suburb: '',
		state: 'NSW',
		postal_code: '',
	})
	const [shippingFields, setShippingFields] = useState({
		id: '',
		parent_id: '',
		first_name: '',
		last_name: '',
		mobile: '',
		email: '',
		address_1: '',
		address_2: '',
		suburb: '',
		state: 'NSW',
		postal_code: '',
	})
	const [addressError, setAddressError] = useState('');
	const handleImageChange = (e) => {
		createImage(e.target.files[0]);
	};

	const createImage = (file) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			let item = {
				...props.detailMember,
				photo: e.target.result
			}
			props.dispatch(membersAction.setMemberDetails(item));
			setChanged(true)
		};
		reader.readAsDataURL(file);
	};

	const deleteImage = () => {
		let item = {
			...props.detailMember,
			photo: ''
		}
		props.dispatch(membersAction.setMemberDetails(item));
		setChanged(false)
	}

	const editMember = (e) => {

			e.preventDefault();
			let data;
			let shippingAddressFinal = {
				id: shippingFields.id,
				parent_id: props.detailMember.id,
				first_name: shippingFields.first_name ? shippingFields.first_name : props.detailMember.first_name,
				last_name: shippingFields.last_name ? shippingFields.last_name : props.detailMember.last_name,
				email: shippingFields.email ? shippingFields.email : props.detailMember.email,
				mobile: shippingFields.mobile ? shippingFields.mobile : props.detailMember.mobile,
				street_address: shippingFields.address_1 ? shippingFields.address_1 : accountFields.address_1,
				city: shippingFields.address_2 ? shippingFields.address_2 : accountFields.address_2,
				suburb: shippingFields.suburb ? shippingFields.suburb : accountFields.suburb,
				state: shippingFields.state ? shippingFields.state : accountFields.state,
				postal_code: shippingFields.postal_code ? shippingFields.postal_code : accountFields.postal_code,
			}
			if (isChanged === true) {
				data = {
					...props.detailMember,
					street_address1: accountFields.address_1,
					street_address2: accountFields.address_2,
					suburb: accountFields.suburb,
					state: accountFields.state,
					postal_code: accountFields.postal_code,
					shipping_address: shippingAddressFinal
				}
			} else {
				data = {
					childrens: props.detailMember.childrens,
					email: props.detailMember.email,
					first_name: props.detailMember.first_name,
					id: props.detailMember.id,
					last_name: props.detailMember.last_name,
					mobile: props.detailMember.mobile,
					emergency_contact_name: props.detailMember.emergency_contact_name,
					emergency_contact_phone: props.detailMember.emergency_contact_phone,
					second_parent_name: props.detailMember.second_parent_name,
					second_parent_phone: props.detailMember.second_parent_phone,
					existing_photo: props.detailMember.photo,
					street_address1: accountFields.address_1,
					street_address2: accountFields.address_2,
					suburb: accountFields.suburb,
					state: accountFields.state,
					postal_code: accountFields.postal_code,
					shipping_address: shippingAddressFinal
				}
			if(!data.street_address1){
				setAddressError('Street address is required');
				return
			}
			else{
				setAddressError('');
				props.dispatch(membersAction.editMember(data));
			}
			
		}
	}

	const handleChange = (name) => (e) => {
			let item = {
				...props.detailMember,
				[name]: e.target.value
			}
			props.dispatch(membersAction.setMemberDetails(item));
	};
	const handleMobileChange = (e, type) => {
		let value = e.target.value.split(' ').join('');
		let number;
		if (value.length === 6 && value[1] === '3' && value[0] === '1') {
			number = ''
			for (let i = 0; i < value.length; i++) {
				if (i === 2 || i === 4) {
					number += ` ${value[i]}`
				} else {
					number += `${value[i]}`
				}
			}
		} else {
			number = new AsYouType('AU').input(e.target.value)
		}
		switch (type) {
			case 'shipping':
				setShippingFields({ ...shippingFields, mobile: number })
				break;
			case 'emergency_contact_phone':
				let item = {
					...props.detailMember,
					emergency_contact_phone: number
				}
				props.dispatch(membersAction.setMemberDetails(item));
				break;
			case 'second_parent_phone':
				let item2 = {
					...props.detailMember,
					second_parent_phone: number
				}
				props.dispatch(membersAction.setMemberDetails(item2));
				break;
			case 'mobile':
				let item3 = {
					...props.detailMember,
					mobile: number
				}
				props.dispatch(membersAction.setMemberDetails(item3));
				break;
			default:
				break;
		}
	}

	useEffect(() => {
		coachHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null);
		props.dispatch(membersAction.getMemberDetail({ id: params.member_id }));
	}, []);

	useEffect(() => {
		setShippingFields({
			id: props.detailMember?.shipping_address?.id,
			parent_id: props.detailMember?.shipping_address?.parent_id,
			first_name: props.detailMember?.shipping_address?.first_name || '',
			last_name: props.detailMember?.shipping_address?.last_name || '',
			mobile: props.detailMember?.shipping_address?.mobile || '',
			email: props.detailMember?.shipping_address?.email || '',
			address_1: props.detailMember?.shipping_address?.street_address || '',
			address_2: props.detailMember?.shipping_address?.city || '',
			suburb: props.detailMember?.shipping_address?.suburb || '',
			state: props.detailMember?.shipping_address?.state || 'NSW',
			postal_code: props.detailMember?.shipping_address?.postal_code || '',
		})
		setAccountFields({
			address_1: props.detailMember?.street_address1 || '',
			address_2: props.detailMember?.street_address2 || '',
			suburb: props.detailMember?.suburb || '',
			state: props.detailMember?.state || 'NSW',
			postal_code: props.detailMember?.postal_code || '',
		})
	}, [props.detailMemberLoading])

	return (
		<section>
			<div className='coach_edit_top_div'>
				<div className='left_edit_top'>
					<div className='go_back_arrow' onClick={()=> history.goBack()}>
						<img src={backArrow} alt='Go Back' />
					</div>
					<div className='bold blue font-36 text-upper float-left'>
						edit {props?.detailMember?.first_name}
					</div>
				</div>
			</div>
			<div className='coach_edit_body'>
				<div className='coach_pfp_div'>
					<div className='coach_edit_pfp'>
						<div className='delete_img_btn'>
							<img
								src={whiteX}
								alt=''
								onClick={() => deleteImage()}
							/>
						</div>
						{isChanged === true ? (
							<img
								src={
									props.detailMember.photo ? props.detailMember.photo : insert
								}
								alt='kid'
							/>
						) : (
							<img
								src={
									props.detailMember.photo ? baseURL + '/image/' + props.detailMember.photo : insert
								}
								alt='kid'
							/>
						)}
					</div>
					<button className='add_photo_btn'>
						<input
							onChange={(e) => {
								handleImageChange(e);
							}}
							type='file'
							id='file'
							name='file'
							className='custom_file_input'
						/>
						<label
							htmlFor='file'
							className='bold blue text-upper add_photo_label'
						>
							<img src={camera} alt='' />
							upload photo
						</label>
					</button>
				</div>
				<div className='coach_edit_form'>
					<div className='font-24 bold blue pt-3 pb-5'>Account Info:</div>
					<form onSubmit={editMember}>
					<div className='edit_form_row'>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>First Name</label>
							<input
								value={props.detailMember.first_name}
								onChange={handleChange('first_name')}
								name='First Name'
								type='text'
								placeholder='First Name'
								className='coach_edit_input'
								required
							/>
						</div>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Family Name</label>
							<input
								value={props.detailMember.last_name}
								onChange={handleChange('last_name')}
								name='Last Name'
								type='text'
								placeholder='Family Name'
								className='coach_edit_input'
								required
							/>
						</div>
					</div>
					<div className='edit_form_row'>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Email address</label>
							<input
								value={props.detailMember.email}
								onChange={handleChange('email')}
								name='email'
								type='email'
								placeholder='Email'
								className='coach_edit_input'
								required
							/>
						</div>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>phone number</label>
							{/* <InputMask */}
							<input
								value={props.detailMember.mobile}
								onChange={(e) => handleMobileChange(e, 'mobile')}
								name='phone_number'
								type='text'
								// maxLength='12'
								placeholder='Mobile Number'
								className='coach_edit_input number_input'
								required
							/>
							{/* </InputMask> */}
						</div>
					</div>
					<div className='edit_form_row'>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Second Parent Name</label>
							<input
								value={props.detailMember.second_parent_name}
								onChange={handleChange('second_parent_name')}
								name='second_parent_name'
								type='text'
								placeholder='Second Parent Name'
								className='coach_edit_input'
							/>
						</div>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Second Parent Number</label>
							{/* <InputMask */}
							<input
								value={props.detailMember.second_parent_phone}
								onChange={(e) => handleMobileChange(e, 'second_parent_phone')}
								name='second_parent_phone'
								type='text'
								placeholder='Second Parent Number'
								className='coach_edit_input number_input'
							/>
							{/* </InputMask> */}

						</div>
					</div>
					<div className='edit_form_row'>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Emergency Contact Name</label>
							<input
								value={props.detailMember.emergency_contact_name}
								onChange={handleChange('emergency_contact_name')}
								name='emergency_contact_name'
								type='text'
								placeholder='Emergency Name'
								className='coach_edit_input'
							/>
						</div>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Emergency Contact Number</label>
							{/* <InputMask */}
							<input
								value={props.detailMember.emergency_contact_phone}
								onChange={(e) => handleMobileChange(e, 'emergency_contact_phone')}
								name='emergency_contact_phone'
								type='text'
								placeholder='Emergency Number'
								className='coach_edit_input number_input'
							/>
							{/* </InputMask> */}

						</div>
					</div>
					<AddressAuto
						required
						addressInfo={accountFields}
						setAddressInfo={setAccountFields}
						styling='member_edit'
						inputStyling='coach_edit_input'
					/>

					{/* Shipping Info */}
					<hr />
					<div className='font-24 bold blue pt-3 pb-5'>Shipping Info:</div>
					<div className='edit_form_row'>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>First Name</label>
							<input
								value={shippingFields.first_name}
								required
								onChange={(e) => { 
									setShippingFields({ ...shippingFields, first_name: e.target.value }) }}
								name='shipping_first_name'
								type='text'
								placeholder='First Name'
								className='coach_edit_input'
							/>
						</div>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Family Name</label>
							<input
								value={shippingFields.last_name}
								onChange={(e) => { setShippingFields({ ...shippingFields, last_name: e.target.value }) }}
								name='shipping_last_name'
								type='text'
								placeholder='Family Name'
								className='coach_edit_input'
							/>
						</div>
					</div>
					<div className='edit_form_row'>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Email address</label>
							<input
								value={shippingFields.email}
								required
								onChange={(e) => { 
									setShippingFields({ ...shippingFields, email: e.target.value }) }}
								name='shipping_email'
								type='email'
								placeholder='Email'
								className='coach_edit_input'
							/>
						</div>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>phone number</label>
							{/* <InputMask */}
							<input
								value={shippingFields.mobile}
								required
								onChange={(e) => { handleMobileChange(e, 'shipping') }}
								name='shipping_phone_number'
								type='text'
								// maxLength='12'
								placeholder='Mobile Number'
								className='coach_edit_input number_input'
							/>
							{/* </InputMask> */}
						</div>
					</div>
					<AddressAuto
						required
						addressInfo={shippingFields}
						setAddressInfo={setShippingFields}
						styling='member_edit'
						inputStyling='coach_edit_input'
					/>
					<p className="error">{addressError}</p>
					<div className='edit_button_div'>
						<div><button
							type='submit'
							className='white bold text-upper save_red_button'
						>
							save
						</button>
						</div>
						<Link
							to={`/admin/members/${props.detailMember.id}`}
							className='white bold text-upper cancel_blue_button'
						>
							cancel
						</Link>
					</div>
					</form>
				</div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => ({
	detailMember: state.membersReducers.detailMember,
	detailMemberLoading: state.membersReducers.detailMemberLoading,
	user: state.register.user
});

export default withRouter(connect(mapStateToProps)(MembersEdit));
