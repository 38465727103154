import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import queryString from 'query-string';
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import NumberFormat from 'react-number-format';

//REDUX
import eventActions from '../../../redux/actions/eventsActions';
import postsActions from '../../../redux/actions/postsActions';

//CSS
import './EventList.css';

//Components
import EventListRow from '../../../Components/Events/EventsListRow';

//Images
import plus from '../../../assets/images/click-plus.svg';
import Checked from "../../../assets/images/CheckBoxChecked.png";
import Unchecked from "../../../assets/images/CheckBoxUnchecked.png";
import copy from "../../../assets/images/copy-white.svg";

//globals
import { coachHandle } from "../../../utils/global"

const useStyles = makeStyles((theme) => ({
	root: {
		"& > *": {
			marginTop: theme.spacing(2),
		},
	},
}));

export const EventList = (props) => {
	let history = useHistory();
	const classes = useStyles();
	const [selectedCategory, setSelectedCategory] = useState('');
	const [eventList, setEventList] = useState([]);
	const [selected, setSelected] = useState(false);
	const [status, setStatus] = useState('');
	const [searchQuery, setSearchQuery] = useState({
		value: '',
		current: '',
	})
	const [isSearch, setIsSearch] = useState(false)
	const [cloneAvailable, setcloneAvailable] = useState(false)

	useEffect(() => {
		coachHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null)
		//get the events categories
		props.dispatch(eventActions.getEventCategories());
	}, []);

	useEffect(() => {
		if (queryString.parse(props.location.search).query) {
			props.dispatch(eventActions.searchEventsByQuery({ query: queryString.parse(props.location.search).query, page: queryString.parse(props.location.search).page, status: status}, selectedCategory))
		} else {
			if (selectedCategory !== '') {
				props.dispatch(eventActions.getEventsByCategoryId({ id: selectedCategory, page: queryString.parse(props.location.search).page, status: status}, { type: 2 }))
			} else {
				props.dispatch(
					eventActions.getEventsByCategoryId({ page: queryString.parse(props.location.search).page, status: status }, { type: 1 })
				);
			}
		}
	}, [queryString.parse(props.location.search).page, queryString.parse(props.location.search).query, selectedCategory, status])

	const handleNewPagination = (e, selectedPage) => {
		props.dispatch(eventActions.changePageState(selectedPage));
		if (isSearch) {
			history.push({
				pathname: "/admin/events",
				search: `?page=${selectedPage}&&query=${searchQuery.current}`,
			});
		} else {
			history.push({
				pathname: "/admin/events",
				search: `?page=${selectedPage}`,
			});
		}
		setSelected(false);
	};

	const handleCategoryChange = (e) => {
		if (e !== '') {
			history.push({
				pathname: '/admin/events',
				search: `?page=${1}`,
			});
			props.dispatch(eventActions.changePageState(1));
			setSelectedCategory(e);
		}else{
			setSelectedCategory('');
			handleCancelSearch();
		}
		setSelected(false);
	};

	const handleStatusChange = (e) => {
		if (e !== '') {
			history.push({
				pathname: '/admin/events',
				search: `?page=${1}`,
			});
			props.dispatch(eventActions.changePageState(1));
			setStatus(e);
		}else{
			setStatus('');
			handleCancelSearch();
		}
		setSelected(false);
	};

	const handleSearch = () => {
		setIsSearch(true);
		setSearchQuery({ ...searchQuery, current: searchQuery.value });
		history.push({
			pathname: '/admin/events',
			search: `?page=${1}&&query=${searchQuery.value}`,
		});
		setSelected(false);
	}
	const handleCancelSearch = () => {
		props.dispatch(eventActions.getEventsByCategoryId({ page: 1 }, { type: 1 }));
		setIsSearch(false);
		setSearchQuery({ ...searchQuery,current:'',value:''});
		props.dispatch(eventActions.changePageState(1));
		history.push({
			pathname: '/admin/events',
			search: `?page=${1}`,
		});
	}

	const changeCategoryModalState = () => {
		props.dispatch(postsActions.popupHandler({ type: 'ADD_EVENT_CATEGORY'}))
	}

	const handleChecked = (id) => {
		let finalData = eventList;
		props?.evenyByCategoryID?.data?.map((zone,i) => {
			if (id === zone.id)
			{
				if(eventList.find(e => e.id === zone.id))
				{
					if (eventList.find(e => e.id === zone.id).checked)
						finalData.splice(eventList.findIndex(e => e.id === zone.id), 1);	
					else
						finalData.find(e => e.id === zone.id).checked = true;
				}
				else
				{
					finalData.push({
						key: i,
						id: zone.id,
						title : zone.title,
						subtitle : zone.subtitle,
						start_date : zone.start_date,
						end_date : zone.end_date,
						time_start : zone.start_time,
						time_end : zone.end_time,
						location : zone.location,
						available_space : <NumberFormat value={zone.available_space} displayType={'text'} thousandSeparator={true} key={"availableSpace-" + i}/>,
						vacant : <NumberFormat value={zone.vacant_space} displayType={'text'} thousandSeparator={true} key={"vacant-" + i}/>,
						occupied : <NumberFormat value={zone.occupied_space} displayType={'text'} thousandSeparator={true} key={"occupied-" + i}/>,
						status : zone.status,
						checked: true
					});
				}
			}
		});

		setEventList(finalData);
		setcloneAvailable(finalData.length);
	  };
	
	  const handleSelectAll = (e) => {
		 let finalData = eventList;
		 props?.evenyByCategoryID?.data?.map((zone,i) => {

			if(finalData.find(e => e.id === zone.id))
			{
				if (e === "checked")
					finalData.find(e => e.id === zone.id).checked = true;
				else
					finalData.splice(eventList.findIndex(e => e.id === zone.id), 1);
			}
			else
			{
				finalData.push({
					key: i,
					id: zone.id,
					title : zone.title,
					subtitle : zone.subtitle,
					start_date : zone.start_date,
					end_date : zone.end_date,
					time_start : zone.start_time,
					time_end : zone.end_time,
					location : zone.location,
					available_space : <NumberFormat value={zone.available_space} displayType={'text'} thousandSeparator={true} key={"availableSpace-" + i}/>,
					vacant : <NumberFormat value={zone.vacant_space} displayType={'text'} thousandSeparator={true} key={"vacant-" + i}/>,
					occupied : <NumberFormat value={zone.occupied_space} displayType={'text'} thousandSeparator={true} key={"occupied-" + i}/>,
					status : zone.status,
					checked: e === "checked" ? true : false
				});
			}
		});
		setEventList(finalData);
		setcloneAvailable(finalData.length);
		setSelected(e === "checked" ? true : false);
	  };


	  const handleClone = () => {
		props.dispatch(postsActions.popupHandler({ type:'CLONE_EVENTS', data:eventList, action:() => handleSuccessCloning()}));
	  };

	  const handleSuccessCloning = () =>{
		handleStatusChange('draft');
		document.getElementById('statusSelect').selectedIndex = 3;
		setEventList([]);
		setcloneAvailable(0);
		setSelected(false);
	  };

	return (
		<section>
			<div className='events_top_div'>
				<div className='bold blue font-36 text-upper float-left'>events</div>
				<div className='events_form_div'>
					{isSearch &&
						(<div className="mr-1">
							<div>
								SHOWING RESULTS FOR "{searchQuery.current}"
							</div>
							<div className="search_cancel">
								<span className="pointer" onClick={() => handleCancelSearch()}>
									CANCEL
								</span>
							</div>
						</div>)}
					{props.eventCategoriesLoading && (
						<div className='text-center mx-auto mt-4'>
							<CircularProgress color='primary' size={30} />
						</div>
					)}
					<div className='dropDown_bar mr-2'>
						<select
							onChange={(e) => {
								handleCategoryChange(e.target.value);
							}}
							name=''
							id=''
						>
							<option key={'1Base'} value=''>
								Select Event Category
							</option>
							{props?.eventCategories?.length > 0
								? props?.eventCategories?.map((zone, i) => {
									return (
										<option key={i} value={zone.id}>
											{zone.name}
										</option>
									);
								})
								: null}
						</select>
					</div>
					<div className='dropDown_bar_status mr-2'>
						<select
							onChange={(e) => {
								handleStatusChange(e.target.value);
							}}
							name=''
							id='statusSelect'
						>
							<option key={'AllStatusOption'} value=''>
								Select Status
							</option>
							<option key={'AllPublicOption'} value='public'>
								Public
							</option>
							<option key={'AllCancelOption'} value='canceled'>
								Cancelled
							</option>
							<option key={'AllDraftOption'} value='draft'>
								Draft
							</option>
							
						</select>
					</div>
					<div className='seach_bar mr-2' style={{width: '200px'}}>
						<input className='search_button' type='search' onClick={() => handleSearch()} />
						<input
							type='text'
							placeholder='Search for Events...'
							className='search_input'
							value={searchQuery.value}
							onChange={(e) => {
								setSearchQuery({ ...searchQuery, value: e.target.value });
							}}
						/>
					</div>
					<div className='event_add_new bold mr-2' onClick={(e) => history.push("/admin/events/new")} style={{cursor: 'pointer'}}>
                  		<img className='plus-image' src={plus}/>
                  		<span className="edit-class">Add New</span>
                	</div>
					<div onClick={changeCategoryModalState} className='category_add_new bold mr-2' style={{cursor: 'pointer'}}>
						<img className='plus-image' src={plus} alt='' />
						<span>New Category</span>
					</div>
					{ <div className={cloneAvailable > 0 ? 'event_clone_btn' : 'event_clone_btn_disable'}>
						<img src={copy} alt="" className="mr-2"  width={20} height={20}/>
						<span onClick={() => { (cloneAvailable > 0) && handleClone() }} className="white bold">Clone</span>
					</div>}
				</div>
			</div>
			<div className='events_list_main_body'>
				<div className='events_list_top'>
				<div className="coach_table_header wi-5">
						{selected === true ? (
						<img
							onClick={() => {
							handleSelectAll("unchecked");
							}}
							src={Checked}
							alt=""
						/>
						) : (
						<img
							onClick={() => {
							handleSelectAll("checked");
							}}
							src={Unchecked}
							alt=""
						/>
						)}
					</div>
					<div className='events_table_header wi-16'>EVENT TITLE</div>
					<div className='events_table_header wi-10'>AGE</div>
					<div className='events_table_header wi-16'>DATE &amp; TIME</div>
					<div className='events_table_header wi-16'>LOCATION</div>
					<div className='events_table_header wi-10'>
						AVAILABLE
						<br />
						SPACE *Day
					</div>
					<div className='events_table_header wi-8'>VACANT</div>
					<div className='events_table_header wi-8'>OCCUPIED</div>
					<div className='events_table_header wi-8'>STATUS</div>
					<div className='events_table_header wi-8'>EDIT</div>
				</div>
				<div className='events_list_container'>
					{props?.evenyByCategoryIDLoading === true ? (
						<div className='text-center mx-auto mt-4'>
							<CircularProgress color='primary' size={30} />
						</div>
					) : props?.evenyByCategoryID?.data?.length > 0 ? (
						props?.evenyByCategoryID?.data?.map((zone, i) => {
							let data = (
								<EventListRow
									key={i}
									id={zone.id}
									title={zone.title}
									subtitle={zone.subtitle}
									start_date={zone.start_date}
									end_date={zone.end_date}
									time_start={zone.start_time}
									time_end={zone.end_time}
									location={zone.location}
									available_space={<NumberFormat value={zone.available_space} displayType={'text'} thousandSeparator={true}/>}
									vacant={<NumberFormat value={zone.vacant_space} displayType={'text'} thousandSeparator={true}/>}
									occupied={<NumberFormat value={zone.occupied_space} displayType={'text'} thousandSeparator={true}/>}
									status={zone.status}
									checked={eventList.length > 0 ? (eventList.find(r => r.id == zone.id) ? eventList.find(r => r.id == zone.id).checked  : false) : false}
									handleChecked={handleChecked}
								/>
							);

							return data;
						})
					) : (
						<h2 className='no_item_available'>No events available</h2>
					)}
				</div>
			</div>
			<div className={classes.root}>
				<Pagination count={Number(props?.evenyByCategoryID?.last_page_url?.split('page=')[1])} defaultPage={ queryString.parse(props.location.search).page? parseInt(queryString.parse(props.location.search).page) : 1 } shape="rounded" onChange={handleNewPagination} key={"paginationEvents"}/>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => ({
	eventCategories: state.eventReducer.eventCategories,
	eventCategoriesLoading: state.eventReducer.eventCategoriesLoading,
	evenyByCategoryID: state.eventReducer.evenyByCategoryID,
	evenyByCategoryIDLoading: state.eventReducer.evenyByCategoryIDLoading,
	user: state.register.user,
	selectedPage: state.eventReducer.selectedPage,
});

export default connect(mapStateToProps)(EventList);
