import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { coachHandle } from "../../../utils/global";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

//Redux
import membersAction from "../../../redux/actions/membersAction";

//Css
import "./MembersList.css";

//Components
import ChildRow from "../../../Components/Members/ChildRow";

//images
import excel from "../../../assets/images/excel.svg";


import { getChildrenExcelDoc } from '../../../config/config'

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function ChildrenList(props) {
  let history = useHistory();
  const classes = useStyles();
  const [childrenList, setChildrenList] = useState([]);
  const [searchQuery, setSearchQuery] = useState({
    value: "",
    current: "",
  });
  const [isSearch, setIsSearch] = useState(false);

  useEffect(() => {
    coachHandle(
      props.user?.roles?.length > 0 ? props.user?.roles[0]?.name : null
    );
    props.dispatch(membersAction.resetChildrenSearch());
  }, []);

  useEffect(() => {
    if (!isSearch) {
      let finalData = [];
      props?.listChildren?.data?.map((zone) => {
        finalData.push({
          parent_id: zone.parent_id,
          link: zone.id,
          profile_img: zone.photo,
          first_name: zone.first_name,
          last_name: zone.last_name,
          dob: zone.dob,
          medical_details: zone.medical_details,
          school: zone.school_name==='Other' ? `Other - ${zone.child_school_name}` : zone.school_name
        });
      });
      setChildrenList(finalData);
    }
  }, [props.listChildren, isSearch]);

  useEffect(() => {
    if (isSearch) {
      let finalData = [];
      props?.searchChildrenData?.data?.map((zone) => {
        finalData.push({
            parent_id: zone.parent_id,
            link: zone.id,
            profile_img: zone.photo,
            first_name: zone.first_name,
            last_name: zone.last_name,
            dob: zone.dob,
            medical_details: zone.medical_details,
            school: zone.school_name==='Other' ? `Other - ${zone.child_school_name}` : zone.school_name
        });
      });
      setChildrenList(finalData);
    }
  }, [props.searchChildrenData, isSearch]);

  useEffect(() => {
    if (isSearch) {
      props.dispatch(
        membersAction.childrenSearch({
          query: queryString.parse(props.location.search).query,
          page: queryString.parse(props.location.search).page,
        })
      );
    } else {
      props.dispatch(
        membersAction.listChildren(queryString.parse(props.location.search).page)
      );
    }
  }, [
    queryString.parse(props.location.search).page,
    queryString.parse(props.location.search).query,
  ]);

  const handleSearch = () => {
    setIsSearch(true);
    setSearchQuery({ ...searchQuery, current: searchQuery.value });
    history.push({
      pathname: "/admin/children",
      search: `?page=${1}&&query=${searchQuery.value}`,
    });
  };

  const handleNewPagination = (e, selectedPage) => {
    props.dispatch(membersAction.changePageState(selectedPage));
    if (isSearch) {
      history.push({
        pathname: "/admin/children",
        search: `?page=${selectedPage}&&query=${searchQuery.current}`,
      });
    } else {
      history.push({
        pathname: "/admin/children",
        search: `?page=${selectedPage}`,
      });
    }
  }

  const callApi = async () => {
    await getChildrenExcelDoc()
  }

  return (
    <section>
      <div className="coach_top_div">
        <div className="bold blue font-36 text-upper float-left">Children</div>
        <div className="coach_form_div">
          {isSearch && (
            <div className="mr-3">
              <div>SHOWING RESULTS FOR "{searchQuery.current}"</div>
              <div className="search_cancel">
                <span
                  className="pointer"
                  onClick={() => {
                    props.dispatch(membersAction.changePageState(1));
                    setIsSearch(false);
                    setSearchQuery({ ...searchQuery, value: "" });
                  }}
                >
                  CANCEL
                </span>
              </div>
            </div>
          )}
          <div className="seach_bar">
            <input
              className="search_button"
              type="search"
              onClick={() => handleSearch()}
            />
            <input
              type="text"
              placeholder="Search for Children..."
              className="search_input"
              value={searchQuery.value}
              onChange={(e) => {
                setSearchQuery({ ...searchQuery, value: e.target.value });
              }}
            />
          </div>
          <div onClick={callApi} className="memberlist_export_btn">
            <img src={excel} alt="" className="mr-2" />
            <span className="white bold text-upper">EXPORT</span>
          </div>
        </div>
      </div>
      <div className="coach_list_div">
        <div className="coach_list_top">
          <div className="events_table_header wi-13">PHOTO</div>
          <div className="events_table_header wi-13">FIRST NAME</div>
          <div className="events_table_header wi-13">FAMILY NAME</div>
          <div className="events_table_header wi-21">MEDICAL DETAILS</div>
          <div className="events_table_header wi-13">SCHOOL</div>
          <div className="events_table_header wi-13">DATE OF BIRTH</div>
          <div className="events_table_header wi-10">VIEW/EDIT</div>
        </div>
        <div className="coach_list_container">
          {props.listChildrenLoading || props.searchChildrenLoading ? (
            <div className="text-center mx-auto mt-4">
              <CircularProgress color="primary" size={30} />
            </div>
          ) : (
            childrenList?.map((zone, i) => {
              let data = (
                <ChildRow
                  key={i}
                  parent_id={zone.parent_id}
                  link={zone.link}
                  profile_img={zone.profile_img}
                  first_name={zone.first_name}
                  last_name={zone.last_name}
                  date_of_birth={zone.dob}
                  school={zone.school}
                  medical_details={zone.medical_details}
                />
              );
              return data;
            })
          )}
        </div>

        <div className={classes.root}>
          <Pagination count={isSearch? props.searchChildrenData.last_page : props.listChildren.last_page} defaultPage={ queryString.parse(props.location.search).page? parseInt(queryString.parse(props.location.search).page) : 1 } shape="rounded" onChange={handleNewPagination} />
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  listChildren: state.membersReducers.listChildren,
  listChildrenLoading: state.membersReducers.listChildrenLoading,
  user: state.register.user,
  searchChildrenData: state.membersReducers.searchChildrenData,
  searchChildrenLoading: state.membersReducers.searchChildrenLoading,
  selectedPage: state.membersReducers.selectedPage,
});

export default connect(mapStateToProps)(ChildrenList);
