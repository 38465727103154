import {
	LIST_POSTS_REQUEST,
	LIST_POSTS_SUCCESS,
	LIST_POSTS_ERROR,
	POPUP_STATE,
	CREATE_POST_REQUEST,
	CREATE_POST_SUCCESS,
	CREATE_POST_ERROR,
	EDIT_POST_REQUEST,
	EDIT_POST_SUCCESS,
	EDIT_POST_ERROR,
	DELETE_POST_REQUEST,
	DELETE_POST_SUCCESS,
	DELETE_POST_ERROR,
} from '../actions/postsActions';

const initialState = {
	popupState: 'CLOSED',
	popupStateLoading: false,
	selectedPost: null,
	posts: [],
	postsLoading: false,
	// newCoach: [],
	// newCoachLoading: false,
};

function coach(state = initialState, action) {
	switch (action.type) {
		case LIST_POSTS_REQUEST:
			return Object.assign({}, state, {
				postsLoading: true,
				posts: [],
			});
		case LIST_POSTS_SUCCESS:
			return Object.assign({}, state, {
				postsLoading: false,
				posts: action.payload.data,
			});
		case LIST_POSTS_ERROR:
			return Object.assign({}, state, {
				postsLoading: false,
				posts: [],
			});
		case POPUP_STATE:
			return Object.assign({}, state, {
				popupState: action.payload.data,
				selectedPost: action.payload.selectedData,
				postAction: action.payload.postAction
			});
		case CREATE_POST_REQUEST:
			return Object.assign({}, state, {
				popupStateLoading: true,
			});
		case CREATE_POST_SUCCESS:
			return Object.assign({}, state, {
				// popupState: 'CLOSED',
				popupStateLoading: false,
			});
		case CREATE_POST_ERROR:
			return Object.assign({}, state, {
				popupStateLoading: false,
			});
		case EDIT_POST_REQUEST:
			return Object.assign({}, state, {
				popupStateLoading: true,
			});
		case EDIT_POST_SUCCESS:
			return Object.assign({}, state, {
				// popupState: 'CLOSED',
				popupStateLoading: false,
			});
		case EDIT_POST_ERROR:
			return Object.assign({}, state, {
				popupStateLoading: false,
			});
		case DELETE_POST_REQUEST:
			return Object.assign({}, state, {
				popupStateLoading: true,
			});
		case DELETE_POST_SUCCESS:
			return Object.assign({}, state, {
				// popupState: 'CLOSED',
				popupStateLoading: false,
			});
		case DELETE_POST_ERROR:
			return Object.assign({}, state, {
				popupStateLoading: false,
			});

		default:
			return state;
	}
}

export default coach;
