import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { baseURL } from '../../../config/config';
import CircularProgress from '@material-ui/core/CircularProgress';
import { coachHandle } from '../../../utils/global';
import { AsYouType } from "libphonenumber-js";
import "react-datepicker/dist/react-datepicker.css";
import AddressAuto from '../../../Components/Address/AddressAuto';

//css
import './AdminEdit.scss';

//redux
import adminActions from '../../../redux/actions/adminActions';
import { connect } from 'react-redux';

//images
import insert from '../../../assets/images/insert-photo.png';
import backArrow from '../../../assets/images/arrow-red-left.svg';
import camera from '../../../assets/images/camera.svg';
import whiteX from '../../../assets/images/x-white.svg';


function AdminEdit(props) {
	const history = useHistory();
	const params = useParams();
	const [adminData, setAdminData] = useState({
		first_name: '',
		last_name: '',
		email: '',
		mobile_phone: '',
		address_1: '',
		address_2: '',
		suburb: '',
		state: '',
		postal_code: '',
		photo: null,
		existing_photo: null,
	});



	const handleOnChangeImage = (e) => {
		createImage(e.target.files[0]);
	};

	const createImage = (file) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			setAdminData({
				...adminData,
				photo: e.target.result,
				existing_photo: null,
			});
		};
		reader.readAsDataURL(file);
		
	};

	const handleEdit = (e) => {
		e.preventDefault();
		props.dispatch(
			adminActions.editAdmin({
				data: adminData,
				id: params.adminId,
			})
		);
	};

	const handleMobileChange = (e) => {
		let value = e.target.value.split(' ').join('');
		let number;
		if (value.length === 6 && value[1] === '3' && value[0] === '1') {
			number = ''
			for (let i = 0; i < value.length; i++) {
				if (i === 2 || i === 4) {
					number += ` ${value[i]}`
				} else {
					number += `${value[i]}`
				}
			}
		} else {
			number = new AsYouType('AU').input(e.target.value)
		}
		setAdminData({
			...adminData,
			mobile_phone: number,
		});
	}

	useEffect(() => {
		coachHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null);
		props.dispatch(adminActions.getAdminDetails(params.adminId));
	}, []);

	useEffect(()=>{
		setAdminData({
		first_name: props.adminDetails.first_name? props.adminDetails.first_name: '',
		last_name: props.adminDetails.last_name? props.adminDetails.last_name: '',
		email: props.adminDetails.email? props.adminDetails.email: '',
		mobile_phone: props.adminDetails.mobile_phone? props.adminDetails.mobile_phone: '',
		address_1: props.adminDetails.street_address1? props.adminDetails.street_address1: '',
		address_2: props.adminDetails.street_address2? props.adminDetails.street_address2: '',
		suburb: props.adminDetails.suburb? props.adminDetails.suburb: '',
		state: props.adminDetails.state? props.adminDetails.state: '',
		postal_code: props.adminDetails.postal_code? props.adminDetails.postal_code: '',
		photo: null,
		existing_photo: props.adminDetails.photo? props.adminDetails.photo: null,
		})
	},[props.adminDetails])

	return (
		<section>
			<div className='coach_edit_top_div'>
				<div className='left_edit_top'>
					<Link to={`/admin/super-admins`}>
						<div className='go_back_arrow'>
							<img src={backArrow} alt='Go Back' />
						</div>
					</Link>
					<div className='bold blue font-36 text-upper float-left'>
						edit {props?.adminDetails?.first_name}
					</div>
				</div>
				<button
					type='button'
					onClick={()=>{
						props.dispatch(adminActions.deleteAdmin({id: params.adminId}, history));
					}}
					className='white bold text-upper save_red_button'
					>
					Delete
				</button>
				{props?.adminDetailsLoading === true && (
					<div className='text-center mx-auto mt-4'>
						<CircularProgress color='primary' size={30} />
					</div>
				)}
			</div>
			<div className='coach_edit_body'>
				<div className='coach_pfp_div'>
					<div className='coach_edit_pfp'>
						<div className='delete_img_btn'>
							<img
								src={whiteX}
								alt=''
								onClick={() => {
									setAdminData({
										...adminData,
										photo: null,
										existing_photo: null
									});
								}}
							/>
						</div>
							<div className='coach_pfp_container'>
								<img
									src={
										adminData.existing_photo !== null
											? baseURL + '/image/' + adminData.existing_photo
											: adminData.photo !== null ? adminData.photo : insert
									}
									alt='admin profile picture'
								/>
							</div>
						
					</div>
					<button className='add_photo_btn'>
						<input
							onChange={(e) => {
								handleOnChangeImage(e);
							}}
							type='file'
							id='file'
							name='file'
							className='custom_file_input'
						/>
						<label
							htmlFor='file'
							className='bold blue text-upper add_photo_label'
						>
							<img src={camera} alt='' />
							update photo
						</label>
					</button>
				</div>
				<div className='coach_edit_form'>
					<form onSubmit={handleEdit}>
					<div className='edit_form_row'>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>First Name*</label>
							<input
								required
								value={adminData.first_name}
								onChange={(e) => {
									setAdminData({
										...adminData,
										first_name: e.target.value,
									});
								}}
								name='First Name'
								type='text'
								placeholder={props.adminDetails.first_name}
								className='coach_edit_input'
							/>
						</div>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Family Name*</label>
							<input
								value={adminData.last_name}
								onChange={(e) => {
									setAdminData({
										...adminData,
										last_name: e.target.value,
									});
								}}
								name='Last Name'
								type='text'
								placeholder={props?.adminDetails?.last_name}
								className='coach_edit_input'
								required
							/>
						</div>
					</div>
					<div className='edit_form_row'>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Email address*</label>
							<input
								value={adminData.email}
								onChange={(e) => {
									setAdminData({
										...adminData,
										email: e.target.value,
									});
								}}
								name='email'
								type='email'
								placeholder={props?.adminDetails?.email}
								className='coach_edit_input'
								required
							/>
						</div>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>phone number*</label>
							<input
								required
								value={adminData.mobile_phone}
								onChange={(e) => handleMobileChange(e)}
								name='phone number'
								type='text'
								// maxLength='12'
								placeholder={props?.adminDetails?.mobile_phone}
								className='coach_edit_input number_input'
							/>
						</div>
					</div>
					<AddressAuto
						required
						addressInfo={adminData}
						setAddressInfo={setAdminData}
						styling='member_edit'
						inputStyling='coach_edit_input'
					/>

					<div className='edit_button_div'>
						<div>
							<button
								type='submit'
								className='white bold text-upper save_red_button'
							>
								save
							</button>
						</div>
						<Link
							to={`/admin/super-admins`}
							className='white bold text-upper cancel_blue_button'
						>
							cancel
						</Link>
						{props?.editCoachLoading === true && (
							<div className='text-center mx-auto mt-4'>
								<CircularProgress color='primary' size={30} />
							</div>
						)}
					</div>
					</form>
				</div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => ({
	adminDetails: state.adminReducer.adminDetails,
	adminDetailsLoading: state.adminReducer.adminDetailsLoading,
	user: state.register.user
});

export default connect(mapStateToProps)(AdminEdit);