import {
	GET_COUPON_CATEGORIES_REQUEST,
	GET_COUPON_CATEGORIES_SUCCESS,
	GET_COUPON_CATEGORIES_ERROR,
	GET_COUPONS_REQUEST,
	GET_COUPONS_SUCCESS,
	GET_COUPONS_ERROR,
	HANDLE_ADD_COUPON, GET_COUPON_DETAILS_REQUEST, GET_COUPON_DETAILS_SUCCESS, GET_COUPON_DETAILS_ERROR,
	CREATE_COUPON_REQUEST, CREATE_COUPON_SUCCESS, CREATE_COUPON_ERROR,
	EDIT_COUPON_REQUEST, EDIT_COUPON_SUCCESS, EDIT_COUPON_ERROR,
	CANCEL_COUPON_REQUEST, CANCEL_COUPON_SUCCESS, CANCEL_COUPON_ERROR,
} from '../actions/couponsActions';
import actions from '../actions/postsActions';

const initialState = {
	couponCategories: [],
	couponCategoriesLoading: false,
	coupons: [],
	couponsLoading: false,
	couponDetails: [],
	couponDetailsLoading: false,
	addcouponLoading: false,
	editcouponLoading: false,
	cancelcouponLoading: false,

	selectedPage: 1,

	rec_dates: [],
	rec_text: '',
	rec_count: 1,

	formData: {
		code: '',
		category_id: '',
		is_one_time: 1,
		value: 0,
		max_uses: 0,
	},
};

function coupon(state = initialState, action) {
	switch (action.type) {
		case GET_COUPON_CATEGORIES_REQUEST:
			return Object.assign({}, state, {
				couponCategoriesLoading: true,
				couponCategories: [],
			});
		case GET_COUPON_CATEGORIES_SUCCESS:
			return Object.assign({}, state, {
				couponCategoriesLoading: false,
				couponCategories: action.payload.data,
			});
		case GET_COUPON_CATEGORIES_ERROR:
			return Object.assign({}, state, {
				couponCategoriesLoading: false,
				couponCategories: [],
			});

		case GET_COUPONS_REQUEST:
			return Object.assign({}, state, {
				couponsLoading: true,
				coupons: [],
			});
		case GET_COUPONS_SUCCESS:
			return Object.assign({}, state, {
				couponsLoading: false,
				coupons: action.payload.data,
			});
		case GET_COUPONS_ERROR:
			return Object.assign({}, state, {
				couponsLoading: false,
				coupons: [],
			});

		case HANDLE_ADD_COUPON:
			return Object.assign({}, state, {
				formData: action.payload,
			});

		case GET_COUPON_DETAILS_REQUEST:
			return Object.assign({}, state, {
				couponDetailsLoading: true,
				couponDetails: [],
			});
		case GET_COUPON_DETAILS_SUCCESS:
			return Object.assign({}, state, {
				couponDetailsLoading: false,
				couponDetails: action.payload.data,
			});
		case GET_COUPON_DETAILS_ERROR:
			return Object.assign({}, state, {
				couponDetailsLoading: false,
				couponDetails: [],
			});

		case CREATE_COUPON_REQUEST:
			return Object.assign({}, state, {
				addCouponLoading: true,
			});
		case CREATE_COUPON_SUCCESS:
			return Object.assign({}, state, {
				addCouponLoading: false,
			});
		case CREATE_COUPON_ERROR:
			return Object.assign({}, state, {
				addCouponLoading: false,
			});

		case EDIT_COUPON_REQUEST:
			return Object.assign({}, state, {
				editCouponLoading: true,
			});
		case EDIT_COUPON_SUCCESS:
			return Object.assign({}, state, {
				editCouponLoading: false,
			});
		case EDIT_COUPON_ERROR:
			return Object.assign({}, state, {
				editCouponLoading: false,
			});

		case CANCEL_COUPON_REQUEST:
			return Object.assign({}, state, {
				cancelCouponLoading: true,
			});
		case CANCEL_COUPON_SUCCESS:
			return Object.assign({}, state, {
				cancelCouponLoading: false,
			});
		case CANCEL_COUPON_ERROR:
			return Object.assign({}, state, {
				cancelCouponLoading: false,
			});
		default:
			return state;
	}
}

export default coupon;
