import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { coachHandle } from '../../../utils/global';
import queryString from 'query-string';
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

//images
import plus from '../../../assets/images/click-plus.svg';

//components
import AdminListRow from '../../../Components/SuperAdmin/AdminListRow';

//Redux
import adminActions from "../../../redux/actions/adminActions";

import './AdminsList.scss';



const useStyles = makeStyles((theme) => ({
	root: {
	  "& > *": {
		marginTop: theme.spacing(2),
	  },
	},
  }))

function AdminsList(props) {
	let history = useHistory();
	const classes = useStyles();

	const [searchQuery, setSearchQuery] = useState({
		value:'',
		current:'',
	})
	const [isSearch, setIsSearch] = useState(false)
	const [numOfPages, setNumOfPages] = useState([])

	useEffect(() => {
		let pages=[]
		for (let i = 1; i <= props?.listAdmins?.last_page; i++) {
			pages.push(i);
		}
		setNumOfPages(pages)
		
	}, [props.listAdmins,isSearch]);

	useEffect(() => {
		if(isSearch){
			props.dispatch(adminActions.listAllAdmins({query:queryString.parse(props.location.search).query, page:queryString.parse(props.location.search).page}));
		}else{
			props.dispatch(adminActions.listAllAdmins({page:queryString.parse(props.location.search).page}))
		}
	}, [queryString.parse(props.location.search).page, queryString.parse(props.location.search).query, isSearch]);

	useEffect(() => {
		coachHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null);
	}, []);

	const handleSearch = (e) => {
		setIsSearch(true);
		setSearchQuery({...searchQuery,current:searchQuery.value});
		
		history.push({
			pathname: '/admin/super-admins',
			search: `?page=${1}&query=${searchQuery.value}`,
		});
	};

	const handleNewPagination = (e, selectedPage) =>{
		props.dispatch(adminActions.changePageState(selectedPage));
		if (isSearch) {
			history.push({
			  pathname: "/admin/super-admins",
			  search: `?page=${selectedPage}&query=${searchQuery.current}`,
			});
		  } else {
			history.push({
			  pathname: "/admin/super-admins",
			  search: `?page=${selectedPage}`,
			});
		  }
	  };


	return (
		<section>
			<div className='coach_top_div'>
				<div className='bold blue font-36 text-upper float-left'>Super Admins</div>
				<div className='coach_form_div'>
					{isSearch&&
					(<div className="mr-3">
						<div>
							SHOWING RESULTS FOR "{searchQuery.current}"
						</div>
						<div className="search_cancel">
							<span className="pointer" onClick={() => {
										props.dispatch(adminActions.changePageState(1));
										setIsSearch(false);
										setSearchQuery({...searchQuery, value:''})
									}}>
								CANCEL		
							</span>
						</div>
					</div>)}
					<div className='seach_bar'>
						<input className='search_button' type='search' onClick={()=>handleSearch()} />
						<input
							type='text'
							placeholder='Search for Admins...'
							className='search_input'
							value={searchQuery.value}
							onChange={(e) => {
								setSearchQuery({...searchQuery,value:e.target.value});
							}}
						/>
					</div>
					<div style={{cursor: 'pointer'}} onClick={(e) => history.push("/admin/super-admins/new-admin")} className='coach_add_new bold'>
						<img src={plus} alt='' />
						<span>Add New</span>
					</div>
				</div>
			</div>
			<div className='coach_list_div'>
				<div className='coach_list_top'>
					<div className='coach_table_header photo_head'>PHOTO</div>
					<div className='coach_table_header name_head'>NAME</div>
					<div className='coach_table_header last_head'>FAMILY NAME</div>
					<div className='coach_table_header detail_head'>MOBILE</div>
					<div className='coach_table_header event_head'>EMAIL</div>
					<div className='coach_table_header view_head'>VIEW/EDIT</div>
				</div>
				<div className='coach_list_container'>
					{props.listAdminsLoading ? (
						<div className='text-center mx-auto mt-4'>
							<CircularProgress color='primary' size={30} />
						</div>
					) : (
						props.listAdmins.data?.map((zone, i) => {
							
							let data = (
								<AdminListRow
									key={i}
									image={zone.photo}
									first_name={zone.first_name}
									last_name={zone.last_name}
									email={zone.email}
									link={zone.id}
									mobile_phone={zone.mobile_phone}
								/>
							);
							return data;
						})
					)}
				</div>
				<div className={classes.root}>
          			<Pagination count={numOfPages?.length} defaultPage={ queryString.parse(props.location.search).page? parseInt(queryString.parse(props.location.search).page) : 1 } shape="rounded" onChange={handleNewPagination} />
       			 </div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => ({
	listAdmins: state.adminReducer.listAdmins,
	listAdminsLoading: state.adminReducer.listAdminsLoading,
	user: state.register.user,
	selectedPage: state.adminReducer.page,
});

export default connect(mapStateToProps)(AdminsList);
