import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'

import moment from 'moment'
import { RRule, RRuleSet, rrulestr } from 'rrule'

//IMAGES
import LineRedImg from "../../../assets/images/Line-red.svg";
import ArrowBlueTopImg from "../../../assets/images/arrow_blue_top.svg";
import ArrowBlueDownImg from "../../../assets/images/arrow_blue_down_pop.svg";
import unchecked from "../../../assets/images/radio-unchecked-blue.svg";
import checkedImg from "../../../assets/images/radio-checked-red.svg"
import radioCheckedLogo from '../../../assets/images/Checked.png';
import radioUncheckedLogo from '../../../assets/images/Unchecked.png';
//REDUX
import eventActions from "../../../redux/actions/eventsActions"
import popupAction from "../../../redux/actions/postsActions"
//CSS
import "./CustomRec.scss";
import "react-multi-date-picker/styles/colors/red.css"
import DatePickerComponent from "../../DatePickerComponent/DatePickerComponent";

//Custom Recurrence
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { Calendar } from "react-multi-date-picker"

export const CustomRec = (props) => {
    const [recType, setRecType] = useState('rule');
    const [dates, setDates] = useState([]);
    const [finalData, setFinalData] = useState({});
    const [recurenceType, setRecurenceType] = useState(1)
    const [recurenceValue, setRecurenceValue] = useState('Daily')
    const [monthlyOptionType, setMonthlyOptionType] = useState(1)
    const [monthlyOption, setMonthlyOption] = useState('First')
    const [frequency, setFrequency] = useState(1)
    const [afterRecurrence, setAfterRecurrence] = useState(1)
    const [endOnDate, setEndOnDate] = useState(moment().toDate())

    const [isOpen, setIsOpen] = useState(false)

    const [sunday, setSunday] = useState(false)
    const [monday, setMonday] = useState(true)
    const [tuesday, setTuesday] = useState(false)
    const [wednesday, setWednesday] = useState(false)
    const [thursday, setThursday] = useState(false)
    const [friday, setFriday] = useState(false)
    const [saturday, setSaturday] = useState(false)
    const [monthlyDay, setMonthlyDay] = useState('MO')
    const [radioButton, setRadioButton] = useState('After')
    

    //RRule
    const [RRDays, setRRDays] = useState([RRule.MO.weekday]);
    const [RRmonthlyDay,  setRRmonthlyDay] = useState(RRule.MO.weekday);

    const minDaySelect = () => {
        let count = 0
        if (sunday === true) {
            count++
        }
        if (monday === true) {
            count++
        }
        if (tuesday === true) {
            count++
        }
        if (wednesday === true) {
            count++
        }
        if (thursday === true) {
            count++
        }
        if (friday === true) {
            count++
        }
        if (saturday === true) {
            count++
        }
        return (count)
    }

    const getDays = () => {
        let final = ''
        if (sunday === true) {
            if (final.length === 0) {
                final = 'SU'
            } else {
                final = final + ',SU'
            }
        }
        if (monday === true) {
            if (final.length === 0) {
                final = 'MO'
            } else {
                final = final + ',MO'
            }
        }
        if (tuesday === true) {
            if (final.length === 0) {
                final = 'TU'
            } else {
                final = final + ',TU'
            }
        }
        if (wednesday === true) {
            if (final.length === 0) {
                final = 'WE'
            } else {
                final = final + ',WE'
            }
        }
        if (thursday === true) {
            if (final.length === 0) {
                final = 'TH'
            } else {
                final = final + ',TH'
            }
        }
        if (friday === true) {
            if (final.length === 0) {
                final = 'FR'
            } else {
                final = final + ',FR'
            }
        }
        if (saturday === true) {
            if (final.length === 0) {
                final = 'SA'
            } else {
                final = final + ',SA'
            }
        }
        return final
    }

    const handleEditDays = (day) => {
        let days_array = [...RRDays];
        if( days_array.includes(day) ){
            days_array.splice(days_array.indexOf(day), 1)
        }
        else{
            days_array.push(day);
        }
        setRRDays(days_array.sort());
    }

    const getStep = () => {
        let final = '';

        switch (monthlyOption) {
            case 'First':
                final = 1;
                break;

            case 'Second':
                final = 2;
                break;

            case 'Third':
                final = 3;
                break;

            case 'Fourth':
                final = 4;
                break;

            case 'Last':
                final = -1;
                break;

            default:
                break;

        }

        return final;
    }

    const handleMonthlyOptions = (e) => {
        if (e === 1) {
            setMonthlyOption('First');
        } else if (e === 2) {
            setMonthlyOption('Second');
        } else if (e === 3) {
            setMonthlyOption('Third');
        } else if (e === 4) {
            setMonthlyOption('Fourth');
        } else if (e === 5) {
            setMonthlyOption('Last');
        }

    }

    const handleValueChange = (e) => {
        if (e === 1) {
            setRecurenceValue('Daily')
        } else if (e === 2) {
            setRecurenceValue('Weekly')
        } else if (e === 3) {
            setRecurenceValue('Monthly')
        }
    }

    const handleTypeChange = (e) => {
        if (e === 'ADD' && recurenceType < 3) {
            setRecurenceType(recurenceType + 1)
            handleValueChange(recurenceType + 1)
        }
        if (e === 'DED' && recurenceType <= 3) {
            setRecurenceType(recurenceType - 1)
            handleValueChange(recurenceType - 1)
        }
    }

    const HandleOccurence = (e) => {
        if (e === 'ADD' && afterRecurrence <= 10) {
            setAfterRecurrence(afterRecurrence + 1)
        } else if (e === 'DED' && afterRecurrence > 1) {
            setAfterRecurrence(afterRecurrence - 1)
        }
    }

    const handleFrequency = (e) => {
        if (e === 'ADD') {
            setFrequency(frequency + 1)
        }
        if (e === 'DED' && frequency >= 2) {
            setFrequency(frequency - 1)
        }
    }

    const handleMonthChange = (e) => {
        if (e === 'ADD' && monthlyOptionType < 5) {
            setMonthlyOptionType(monthlyOptionType + 1)
            handleMonthlyOptions(monthlyOptionType + 1)
        } else if (e === 'DED' && monthlyOptionType > 1) {
            setMonthlyOptionType(monthlyOptionType - 1)
            handleMonthlyOptions(monthlyOptionType - 1)
        }
    }

    const handleSelectCustomDates = (e) => {
        let selected_dates = [];
        e.map((date)=>{
            let item = moment([date.year, date.month.number-1, date.day]).format('DD/MM/YYYY');
            selected_dates.push(item);
        })
        setDates(selected_dates)
        props.dispatch(eventActions.handleNewRecData({
            count: selected_dates.length,
            dates: selected_dates,
            text: "custom",
        }))
    }

    useEffect(() => {
        let final = {}
        let start_date = new Date(props.formData.start_date);
        let end_date = new Date(endOnDate);
        let rule;
        if (recurenceValue === 'Daily') {
            if (radioButton === 'Never') {
                final = {
                    recurrence_type: 'daily_never_end',
                    frequency: 'daily',
                    interval: frequency,
                    by_day: getDays(),
                }
                rule = new RRule({
                    freq: RRule.DAILY,
                    byweekday: RRDays,
                    dtstart: new Date(Date.UTC(start_date.getUTCFullYear(), start_date.getMonth(), start_date.getDate())),
                    until: new Date(Date.UTC(end_date.getUTCFullYear(), end_date.getMonth(), end_date.getDate())),
                  })
                
            } else if (radioButton === 'After') {
                final = {
                    recurrence_type: 'daily_on_week_days_after_count', //'daily_end_after_count',
                    frequency: 'daily',
                    // interval: frequency,
                    after_reccurence: afterRecurrence,
                    by_day: getDays(),
                }
                rule = new RRule({
                    freq: RRule.DAILY,
                    byweekday: RRDays,
                    dtstart: new Date(Date.UTC(start_date.getUTCFullYear(), start_date.getMonth(), start_date.getDate())),
                    count: afterRecurrence
                  })
            } else if (radioButton === 'On') {
                final = {
                    recurrence_type: 'daily_on_week_days', //'daily_until',
                    frequency: 'daily',
                    interval: frequency,
                    ends_on: moment(endOnDate, "YYYY-MM-DD").format('YYYY-MM-DD'),
                    by_day: getDays(),
                }
                rule = new RRule({
                    freq: RRule.DAILY,
                    byweekday: RRDays,
                    dtstart: new Date(Date.UTC(start_date.getUTCFullYear(), start_date.getMonth(), start_date.getDate())),
                    until: new Date(Date.UTC(end_date.getUTCFullYear(), end_date.getMonth(), end_date.getDate())),
                  })
            }
            if(rule.all().length>30){
                let array_of_dates = rule.all();
                array_of_dates.length = 30;
                props.dispatch(eventActions.handleNewRecData({
                    count: 30,
                    dates: array_of_dates,
                    text: rule.toText()
                }))
            }
            else{
                props.dispatch(eventActions.handleNewRecData({
                    count: rule.all().length,
                    dates: rule.all(),
                    text: rule.toText()
                }))
            }
        } else if (recurenceValue === 'Weekly') {
            // if (radioButton === 'Never') {
            //     final = {
            //         recurrence_type: 'weekly_on_week_days',
            //         frequency: 'weekly',
            //         interval: frequency,
            //         by_day: getDays()

            //     }
            // } else 
            if (radioButton === 'After') {
                final = {
                    recurrence_type: 'weekly_on_week_days_end_after_count',
                    frequency: 'weekly',
                    interval: frequency,
                    after_reccurence: afterRecurrence,
                    by_day: getDays(),
                    by_step: 1,
                }
                rule = new RRule({
                    freq: RRule.WEEKLY,
                    interval: frequency,
                    count: afterRecurrence,
                    byweekday: RRDays,
                    dtstart: new Date(Date.UTC(start_date.getUTCFullYear(), start_date.getMonth(), start_date.getDate())),
                  })
            } else if (radioButton === 'On') {
                final = {
                    recurrence_type: 'weekly_on_week_days_until',
                    frequency: 'weekly',
                    interval: frequency,
                    ends_on: moment(endOnDate, "YYYY-MM-DD").format('YYYY-MM-DD'),
                    by_day: getDays(),
                    by_step: 1,
                }
                rule = new RRule({
                    freq: RRule.WEEKLY,
                    interval: frequency,
                    byweekday: RRDays[0],
                    dtstart: new Date(Date.UTC(start_date.getUTCFullYear(), start_date.getMonth(), start_date.getDate())),
                    until: new Date(Date.UTC(end_date.getUTCFullYear(), end_date.getMonth(), end_date.getDate())),
                  })
            }
            props.dispatch(eventActions.handleNewRecData({
                count: rule.all().length,
                dates: rule.all(),
                text: rule.toText()
            }))
        } else if (recurenceValue === 'Monthly') {
            if (radioButton === 'After') {
                final = {
                    recurrence_type: 'monthly_after_count',
                    frequency: 'monthly',
                    interval: frequency,
                    by_step: getStep(),
                    after_reccurence: afterRecurrence,
                    by_day: monthlyDay,
                }
                rule = new RRule({
                    freq: RRule.MONTHLY,
                    interval: frequency,
                    byweekday: RRmonthlyDay,
                    bysetpos: getStep(),
                    count:afterRecurrence,
                    dtstart: new Date(Date.UTC(start_date.getUTCFullYear(), start_date.getMonth(), start_date.getDate())),
                  })
            } else if (radioButton === 'On') {
                final = {
                    recurrence_type: 'monthly_until',
                    frequency: 'monthly',
                    interval: frequency,
                    by_step: getStep(),
                    ends_on: moment(endOnDate, "YYYY-MM-DD").format('YYYY-MM-DD'),
                    by_day: monthlyDay,
                }
                rule = new RRule({
                    freq: RRule.MONTHLY,
                    interval:frequency,
                    byweekday: RRmonthlyDay,
                    bysetpos: getStep(),
                    dtstart: new Date(Date.UTC(start_date.getUTCFullYear(), start_date.getMonth(), start_date.getDate())),
                    until: new Date(Date.UTC(end_date.getUTCFullYear(), end_date.getMonth(), end_date.getDate())),
                })
            }
            props.dispatch(eventActions.handleNewRecData({
                count: rule.all().length,
                dates: rule.all(),
                text: rule.toText()
            }))
        }

        setFinalData(final);
    }, [recurenceValue, radioButton, endOnDate, RRDays, frequency, afterRecurrence, monthlyDay ]);


    const handleSubmit = () => {
        if(recType==='rule'){
            props.dispatch(eventActions.handleAddEventRecurenceData({
                name: "recurrence",
                data: finalData,
                formData: props?.formData,
                name2: 'is_recurring',
                data2: 1,
                data3: 0,
                name3: "is_full_day_event",
                name4: 'is_custom_rec',
                data4: 0
            }));
        }
        else{
            let new_dates = [];
            dates.map((date)=>{
                let moment_item = moment(date, "DD/MM/YYYY");
                let date_format = moment_item.format("YYYY-MM-DD");
                new_dates.push(date_format);
            })
            props.dispatch(eventActions.handleAddEventRecurenceData({
                name: "recurrence",
                data: new_dates,
                formData: props?.formData,
                name2: 'is_recurring',
                data2: 1,
                data3: 0,
                name3: "is_full_day_event",
                name4: 'is_custom_rec',
                data4: 1
            }));
        }
        closePopup()
    }

    const closePopup = () => {
        props.dispatch(popupAction.popupHandler({ type: "CLOSE" }))
    }

    return (
        <div>
            <div className="form_style">
                <img src={LineRedImg} alt='' />
            </div>
            <div className="form_title text-upper blue font-36 bold">
                CUSTOM RECURRENCE
            </div>
            <div className="rec_type">
            <div className='blue font-16 event_recurence_radio'>
								<div
									onClick={() => {
										setRecType('rule')
										props.dispatch(eventActions.handleNewRecData({
											count: 1,
											dates: [],
											text: ''
										}))
									}}
									className='mr-1'
								>
									<img
										src={
											recType === 'rule'
												? radioCheckedLogo
												: radioUncheckedLogo
										}
										alt=''
									/>
								</div>
								<span>Rule</span>
							</div>
							<div className='blue font-16 event_recurence_radio'>
								<div
									onClick={() => {
										setRecType('custom');
                                        props.dispatch(eventActions.handleNewRecData({
											count: 1,
											dates: [],
											text: 'custom'
										}))
									}}
									className='mr-1'
								>
									<img
										src={
											recType === 'custom'
												? radioCheckedLogo
												: radioUncheckedLogo
										}
										alt=''
									/>
								</div>
								<span>Custom Recurrence</span>
							</div>
            </div>
            {recType==='rule'?
            <>
                        <div className="d-inline-block">
                <div className="form_title blue font-14 bold d-inline-block">
                    REPEAT EVERY
                </div>
                <div className="text_one_form position-relative white regular font-16 pt-40 d-inline-block" >
                    <div className="position-absolute top_one" onClick={() => { handleFrequency('ADD') }} style={{ height: "20px" }}>
                        <img src={ArrowBlueTopImg} className='pointer' alt='' />
                    </div>
                    <div className="position-absolute down_one up" onClick={() => { handleFrequency('DED') }} style={{ height: "20px" }}>
                        <img src={ArrowBlueDownImg} className='pointer' alt='' />
                    </div>
                    <input
                        type="text"
                        className="form_two blue"
                        disabled
                        placeholder="01"
                        value={frequency.toString().length < 2 ? '0' + frequency : frequency}
                    ></input>
                </div>
                <div className="text_one_form position-relative white regular font-16 pt-40 d-inline-block" style={{ marginLeft: "4px" }}>
                    <div className="position-absolute top_one" onClick={() => { handleTypeChange('ADD') }} style={{ height: "20px" }}>
                        <img src={ArrowBlueTopImg} className='pointer' alt='' />
                    </div>
                    <div className="position-absolute down_three" onClick={() => { handleTypeChange('DED') }}>
                        <img src={ArrowBlueDownImg} className='pointer' alt='' />
                    </div>
                    <input
                        type="textarea"
                        className="form_two blue"
                        placeholder="Week"
                        disabled
                        value={recurenceValue}
                    ></input>
                </div>
            </div>

            {recurenceValue === 'Weekly' ||  recurenceValue === 'Daily' ?
                <>
                    <div className="form_title blue font-14 bold pt-3 text-upper">
                        REPEAT on
                    </div>
                    <div className="d-inline-block">
                        <div onClick={() => { if (minDaySelect() > 1) { setSunday(!sunday) } else if (minDaySelect() === 1 && sunday === false) { setSunday(!sunday) } ;handleEditDays(RRule.SU.weekday) }} className={`d-inline-block text-upper font-14 bold daily_style ${sunday ? 'daily_style_active' : null}`}>
                            S
                        </div>
                        <div onClick={() => { if (minDaySelect() > 1) { setMonday(!monday) } else if (minDaySelect() === 1 && monday === false) { setMonday(!monday) } ;handleEditDays(RRule.MO.weekday) }} className={`d-inline-block text-upper font-14 bold daily_style ${monday ? 'daily_style_active' : null}`}>
                            M
                        </div>
                        <div onClick={() => { if (minDaySelect() > 1) { setTuesday(!tuesday) } else if (minDaySelect() === 1 && tuesday === false) { setTuesday(!tuesday) } ;handleEditDays(RRule.TU.weekday)}} className={`d-inline-block text-upper font-14 bold daily_style ${tuesday ? 'daily_style_active' : null}`}>
                            T
                        </div>
                        <div onClick={() => { if (minDaySelect() > 1) { setWednesday(!wednesday) } else if (minDaySelect() === 1 && wednesday === false) { setWednesday(!wednesday) } ;handleEditDays(RRule.WE.weekday) }} className={`d-inline-block text-upper font-14 bold daily_style ${wednesday ? 'daily_style_active' : null}`}>
                            W
                        </div>
                        <div onClick={() => { if (minDaySelect() > 1) { setThursday(!thursday) } else if (minDaySelect() === 1 && thursday === false) { setThursday(!thursday) } ;handleEditDays(RRule.TH.weekday) }} className={`d-inline-block text-upper font-14 bold daily_style ${thursday ? 'daily_style_active' : null}`}>
                            T
                        </div>
                        <div onClick={() => { if (minDaySelect() > 1) { setFriday(!friday) } else if (minDaySelect() === 1 && friday === false) { setFriday(!friday) } ;handleEditDays(RRule.FR.weekday) }} className={`d-inline-block text-upper font-14 bold daily_style ${friday ? 'daily_style_active' : null}`}>
                            F
                        </div>
                        <div onClick={() => { if (minDaySelect() > 1) { setSaturday(!saturday) } else if (minDaySelect() === 1 && saturday === false) { setSaturday(!saturday) } ;handleEditDays(RRule.SA.weekday) }} className={`d-inline-block text-upper font-14 bold daily_style ${saturday ? 'daily_style_active' : null}`}>
                            S
                        </div>
                    </div>
                </> : null}
            {recurenceValue === 'Monthly' ?
                <div>
                    <span className="blue font-14 bold pt-3 text-upper mr-1">
                        REPEAT on
                    </span>
                    <div className="text_one_form position-relative white regular font-16 pt-40 d-inline-block" style={{ marginLeft: "4px" }}>
                        <div className="position-absolute top_one" style={{ height: "20px" }}>
                            <img src={ArrowBlueTopImg} onClick={() => { handleMonthChange('ADD') }} className='pointer' alt='' />
                        </div>
                        <div className="position-absolute down_three">
                            <img src={ArrowBlueDownImg} onClick={() => { handleMonthChange('DED') }} className='pointer' alt='' />
                        </div>
                        <input
                            type="textarea"
                            className="form_two blue"
                            placeholder="Week"
                            disabled
                            value={monthlyOption}
                        ></input>
                    </div>
                    <div className="d-inline-block">
                        <div onClick={() => {setMonthlyDay('SU'); setRRmonthlyDay(RRule.SU.weekday)}} className={`d-inline-block text-upper font-14 bold daily_style ${monthlyDay === 'SU' ? 'daily_style_active' : null}`}>
                            S</div>
                        <div onClick={() => {setMonthlyDay('MO'); setRRmonthlyDay(RRule.MO.weekday)}} className={`d-inline-block text-upper font-14 bold daily_style ${monthlyDay === 'MO' ? 'daily_style_active' : null}`}>
                            M</div>
                        <div onClick={() => {setMonthlyDay('TU'); setRRmonthlyDay(RRule.TU.weekday)}} className={`d-inline-block text-upper font-14 bold daily_style ${monthlyDay === 'TU' ? 'daily_style_active' : null}`}>
                            T</div>
                        <div onClick={() => {setMonthlyDay('WE'); setRRmonthlyDay(RRule.WE.weekday)}} className={`d-inline-block text-upper font-14 bold daily_style ${monthlyDay === 'WE' ? 'daily_style_active' : null}`}>
                            W</div>
                        <div onClick={() => {setMonthlyDay('TH'); setRRmonthlyDay(RRule.TH.weekday)}} className={`d-inline-block text-upper font-14 bold daily_style ${monthlyDay === 'TH' ? 'daily_style_active' : null}`}>
                            T</div>
                        <div onClick={() => {setMonthlyDay('FR'); setRRmonthlyDay(RRule.FR.weekday)}} className={`d-inline-block text-upper font-14 bold daily_style ${monthlyDay === 'FR' ? 'daily_style_active' : null}`}>
                            F</div>
                        <div onClick={() => {setMonthlyDay('SA'); setRRmonthlyDay(RRule.WE.weekday)}} className={`d-inline-block text-upper font-14 bold daily_style ${monthlyDay === 'SA' ? 'daily_style_active' : null}`}>
                            S</div>
                    </div>
                </div>
                : null}
            <div className="form_title blue font-14 bold pt-4 text-upper">
                Ends
            </div>
            <div className="top_top" style={styles.container}>
                <div onClick={() => { setRadioButton('On') }} className="d-inline-block">
                    {radioButton === 'On' ? <img style={styles.radioButton} src={checkedImg} alt='' /> : <img style={styles.radioButton} src={unchecked} alt='unChecked' />}
                </div>
                <div className="d-inline-block">
                    <span className="regular font-16 blue">On</span>
                </div>
                <DatePickerComponent
                    containerClass="text_all_area_pop d-inline-block position-relative pt-2 ml-4 blue regular font-16"
                    name='expirationDate'
                    selected={endOnDate}
                    minDate={moment().toDate()}
                    handleDateChange={(e) => { setEndOnDate(e) }}
                    open={isOpen}
                    disabled={radioButton === 'On' ? false : true}
                    setIsOpenTrue={() => radioButton === 'On' ? setIsOpen(true) : null}
                    setIsOpenFalse={() => setIsOpen(false)}
                    closeOnScroll={false}
                    popperPlacement={"right"}
                />
            </div>
            <div className="top_top" style={styles.container}>
                <div onClick={() => { setRadioButton('After') }} className="d-inline-block">
                    {radioButton === 'After' ? <img style={styles.radioButton} src={checkedImg} alt='' /> : <img style={styles.radioButton} src={unchecked} alt='unChecked' />}
                </div>
                <div className="d-inline-block">
                    <span className="regular font-16 blue">After</span>
                </div>
                <div className="text_all_area_pop d-inline-block position-relative pt-2 ml-2 blue regular font-16">
                    <div className="position-absolute down_two">
                        <img onClick={() => { HandleOccurence('DED') }} src={ArrowBlueDownImg} className='pointer' alt='' />
                    </div>
                    <div className="position-absolute top_two">
                        <img onClick={() => { HandleOccurence('ADD') }} src={ArrowBlueTopImg} className='pointer' alt='' />
                    </div>
                    <input disabled={radioButton === 'After' ? false : true}
                        className="blue" type="text"
                        placeholder="13 Occurrences"
                        readOnly
                        value={afterRecurrence === 1 ? afterRecurrence + " Occurrence" : afterRecurrence + " Occurrences"}></input>
                </div>
            </div>
            </>
            :
            <>
              <Calendar
                className="red custom_date_picker"
                value={dates}
                onChange={handleSelectCustomDates}
                format="DD/MM/YYYY"
                multiple={true}
                sort={true}
                numberOfMonths={2}
                minDate={props?.formData?.start_date}
                plugins={[
                    <DatePanel />
                ]}
                >
                </Calendar>
            </>
            }
            
            {(props.rec_dates.length>0 && recType=='rule') &&
            <div>
            <div className="d-inline-block">
                <span className="form_title blue font-14 bold pt-3 text-upper">Recurrence Statement</span>
            </div>
            <div className="top_top">
                <span className="form_title blue font-14 pt-3">
                    {props.rec_text}
                </span>
            </div>

            <div>
            <div className="d-inline-block">
                <span className="form_title blue font-14 bold pt-3 text-upper">Recurrence dates</span>
            </div>
                
            {props.rec_dates.length!==0 && 
					<ol>
					<div className="grid_display_dates">

						{props?.rec_dates?.map(rec => {
							return(
								<span className="form_title blue font-14 pt-3"><li>{props.rec_text!=='custom' ? moment(rec).format('DD/MM/YYYY') : rec}</li></span>
							)	
						})}

					</div>
					</ol>
            }
            </div>
            </div>
            }
            
            <div onClick={() => { handleSubmit() }} className="edit-pen-pop white d-inline-block blue-bg text-upper text-center bold font-14 red-bg mt-4">
                <p>
                    <span className="edit-class text-upper">Save</span>
                </p>
            </div>
            <div className="edit-pen-pop white blue-bg d-inline-block text-upper text-center bold font-14 blue-bg mt-4">
                <p
                    onClick={() => closePopup()}
                >
                    <span className="edit-class">CANCEL</span>
                </p>
            </div>
        </div>
    );
};


const styles = {
    radioButton: {
        width: "24px",
        height: "24px"
    },
    container: {
        display: 'flex',
        alignItems: "center"
    }
}
const mapStateToProps = (state) => ({
    formData: state.eventReducer.formData,
    rec_dates: state.eventReducer.rec_dates,
    rec_text: state.eventReducer.rec_text,
})


export default connect(mapStateToProps)(CustomRec)
