import { deleteProducts, getStoreCategories, addCategory, getProducts } from '../../config/config';
import postsActions from './postsActions';
import { toast } from 'react-toastify';

export const DELETE_PRODUCTS_REQUEST = 'DELETE_PRODUCTS_REQUEST';
export const DELETE_PRODUCTS_SUCCESS = 'DELETE_PRODUCTS_SUCCESS';
export const DELETE_PRODUCTS_ERROR = 'DELETE_PRODUCTS_ERROR';

export const ASSIGN_CATEGORY = 'ASSIGN_CATEGORY';

export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';

export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';

const actions = {
    deleteProducts: (data) => async (dispatch) => {
        try {
            dispatch({
                type: DELETE_PRODUCTS_REQUEST,
            });

            let headers = {
                'Authorization': 'Bearer ' + localStorage.access_token,
                'Content-type': 'application/x-www-form-urlencoded'
            }

            await deleteProducts(data, headers).then((response) => {
                if (response.data.code === 200) {
                    dispatch({
                        type: DELETE_PRODUCTS_SUCCESS,
                    });
                    toast.success('Product(s) deleted');
                    dispatch(actions.getProducts({id: data.id}))
                    dispatch(postsActions.popupHandler({type: 'CLOSE'}))

                } else {
                    dispatch({
                        type: DELETE_PRODUCTS_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                    toast.error('Error: ' + response.message);

                }
            });
        } catch (error) {
            dispatch({
                type: DELETE_PRODUCTS_ERROR,
                payload: {
                    message: 'Error deleting items',
                },
            });
        }
    },

    getStoreCategories: (data) => async (dispatch) => {
        try {
            dispatch({
                type: GET_CATEGORIES_REQUEST,
            });

            await getStoreCategories(data).then((response) => {
                if (response.data.code === 200) {
                    dispatch({
                        type: GET_CATEGORIES_SUCCESS,
                        data: response.data.data
                    });

                } else {
                    dispatch({
                        type: GET_CATEGORIES_ERROR,
                    });
                    toast.error('Error: ' + response.message);

                }
            });
        } catch (error) {
            dispatch({
                type: GET_CATEGORIES_ERROR,
            });
        }
    },

    getProducts: (data) => async (dispatch) => {
        try {
            dispatch({
                type: GET_PRODUCTS_REQUEST,
            });

            await getProducts(data).then((response) => {
                if (response.data.code === 200) {
                    dispatch({
                        type: GET_PRODUCTS_SUCCESS,
                        data: response.data.data
                    });

                } else {
                    dispatch({
                        type: GET_PRODUCTS_ERROR,
                    });
                    toast.error('Error: ' + response.message);

                }
            });
        } catch (error) {
            dispatch({
                type: GET_PRODUCTS_ERROR,
            });
        }
    },

    createStoreCategory: (data) => async (dispatch) => {
        try{
            await addCategory(data).then((response) => {
                if (response.data.code === 200) {
                    toast.success('Store category successfully added');
                    dispatch(actions.getStoreCategories({page: 1}))
                    dispatch(postsActions.popupHandler({type: 'CLOSE'}))

                } else {
                    toast.error('Error: ' + response.message);

                }
            });
        }catch (error) {
            toast.error('Error: ' + error.message);
        }
    },

    assignCategory: (data) => async (dispatch) => {
        dispatch({
          type: ASSIGN_CATEGORY,
          data: data
        });
    },
};

export default actions;
