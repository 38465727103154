import React from 'react';
import { connect } from 'react-redux';

//images
import LineRedImg from '../../../assets/images/Line-red.svg';


export const Info = (props) => {

	return (
		<div>
			<div className='form_style'>
				<img src={LineRedImg} />
			</div>
			<div className='form_title blue font-18 bold text-upper'>
				CANCELLED NOTES 
			</div>
			<div className='blue font-16'>
				{ props.selectedPost.data } 
			</div>
		</div>
	);
};

const styles = {
	red_btn: {
		width: '200px',
		background: '#d91c24',
		height: '70px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	blue_btn: {
		width: '200px',
		marginRight: '20px',
		background: '#262262',
		height: '70px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
};

const mapStateToProps = (state) => ({
	selectedPost: state.postReducer?.selectedPost,
});

export default connect(mapStateToProps)(Info);
