import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { baseURL } from '../../../config/config';
import CircularProgress from '@material-ui/core/CircularProgress';
import { coachHandle } from '../../../utils/global';
import { AsYouType } from "libphonenumber-js";
import "react-datepicker/dist/react-datepicker.css";
//css
import './CoachEdit.scss';

//redux
import coachActions from '../../../redux/actions/coachActions';
import { connect } from 'react-redux';

//images
import calendarImg from '../../../assets/images/calendar.svg';
import insert from '../../../assets/images/insert-photo.png';
import backArrow from '../../../assets/images/arrow-red-left.svg';
import camera from '../../../assets/images/camera.svg';
import checked from '../../../assets/images/CheckBoxChecked.png';
import unchecked from '../../../assets/images/CheckBoxUnchecked.png';
import whiteX from '../../../assets/images/x-white.svg';
import moment from 'moment';
import DatePickerComponent from '../../../Components/DatePickerComponent/DatePickerComponent';
import AddressAuto from '../../../Components/Address/AddressAuto';

function CoachEdit(props) {
	const params = useParams();
	const [isOpen, setIsOpen] = useState(false)
	const [editCoachData, setEditCoachData] = useState({
		first_name: '',
		last_name: '',
		email: '',
		mobile_phone: '',
		address_1: '',
		address_2: '',
		suburb: '',
		state: '',
		postal_code: '',
		coach_catgegory_id: '',
		is_active: '',
		equipment: '',
		qualifications: '',
		date_of_birth: '',
		profile_picture: '',
		workingWithChildrenDetails: '',
		workingWithChildrenDate: moment().toDate(),
	});
	const [isChecked, setIsChecked] = useState(false);
	const [pictureError, setPictureError] = useState('');
	const [addressError, setAddressError] = useState('');
	const [expDateError, setExpDateError] = useState('');
	const [dobError, setDobError] = useState('');

	const [isChanged, setChanged] = useState(false);

	const handleChecked = () => {
		setIsChecked(!isChecked);
		setEditCoachData({ ...editCoachData, ['is_active']: + !isChecked });
	}

	const handleOnChangeImage = (e) => {
		if(e.target.files[0]){
			setPictureError('');
		}
		createImage(e.target.files[0]);
	};

	const createImage = (file) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			setEditCoachData({
				...editCoachData,
				profile_picture: e.target.result,
			});
		};
		reader.readAsDataURL(file);
	};

	const handleEdit = (e) => {
		e.preventDefault();

		let finalEditedCoach;
		if (isChanged === true) {
			finalEditedCoach = {
				first_name: editCoachData.first_name,
				last_name: editCoachData.last_name,
				email: editCoachData.email,
				mobile_phone: editCoachData.mobile_phone,
				street_address1: editCoachData.address_1,
				street_address2: editCoachData.address_2,
				suburb: editCoachData.suburb,
				state: editCoachData.state,
				postal_code: editCoachData.postal_code,
				profile_picture: editCoachData.profile_picture,
				coach_catgegory_id: editCoachData.coach_catgegory_id,
				is_active: editCoachData.is_active,
				equipment: editCoachData.equipment,
				qualifications: editCoachData.qualifications,
				date_of_birth: moment(editCoachData.date_of_birth).format("YYYY-MM-DD"),
				coach_children_working_description: editCoachData.workingWithChildrenDetails,
				coach_children_working_date: moment(editCoachData.workingWithChildrenDate).format("YYYY-MM-DD"),
			};

			if(!finalEditedCoach.profile_picture){
				setPictureError('Profile picture is required');
				return
			}
		} else {
			finalEditedCoach = {
				first_name: editCoachData.first_name,
				last_name: editCoachData.last_name,
				email: editCoachData.email,
				mobile_phone: editCoachData.mobile_phone,
				street_address1: editCoachData.address_1,
				street_address2: editCoachData.address_2,
				suburb: editCoachData.suburb,
				state: editCoachData.state,
				postal_code: editCoachData.postal_code,
				coach_catgegory_id: editCoachData.coach_catgegory_id,
				is_active: editCoachData.is_active,
				equipment: editCoachData.equipment,
				qualifications: editCoachData.qualifications,
				date_of_birth: moment(editCoachData.date_of_birth).format("YYYY-MM-DD"),
				coach_children_working_description: editCoachData.workingWithChildrenDetails,
				coach_children_working_date: moment(editCoachData.workingWithChildrenDate).format("YYYY-MM-DD"),
				existing_profile_image: props?.coachDetails?.profile_picture,
			};
			if(!finalEditedCoach.existing_profile_image){
				setPictureError('Profile picture is required');
				return
			}
		}

		if(!finalEditedCoach.street_address1 || !finalEditedCoach.street_address2 || !finalEditedCoach.suburb || !finalEditedCoach.state || !finalEditedCoach.postal_code){
			setAddressError('Address is required');
			return
		}
		
		if(!editCoachData.workingWithChildrenDate){
			setExpDateError('Expiration date is required');
			return
		}

		if(!editCoachData.date_of_birth){
			setDobError('Birth date is required');
			return
		}

		setPictureError('');
		setAddressError('');
		setExpDateError('');
		setDobError('');
		props.dispatch(
			coachActions.editSelectedCoach({
				data: finalEditedCoach,
				id: params.coachId,
			})
		);
	};

	const handleMobileChange = (e) => {
		let value = e.target.value.split(' ').join('');
		let number;
		if (value.length === 6 && value[1] === '3' && value[0] === '1') {
			number = ''
			for (let i = 0; i < value.length; i++) {
				if (i === 2 || i === 4) {
					number += ` ${value[i]}`
				} else {
					number += `${value[i]}`
				}
			}
		} else {
			number = new AsYouType('AU').input(e.target.value)
		}
		setEditCoachData({
			...editCoachData,
			mobile_phone: number,
		});
	}
	useEffect(() => {
		setEditCoachData({
			first_name: props?.coachDetails?.first_name
				? props?.coachDetails?.first_name
				: '',
			last_name: props?.coachDetails?.last_name
				? props?.coachDetails?.last_name
				: '',
			email: props?.coachDetails?.email ? props?.coachDetails?.email : '',
			mobile_phone: props?.coachDetails?.mobile_phone
				? props?.coachDetails?.mobile_phone
				: '',
			address_1: props?.coachDetails?.street_address1 || '',
			address_2: props?.coachDetails?.street_address2 || '',
			suburb: props?.coachDetails?.suburb || '',
			state: props?.coachDetails?.state || '',
			postal_code: props?.coachDetails?.postal_code || '',
			profile_picture: props?.coachDetails?.profile_picture
				? props?.coachDetails?.profile_picture
				: '',
			coach_catgegory_id: props?.coachDetails?.coach_catgegory_id || '',
			is_active: props?.coachDetails?.is_active || '',
			equipment: props?.coachDetails?.equipment || '',
			qualifications: props?.coachDetails?.qualifications || '',
			date_of_birth: props?.coachDetails?.date_of_birth ? moment(props?.coachDetails?.date_of_birth).toDate() : '',
			workingWithChildrenDetails: props?.coachDetails?.coach_children_working_description ? props?.coachDetails?.coach_children_working_description : '',
			workingWithChildrenDate: props?.coachDetails?.coach_children_working_date ? moment(props?.coachDetails?.coach_children_working_date).toDate() : moment().toDate(),
		});
		if(props?.coachDetails?.is_active!==null){
			if(props?.coachDetails?.is_active===1){
				setIsChecked(true);
			}
			else{
				setIsChecked(false);
			}
		}
	}, [props?.coachDetails]);

	useEffect(() => {
		coachHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null);
		props.dispatch(coachActions.getCoachProfileSuper(params.coachId));
		props.dispatch(coachActions.getCoachCategories())
	}, []);

	return (
		<section>
			<div className='coach_edit_top_div'>
				<div className='left_edit_top'>
					<Link to={`/admin/coaches/coach-detail/${params.coachId}`}>
						<div className='go_back_arrow'>
							<img src={backArrow} alt='Go Back' />
						</div>
					</Link>
					<div className='bold blue font-36 text-upper float-left'>
						edit {props?.coachDetails?.first_name}
					</div>
				</div>
				{props?.coachDetailsLoading === true && (
					<div className='text-center mx-auto mt-4'>
						<CircularProgress color='primary' size={30} />
					</div>
				)}
			</div>
			<div className='coach_edit_body'>
				<div className='coach_pfp_div'>
					<div className='coach_edit_pfp'>
						<div className='delete_img_btn'>
							<img
								src={whiteX}
								alt=''
								onClick={() => {
									setPictureError("Profile picture is required");
									setEditCoachData({
										...editCoachData,
										profile_picture: '',
									});
								}}
							/>
						</div>
						{isChanged === true ? (
							<div className='coach_pfp_container'>
								<img
									src={
										editCoachData.profile_picture !== ''
											? editCoachData.profile_picture
											: insert
									}
									alt='coach pfp'
								/>
							</div>
						) : (
							<div className='coach_pfp_container'>
								<img
									src={
										editCoachData.profile_picture !== ''
											? baseURL + '/image/' + editCoachData.profile_picture
											: insert
									}
									alt='coach pfp'
								/>
							</div>
						)}
					</div>
					<button className='add_photo_btn'>
						<input
							onChange={(e) => {
								handleOnChangeImage(e);
								setChanged(true);
							}}
							type='file'
							id='file'
							name='file'
							className='custom_file_input'
						/>
						<label
							htmlFor='file'
							className='bold blue text-upper add_photo_label'
						>
							<img src={camera} alt='' />
							update photo
						</label>
					</button>
					<p className="error">{pictureError}</p>
				</div>
				<div className='coach_edit_form'>
					<form onSubmit={handleEdit}>
					<div className='edit_form_row'>
						<label className='bold blue text-upper font-24 pb-4'>Coach Info:</label>
					</div>
					<div className='edit_form_row'>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>First Name*</label>
							<input
								required
								value={editCoachData.first_name}
								onChange={(e) => {
									setEditCoachData({
										...editCoachData,
										first_name: e.target.value,
									});
								}}
								name='First Name'
								type='text'
								placeholder={props?.coachDetails?.first_name}
								className='coach_edit_input'
							/>
						</div>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Last Name*</label>
							<input
								value={editCoachData.last_name}
								onChange={(e) => {
									setEditCoachData({
										...editCoachData,
										last_name: e.target.value,
									});
								}}
								name='Last Name'
								type='text'
								placeholder={props?.coachDetails?.last_name}
								className='coach_edit_input'
								required
							/>
						</div>
					</div>
					<div className='edit_form_row'>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Email address*</label>
							<input
								value={editCoachData.email}
								onChange={(e) => {
									setEditCoachData({
										...editCoachData,
										email: e.target.value,
									});
								}}
								name='email'
								type='email'
								placeholder={props?.coachDetails?.email}
								className='coach_edit_input'
								required
							/>
						</div>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>mobile number*</label>
							{/* <InputMask */}
							<input
								required
								value={editCoachData.mobile_phone}
								onChange={(e) => handleMobileChange(e)}
								name='phone number'
								type='text'
								// maxLength='12'
								placeholder={props?.coachDetails?.mobile_phone}
								className='coach_edit_input number_input'
							/>
						</div>
					</div>
					<div className='edit_form_row'>
						<div className='coach_new_input_div font-18 pr-3'>
							<label className='bold blue text-upper'>Date Of Birth*</label>
							<DatePickerComponent
								required
								containerClass="coach_new_date coach_new_input"
								name='date_of_birth'
								selected={editCoachData.date_of_birth}
								handleDateChange={
									(e) => {
									if(!e){
										setDobError('Birth date is required');
									}
									else{
										setDobError('');
									}
									setEditCoachData({
										...editCoachData,
										date_of_birth: e,
									});
									}}
								open={isOpen}
								setIsOpenTrue={() => setIsOpen(true)}
								setIsOpenFalse={() => setIsOpen(false)}
							/>
							<p className="error">{dobError}</p>
						</div>
					</div>
					<div className='edit_form_row'>
						<label className='bold blue text-upper font-24 pb-4 pt-4'>Address Info:</label>
					</div>
					<AddressAuto
						required
						addressInfo={editCoachData}
						setAddressInfo={setEditCoachData}
						styling='member_edit'
						inputStyling='coach_edit_input'
					/>
					{/* <div className='edit_form_row'>

						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Street Address*</label>
							<input
								value={editCoachData.street}
								onChange={(e) => {
									setEditCoachData({
										...editCoachData,
										street: e.target.value,
									});
								}}
								name='Street Address'
								type='text'
								placeholder={props?.coachDetails?.street}
								className='coach_edit_input'
							/>
						</div>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Suburb*</label>
							<input
								value={editCoachData.suburb}
								onChange={(e) => {
									setEditCoachData({
										...editCoachData,
										suburb: e.target.value,
									});
								}}
								name='Suburb'
								type='text'
								placeholder={props?.coachDetails?.suburb}
								className='coach_edit_input'
							/>
						</div>
					</div> */}
					<div className='edit_form_row'>
						<label className='bold blue text-upper font-24 pb-4 pt-4'>Working with children:</label>
					</div>
					<div className='edit_form_row'>
						<div className='coach_new_input_div font-18 '>
							<label className='bold blue text-upper'>Level*</label>
							<select
								onChange={(e) => {
									setEditCoachData({
										...editCoachData,
										coach_catgegory_id: e.target.value,
									});
								}}
								name='category'
								placeholder='Category'
								className='coach_new_input'
								value={editCoachData.coach_catgegory_id}
								required
							>
								<option selected value=''>Select a level</option>
								{!props.coachCategoriesLoading && props.coachCategories.map((category)=>{
									return(
									<option value={category.id}>{category.coach_category_name}</option>
									)
								})}
							</select>
						</div>
						<div className='coach_new_input_div font-18 '>
							<label className='bold blue text-upper'>Expiration date*</label>
							{/* <div className='coach_new_date coach_new_input' style={{background:`url("${calendarImg}") no-repeat 90% #fff`}}>
									<DatePicker
										name='expirationDate'
										selected={editCoachData.workingWithChildrenDate}
										dateFormat="yyyy-MM-dd"
										// minDate={moment().toDate()}
										onChange={(e)=>setEditCoachData({...editCoachData,workingWithChildrenDate:e})}
										open={isOpen}
										onCalendarOpen={() => setIsOpen(true)}
										onCalendarClose={() => setIsOpen(false)}
									/>
								</div> */}
							<DatePickerComponent
								required
								name='expirationDate'
								containerClass="coach_new_date coach_new_input"
								selected={editCoachData.workingWithChildrenDate}
								minDate={moment().toDate()}
								handleDateChange={
									(e) => {
										if(!e){
											setExpDateError('Expiration date is required');
										}
										else{
											setExpDateError('');
										}
										setEditCoachData({ ...editCoachData, workingWithChildrenDate: e })
										
								}}
								open={isOpen}
								setIsOpenTrue={() => setIsOpen(true)}
								setIsOpenFalse={() => setIsOpen(false)}
							/>
							<p className="error">{expDateError}</p>
						</div>
					</div>
					<div className='edit_form_row'>
						<div className='coach_edit_input_div font-18 '>
							<label className='bold blue text-upper'>Description*</label>
							<textarea
								value={editCoachData.workingWithChildrenDetails}
								onChange={(e) => {
									setEditCoachData({
										...editCoachData,
										workingWithChildrenDetails: e.target.value,
									});
								}}
								name='workWithChildrenDetails'
								placeholder='Working with children details goes here ...'
								className='coach_new_input'
								rows="5"
								required
							/>
						</div>
						<div className='coach_new_input_div font-18 '>
							<label className='bold blue text-upper'>Qualifications</label>
							<textarea
								value={editCoachData.qualifications}
								onChange={(e) => {
									setEditCoachData({
										...editCoachData,
										qualifications: e.target.value,
									});
								}}
								name='qualifications'
								placeholder='Qualifications go here ...'
								className='coach_new_input'
								rows="5"
							/>
						</div>
					</div>
					<div className='edit_form_row'>
						<div className='coach_new_input_div font-18 pr-3'>
							<label className='bold blue text-upper'>Provided Equipment</label>
							<textarea
								value={editCoachData.equipment}
								onChange={(e) => {
									setEditCoachData({
										...editCoachData,
										equipment: e.target.value,
									});
								}}
								name='equipment'
								placeholder='Equipments go here ...'
								className='coach_new_input'
								rows="5"
							/>
						</div>
					</div>
					
					<div className='edit_form_row'>
						<div className='coach_new_input_div font-18' style={{display:'flex', flexDirection:'row', gap: '10px'}}>
							<label className='bold blue text-upper'>Is Active?</label>
							<img src={isChecked? checked : unchecked} onClick={handleChecked} style={{width:'18pt', height: '18pt'}}></img>
						</div>
					</div>
					<p className='error'>{addressError}</p>
					<div className='edit_button_div'>
						<div>
							<button
								type='submit'
								className='white bold text-upper save_red_button'
							>
								save
							</button>
						</div>
						<Link
							to={`/admin/coaches/coach-detail/${params.coachId}`}
							className='white bold text-upper cancel_blue_button'
						>
							cancel
						</Link>
						{props?.editCoachLoading === true && (
							<div className='text-center mx-auto mt-4'>
								<CircularProgress color='primary' size={30} />
							</div>
						)}
					</div>
					</form>
				</div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => ({
	editCoach: state.coachreducers.editCoach,
	editCoachLoading: state.coachreducers.editCoachLoading,
	coachDetails: state.coachreducers.coachDetails,
	coachDetailsLoading: state.coachreducers.coachDetailsLoading,
	coachCategoriesLoading: state.coachreducers.coach_categories_loading,
	coachCategories: state.coachreducers.coach_categories,
	user: state.register.user
});

export default connect(mapStateToProps)(CoachEdit);