import React from 'react';
import { connect } from 'react-redux';
//REDUX
import eventActions from "../../../redux/actions/eventsActions"
import popupAction from "../../../redux/actions/postsActions"

//images
import LineRedImg from '../../../assets/images/Line-red.svg';

//Components
import EventsPopUpRow from '../../../Components/Events/EventsPopUpRow';

export const EventClone = (props) => {

	const handleConfirmClone = () => {
		
		let eventsToClone = props.selectedPost.data.map(e => e.id);
		props.dispatch(eventActions.cloneEvents(eventsToClone))
		props.dispatch(popupAction.popupHandler({ type: "CLOSE" }))

		props.selectedPost.action();
	}

	return (
		<div>
			<div className='form_style'>
				<img src={LineRedImg} />
			</div>
			<div className='form_title blue font-18 bold text-upper'>
				Are you sure you want to clone this { props.selectedPost.data.length } events?
			</div>
			<div className='events_list_main_body'>
				<div className="events_list_top">
					<div className="events_table_header wi-30">EVENT TITLE</div>
					<div className="events_table_header wi-17">AGE</div>
					<div className="events_table_header wi-23">DATE TIME</div>
					<div className="events_table_header wi-30">LOCATION</div>
				</div>
				<div className='events_list_container'>
					{props?.selectedPost?.data?.map((zone, i) => {
						return(
							<EventsPopUpRow
							key={i}
							title={zone.title}
							subtitle={zone.subtitle}
							start_date={zone.start_date}
							end_date={zone.end_date}
							time_start={zone.start_time}
							time_end={zone.end_time}
							location={zone.location}
							/>
						);
						})
					}
				</div>
			</div>

			<div className='d-flex flex-row mt-5'>
				<div onClick={() => { props.dispatch(popupAction.popupHandler({ type: "CLOSE" })) }} className='white text-upper bold font-18' style={styles.blue_btn}>
					Go back
				</div>
				<div onClick={() => { handleConfirmClone() }} className='white bold text-upper font-18' style={styles.red_btn}>
					PROCEED
				</div>
			</div>
		</div>
	);
};

const styles = {
	red_btn: {
		width: '200px',
		background: '#d91c24',
		height: '70px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	blue_btn: {
		width: '200px',
		marginRight: '20px',
		background: '#262262',
		height: '70px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
};

const mapStateToProps = (state) => ({
	selectedPost: state.postReducer?.selectedPost,
});

export default connect(mapStateToProps)(EventClone);
