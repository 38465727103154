import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format';
//CSS
import './Dashboard.css';

//IMAGES
import MoneyBlue from '../../assets/images/moneyIconBlue.svg';
import arrowDownRed from '../../assets/images/arrow_red_select.svg';

//COMPONENTS
// import Chart from "./Chart"
import CircularProgress from '@material-ui/core/CircularProgress';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Autocomplete from 'react-google-autocomplete'

//REDUX
import postActions from "../../redux/actions/postsActions";
import Post from '../Noticeboard/Post';
import dashboardActions from "../../redux/actions/dashboardActions"
import moment from 'moment-timezone';

const text = 0 + '%';

const Dashboard = (props) => {
	const [membersDate, setMembersDate] = useState('week')
	const [accountDate, setAccountDate] = useState('week')
	const [locations, setLocations] = useState([])
	const [eventMetrics, setEventMetrics] = useState({
		period: 'week',
		location: '',
		program: '',
	})

	useEffect(() => {
		props.dispatch(postActions.listPosts({ thisMonth: 3, previous: 0 }));
		props.dispatch(dashboardActions.getPrograms());
	}, []);

	useEffect(() => {
		props.dispatch(dashboardActions.getMembers({ filter: membersDate }))
	}, [membersDate])

	useEffect(() => {
		props.dispatch(dashboardActions.getEventsBookingRevenue({ filter: accountDate }))
		props.dispatch(dashboardActions.getProductsSalesbyQuarters({ filter: accountDate }))
	}, [accountDate])

	useEffect(() => {
		props.dispatch(dashboardActions.getShopRevenue({ data: eventMetrics }))
	}, [eventMetrics])

	useEffect(()=>{
		let locations_array = [];
		props.programs.map((program)=>{
			if(!locations_array.includes(program.location))
				locations_array.push(program.location)
		})
		locations_array.sort();
		setLocations(locations_array);
	}, [props.programs])


	const handleRevenuePercentage = () => {
		let booking
		let shop
		if (props.eventBookingRevenue.length > 0) {
			if (props.eventBookingRevenue[0] === null) {
				booking = 0
			} else {
				booking = props.eventBookingRevenue[0]
			}
		}

		if (props.productSales.length > 0) {
			if (props.productSales[0] === null) {
				shop = 0
			} else {
				shop = props.productSales[0]
			}
		}

		return (booking / (booking + shop))
	}
	const percentage = (initial, total) => {
		let final = ((initial / total) * 100)?.toFixed(0)
		return (final)
	}
	const vacantTotal = (type) => {
		if (type === 'occupied') {
			let final = 0
			props.shopRevenue?.map((zone, i) => {
				final += zone.occupied_space
			})
			return (final)
		} else {
			let final = 0
			props.shopRevenue?.map((zone, i) => {
				final += Number(zone?.vacant_space)
			})
			return (final)
		}
	}
	return (
		<>
			<div className="" style={{ marginLeft: "10px", marginRight: "10px" }} >
				<div className='posts-section'>
					<h2 className='text-upper blue bold font-24'>recently added</h2>
					<div className='post-container row' style={{ marginLeft: "10px" }}>
						{props.postsLoading === true ? <div className='text-center mx-auto mt-4'>
							<CircularProgress color='primary' size={30} />
						</div> :
							props?.posts?.current_month?.length > 0 ? (
								props?.posts?.current_month?.map((zone, i) => {
									if (i < 3) {
										if (zone.image !== null && zone.image !== '') {
											return <Post type={1} all={zone} key={i} />;
										} else {
											return <Post type={2} all={zone} key={i} />;
										}
									}
								})
							) : <h2 className='no_item_available'>No posts available</h2>
						}
					</div>
				</div>
			</div>
			{/* <section className='main-dash-detail row'> */}

			<section className='row' style={{ marginTop: "10px" }}>
				<div className='members-section col'>
					<div className='member-header-div'>
						<span className='blue bold text-upper font-24'>members</span>
						<div>
							<select onChange={(e) => { setMembersDate(e.target.value) }} name='' id='' className='time-selector font-16 pointer' style={{ background: `url(${arrowDownRed}) no-repeat right #fff`, paddingRight: '1.5rem' }}>
								<option value='week'>This Week</option>
								<option value='month'>This Month</option>
								<option value='year'>This Year</option>
							</select>
							{/* <img src={arrowDownRed} alt='' /> */}
						</div>
					</div>
					<div className='member-data-div row'>
						<div style={{ width: '230px' }}>
							<CircularProgressbar
								styles={styles.circularProgres}
								value={props.membersDashboard?.parent_bookin_count / props.membersDashboard?.total_parent_count}
								maxValue={1}
								text={
									<tspan style={{ fontSize: '24', fontWeight: '600' }}>
										{props.membersDashboard?.parent_bookin_count ? `${((props.membersDashboard.parent_bookin_count / props.membersDashboard.total_parent_count) * 100).toFixed(0)}%` : '0%'}
										<tspan x='50%' dx='0' dy='12' style={{ fontSize: '5' }}>
											Members
										</tspan>
									</tspan>
								}
								counterClockwise={true}
							/>
						</div>
						<div className='member-numbers-container col'>
							<div className='member-number-text'>
								<span style={styles.membersHeader}>{props.membersDashboard.parent_bookin_count}{props.membersDashboard.parent_bookin_count===1?<span> MEMBER</span> : <span> MEMBERS</span>}</span>
								<span style={styles.membersHeader1}>{props.membersDashboard.kids_count}{props.membersDashboard.kids_count===1?<span> KID</span> : <span> KIDS</span>} </span>
							</div>
						</div>
					</div>
				</div>

				<div className='account-section col' >
					<div className='account-header-div'>
						<span className='blue bold text-upper font-24'>account</span>
						<div>
							<select name='' id='' onChange={(e) => { setAccountDate(e.target.value) }} className='time-selector font-16 pointer' style={{ background: `url(${arrowDownRed}) no-repeat right #fff`, paddingRight: '1.5rem' }}>
								<option value='week'>This Week</option>
								<option value='month'>This Month</option>
								<option value='year'>This Year</option>
							</select>
							{/* <img src={arrowDownRed} alt='' /> */}
						</div>
					</div>
					<div className='account-data-div row'>
						<div style={{ width: '230px', position: 'relative' }}>
							<CircularProgressbar
								styles={styles.circularProgres1}
								value={handleRevenuePercentage()}
								maxValue={1}
								text={
									<tspan
										x='50%'
										dx='0'
										dy='6'
										style={{ fontSize: '10px', fontWeight: '600' }}
									>
										{accountDate === 'year' ? (moment().tz('Australia/Sydney').format('YYYY')) : (moment().tz('Australia/Sydney').format('MMMM YYYY'))}

									</tspan>
								}
								counterClockwise={true}
							/>
							<img
								style={{
									position: 'absolute',
									top: 70,
									left: '50%',
									transform: 'translateX(-30%)',
									width: '34.5px',
								}}
								src={MoneyBlue}
								alt=''
							/>
						</div>
						<div className='account-numbers-container col'>
							<div className='event-numbers'>
								<span style={{ color: '#262262', fontSize: '14px' }}>
									Event Bookings
								</span>
								<span
									style={{
										color: '#262262',
										fontSize: '28px',
										fontWeight: 'bold',
									}}
								>
									{props.eventBookingRevenue.length > 0 ?
										props.eventBookingRevenue[0] === null ?
											<NumberFormat value={Number(0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> :
											<NumberFormat value={Number(props.eventBookingRevenue[0]).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
										: <NumberFormat value={Number(0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />}

								</span>
							</div>
							<div className='sales-numbers'>
								<span
									style={{
										color: '#D91C24',
										fontSize: '14px',
									}}
								>
									Shop Sales
								</span>
								<span
									style={{
										color: '#D91C24',
										fontSize: '28px',
										fontWeight: 'bold',
									}}
								>
									{props.productSales.length > 0 ?
										props.productSales[0] === null ?
										<NumberFormat value={Number(0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> :
										<NumberFormat value={Number(props.productSales[0]).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
										: <NumberFormat value={Number(0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />}
								</span>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='events-metric'>
				<div className='event-metric-header-div'>
					<span className='blue bold text-upper font-24'>
						Events OCCUPANCY METRICS
					</span>
					<div className="filters_container">
						<div style={{display: 'flex', flexDirection: 'column'}}>
						<label style={{color:'#D91C24', fontWeight:'bold'}}>Program</label>
						{props.programs&&
						<select name='programs' id='dashboard_programs' className='time-selector font-16 pointer' style={{ background: `url(${arrowDownRed}) no-repeat right #fff`, paddingRight: '1.5rem'}} onChange={(e) => {setEventMetrics({...eventMetrics, program: e.target.value})}}>
							<option value=''>All Programs</option>
							{props.programs.map((program)=>{
								return(
								<option value={program.id}>{program.title}</option>
								)
							})}
						</select>
						}
						</div>
						<div style={{display: 'flex', flexDirection: 'column'}}>
						<label style={{color:'#D91C24', fontWeight:'bold'}}>Period</label>
						<select name='' id='' className='time-selector font-16 pointer' onChange={(e) => {setEventMetrics({...eventMetrics, period: e.target.value})}} style={{ background: `url(${arrowDownRed}) no-repeat right #fff`}}>
							<option value='week'>This Week</option>
							<option value='month'>This Month</option>
							<option value='year'>This Year</option>
						</select>
						</div>
						<div style={{display: 'flex', flexDirection: 'column'}}>
							<label style={{color:'#D91C24', fontWeight:'bold'}}>Location</label>
							{locations&&
							<select name='locations' id='dashboard_locations' className='time-selector font-16 pointer' style={{ background: `url(${arrowDownRed}) no-repeat right #fff`, paddingRight: '1.5rem'}} onChange={(e) => {setEventMetrics({...eventMetrics, location: e.target.value})}}>
								<option value=''>All Locations</option>
								{locations.map((location)=>{
									return(
									<option value={location}>{location}</option>
									)
								})}
							</select>}
						</div>
						{/* <img src={arrowDownRed} alt='' /> */}
					</div>
				</div>
				<div className='event-metric-data-container row'>
					<div className="col" style={{ height: "300px", display: "flex", position: 'relative' }}>
						<hr style={{ borderTop: "1px dashed  #A8A8A8", position: "absolute", left: 0, right: 0, top: 0 }} />
						<hr style={{ borderTop: "1px dashed  #A8A8A8", position: "absolute", left: 0, right: 0, top: "75%" }} />
						<hr style={{ borderTop: "1px dashed  #A8A8A8", position: "absolute", left: 0, right: 0, top: "50%" }} />
						<hr style={{ borderTop: "1px dashed  #A8A8A8", position: "absolute", left: 0, right: 0, top: "25%" }} />
						<div className='w-100' style={{ height: "300px", display: "flex", position: 'absolute', overflowX: "auto" }}>
							{props.shopRevenue?.map((zone, i) => {
								return (
									<div key={i} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginLeft: "2px", marginRight: "2px", minWidth: '10%' }}>
										<div style={{ backgroundColor: '#F2F3F4', width: '9px', height: '100%', borderRadius: "8px", zIndex: 1, display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "flex-end" }}>
											<div style={{ backgroundColor: '#262262', width: '9px', height: `${percentage(zone.vacant_space, zone.available_space)}%`, borderRadius: "8px", zIndex: 2, marginBottom: -3 }}>
											</div>
											<div style={{ backgroundColor: '#D91C24', width: '9px', height: `${percentage(zone.occupied_space, zone.available_space) <= 1 ? 2 : percentage(zone.occupied_space, zone.available_space)}%`, borderRadius: "8px", zIndex: 1 }}>
											</div>
										</div>
										<div title={zone.title}>
											{zone.title.length>5?zone.title.substring(0,5):zone.title}
										</div>
									</div>
								)
							})}
						</div>

					</div>
					<div className='metric-numbered-data col-md-2'>
						<div className='metric-data-circles'>
							<div style={styles.circleOccupancyMetrics}>
								<p style={styles.circleTextMetrics1Type1}>Vacant</p>
								<p style={styles.circleTextMetrics2Type1}>{<NumberFormat value={vacantTotal('Vacant')} displayType={'text'} thousandSeparator={true}/>}</p>
							</div>
							<div style={styles.circleOccupancyMetrics}>
								<p style={styles.circleTextMetrics1Type2}>Booked</p>
								<p style={styles.circleTextMetrics2Type2}>{<NumberFormat value={vacantTotal('occupied')} displayType={'text'} thousandSeparator={true}/>}</p>
							</div>
						</div>
						{/* <div className='metric-data-legend'>
							<div style={styles.miniCircle1}></div>
							<span style={styles.smallCircleText}>Available</span>
							<div style={styles.miniCircle2}></div>
							<span style={styles.smallCircleText}>Occupied</span>
						</div> */}
					</div>
				</div>
			</section>
		</>
	);
};

const styles = {
	circularProgres: {
		root: { width: '100%' },
		path: {
			strokeLinecap: 'butt',
			stroke: '#262262',
		},
		text: {
			fill: '#D91C24',
		},
		trail: {
			stroke: '#BFBDD6',
		},
	},
	circularProgres1: {
		root: { width: '100%' },
		path: {
			strokeLinecap: 'butt',
			stroke: '#262262',
		},
		text: {
			fill: '#262262',
			marginTop: '10px',
		},
		trail: {
			stroke: '#D91C24',
		},
	},
	circle: {
		margin: '5px 5px 5px 0px',
		height: '96px',
		width: '96px',
		backgroundColor: '#262262',
		borderRadius: '50%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: 23,
		alignItems: 'center',
		color: '#fff',
	},
	circle1: {
		margin: '5px',
		height: '96px',
		width: '96px',
		backgroundColor: '#514C91',
		borderRadius: '50%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: 23,
		alignItems: 'center',
		color: '#fff',
	},
	circleOccupancyMetrics: {
		margin: '5px',
		height: '96px',
		width: '96px',
		backgroundColor: '#F2F2F2',
		borderRadius: '50%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: 23,
		alignItems: 'center',
	},
	miniCircle1: {
		height: '19px',
		width: '19px',
		backgroundColor: '#262262',
		borderRadius: '50%',
	},
	miniCircle2: {
		height: '19px',
		width: '19px',
		backgroundColor: '#D91C24',
		borderRadius: '50%',
	},
	circleTextMetrics1Type1: {
		fontSize: '12px',
		fontStyle: 'bold',
		margin: 0,
		color: '#262262',
	},
	circleTextMetrics2Type1: {
		fontSize: '45px',
		fontStyle: 'bold',
		margin: 0,
		color: '#262262',
	},
	circleTextMetrics1Type2: {
		fontSize: '12px',
		fontStyle: 'bold',
		margin: 0,
		color: '#D91C24',
	},
	circleTextMetrics2Type2: {
		fontSize: '45px',
		fontStyle: 'bold',
		margin: 0,
		color: '#D91C24',
	},
	circleText1: {
		fontSize: '10px',
		fontStyle: 'bold',
		margin: 0,
	},
	circleText2: {
		fontSize: '36px',
		fontStyle: 'bold',
		margin: 0,
	},
	membersHeader: {
		fontSize: '21px',
		fontWeight: 'bold',
		color: '#262262',
	},
	membersHeader1: {
		fontSize: '21px',
		fontWeight: 'bold',
		color: '#D91C24',
	},
	smallCircleText: {
		marginLeft: "6px",
		marginRight: "6px",
		fontSize: '13px',
		color: '#262262',
	},
};

const mapStateToProps = (state) => ({
	posts: state.postReducer.posts,
	postsLoading: state.postReducer.postsLoading,

	membersDashboard: state.dashboard.membersDashboard,
	membersDashboardLoading: state.dashboard.membersDashboardLoading,

	eventBookingRevenue: state.dashboard.eventBookingRevenue,
	eventBookingRevenueLoading: state.dashboard.eventBookingRevenueLoading,

	shopRevenue: state.dashboard.shopRevenue,
	shopRevenueLoading: state.dashboard.shopRevenueLoading,

	productSales: state.dashboard.productSales,
	productSalesLoading: state.dashboard.productSalesLoading,

	programs: state.dashboard.programs,
})

export default connect(mapStateToProps)(Dashboard)

