import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { coachHandle } from '../../../utils/global';
import queryString from 'query-string';
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import coachActions from '../../../redux/actions/coachActions';

//images
import plus from '../../../assets/images/click-plus.svg';

//components
import CoachListRow from '../../../Components/Coach/CoachListRow';

//Redux
import membersAction from "../../../redux/actions/membersAction";

import './CoachList.scss';


const useStyles = makeStyles((theme) => ({
	root: {
	  "& > *": {
		marginTop: theme.spacing(2),
	  },
	},
  }))

function CoachList(props) {
	let history = useHistory();
	const classes = useStyles();

	const [coachList, setCoachList] = useState([props?.listCoach]);
	const [searchQuery, setSearchQuery] = useState({
		value:'',
		current:'',
	})
	const [isSearch, setIsSearch] = useState(false)
	const [numOfPages, setNumOfPages] = useState([])

	useEffect(() => {
		if(!isSearch){
			let pages=[]
			for (let i = 1; i <= props?.listCoach?.last_page; i++) {
				pages.push(i);
			}
			setNumOfPages(pages)
			let finalData = []
			props?.listCoach?.data?.map(zone => {
				let events = []
				zone.assigned_event_programs?.map((zone1) => {
					if (events.length === 0) {
						events.push({
							description: zone1?.event?.description,
							id: zone1?.event?.id,
							location: zone1?.event?.location,
							title: zone1?.event?.title,
						})
					} else {
						let counter = 0
						events.map((zone2) => {
							if (zone2?.id === zone1?.event?.id) {
								counter++
							}
						})
						if (counter <= 1) {
							events.push({
								description: zone1?.event?.description,
								id: zone1?.event?.id,
								location: zone1?.event?.location,
								title: zone1?.event?.title,
							})
						}
					}
				})
				finalData.push({
					events: events,
					email: zone?.email,
					first_name: zone?.first_name,
					id: zone?.id,
					last_name: zone?.last_name,
					mobile_phone: zone?.mobile_phone,
					profile_picture: zone?.profile_picture,
					street: zone?.street,
					suburb: zone?.suburb,
					uuid: zone?.uuid,
					description: zone?.coach_children_working_description
				})
			})
			setCoachList(finalData)
		}
	}, [props.listCoach,isSearch]);
	
	useEffect(() => {
		if(isSearch){
			let pages=[]
			for(let i=1 ; i<= props?.searchCoachData?.last_page; i++){
				pages.push(i);
			}
			setNumOfPages(pages)
			let finalData = []
			props?.searchCoachData?.data?.map(zone => {
				let events = []
				zone.assigned_event_programs?.map((zone1) => {
					if (events.length === 0) {
						events.push({
							description: zone1?.event?.description,
							id: zone1?.event?.id,
							location: zone1?.event?.location,
							title: zone1?.event?.title,
						})
					} else {
						let counter = 0
						events.map((zone2) => {
							if (zone2?.id === zone1?.event?.id) {
								counter++
							}
						})
						if (counter <= 1) {
							events.push({
								description: zone1?.event?.description,
								id: zone1?.event?.id,
								location: zone1?.event?.location,
								title: zone1?.event?.title,
							})
						}
					}
				})
				finalData.push({
					events: events,
					email: zone?.email,
					first_name: zone?.first_name,
					id: zone?.id,
					last_name: zone?.last_name,
					mobile_phone: zone?.mobile_phone,
					profile_picture: zone?.profile_picture,
					street: zone?.street,
					suburb: zone?.suburb,
					uuid: zone?.uuid,
					description: zone?.coach_children_working_description
				})
			})
			setCoachList(finalData)
		}
		
	}, [props.searchCoachData,isSearch])

	useEffect(() => {
		if(isSearch){
			props.dispatch(coachActions.coachSearch({query:queryString.parse(props.location.search).query, page:queryString.parse(props.location.search).page}));
		}else{
			props.dispatch(coachActions.listCoaches(queryString.parse(props.location.search).page))
		}
	}, [queryString.parse(props.location.search).page, queryString.parse(props.location.search).query]);

	useEffect(() => {
		coachHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null);
		props.dispatch(coachActions.resetCoachSearch());
	}, []);

	const handleSearch = (e) => {
		setIsSearch(true);
		setSearchQuery({...searchQuery,current:searchQuery.value});
		// props.dispatch(coachActions.coachSearch({query:searchQuery.value,page:1}));
		history.push({
			pathname: '/admin/coaches',
			search: `?page=${1}&&query=${searchQuery.value}`,
		});
	};

	const handleNewPagination = (e, selectedPage) =>{
		props.dispatch(membersAction.changePageState(selectedPage));
		if (isSearch) {
			history.push({
			  pathname: "/admin/coaches",
			  search: `?page=${selectedPage}&&query=${searchQuery.current}`,
			});
		  } else {
			history.push({
			  pathname: "/admin/coaches",
			  search: `?page=${selectedPage}`,
			});
		  }
	  };


	return (
		<section>
			<div className='coach_top_div'>
				<div className='bold blue font-36 text-upper float-left'>coaches</div>
				<div className='coach_form_div'>
					{isSearch&&
					(<div className="mr-3">
						<div>
							SHOWING RESULTS FOR "{searchQuery.current}"
						</div>
						<div className="search_cancel">
							<span className="pointer" onClick={() => {
										props.dispatch(membersAction.changePageState(1));
										setIsSearch(false);
										setSearchQuery({...searchQuery, value:''})
									}}>
								CANCEL		
							</span>
						</div>
					</div>)}
					<div className='seach_bar'>
						<input className='search_button' type='search' onClick={()=>handleSearch()} />
						<input
							type='text'
							placeholder='Search for Coaches...'
							className='search_input'
							value={searchQuery.value}
							onChange={(e) => {
								setSearchQuery({...searchQuery,value:e.target.value});
							}}
						/>
					</div>
					<div style={{cursor: 'pointer'}} onClick={(e) => history.push("/admin/coaches/new-coach")} className='coach_add_new bold'>
						<img src={plus} alt='' />
						<span>Add New</span>
					</div>
				</div>
			</div>
			<div className='coach_list_div'>
				<div className='coach_list_top'>
					<div className='coach_table_header photo_head'>PHOTO</div>
					<div className='coach_table_header name_head'>NAME</div>
					<div className='coach_table_header last_head'>FAMILY NAME</div>
					<div className='coach_table_header detail_head'>DETAILS</div>
					<div className='coach_table_header event_head'>ASSIGNED EVENTS</div>
					<div className='coach_table_header view_head'>VIEW/EDIT</div>
				</div>
				<div className='coach_list_container'>
					{props.listCoachLoading ? (
						<div className='text-center mx-auto mt-4'>
							<CircularProgress color='primary' size={30} />
						</div>
					) : (
						coachList?.map((zone, i) => {
							let listOfEvents=[];
							zone?.events?.map((event,index)=>{
								if(index===0){
									listOfEvents.push(event)
								}else{
									let exist=false;
									listOfEvents?.map(x=>{
										if(x.id===event.id){
											exist=true;
										}
									})
									if(!exist){
										listOfEvents.push(event)
									}
								}
							})
							
							let data = (
								<CoachListRow
									key={i}
									image={zone.profile_picture}
									first_name={zone.first_name}
									last_name={zone.last_name}
									email={zone.email}
									link={zone.uuid}
									mobile_phone={zone.mobile_phone}
									street={zone.street}
									suburb={zone.suburb}
									description={zone.description}
									event={listOfEvents}
								/>
							);
							return data;
						})
					)}
				</div>
				<div className={classes.root}>
          			<Pagination count={numOfPages?.length} defaultPage={ queryString.parse(props.location.search).page? parseInt(queryString.parse(props.location.search).page) : 1 } shape="rounded" onChange={handleNewPagination} />
       			 </div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => ({
	listCoach: state.coachreducers.listCoach,
	listCoachLoading: state.coachreducers.listCoachLoading,
	user: state.register.user,
	searchCoachData:state.coachreducers.searchCoachData,
	searchCoachLoading:state.coachreducers.searchCoachLoading,
	selectedPage: state.membersReducers.selectedPage,
});

export default connect(mapStateToProps)(CoachList);
