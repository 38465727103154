import {
	LIST_COACH_REQUEST,
	LIST_COACH_SUCCESS,
	LIST_COACH_ERROR,
	NEW_COACH_REQUEST,
	NEW_COACH_SUCCESS,
	NEW_COACH_ERROR,
	EDIT_COACH_REQUEST,
	EDIT_COACH_SUCCESS,
	EDIT_COACH_ERROR,
	COACH_DETAILS_REQUEST,
	COACH_DETAILS_SUCCESS,
	COACH_DETAILS_ERROR,
	COACH_EVENT_DETAIL_REQUEST,
	COACH_EVENT_DETAIL_SUCCESS,
	COACH_EVENT_DETAIL_ERROR,
	COACH_SEARCH_REQUEST,
	COACH_SEARCH_SUCCESS,
	COACH_SEARCH_ERROR,
	CLEAR_SEARCH,
	CLEAR_COACH_EVENT_DETAILS,
	GET_ASSIGNED_EVENTS_REQUEST,
	GET_ASSIGNED_EVENTS_SUCCESS,
	GET_ASSIGNED_EVENTS_ERROR,
	REMOVE_COACH_ASSIGNMENT_REQUEST,
	REMOVE_COACH_ASSIGNMENT_SUCCESS,
	REMOVE_COACH_ASSIGNMENT_ERROR,
	COACH_CATEGORIES_REQUEST,
	COACH_CATEGORIES_ERROR,
	COACH_CATEGORIES_SUCCESS
} from '../actions/coachActions';

const initialState = {
	listCoach: [],
	listCoachLoading: false,
	newCoach: [],
	newCoachLoading: false,
	editCoach: [],
	editCoachLoading: false,
	coachDetails: [],
	coachDetailsLoading: false,
	coachEventById: [],
	coachEventByIdLoading: false,

	searchCoachLoading:false,
	searchCoachData:[],

	coachAssignedEvents:[],
	coachAssignedEventsLoading:false,
	coachAssignmentRevokingLoading:false,

	coach_categories_loading: false,
	coach_categories: [],
};

function coach(state = initialState, action) {
	switch (action.type) {
		case LIST_COACH_REQUEST:
			return Object.assign({}, state, {
				listCoachLoading: true,
				listCoach: [],
			});
		case LIST_COACH_SUCCESS:
			return Object.assign({}, state, {
				listCoachLoading: false,
				listCoach: action.payload.data,
			});
		case LIST_COACH_ERROR:
			return Object.assign({}, state, {
				listCoachLoading: false,
				listCoach: [],
			});
		case NEW_COACH_REQUEST:
			return Object.assign({}, state, {
				newCoachLoading: true,
			});

		case NEW_COACH_SUCCESS:
			return Object.assign({}, state, {
				newCoach: action.payload.data,
				newCoachLoading: false,
			});

		case NEW_COACH_ERROR:
			return Object.assign({}, state, {
				newCoach: action.payload,
				newCoachLoading: false,
			});
		case COACH_DETAILS_REQUEST:
			return Object.assign({}, state, {
				coachDetails: [],
				coachDetailsLoading: true,
			});
		case COACH_DETAILS_SUCCESS:
			return Object.assign({}, state, {
				coachDetails: action.payload.data,
				coachDetailsLoading: false,
			});
		case COACH_DETAILS_ERROR:
			return Object.assign({}, state, {
				coachDetails: action.payload.message,
				coachDetailsLoading: false,
			});
		case EDIT_COACH_REQUEST:
			return Object.assign({}, state, {
				editCoach: [],
				editCoachLoading: true,
			});
		case EDIT_COACH_SUCCESS:
			return Object.assign({}, state, {
				editCoach: action.payload.data,
				editCoachLoading: false,
			});
		case EDIT_COACH_ERROR:
			return Object.assign({}, state, {
				editCoach: action.payload.message,
				editCoachLoading: false,
			});
		case CLEAR_COACH_EVENT_DETAILS:
			return Object.assign({},state,{
				coachEventById:[],
			})
		case COACH_EVENT_DETAIL_REQUEST:
			return Object.assign({}, state, {
				coachEventById: [],
				coachEventByIdLoading: true,
			});
		case COACH_EVENT_DETAIL_SUCCESS:
			return Object.assign({}, state, {
				coachEventById: action.payload.data,
				coachEventByIdLoading: false,
			});
		case COACH_EVENT_DETAIL_ERROR:
			return Object.assign({}, state, {
				coachEventById: action.payload.message,
				coachEventByIdLoading: false,
			});
		case COACH_SEARCH_REQUEST:
			return Object.assign({}, state, {
				searchCoachLoading: true,
				searchCoachData: [],
			});
		case COACH_SEARCH_SUCCESS:
			return Object.assign({}, state, {
				searchCoachLoading: false,
				searchCoachData: action.payload.data,
			});
		case COACH_SEARCH_ERROR:
			return Object.assign({}, state, {
				searchCoachLoading: false,
				searchCoachData: [],
			});
			
		case CLEAR_SEARCH:
			return Object.assign({}, state, {
				searchCoachLoading: false,
				searchCoachData: [],
			});
			

			
			
			
		case GET_ASSIGNED_EVENTS_REQUEST:
			return Object.assign({}, state, {
				coachAssignedEvents: [],
				coachAssignedEventsLoading: true,
			});
		case GET_ASSIGNED_EVENTS_SUCCESS:
			return Object.assign({}, state, {
				coachAssignedEvents: action.payload.data,
				coachAssignedEventsLoading: false,
			});
		case GET_ASSIGNED_EVENTS_ERROR:
			return Object.assign({}, state, {
				coachAssignedEvents: [],
				coachAssignedEventsLoading:false,
			});


		case REMOVE_COACH_ASSIGNMENT_REQUEST:
			return Object.assign({}, state, {
				coachAssignmentRevokingLoading: true,
			});
		case REMOVE_COACH_ASSIGNMENT_SUCCESS:
			return Object.assign({}, state, {
				coachAssignmentRevokingLoading: false,
			});
		case REMOVE_COACH_ASSIGNMENT_ERROR:
			return Object.assign({}, state, {
				coachAssignmentRevokingLoading:false,
			});

		case COACH_CATEGORIES_REQUEST:
			return Object.assign({}, state, {
				coach_categories_loading:true,
				coach_categories: [],
			});

		case COACH_CATEGORIES_SUCCESS:
			return Object.assign({}, state, {
				coach_categories_loading:false,
				coach_categories: action.data,
			});

		case COACH_CATEGORIES_ERROR:
			return Object.assign({}, state, {
				coach_categories_loading:false,
				coach_categories: [],
			});
				
				
				
		default:
			return state;
		}
	}
			
export default coach;
