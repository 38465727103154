import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import LineRedImg from '../../../assets/images/Line-red.svg';
// import { toast } from 'react-toastify';

// import "./Program.scss"
import './CustomRec.scss';

//REDUX
import popupAction from '../../../redux/actions/postsActions';
import eventPostAction from '../../../redux/actions/eventsActions';

import checkBoxChecked from '../../../assets/images/CheckBoxChecked.png';
import checkBoxUnchecked from '../../../assets/images/CheckBoxUnchecked.png';
import DatePickerComponent from '../../../Components/DatePickerComponent/DatePickerComponent';
import moment from 'moment';

export const Program = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [program, setProgram] = useState({
		title: '',
		ticket_price: '',
		name: '',
		number_of_days: 1,
		is_akv_allowed: 0,
		discount: 0,
		from: null,
		to: null,
	});

	const handleDataChange = (e) => {
		setProgram({
			...program,
			[e.target.name]: e.target.name === 'ticket_price' ? e.target.value === 0 ? 0 : Math.abs(Number(e.target.value)) : e.target.value,
		});
	};

	const submitProgramData = () => {
		if (props.type === 'EDIT_EVENT_PROG_ADD') {
			let finaldata = {
				title: program.title,
				ticket_price: program.ticket_price === 0 || program.ticket_price === '' ? 0 : Math.abs(program.ticket_price),
				name: program.name,
				number_of_days: props.formData?.is_full_day_event === 1 ? 1 : program?.number_of_days,
				is_akv_allowed: program.is_akv_allowed,
				event_id: props.selectedPost,
				from: program?.from,
				to: program?.to,
				discount: program?.discount,
				ptype: 'new',
			}
			props.dispatch(
				eventPostAction.handleAddEventProgram({
					program: finaldata,
					formData: props.formData,
				})
			);

		} else {
			let final = {
				title: program.title,
				ticket_price: program.ticket_price === 0 || program.ticket_price === '' ? 0 : Math.abs(program.ticket_price),
				name: program.name,
				number_of_days: program?.number_of_days,
				from: program?.from,
				to: program?.to,
				discount: program?.discount,
				is_akv_allowed: program.is_akv_allowed
			}
			props.dispatch(
				eventPostAction.handleAddEventProgram({
					program: final,
					formData: props.formData,
				})
			);
		}
		props.dispatch(popupAction.popupHandler({ type: 'CLOSE' }));
	};

	const submitProgramDataEdit = () => {
		if (props.type === 'EDIT_EVENT_PROG_EDIT') {
			let finaldata = {
				title: program.title,
				ticket_price: program.ticket_price === 0 || program.ticket_price === '' ? 0 : Math.abs(program.ticket_price),
				name: program.name,
				number_of_days: props.formData?.is_full_day_event === 1 ? 1 : program?.number_of_days,
				is_akv_allowed: program.is_akv_allowed,
				from: program?.from,
				to: program?.to,
				discount: program?.discount,
				event_id: props.selectedPost.event_id,
				id: props.selectedPost.id,
				ptype: props.selectedPost.ptype,
			}
			props.dispatch(
				eventPostAction.handleEditEventProgram({
					program: finaldata,
					formData: props.formData,
					id: props.selectedPost.id,
					type: 'edit'
				})
			);
			// toast.success('Program edited')
		} else {
			let finalData = {
				title: program.title,
				ticket_price: program.ticket_price === 0 || program.ticket_price === '' ? 0 : Math.abs(program.ticket_price),
				name: program.name,
				from: program?.from,
				to: program?.to,
				discount: program?.discount,
				number_of_days: props.formData?.is_full_day_event === 1 ? 1 : program?.number_of_days,
				is_akv_allowed: program.is_akv_allowed,
			}
			props.dispatch(
				eventPostAction.handleEditEventProgram({
					program: finalData,
					formData: props.formData,
					id: props.selectedPost.id,
					type: 'add'
				})
			)
			// toast.success('Program edited')
		}

		props.dispatch(popupAction.popupHandler({ type: 'CLOSE' }));
	};
	useEffect(() => {
		if (props.type === 'EDIT') {
			setProgram({
				...program,
				title: props.selectedPost.title,
				ticket_price: parseInt(props.selectedPost.ticket_price?.props?.value),
				name: props.selectedPost.name,
				number_of_days: props.selectedPost.number_of_days,
				is_akv_allowed: props.selectedPost.is_akv_allowed,
				from: props?.selectedPost?.from,
				to: props?.selectedPost?.to,
				discount: props?.selectedPost?.discount,
			});
		} else if (props.type === 'EDIT_EVENT_PROG_EDIT') {
			setProgram({
				...program,
				title: props.selectedPost.title,
				ticket_price: parseInt(props.selectedPost.ticket_price?.props?.value),
				is_akv_allowed: props.selectedPost.is_akv_allowed,
				name: props.selectedPost.name,
				number_of_days: props.selectedPost.number_of_days,
				from: props?.selectedPost?.from,
				to: props?.selectedPost?.to,
				discount: props?.selectedPost?.discount,
				ptype: props.selectedPost.ptype,
				id: props.selectedPost.id,
				event_id: props.selectedPost.event_id,
			});
		}
	}, [props.selectedPost]);
	return (
		<div>
			<form
				onSubmit={
					props.type === 'EDIT' || props.type === 'EDIT_EVENT_PROG_EDIT' ? submitProgramDataEdit : submitProgramData
				}
			>
				<div className='form_style'>
					<img src={LineRedImg} />
				</div>
				<div className='title_form blue font-36 bold text-upper'>
					Add event program
				</div>
				<div className='text_one_form white regular font-16 pt-40'>
					<label className="program_labels">Program Title *</label>
					<input
						type='text'
						required
						value={program.title}
						className='form_two blue'
						name='title'
						onChange={(e) => handleDataChange(e)}
						placeholder='Program title'
					></input>
				</div>
				<div className='text_one_form regular font-16 pt-3'>
					<label className="program_labels">Program Name *</label>
					<input
						type='text'
						required
						name='name'
						value={program.name}
						onChange={(e) => handleDataChange(e)}
						className='form_two blue'
						placeholder='Program Name'
					></input>
				</div>
				<div className='text_one_form regular font-16 pt-3'>
					<label className="program_labels">Program Tickets Price *</label>
					<input
						type='number'
						required
						value={program.ticket_price}
						onChange={(e) => handleDataChange(e)}
						name='ticket_price'
						className='form_two blue'
						placeholder='Program Tickets price'
					></input>
				</div>
				<div className='text_one_form regular font-16 pt-3'>
					<label className="program_labels">Number of Selections(day/week) *</label>
					<input
						type='number'
						value={props.formData?.is_full_day_event === 1 ? 1 : program?.number_of_days}
						onChange={(e) => handleDataChange(e)}
						name='number_of_days'
						className='form_two blue'
						placeholder='Number Of Selections (day/week)'
						max={props.rec_count}
						min={1}
					></input>
				</div>
				{props.rec_count<program?.number_of_days && <p className='error font-14'>Number of days cannot exceed the maximum</p>}
				<div className='akv_part text_one_form regular font-16 pt-3'>
					{program.is_akv_allowed === 1 ?
						<div onClick={() => setProgram({ ...program, is_akv_allowed: 0 })} className='radio_btn'>
							<img src={checkBoxChecked} alt='' />
							<label className=' blue'>Active Kids Voucher</label>
						</div>
						:
						<div onClick={() => setProgram({ ...program, is_akv_allowed: 1 })} className='radio_btn'>
							<img src={checkBoxUnchecked} alt='' />
							<label className=' blue'>Active Kids Voucher</label>
						</div>
					}
				</div>
				<div className='title_form blue font-36 bold text-upper'>
					Program Special Offer
				</div>
				<div className='offer_dates'>
				<div className='text_one_form regular font-16 pt-3'>
					<label className="program_labels">Start date</label>
					<DatePickerComponent
						containerClass="event_calendar"
						placeholder='Start Date'
						selected={program?.from!== null ? moment(program?.from).toDate() : ''}
						minDate={moment().toDate()}
						maxDate={program?.to!== null ? moment(program?.to).toDate() : ''}
						handleDateChange={(e) => {
							setProgram({
								...program,
								from: e,
							})
						}}
						open={isOpen}
						setIsOpenTrue={() => setIsOpen(true)}
						setIsOpenFalse={() => setIsOpen(false)}
					/>
				</div>
				<div className='text_one_form regular font-16 pt-3'>
					<label className="program_labels">End date</label>
					<DatePickerComponent
						containerClass="event_calendar"
						placeholder='End Date'
						selected={program?.to!== null ? moment(program?.to).toDate() : ''}
						minDate={program?.from!== null ? moment(program?.from).toDate() : ''}
						handleDateChange={(e) => {
							setProgram({
								...program,
								to: e,
							})
						}}
						open={isOpen}
						setIsOpenTrue={() => setIsOpen(true)}
						setIsOpenFalse={() => setIsOpen(false)}
					/>
				</div>
				<div className='text_one_form regular font-16 pt-3'>
					<label className="program_labels">Amount</label>
					<input
						onChange={(e) => {
							setProgram({
								...program,
								discount: e.target.value
							})
						}}
						max={program?.ticket_price}
						name='discount'
						type='number'
						placeholder='Amount'
						value={program?.discount}
						className='number_input'
					></input>
				</div>
				</div>

				
				<div className='d-inline-block pt-4 all_head'>
					<button
						type="submit"
						className='d-inline-block red-bg text-center text-upper button_edit white font-14'
					>
						Save
					</button>
				</div>
			</form>
		</div>
	);
};

const mapStateToProps = (state) => ({
	formData: state.eventReducer.formData,
	rec_count: state.eventReducer.rec_count,
	selectedPost: state.postReducer.selectedPost,
});

export default connect(mapStateToProps)(Program);
