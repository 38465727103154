import React, { Fragment } from "react";

//images
import holidayCampBanner from "./pdfAsseets/Holiday-Camp-banner.png";
import logo from "./pdfAsseets/logo.png";
import basketball from "./pdfAsseets/basketball.png";
import fb from "./pdfAsseets/fb.png";
import twitter from "./pdfAsseets/twitter.png";
import insta from "./pdfAsseets/insta.png";
import divider from "./pdfAsseets/divider.png";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: "auto",
    marginRight: "auto",
  },
  titleContainer: {
    flexDirection: "row",
    marginTop: 24,
  },
  reportTitle: {
    color: "#262262",
    letterSpacing: 4,
    fontSize: 25,
    textAlign: "center",
    textTransform: "uppercase",
  },
  invoiceNoContainer: {
    flexDirection: "row",
    marginTop: 20,
    justifyContent: "flex-end",
  },
  invoiceDateContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: "bold",
  },
  label: {
    // width: 60
  },
  headerContainer: {
    marginTop: 15,
  },
  billTo: {
    marginTop: 20,
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#262262",
  },
  container: {
    flexDirection: "row",
    borderBottomColor: "#262262",
    backgroundColor: "#262262",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
    color: "#fff",
  },
  description: {
    width: "60%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  // qty: {
  //     width: '10%',
  //     borderRightColor: borderColor,
  //     borderRightWidth: 1,
  // },
  rate: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  amount: {
    width: "20%",
  },
  row: {
    flexDirection: "row",
    borderBottomColor: "#262262",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 50,
    fontStyle: "bold",
  },
  description2: {
    width: "60%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
    overflowWrap: "break-word",
  },
  // qty2: {
  //     width: '10%',
  //     borderRightColor: borderColor,
  //     borderRightWidth: 1,
  //     textAlign: 'right',
  //     paddingRight: 8,
  // },
  rate2: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "center",
    // paddingRight: 8,
  },
  amount2: {
    width: "20%",
    textAlign: "center",
    // paddingRight: 8,
  },
  titleContainer2: {
    flexDirection: "row",
    marginTop: 12,
  },
  reportTitle2: {
    fontSize: 12,
    textAlign: "center",
    textTransform: "uppercase",
  },
});

function PdfDocument(props) {
  const number_with_commas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            width: "100%",
            backgroundColor: "#262262",
            height: "200px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "12px 14px",
          }}
        >
          <Image
            source={logo}
            style={{ objectFit: "contain", width: 230 }}
            alt=""
          />
          <Text
            style={{
              color: "white",
              fontSize: 13,
              paddingTop: "15px",
              fontWeight: "extrabold",
            }}
          >
            NO1 DRAFT PICK PTY LTD - ABN 71 166 532 303
          </Text>
        </View>
        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle}>Invoice</Text>
        </View>

        <Fragment>
          <View style={styles.invoiceNoContainer}>
            <Text style={styles.label}>Invoice No:</Text>
            <Text style={styles.invoiceDate}>{props.data?.order_id}</Text>
          </View>
          <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>Date: </Text>
            <Text style={styles.invoiceDate}>
              {props.data?.transaction_date}
            </Text>
          </View>
        </Fragment>

        <View style={styles.headerContainer}>
          <Text style={styles.billTo}>Bill To:</Text>
          <Text>
            {props.data?.first_name} {props.data?.last_name}
          </Text>
          <Text>{props.data?.email}</Text>
        </View>

        <View style={styles.tableContainer}>
          <View style={styles.container}>
            <Text style={styles.description}>Item Description</Text>
            {/* <Text style={styles.qty}>Qty</Text> */}
            <Text style={styles.rate}>@</Text>
            <Text style={styles.amount}>Amount</Text>
          </View>

          <View
            style={styles.row}
            key={props.data?.invoice_transaction_id.toString()}
          >
            <Text style={styles.description2}>{props.data?.title}</Text>
            {/* <Text style={styles.qty2}>{props.data?.qty}</Text> */}
            <Text style={styles.rate2}>{props.data?.currency}</Text>
            <Text style={styles.amount2}>
              ${number_with_commas(Number(props.data?.total_dollar).toFixed(2))}
            </Text>
          </View>

          {/* <InvoiceTableBlankSpace rowsCount={tableRowsCount - invoice.items.length} /> */}
          {/* <InvoiceTableFooter items={invoice.items} /> */}
        </View>

        <View style={styles.titleContainer2}>
          <Text style={styles.reportTitle2}>Thank you for your business</Text>
        </View>
      </Page>
    </Document>
  );
}

export default PdfDocument;
