import { ASSIGN_CATEGORY,
	 GET_CATEGORIES_REQUEST, 
	 GET_CATEGORIES_SUCCESS, 
	 GET_CATEGORIES_ERROR,
	 GET_PRODUCTS_REQUEST,
	 GET_PRODUCTS_SUCCESS,
	 GET_PRODUCTS_ERROR
	} from '../actions/shopActions';

const initialState = {

	categoriesList: [],
	categoriesListLoading: false,

	category: '',

	productsList: [],
	productsListLoading: false
};

function ShopReducer(state = initialState, action) {
	switch (action.type) {
		case GET_CATEGORIES_REQUEST:
			return Object.assign({}, state, {
				categoriesList: [],
				categoriesListLoading: true,
			});
		case GET_CATEGORIES_SUCCESS:
			return Object.assign({}, state, {
				categoriesList: action.data,
				categoriesListLoading: false,
			});	
		case GET_CATEGORIES_ERROR:
			return Object.assign({}, state, {
				categoriesList: [],
				categoriesListLoading: false,
			});
		case GET_PRODUCTS_REQUEST:
			return Object.assign({}, state, {
				productsList: [],
				productsListLoading: true,
			});
		case GET_PRODUCTS_SUCCESS:
			return Object.assign({}, state, {
				productsList: action.data,
				productsListLoading: false,
			});
		case GET_PRODUCTS_ERROR:
			return Object.assign({}, state, {
				productsList: [],
				productsListLoading: false,
			});
		case ASSIGN_CATEGORY:
			return Object.assign({}, state, {
				category: action.data,
			});
		
		
		default:
			return state;
		}
	}
			
export default ShopReducer;
