import {
    getAssignedEventsToCoach, viewEventDetailsCoach, viewAssignedEventProgramsCoach, viewEventBookingDetailsCoach,
    sendMailCoach, programStatusCoach, setChildrenAttendanceCoach
} from '../../config/config';
import { toast } from 'react-toastify';

export const GET_ASSIGNED_COACH_EVENTS_REQUEST = 'GET_ASSIGNED_COACH_EVENTS_REQUEST';
export const GET_ASSIGNED_COACH_EVENTS_SUCCESS = 'GET_ASSIGNED_COACH_EVENTS_SUCCESS';
export const GET_ASSIGNED_COACH_EVENTS_ERROR = 'GET_ASSIGNED_COACH_EVENTS_ERROR';

export const GET_ASSIGNED_COACH_EVENTS_DETAILS_REQUEST = 'GET_ASSIGNED_COACH_EVENTS_DETAILS_REQUEST';
export const GET_ASSIGNED_COACH_EVENTS_DETAILS_SUCCESS = 'GET_ASSIGNED_COACH_EVENTS_DETAILS_SUCCESS';
export const GET_ASSIGNED_COACH_EVENTS_DETAILS_ERROR = 'GET_ASSIGNED_COACH_EVENTS_DETAILS_ERROR';

export const GET_ASSIGNED_COACH_PROGRAMS_REQUEST = 'GET_ASSIGNED_COACH_PROGRAMS_REQUEST';
export const GET_ASSIGNED_COACH_PROGRAMS_SUCCESS = 'GET_ASSIGNED_COACH_PROGRAMS_SUCCESS';
export const GET_ASSIGNED_COACH_PROGRAMS_ERROR = 'GET_ASSIGNED_COACH_PROGRAMS_ERROR';

export const GET_ASSIGNED_COACH_PROGRAMS_DETAILS_REQUEST = 'GET_ASSIGNED_COACH_PROGRAMS_DETAILS_REQUEST';
export const GET_ASSIGNED_COACH_PROGRAMS_DETAILS_SUCCESS = 'GET_ASSIGNED_COACH_PROGRAMS_DETAILS_SUCCESS';
export const GET_ASSIGNED_COACH_PROGRAMS_DETAILS_ERROR = 'GET_ASSIGNED_COACH_PROGRAMS_DETAILS_ERROR';

export const SEND_MAIL_COACH_REQUEST = 'SEND_MAIL_COACH_REQUEST';
export const SEND_MAIL_COACH_SUCCESS = 'SEND_MAIL_COACH_SUCCESS';
export const SEND_MAIL_COACH_ERROR = 'SEND_MAIL_COACH_ERROR';

export const PROGRAM_STATUS_COACH_REQUEST = 'PROGRAM_STATUS_COACH_REQUEST';
export const PROGRAM_STATUS_COACH_SUCCESS = 'PROGRAM_STATUS_COACH_SUCCESS';
export const PROGRAM_STATUS_COACH_ERROR = 'PROGRAM_STATUS_COACH_ERROR';

export const CHILDREN_ATTENDANCE_COACH_REQUEST = 'CHILDREN_ATTENDANCE_COACH_REQUEST';
export const CHILDREN_ATTENDANCE_COACH_SUCCESS = 'CHILDREN_ATTENDANCE_COACH_SUCCESS';
export const CHILDREN_ATTENDANCE_COACH_ERROR = 'CHILDREN_ATTENDANCE_COACH_ERROR';

const actions = {
    listCoaches: (data) => async (dispatch) => {
        try {
            dispatch({
                type: GET_ASSIGNED_COACH_EVENTS_REQUEST,
            });
            await getAssignedEventsToCoach(data, 'Bearer ' + localStorage.access_token).then((response) => {
                if (response.data.code === 200) {
                    dispatch({
                        type: GET_ASSIGNED_COACH_EVENTS_SUCCESS,
                        payload: {
                            data: response.data.data,
                        },
                    });
                } else {
                    dispatch({
                        type: GET_ASSIGNED_COACH_EVENTS_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                }
            });
        } catch (error) {
            dispatch({
                type: GET_ASSIGNED_COACH_EVENTS_ERROR,
                payload: {
                    message: 'Error Loading Events',
                },
            });
        }
    },


    viewEventDetails: (data) => async (dispatch) => {
        try {
            dispatch({
                type: GET_ASSIGNED_COACH_EVENTS_DETAILS_REQUEST,
            });
            await viewEventDetailsCoach(data, 'Bearer ' + localStorage.access_token).then((response) => {
                if (response.data.code === 200) {
                    dispatch({
                        type: GET_ASSIGNED_COACH_EVENTS_DETAILS_SUCCESS,
                        payload: {
                            data: response.data.data,
                        },
                    });
                } else {
                    dispatch({
                        type: GET_ASSIGNED_COACH_EVENTS_DETAILS_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                }
            });
        } catch (error) {
            dispatch({
                type: GET_ASSIGNED_COACH_EVENTS_DETAILS_ERROR,
                payload: {
                    message: 'Error Loading Events',
                },
            });
        }
    },

    getPrograms: (data) => async (dispatch) => {
        try {
            dispatch({
                type: GET_ASSIGNED_COACH_PROGRAMS_REQUEST,
            });
            await viewAssignedEventProgramsCoach(data, 'Bearer ' + localStorage.access_token).then((response) => {
                if (response.data.code === 200) {
                    dispatch({
                        type: GET_ASSIGNED_COACH_PROGRAMS_SUCCESS,
                        payload: {
                            data: response.data.data,
                        },
                    });
                } else {
                    dispatch({
                        type: GET_ASSIGNED_COACH_PROGRAMS_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                }
            });
        } catch (error) {
            dispatch({
                type: GET_ASSIGNED_COACH_PROGRAMS_ERROR,
                payload: {
                    message: 'Error Loading Events',
                },
            });
        }
    },

    getProgramsDetails: (data) => async (dispatch) => {
        try {
            dispatch({
                type: GET_ASSIGNED_COACH_PROGRAMS_DETAILS_REQUEST,
            });
            await viewEventBookingDetailsCoach(data, 'Bearer ' + localStorage.access_token).then((response) => {
                if (response.data.code === 200) {
                    dispatch({
                        type: GET_ASSIGNED_COACH_PROGRAMS_DETAILS_SUCCESS,
                        payload: {
                            data: response.data.data,
                        },
                    });
                } else {
                    dispatch({
                        type: GET_ASSIGNED_COACH_PROGRAMS_DETAILS_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                }
            });
        } catch (error) {
            dispatch({
                type: GET_ASSIGNED_COACH_PROGRAMS_DETAILS_ERROR,
                payload: {
                    message: 'Error Loading Events',
                },
            });
        }
    },

    sendEmailCoach: (data) => async (dispatch) => {
        try {
            dispatch({
                type: SEND_MAIL_COACH_REQUEST,
            });
            await sendMailCoach(data, 'Bearer ' + localStorage.access_token).then((response) => {
                console.log(response)
                console.log(data)
                if (response.data.code === 200) {
                    dispatch({
                        type: SEND_MAIL_COACH_SUCCESS,
                    });
                    toast.success('Email Sent');

                } else {
                    dispatch({
                        type: SEND_MAIL_COACH_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                    toast.error('Error: ' + response.message);

                }
            });
        } catch (error) {
            dispatch({
                type: SEND_MAIL_COACH_ERROR,
                payload: {
                    message: 'Error sending emails',
                },
            });
        }
    },

    programStatusCoach: (data) => async (dispatch) => {
        try {
            dispatch({
                type: PROGRAM_STATUS_COACH_REQUEST,
            });
            await programStatusCoach(data, 'Bearer ' + localStorage.access_token).then((response) => {
                console.log(response)
                if (response.data.code === 200) {
                    dispatch({
                        type: PROGRAM_STATUS_COACH_SUCCESS,
                    });
                    toast.success('Program status updated');

                } else {
                    dispatch({
                        type: PROGRAM_STATUS_COACH_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                    toast.error('Error: ' + response.message);

                }
            });
        } catch (error) {
            dispatch({
                type: PROGRAM_STATUS_COACH_ERROR,
                payload: {
                    message: 'Error updating status',
                },
            });
        }
    },


    userCheckInCoach: (data) => async (dispatch) => {
        try {
            dispatch({
                type: CHILDREN_ATTENDANCE_COACH_REQUEST,
            });
            await setChildrenAttendanceCoach(data, 'Bearer ' + localStorage.access_token).then((response) => {
                console.log('checking',response)
                if (response.data.code === 200) {
                    dispatch({
                        type: CHILDREN_ATTENDANCE_COACH_SUCCESS,
                    });
                    toast.success('Child status updated');

                } else {
                    dispatch({
                        type: CHILDREN_ATTENDANCE_COACH_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                    toast.error('Error: ' + response.message);

                }
            });
        } catch (error) {
            dispatch({
                type: CHILDREN_ATTENDANCE_COACH_ERROR,
                payload: {
                    message: 'Error updating status',
                },
            });
        }
    },
};

export default actions;
