import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { coachHandle } from "../../../utils/global";
import moment from "moment";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

//Redux
import membersAction from "../../../redux/actions/membersAction";
import postsActions from "../../../redux/actions/postsActions";

//Css
import "./MembersList.css";

//Components
import MembersRow from "../../../Components/Members/MembersRow";

//images
import whiteX from "../../../assets/images/x-white.svg";
import Checked from "../../../assets/images/CheckBoxChecked.png";
import Unchecked from "../../../assets/images/CheckBoxUnchecked.png";
import excel from "../../../assets/images/excel.svg";

import { getExcelDoc } from '../../../config/config'

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function MembersList(props) {
  let history = useHistory();
  const classes = useStyles();
  const [membersList, setMembersList] = useState([]);
  const [selected, setSelected] = useState(false);
  const [memberSelected, setMemberSelected] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    value: "",
    current: "",
  });
  const [isSearch, setIsSearch] = useState(false);

  useEffect(() => {
    coachHandle(
      props.user?.roles?.length > 0 ? props.user?.roles[0]?.name : null
    );
    props.dispatch(membersAction.resetMemberSearch());
  }, []);

  useEffect(() => {
    if (!isSearch) {
      let finalData = [];
      props?.listMembers?.data?.map((zone) => {
        finalData.push({
          link: zone.id,
          profile_img: zone.photo,
          first_name: zone.first_name,
          last_name: zone.last_name,
          mobile_number: zone.mobile,
          email: zone.email,
          street: `${zone.street_address1}, ${zone.street_address2}`,
          suburb: zone.suburb,
          state: zone.state,
          postal_code: zone.postal_code,
          register_date: zone.created_at,
          last_booking: zone.booking_date,
          number_kids: zone.numberOfChildrens,
          checked: false,
        });
      });
      setMembersList(finalData);
    }
  }, [props.listMembers, isSearch]);

  useEffect(() => {
    if (isSearch) {
      let finalData = [];
      props?.searchMembersData?.data?.map((zone) => {
        finalData.push({
          link: zone.id,
          profile_img: zone.photo,
          first_name: zone.first_name,
          last_name: zone.last_name,
          mobile_number: zone.mobile,
          email: zone.email,
          street: `${zone.street_address1}, ${zone.street_address2}`,
          suburb: zone.suburb,
          state: zone.state,
          postal_code: zone.postal_code,
          register_date: zone.created_at,
          last_booking: zone.booking_date,
          number_kids: zone.numberOfChildrens,
          checked: false,
        });
      });
      setMembersList(finalData);
    }
  }, [props.searchMembersData, isSearch]);

  useEffect(() => {
    if (isSearch) {
      props.dispatch(
        membersAction.membersSearch({
          query: queryString.parse(props.location.search).query,
          page: queryString.parse(props.location.search).page,
        })
      );
    } else {
      props.dispatch(
        membersAction.listMembers(queryString.parse(props.location.search).page)
      );
    }
  }, [
    queryString.parse(props.location.search).page,
    queryString.parse(props.location.search).query,
  ]);

  const handleSearch = () => {
    setIsSearch(true);
    setSearchQuery({ ...searchQuery, current: searchQuery.value });
    history.push({
      pathname: "/admin/members",
      search: `?page=${1}&&query=${searchQuery.value}`,
    });
  };

  const handleChecked = (id) => {
    let finalData = [];
    let flagSelected = false
    membersList.map((zone) => {
      if (zone.link === id) {
        finalData.push({
          link: zone.link,
          profile_img: zone.profile_img,
          first_name: zone.first_name,
          last_name: zone.last_name,
          mobile_number: zone.mobile_number,
          email: zone.email,
          street: zone.street,
          suburb: zone.suburb,
          state: zone.state,
          postal_code: zone.postal_code,
          register_date: zone.register_date,
          last_booking: zone.last_booking,
          number_kids: zone.number_kids,
          checked: !zone.checked,
        });
      } else {
        finalData.push({
          link: zone.link,
          profile_img: zone.profile_img,
          first_name: zone.first_name,
          last_name: zone.last_name,
          mobile_number: zone.mobile_number,
          email: zone.email,
          street: zone.street,
          suburb: zone.suburb,
          state: zone.state,
          postal_code: zone.postal_code,
          register_date: zone.register_date,
          last_booking: zone.last_booking,
          number_kids: zone.number_kids,
          checked: zone.checked,
        });
      }
    });
    finalData.map(zone => {
      if (zone.checked) {
        flagSelected = true
      }
    })
    setMemberSelected(flagSelected)
    setMembersList(finalData);
  };

  const handleSelectAll = (e) => {
    let finalData = [];
    membersList.map((zone) => {
      finalData.push({
        link: zone.link,
        profile_img: zone.profile_img,
        first_name: zone.first_name,
        last_name: zone.last_name,
        mobile_number: zone.mobile_number,
        email: zone.email,
        street: zone.street,
        suburb: zone.suburb,
        state: zone.state,
        postal_code: zone.postal_code,
        register_date: zone.register_date,
        last_booking: zone.last_booking,
        number_kids: zone.number_kids,
        checked: e === "checked" ? true : false,
      });
    });
    setMembersList(finalData);
    setMemberSelected(false);
    setSelected(e === "checked" ? true : false);
  };

  const handleDelete = () => {
    let members = [];
    membersList.map((zone) => {
      if (zone.checked === true) {

        members.push(zone.link);
      }
    });
    if(isSearch){
      setIsSearch(false)
      setSearchQuery({
        value: '',
        current: ''
      })
    }
    props.dispatch(postsActions.popupHandler({type: 'DELETE_MEMBERS', data: members }))
  };

  const handleNewPagination = (e, selectedPage) => {
    props.dispatch(membersAction.changePageState(selectedPage));
    setSelected(false)
    setMemberSelected(false);
    if (isSearch) {
      history.push({
        pathname: "/admin/members",
        search: `?page=${selectedPage}&&query=${searchQuery.current}`,
      });
    } else {
      history.push({
        pathname: "/admin/members",
        search: `?page=${selectedPage}`,
      });
    }
  }
  const callApi = async () => {
    await getExcelDoc()
  }

  return (
    <section>
      <div className="coach_top_div">
        <div className="bold blue font-36 text-upper float-left">Members</div>
        <div className="coach_form_div">
          {isSearch && (
            <div className="mr-3">
              <div>SHOWING RESULTS FOR "{searchQuery.current}"</div>
              <div className="search_cancel">
                <span
                  className="pointer"
                  onClick={() => {
                    props.dispatch(membersAction.changePageState(1));
                    setIsSearch(false);
                    setSearchQuery({ ...searchQuery, value: "" });
                  }}
                >
                  CANCEL
                </span>
              </div>
            </div>
          )}
          <div className="seach_bar">
            <input
              className="search_button"
              type="search"
              onClick={() => handleSearch()}
            />
            <input
              type="text"
              placeholder="Search for Members..."
              className="search_input"
              value={searchQuery.value}
              onChange={(e) => {
                setSearchQuery({ ...searchQuery, value: e.target.value });
              }}
            />
          </div>
          <div onClick={callApi} className="memberlist_export_btn">
            <img src={excel} alt="" className="mr-2" />
            <span className="white bold text-upper">EXPORT</span>
          </div>
          <div className={selected || memberSelected ? 'member_delete_btn' : 'member_delete_btn_disable'}>
            <img src={whiteX} alt="" className="mr-2" />
            <span onClick={() => { (selected || memberSelected) && handleDelete() }} className="white bold text-upper">DELETE</span>
          </div>
        </div>
      </div>
      <div className="coach_list_div">
        <div className="coach_list_top">
          <div className="coach_table_header wi-5">
            {selected === true ? (
              <img
                onClick={() => {
                  handleSelectAll("unchecked");
                }}
                src={Checked}
                alt=""
              />
            ) : (
              <img
                onClick={() => {
                  handleSelectAll("checked");
                }}
                src={Unchecked}
                alt=""
              />
            )}
          </div>
          <div className="events_table_header wi-10">PHOTO</div>
          <div className="events_table_header wi-10">NAME</div>
          <div className="events_table_header wi-10">FAMILY NAME</div>
          <div className="events_table_header wi-10">PHONE NUMBER</div>
          <div className="events_table_header wi-10">EMAIL ADDRESS</div>
          <div className="events_table_header wi-13">ADDRESS</div>
          <div className="events_table_header wi-10">REGISTER DATE</div>
          <div className="events_table_header wi-10">LAST BOOKING</div>
          <div className="events_table_header wi-8">NB. OF KIDS</div>
          <div className="events_table_header wi-8">VIEW/ EDIT</div>
        </div>
        <div className="coach_list_container">
          {props.listMembersLoading || props.searchMembersLoading ? (
            <div className="text-center mx-auto mt-4">
              <CircularProgress color="primary" size={30} />
            </div>
          ) : (
            membersList?.map((zone, i) => {
              let data = (
                <MembersRow
                  key={i}
                  link={zone.link}
                  profile_img={zone.profile_img}
                  first_name={zone.first_name}
                  last_name={zone.last_name}
                  mobile_number={zone.mobile_number}
                  email={zone.email}
                  street={zone.street}
                  suburb={zone.suburb}
                  state={zone.state}
                  postal_code={zone.postal_code}
                  register_date={zone.register_date}
                  last_booking={
                    zone.last_booking
                      ? moment(zone.last_booking).format("DD/MM/YYYY")
                      : "N/A"
                  }
                  number_kids={zone.number_kids}
                  checked={zone.checked}
                  handleChecked={handleChecked}
                />
              );
              return data;
            })
          )}
        </div>

        <div className={classes.root}>
          <Pagination count={isSearch? props.searchMembersData.last_page : props.listMembers.last_page} defaultPage={ queryString.parse(props.location.search).page? parseInt(queryString.parse(props.location.search).page) : 1 } shape="rounded" onChange={handleNewPagination} />
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  listMembers: state.membersReducers.listMembers,
  listMembersLoading: state.membersReducers.listMembersLoading,
  user: state.register.user,
  searchMembersData: state.membersReducers.searchMembersData,
  searchMembersLoading: state.membersReducers.searchMembersLoading,
  selectedPage: state.membersReducers.selectedPage,
});

export default connect(mapStateToProps)(MembersList);
