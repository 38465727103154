import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux'
import postActions from '../../../redux/actions/postsActions';
import homeActions from '../../../redux/actions/homeActions'
import HomeCarouselCard from './HomeCarouselCard';
import "./HomePageSetup.css"

import plus from '../../../assets/images/click-plus.svg';

export const HomePageSetup = (props) => {
    // const [CarouselItems, setCarouselItems] = useState([{
    //     id:1,
    //     headline:'POJECT X',
    //     title:'projectX',
    //     subtitle:'Best Event of the DECADE',
    //     buttonText:'Press To Apply',
    //     buttonURL:'https://google.com',
    //     bannerImageExisting:'https://picsum.photos/1920/750',
    // },{
    //     id:2,
    //     headline:'Don\'t Talk To Me',
    //     title:'DTTM',
    //     subtitle:'dttm',
    //     buttonText:'DTTM',
    //     buttonURL:'https://google.com',
    //     bannerImageExisting:'https://picsum.photos/1920/750',
    // },{
    //     id:3,
    //     headline:'TO THE MOON',
    //     title:'toTheMoon',
    //     subtitle:'Let\'s gO Up',
    //     buttonText:'MOOON',
    //     buttonURL:'https://google.com',
    //     bannerImageExisting:'https://picsum.photos/1920/750',
    // }])
    useEffect(() => {
        props.getCarouselItems()
    }, [])

    return (
        <div>
            <div className='events_top_div'>
                <div className='bold blue font-36 text-upper float-left'>Home page</div>
                <div className='events_form_div'>
                    <div className='event_add_new bold pointer'
                        onClick={() => props.popupHandler({ type: 'ADD_CAROUSEL_ITEM' })}>
                        <img src={plus} alt='add' />
                        <span>Add New</span>
                    </div>
                </div>
            </div>
            <Row>
                {console.log(props?.carouselItems)}
                {props?.carouselItems?.map((zone, i) => {
                    return (
                        <Col xs={6} key={`CI-${i}`}>
                            <HomeCarouselCard
                                data={zone}
                                type="add"
                            />
                        </Col>)
                })}
            </Row>

        </div>
    )
}

const mapStateToProps = (state) => ({
    carouselItems: state.homeReducer.getCarouselItemsData
})

const mapDispatchToProps = (dispatch) => ({
    popupHandler: (data) => { dispatch(postActions.popupHandler(data)) },
    getCarouselItems: () => { dispatch(homeActions.getCarouselItems()) }
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePageSetup)
