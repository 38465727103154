import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

//redux
import coachActions from "../../redux/actions/coachPortalActions"
import eventActions from '../../redux/actions/eventsActions';

//images
import greenCheck from '../../assets/images/green-tick.svg';
import ArrowBlueImg from '../../assets/images/arrow_blue_down.svg';

//CSS
import '../Events/EventsListRow.css';

function EventProgramRow(props) {
	const [isDone, setIsDone] = useState(false);
	const [selectVal, setSelectVal] = useState(props.status);
	const [programHover, setProgramHover] = useState(false)

	const handleValue = (e) => {
		setSelectVal(e.target.value);
		let data = {
			event_Id:params.event_id,
			recurrence_id:props.id,
			status: e.target.value
		}
		props.dispatch(coachActions.programStatusCoach({ coachId:props.user.id, data: data }))
	};
	const params = useParams();

	return (
		<div className='event_purchaser_row'>
			<div className='select_user_div wi-13 events_row_in' style={{overflowY: 'auto', paddingTop: '20px'}} onMouseEnter={()=>{setProgramHover(true)}} onMouseLeave={()=>{setProgramHover(false)}}>
				<Link
					to={`/coach/events/${params.event_id}/programs/${props.id}`}
					className='blue'
				>
					{props.program}
					{programHover&&<div className="programHover">Check in students</div>}
				</Link>
			</div>
			{/* {console.log(props.program)} */}
			<div className='event_purchaser_row_col wi-13'>{props.start_date} to {props.end_date}</div>
			<div className='event_purchaser_row_col wi-21'>{props.title}</div>
			{/* <div className='event_purchaser_row_col wi-17'>{props.coach_name}</div> */}
			<div className='event_purchaser_row_col wi-10'>{props.booking_count}</div>
			<div className='event_purchaser_row_col wi-16'>
				{props.attended} Attended
			</div>
			<div className='event_purchaser_row_col wi-16'>
				{selectVal === 'completed' ? <img src={greenCheck} alt='' /> : ''}
				<select
					name=''
					id=''
					style={styles.dropDown}
					value={selectVal}
					onChange={(e) => handleValue(e)}
				>
					<option value='pending'>Pending</option>
					<option value='completed'>Completed</option>
				<img src={ArrowBlueImg} alt='' />
				</select>
			</div>
		</div>
	);
}

const styles = {
	dropDown: {
		border: 'none',
		color: '#262262',
		width: '150px',
		padding: '10px',
		background: `url(${ArrowBlueImg}) no-repeat right #fff`,
	},
};

const mapStateToProps = (state) => ({
	user: state.register.user,
	// eventDetails: state.eventReducer.eventDetails,
	// eventDetailsLoading: state.eventReducer.eventDetailsLoading,
});

export default connect(mapStateToProps)(EventProgramRow);
