import React, { useState } from 'react'
import { connect } from 'react-redux'
import couponsActions from '../../../redux/actions/couponsActions'

import './NewCoupon.css';
import './popup.css';

//Images
import LineRedImg from '../../../assets/images/Line-red.svg';
import checked from '../../../assets/images/CheckBoxChecked.png';
import unchecked from '../../../assets/images/CheckBoxUnchecked.png';

import DatePickerComponent from '../../../Components/DatePickerComponent/DatePickerComponent';
import NumberFormat from 'react-number-format';
import moment from 'moment';

export const NewCoupon = (props) => {
const [coupon, setCoupon] = useState({
    type: '',
    code: '',
    message:'',
    expiration_date: '',
    value: 0,
    max_uses: 0,
    is_one_time: false,
    children: null});
const [] = useState('');
const [isChecked, setIsChecked] = useState(false);
const [isOpen, setIsOpen] = useState(false);

const handleDataChange = (e,name) => {
    setCoupon({
        ...coupon,
        [name]: e.target.value
    })
}

const createCoupon = (e) => {   
    e.preventDefault();
    props.dispatch(couponsActions.createCoupon({ data: coupon }))
}

const handleDateChange = (data, name) => {
    let date = data===null ? moment().toDate() : moment(data).format('YYYY-MM-DD');

    setCoupon({
        ...coupon,
        [name]: date
    });
}

const handleValueChange = (data, name) => {
    let couponValue = data.target.value===null ? '0' : data.target.value.replace('$','');;

    setCoupon({
        ...coupon,
        [name]: couponValue
    });
}

const handleOpenCalendar = () => {
    setIsOpen(!isOpen)
}

const handleChecked = () => {
    setIsChecked(!isChecked);
    setCoupon({
        ...coupon,
        ['is_one_time']: + !isChecked
    });
}

    return (
        <div>
            <div style={styles.headerContainer}>
				<img src={LineRedImg} alt='line Red' style={styles.lineRed} />
				<span style={styles.title}>New Coupon</span>
			</div>
                <form onSubmit={createCoupon}>
                        <label className='labels'>
                            Type *
                        </label>
                        <select
							onChange={(e) => {
								handleDataChange(e, 'type');
							}}
							name='type'
							id='usedSelect'
                            className='popup_select'
						>
							<option key={'AllTypes'} value=' '>
								Select Type
							</option>
							<option key={'BirthDay'} value='2'>
								Birthday Voucher
							</option>
							<option key={'Discount'} value='3'>
								Discount Voucher
							</option>
                            <option key={'School'} value='4'>
								School Voucher
							</option>
						</select>
                        <label className='labels'>
                            Code *
                        </label>
                        <input
                            type='text'
                            className='popup_Input_Carousel'
                            placeholder='Code'
                            name='code'
                            style={styles.inputTitle}
                            required
                            onChange={(e) => {
                                handleDataChange(e,'code');
                            }}
                        ></input>
                        <label className='labels'>
                            Message
                        </label>
                        <textarea
                            type='text'
                            placeholder='Meta Description'
                            name='meta_description'
                            className='popup_Input_Carousel'
                            style={styles.message}
                            value={coupon.message}
                            onChange={(e) => {
                                handleDataChange(e,'message');
                            }}
                        ></textarea>
                        <label className='labels'>
                            Expiration Date *
                        </label>
                        <div className='popup_Input_Carousel'>
                        <DatePickerComponent
								containerClass="popup_calendar"
								name='expiration_date'
								selected={coupon.expiration_date ? moment(coupon.expiration_date).toDate() : moment().toDate()}
								minDate={moment().toDate()}
								handleDateChange={(e) => handleDateChange(e, 'expiration_date')}
								open={isOpen}
								setIsOpenTrue={() => setIsOpen(true)}
								setIsOpenFalse={() => setIsOpen(false)}
							/>
                        </div>
                        <label className='labels'>
                            Value * 
                        </label>
                        <NumberFormat 
                            id={"new_Coupon_Value"}
                            displayType={'input'} 
                            thousandSeparator={true} 
                            prefix={'$'}
                            className="popup_Input_Number"
                            onChange={(e) => {
                                handleValueChange(e,'value');
                            }}
                        />
                        <label className='labels'>
                            Max Uses *
                        </label>
                        <input
                            type='number'
                            placeholder='Max Uses'
                            name='max_uses'
                            className='popup_Input_Carousel'
                            style={styles.inputTitle}
                            required
                            onChange={(e) => {
                                handleDataChange(e,'max_uses');
                            }}
                            min={1}
                        ></input>
                        {/* <label className='labels' style={{marginTop: '20pt', marginBottom: '20pt'}}>
                            Is one time ?
                        </label>
                        <img src={isChecked? checked : unchecked} onClick={handleChecked} style={{width:'18pt', height: '18pt', marginLeft: '10pt'}}></img> */}

                        <div className='d-flex justify-content-between red' style={{marginTop: '20pt'}}>
                        <button className='popup_button' style={styles.submit}
                        type="submit"
                            >
                                Create Coupon
                        </button>
                        </div>
                </form>
        </div>
    )
}
const styles = {
	title: {
		color: '#262262',
		fontSize: '36px',
		fontStyle: 'bold',
		marginTop: '5px',
        fontWeight: 'bold',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	lineRed: {
		width: '88px',
	},
	inputTitle: {
		width: '100%',
        marginTop:0,
		fontSize: '14pt',
	},
	textArea: {
		width: '100%',
		height: '200px',
		fontSize: '14pt',
        color: '#262262',
        marginTop:0,
	},
    image_container: {
        width: '70%',
        height:'300px',
        objectFit: 'fill',	
    },
    submit:{
        paddingTop: '5px'
    }
};

const mapStateToProps = (state) => ({
    
})



export default connect(mapStateToProps)(NewCoupon)
