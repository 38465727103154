import React from 'react'
import { connect } from 'react-redux';
import postActions from '../../../redux/actions/postsActions';
import { baseURL } from '../../../config/config';

import xWhite from '../../../assets/images/x-white.svg'



function HomeCarouselCard(props) {
    return (
        <div className={`HomeCarouselCard_Container ${props.type === "delete" && "DeleteCarouselCard_Container"}`}>
            {props?.type !== "delete" && <div className="HomeCarouselCard_Remove"
                onClick={() => props.popupHandler({ type: 'DELETE_CAROUSEL_ITEM', data: props.data })}>
                <img src={xWhite} alt="delete banner" />
            </div>}
            <div className='blue'><strong className='blue bold font-18'>Headline: </strong>{props?.data?.headline}</div>
            <div className='blue'><strong className='blue bold font-18'>Title: </strong>{props?.data?.title}</div>
            <div className='blue'><strong className='blue bold font-18'>Subtitle: </strong>{props?.data?.subtitle}</div>
            <div className='blue'><strong className='blue bold font-18'>Button Text: </strong>{props?.data?.button_text}</div>
            <div className='blue'><strong className='blue bold font-18'>Button URL: </strong>{props?.data?.button_url}</div>
            {props?.type !== "delete" && <div className="d-flex flex-column blue"><strong className='blue bold font-18'>Banner Image: </strong><img className="HomeCarouselCard_Image" src={`${baseURL}/image/${props?.data?.image}`} alt='' /></div>}
            {props?.type !== "delete" && <div className="HomeCarouselCard_Edit">
                <div className="HomeCarouselCard_EditButton" onClick={() => props.popupHandler({ type: 'EDIT_CAROUSEL_ITEM', data: props.data })}>EDIT</div>
            </div>}
        </div>
    )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
    popupHandler: (data) => { dispatch(postActions.popupHandler(data)) }
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeCarouselCard)
