import React from 'react';
import NumberFormat from 'react-number-format';

//CSS
import './OrderRow.css';


const OrderItemRow = (props) => {


	return (
		<div className='coach_row_table' style={{height:'90px'}}>
			<div className='coach_row_row'>
				<div className='order_row_col wi-25'>{props?.name}</div>
				<div className='order_row_col wi-17'>{props.size}</div>
				<div className='order_row_col wi-13'>
					{props.quantity}
				</div>
				<div className='order_row_col wi-21'>
					${<NumberFormat value={Number(props.price).toFixed(2)} displayType={'text'} thousandSeparator={true} />}
				</div>
				<div className='order_row_col wi-21'>
					${<NumberFormat value={Number(props.total).toFixed(2)} displayType={'text'} thousandSeparator={true} />}
				</div>
			</div>
		</div>
	);
}

export default OrderItemRow;
