import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import adminActions from '../../../redux/actions/adminActions';
import { coachHandle } from '../../../utils/global';
import { AsYouType } from "libphonenumber-js";
import './NewAdmin.scss';
import "react-datepicker/dist/react-datepicker.css";
import insert from '../../../assets/images/insert-photo.png';
import backArrow from '../../../assets/images/arrow-red-left.svg';
import camera from '../../../assets/images/camera.svg';
import AddressAuto from '../../../Components/Address/AddressAuto';

function NewAdmin(props) {
	const [adminFields, setAdminFields] = useState({
		first_name: '',
		last_name: '',
		email: '',
		mobile_phone: '',
		address_1: '',
		address_2: '',
		suburb: '',
		state: '',
		postal_code: '',
		photo: '',
	});


	const handleChange = (data) => (e) => {
		setAdminFields({ ...adminFields, [data]: e.target.value });
	};

	const handleImageChange = (e) => {
		createImage(e.target.files[0]);
	};
	const createImage = (file) => {
		let reader = new FileReader();
		reader.onload = (e) => {
			setAdminFields({
				...adminFields,
				photo: e.target.result,
			});
		};
		reader.readAsDataURL(file);
	};

	const createAdmin = (e) => {
		e.preventDefault();
		props.dispatch(adminActions.newAdmin(adminFields));
		
		
	};
	const handleMobileChange = (e) => {
		let value = e.target.value.split(' ').join('');
		let number;
		if (value.length === 6 && value[1] === '3' && value[0] === '1') {
			number = ''
			for (let i = 0; i < value.length; i++) {
				if (i === 2 || i === 4) {
					number += ` ${value[i]}`
				} else {
					number += `${value[i]}`
				}
			}
		} else {
			number = new AsYouType('AU').input(e.target.value)
		}
		
		setAdminFields({ ...adminFields, mobile_phone: number });
	}

	useEffect(() => {
		coachHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null);
	}, []);
	return (
		<section>
			<div className='coach_new_top_div'>
				<div className='left_new_top'>
					<Link to='/admin/super-admins'>
						<div className='go_back_arrow'>
							<img src={backArrow} alt='Go Back' />
						</div>
					</Link>
					<div className='bold blue font-36 text-upper float-left'>
						add new admin
					</div>
				</div>
			</div>
			<div className='coach_new_body'>
				<div className='coach_pfp_div'>
					<div className='coach_new_pfp'>
						{adminFields.photo === '' ? (
							<img src={insert} alt='admin profile picture' />
						) : (
							<img src={adminFields.photo} alt='admin profile picture' />
						)}
					</div>
					<button className='add_photo_btn'>
						<input
							onChange={(e) => {
								handleImageChange(e);
							}}
							type='file'
							id='file'
							name='file'
							className='custom_file_input'
						/>
						<label
							htmlFor='file'
							className='bold blue text-upper add_photo_label'
						>
							<img src={camera} alt='' />
							upload photo
						</label>
					</button>
				</div>
				<div className='coach_new_form'>
					<form onSubmit={createAdmin}>
					<div className='new_form_row'>
						<div className='coach_new_input_div font-18 '>
							<label className='bold blue text-upper'>First Name*</label>
							<input
								value={adminFields.first_name}
								onChange={handleChange('first_name')}
								name='First Name'
								type='text'
								placeholder='First Name'
								className='coach_new_input'
								required
							/>
						</div>
						<div className='coach_new_input_div font-18 '>
							<label className='bold blue text-upper'>Family Name*</label>
							<input
								value={adminFields.last_name}
								onChange={handleChange('last_name')}
								name='Last Name'
								type='text'
								placeholder='Family Name'
								className='coach_new_input'
								required
							/>
						</div>
					</div>
					<div className='new_form_row'>
						<div className='coach_new_input_div font-18 '>
							<label className='bold blue text-upper'>Email address*</label>
							<input
								value={adminFields.email}
								onChange={handleChange('email')}
								name='email'
								type='email'
								placeholder='example@gmail.com'
								className='coach_new_input'
								required
							/>
						</div>
						<div className='coach_new_input_div font-18 '>
							<label className='bold blue text-upper'>phone number*</label>
							<input
								value={adminFields.mobile_phone}
								onChange={(e) => handleMobileChange(e)}
								name='phone number'
								type='text'
								placeholder='ex: 9999 999 999'
								className='coach_new_input number_input'
								required
							/>
						</div>
					</div>
					<AddressAuto
						required
						addressInfo={adminFields}
						setAddressInfo={setAdminFields}
						styling='member_edit'
						inputStyling='coach_edit_input'
					/>

					<div className='edit_button_div'>
						<button
							disabled={
								adminFields.first_name === '' ||
								adminFields.last_name === '' ||
								adminFields.email === '' ||
								adminFields.mobile_phone === ''
								? true
								: false
							}
							type="submit"
							className='white bold text-upper new_save_red_button'
						>
							save
						</button>
						<Link
							to='/admin/super-admins'
							className='white bold text-upper cancel_blue_button'
						>
							cancel
						</Link>
					</div>
					</form>
				</div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => ({
	user: state.register.user
});

export default connect(mapStateToProps)(NewAdmin);
