import {
    GET_MEMBERS_DASHBOARD_REQUEST, GET_MEMBERS_DASHBOARD_SUCCESS, GET_MEMBERS_DASHBOARD_ERROR,
    GET_EVENTS_BOOKING_REVENUE_REQUEST, GET_EVENTS_BOOKING_REVENUE_SUCCESS, GET_EVENTS_BOOKING_REVENUE_ERROR,
    GET_SHOP_REVENUE_REQUEST, GET_SHOP_REVENUE_SUCCESS, GET_SHOP_REVENUE_ERROR,
    GET_PRODUCT_SALES_REQUEST, GET_PRODUCT_SALES_SUCCESS, GET_PRODUCT_SALES_ERROR, GET_PROGRAMS_REQUEST, GET_PROGRAMS_SUCCESS, GET_PROGRAMS_ERROR
} from '../actions/dashboardActions';




const initialState = {
    membersDashboard: [],
    membersDashboardLoading: false,

    eventBookingRevenue: [],
    eventBookingRevenueLoading: false,

    shopRevenue: [],
    shopRevenueLoading: false,

    productSales: [],
    productSalesLoading: false,

    programs: []

};

function coach(state = initialState, action) {
    switch (action.type) {
        case GET_MEMBERS_DASHBOARD_REQUEST:
            return Object.assign({}, state, {
                membersDashboardLoading: true,
                membersDashboard: [],
            });
        case GET_MEMBERS_DASHBOARD_SUCCESS:
            return Object.assign({}, state, {
                membersDashboardLoading: false,
                membersDashboard: action.payload.data,
            });
        case GET_MEMBERS_DASHBOARD_ERROR:
            return Object.assign({}, state, {
                membersDashboardLoading: false,
                membersDashboard: [],
            });



        case GET_EVENTS_BOOKING_REVENUE_REQUEST:
            return Object.assign({}, state, {
                eventBookingRevenueLoading: true,
                eventBookingRevenue: [],
            });
        case GET_EVENTS_BOOKING_REVENUE_SUCCESS:
            return Object.assign({}, state, {
                eventBookingRevenueLoading: false,
                eventBookingRevenue: action.payload.data,
            });
        case GET_EVENTS_BOOKING_REVENUE_ERROR:
            return Object.assign({}, state, {
                eventBookingRevenueLoading: false,
                eventBookingRevenue: [],
            });





        case GET_SHOP_REVENUE_REQUEST:
            return Object.assign({}, state, {
                shopRevenueLoading: true,
                shopRevenue: [],
            });
        case GET_SHOP_REVENUE_SUCCESS:
            return Object.assign({}, state, {
                shopRevenueLoading: false,
                shopRevenue: action.payload.data,
            });
        case GET_SHOP_REVENUE_ERROR:
            return Object.assign({}, state, {
                shopRevenueLoading: false,
                shopRevenue: [],
            });





        case GET_PRODUCT_SALES_REQUEST:
            return Object.assign({}, state, {
                productSalesLoading: true,
                productSales: [],
            });
        case GET_PRODUCT_SALES_SUCCESS:
            return Object.assign({}, state, {
                productSalesLoading: false,
                productSales: action.payload.data,
            });
        case GET_PRODUCT_SALES_ERROR:
            return Object.assign({}, state, {
                productSalesLoading: false,
                productSales: [],
            });

        case GET_PROGRAMS_REQUEST:
            return Object.assign({}, state, {
                programs: [],
            });
        case GET_PROGRAMS_SUCCESS:
            return Object.assign({}, state, {
                programs: action.payload.data,
            });
        case GET_PROGRAMS_ERROR:
            return Object.assign({}, state, {
                programs: [],
            });

        default:
            return state;
    }
}

export default coach;
