import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { baseURL } from '../../config/config';
//CSS
import './CoachListRow.css';

//IMAGES
import arrow from '../../assets/images/right-arrow-blck.svg';

function CoachListRow(props) {
	return (
		<div className='coach_row_table'>
			<div className='coach_row_row'>
				<div className='coach_row_col photo_head'>
					<img
						src={baseURL + '/image/' + props?.image}
						alt=''
						className='coach_img'
					/>
				</div>
				<div className='coach_row_col name_head'>{props.first_name}</div>
				<div className='coach_row_col last_head'>{props.last_name}</div>
				<div className='coach_row_col detail_head'>
					<div>
						<p><strong>Phone:</strong>{props.mobile_phone}</p>
						<p><strong>Email:</strong>{props.email}</p>
						<p>
							<strong>Description:</strong>{props.description ? props.description : "N/A"}
						</p>
					</div>
				</div>
				<div className='coach_row_col event_head'>
					{props.event?.length > 0 ? props.event?.map((zone,i)=>{
					return(<div key={`eventList-${i}`}>{`${zone.title} ${props.event?.length -1 ===i ? '' :' , '}`}</div>)
				}): 'No Assigned Event'}</div>
				<div className='coach_row_col coach_detail_link view_head'>
					<Link to={`/admin/coaches/coach-detail/${props.link}`}>
						<div className='arrow_div'>
							<img src={arrow} alt='view/edit' />
						</div>
					</Link>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(CoachListRow);
