import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import shopActions from '../../../redux/actions/shopActions'
//CSS
import './CategoryRow.css';

//IMAGES
import arrow from '../../../assets/images/right-arrow-blck.svg';

function CategoryRow(props) {
	return (
		<div className='coach_row_table'>
			<div className='coach_row_row'>
				<div className='coach_row_col col-4 center'>{props.name}</div>
				<div className='coach_row_col col-4 center'>{props.countProducts}</div>
				<div className='coach_row_col coach_detail_link col-4 center'>
					<Link to={`/admin/stores/${props.zone.id}/products`} onClick={()=>{props.dispatch(shopActions.assignCategory(props.name))}}>
						<div className='arrow_div'>
							<img src={arrow} alt='view/edit' />
						</div>
					</Link>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(CategoryRow);
