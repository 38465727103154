import React, { useState } from 'react';
import moment from 'moment';
import './EventPurchaserRow.css';

import checkedBox from '../../assets/images/CheckBoxChecked.png';
import uncheckedBox from '../../assets/images/CheckBoxUnchecked.png';

let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

function EventPurchaserRow(props) {
	return (
		<div className='event_purchaser_row'>
			<div className='select_user_div wi-8'>
				<img
					onClick={() => { props.handleCheckedUsers(props.userId, props.checked) }}
					src={!props.checked ? uncheckedBox : checkedBox}
					alt='check'
				/>
			</div>
			<div className='event_purchaser_row_col wi-18'>{props.first_name} {props.last_name}</div>
			<div className='event_purchaser_row_col wi-13'>{props.mobile_phone}</div>
			<div className='event_purchaser_row_col wi-20'>{props.program_title}</div>
			<div className='event_purchaser_row_col wi-13'>{props.date? moment(props.date).format('DD-MM-YYYY') : ''}</div>
			<div className="event_purchaser_row_col wi-13">{props.active_kids_voucher? 'AKV' : (props.gift_voucher? 'GIFT CODE' : (props.birthday_voucher? 'BIRTHDAY VOUCHER' : ''))}</div>
            <div className="event_purchaser_row_col wi-13">{props.active_kids_voucher? USDollar.format(100) 
															 : (props.gift_voucher? USDollar.format(JSON.parse(props.gift_voucher).value)
															 : (props.birthday_voucher? USDollar.format(JSON.parse(props.birthday_voucher)?.value) : ''))}</div>
            <div className="event_purchaser_row_col wi-13">{props.active_kids_voucher? JSON.parse(props.active_kids_voucher)?.map((zone => (<text>{zone?.voucher_code}</text>)))
															 :(props.gift_voucher? JSON.parse(props.gift_voucher).code
															 :(props.birthday_voucher? JSON.parse(props.birthday_voucher).code : ''))}</div>
			<div className='invisible-scrollbar wi-20'>
				<ul className= 'event_purchaser_row_col' style={{height: '100%'}}>{props.active_kids_voucher ?
					JSON.parse(props.active_kids_voucher)?.map((zone => (

						<li key={zone?.voucher_code}>{zone?.name}<br />
							{zone?.dob ? moment(zone?.dob).format('DD-MM-YYYY') : ''}
						</li>

					)))
					:
					''}
				</ul>
			</div >
			<div className='event_purchaser_row_col wi-10'>${props.price}</div>
		</div >
	);
}

export default EventPurchaserRow;
