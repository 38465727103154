import {
	getCarouselItems, addCarouselItem, editCarouselItem, deleteCarouselItem,
	masterSearch,
} from '../../config/config';
import { toast } from 'react-toastify';
import postActions from './postsActions'

export const ADD_CAROUSEL_ITEM_REQUEST = 'ADD_CAROUSEL_ITEM_REQUEST';
export const ADD_CAROUSEL_ITEM_SUCCESS = 'ADD_CAROUSEL_ITEM_SUCCESS';
export const ADD_CAROUSEL_ITEM_ERROR = 'ADD_CAROUSEL_ITEM_ERROR';

export const EDIT_CAROUSEL_ITEM_REQUEST = 'EDIT_CAROUSEL_ITEM_REQUEST';
export const EDIT_CAROUSEL_ITEM_SUCCESS = 'EDIT_CAROUSEL_ITEM_SUCCESS';
export const EDIT_CAROUSEL_ITEM_ERROR = 'EDIT_CAROUSEL_ITEM_ERROR';

export const DELETE_CAROUSEL_ITEM_REQUEST = 'DELETE_CAROUSEL_ITEM_REQUEST';
export const DELETE_CAROUSEL_ITEM_SUCCESS = 'DELETE_CAROUSEL_ITEM_SUCCESS';
export const DELETE_CAROUSEL_ITEM_ERROR = 'DELETE_CAROUSEL_ITEM_ERROR';

export const GET_CAROUSEL_ITEM_REQUEST = 'GET_CAROUSEL_ITEM_REQUEST';
export const GET_CAROUSEL_ITEM_SUCCESS = 'GET_CAROUSEL_ITEM_SUCCESS';
export const GET_CAROUSEL_ITEM_ERROR = 'GET_CAROUSEL_ITEM_ERROR';

export const MASTER_SEARCH_REQUEST = 'MASTER_SEARCH_REQUEST';
export const MASTER_SEARCH_SUCCESS = 'MASTER_SEARCH_SUCCESS';
export const MASTER_SEARCH_ERROR = 'MASTER_SEARCH_ERROR';
export const CLEAR_MASTER_SEARCH = 'CLEAR_MASTER_SEARCH';

const actions = {
	clearMasterSearch:() =>(dispatch)=>{
		dispatch({
			type:CLEAR_MASTER_SEARCH,
		})
	},
	masterSearch:(data)=>async (dispatch)=>{
		try {
			dispatch({
				type:MASTER_SEARCH_REQUEST
			})
			await masterSearch(data)
				.then(response=>{
					if(response.data.code===200){
						dispatch({
							type:MASTER_SEARCH_SUCCESS,
							payload:response.data
						})
					}else{
						dispatch({
							type:MASTER_SEARCH_ERROR
						})
						toast.error(response?.data?.message)
					}
				})
		} catch (error) {
			dispatch({
				type:MASTER_SEARCH_ERROR
			})
			if(error?.response?.data?.message){
				toast.error(error.response.data.message)
			}else{
				toast.error(error)
			}
		}
	},
	getCarouselItems: () => async (dispatch) => {
		try {
			dispatch({
				type: GET_CAROUSEL_ITEM_REQUEST,
			});
			await getCarouselItems().then((response) => {
				if (response.data.code === 200) {
					dispatch({
						type: GET_CAROUSEL_ITEM_SUCCESS,
						payload: {
							data:response.data.data
						},
					});
				} else {
					dispatch({
						type: GET_CAROUSEL_ITEM_ERROR,
					});
                    toast.error(response?.data?.message)
				}
			});
		} catch (error) {
			dispatch({
				type: GET_CAROUSEL_ITEM_ERROR,
			});
            toast.error("Get Carousel Item Failed!")
		}
	},

	addCarouselItem: (data) => async (dispatch) => {
		try {
			dispatch({
				type: ADD_CAROUSEL_ITEM_REQUEST,
			});
			await addCarouselItem(data).then((response) => {
				if (response.data.code === 200) {
                    dispatch({
                        type: ADD_CAROUSEL_ITEM_SUCCESS,
					});
                    dispatch(actions.getCarouselItems());
                    // dispatch(postActions.popupHandler({ type: 'CLOSE' }))
                    toast.success(response?.data?.message)
				} else {
					dispatch({
						type: ADD_CAROUSEL_ITEM_ERROR,
					});
                    toast.error(response?.data?.message)
				}
			});
		} catch (error) {
			dispatch({
				type: ADD_CAROUSEL_ITEM_ERROR,
			});
            toast.error("Add Carousel Item Failed!")
		}
	},

	editCarouselItem: (data,id) => async (dispatch) => {
		try {
			dispatch({
				type: EDIT_CAROUSEL_ITEM_REQUEST,
			});
			await editCarouselItem(data,id).then((response) => {
				if (response.data.code === 200) {
					dispatch({
						type: EDIT_CAROUSEL_ITEM_SUCCESS,
					});
                    dispatch(actions.getCarouselItems());
                    // dispatch(postActions.popupHandler({ type: 'CLOSE' }))
                    toast.success(response?.data?.message)
				} else {
					dispatch({
						type: EDIT_CAROUSEL_ITEM_ERROR,
					});
                    toast.error(response?.data?.message)
				}
			});
		} catch (error) {
			dispatch({
				type: EDIT_CAROUSEL_ITEM_ERROR,
			});
            toast.error("Edit Carousel Item Failed!")
		}
	},

	deleteCarouselItem: (id) => async (dispatch) => {
		try {
			dispatch({
				type: DELETE_CAROUSEL_ITEM_REQUEST,
			});
			await deleteCarouselItem(id).then((response) => {
				if (response.data.code === 200) {
					dispatch({
						type: DELETE_CAROUSEL_ITEM_SUCCESS,
					});
					dispatch(actions.getCarouselItems());
                    dispatch(postActions.popupHandler({ type: 'CLOSE' }))
                    toast.success(response?.data?.message)
				} else {
					dispatch({
						type: DELETE_CAROUSEL_ITEM_ERROR,
					});
					toast.error(response?.data?.message)
				}
			});
		} catch (error) {
			dispatch({
				type: DELETE_CAROUSEL_ITEM_ERROR,
			});
			toast.error("Delete Carousel Item Failed!")
		}
	},

};

export default actions;
