import React, { useState, useEffect } from "react";
// import { Link } from 'react-router-dom';
// import { connect } from 'react-redux';
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfDocument3 from "./PdfDocument3";
import moment from "moment";
import NumberFormat from "react-number-format";

//images
import download from "../../assets/images/download_icon.svg";
function PurchaseRow(props) {
  return (
    <div style={styles.invoiceRow}>
      <div className="coach_row_row">
        <div className="coach_row_col wi-21">{props?.number}</div>
        <div className="coach_row_col wi-16">
          {props?.date ? moment(props.date).format("DD/MM/YYYY") : ""}
        </div>
        <div className="coach_row_col wi-13">
          <NumberFormat
            value={Number(props?.paid).toFixed(2)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </div>
        <div className="coach_row_col wi-21">
          <div>
            <PDFDownloadLink
              key={Math.random()}
              document={<PdfDocument3 data={props?.pdfData} />}
              fileName={`invoice_${props?.pdfData?.order_number}.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <div>
                    <span className="bold text-upper font-16 blue mr-3">
                      download PDF
                    </span>
                    <img src={download} alt="" />
                  </div>
                )
              }
            </PDFDownloadLink>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  invoiceRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "90px",
    background: "#fff",
    marginBottom: "10px",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.08)",
  },
};

export default PurchaseRow;
