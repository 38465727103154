import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from "../../history";

//CSS
import './EventsListRow.css';

//REDUX
import eventActions from '../../redux/actions/eventsActions';

//Images
import arrow from '../../assets/images/right-arrow-blck.svg';
import Checked from '../../assets/images/CheckBoxChecked.png';
import Unchecked from '../../assets/images/CheckBoxUnchecked.png';
import xWhite from '../../assets/images/x-delete.svg'
import moment from 'moment';
import { CheckBox } from '@material-ui/icons';

function EventsListRow(props) {

	const deleteEvent = () =>{
		props.dispatch(eventActions.deleteEvent(props.id, history))
	};

	const [isChecked, setIsChecked] = useState(false);

	useEffect(() => {
		setIsChecked(props.checked);
	}, [props.checked]);

	return (
		<div className='events_row_table'>
			<div className={props.status === "canceled" ? 'events_row_row_canceled events_row_row' : 'events_row_row'}>
			<div className='coach_row_col check_col wi-5'>
					<img
						src={!isChecked ? Unchecked : Checked}
						alt=''
						onClick={() => {
							props.handleChecked(props.id);
							setIsChecked(!isChecked);
						}}
						className='check_box_member'
					/>
				</div>
				<div className='wi-16 events_row_col'>
					<Link to={`/admin/events/${props.id}`} className='event_detail_link'  key={"link-" + props.id}>
						{props.title}
					</Link>
				</div>
				<div className='wi-10 events_row_col'>{props.subtitle}</div>
				<div className='wi-16 events_row_col event_date_col'>
					<div className='d-flex justify-content-between w-100'>
						<span className='blue bold text-upper'>START</span>
						<span className='mr-2'>{props.start_date ? moment(props.start_date).format("DD/MM/YYYY") : ''}</span>
					</div>
					<div className='d-flex justify-content-between w-100'>
						<span className='blue bold text-upper'>END</span>
						<span className='mr-2'>{props.end_date ? moment(props.end_date).format("DD/MM/YYYY") : ''}</span>
					</div>
					<div className='w-100'>
						<span>
							{props.time_start} - {props.time_end}
						</span>
					</div>
				</div>
				<div className='wi-16 events_row_col'>{props.location}</div>
				<div className='wi-10 events_row_col'> {props.available_space}</div>
				<div className='wi-8 events_row_col'> {props.vacant}</div>
				<div className='wi-8 events_row_col'> {props.occupied}</div>
				<div className='wi-8 events_row_col'> {props.status}</div>
				<div className='wi-8 events_edit_link'>
					{props.status === "canceled" ?
						<div className='arrow_div' onClick={() => deleteEvent()}>
							<img src={xWhite} alt="delete banner" />
						</div>
						: <Link to={`/admin/events/${props.id}/edit`} key={"edit-" + props.id}>
							<div className='arrow_div'>
								<img src={arrow} alt='edit' />
							</div>
						</Link>}
				</div>
			</div>
		</div>
	);
}

export default connect()(EventsListRow);
