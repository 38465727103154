import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

//REDUX
import postActions from '../../../redux/actions/postsActions';
//IMAGES
import postOne from '../../../assets/images/post_one.png';
//Components
import Post from '../../Noticeboard/Post';

import './popup.css';

function DeletePost(props) {
	const handleCancel = () => {
		props.dispatch(
			postActions.popupHandler({ type: 'CLOSE' })
		);
	};
	const handleDelete = () => {
		props.dispatch(postActions.deletePost({ id: props.selectedPost.id }));
	};
	return (
		<div>
			<span style={styles.title}>
				Are you sure you want to delete this post from your noticeboard ?
			</span>
			<div className='row ml-1'>
				<Post type={1} all={props?.selectedPost} delete='yes' />
			</div>
			<div>
				<div style={styles.buttonsWrapper}>
					<button
						onClick={() => {
							handleDelete();
						}}
						className='popup_button_2'
					>
						DELETE
					</button>
					<button
						onClick={() => {
							handleCancel();
						}}
						className='popup_button'
						style={{ marginLeft: '14px' }}
					>
						CANCEL
					</button>
					{props?.popupStateLoading === true && (
						<div className='text-center mx-auto mt-4'>
							<CircularProgress color='primary' size={30} />
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	selectedPost: state.postReducer?.selectedPost,
	popupStateLoading: state.postReducer?.popupStateLoading,
});

const styles = {
	title: {
		fontSize: '24px',
		color: '#262262',
	},
	buttonsWrapper: {
		marginTop: '39px',
	},
};

export default connect(mapStateToProps)(DeletePost);
