import React, { useState } from 'react'
import { connect } from 'react-redux'
import shopActions from '../../../redux/actions/shopActions'

import './popup.css';

//Images
import LineRedImg from '../../../assets/images/Line-red.svg';

export const StoreCategory = (props) => {
const [name, setName] = useState('');

const createCategory = (e) => {
    e.preventDefault();
    props.dispatch(shopActions.createStoreCategory({name: name }))
}

    return (
        <div>
            <div style={styles.headerContainer}>
				<img src={LineRedImg} alt='line Red' style={styles.lineRed} />
				<span style={styles.title}>Add Store Category</span>
			</div>
                <form onSubmit={createCategory}>
                        <label className='labels'>
                            Category Name
                        </label>
                        <input
                            type='text'
                            className='popup_Input_Carousel'
                            placeholder='Category Name'
                            name='name'
                            style={styles.inputTitle}
                            value={name}
                            required
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                        ></input>
                        
                        <div className='d-flex justify-content-between red'>
                        <button className='popup_button' style={styles.submit}
                        type="submit"
                            >
                                Create Category
                        </button>
                        </div>
                </form>
        </div>
    )
}
const styles = {
	title: {
		color: '#262262',
		fontSize: '36px',
		fontStyle: 'bold',
		marginTop: '5px',
        fontWeight: 'bold',
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	lineRed: {
		width: '88px',
	},
	inputTitle: {
		width: '100%',
        marginTop:0,
		fontSize: '14pt',
	},
	textArea: {
		width: '100%',
		height: '200px',
		fontSize: '14pt',
        color: '#262262',
        marginTop:0,
	},
    image_container: {
        width: '70%',
        height:'300px',
        objectFit: 'fill',	
    },
    submit:{
        marginTop: '15px'
    }
};

const mapStateToProps = (state) => ({
    
})



export default connect(mapStateToProps)(StoreCategory)