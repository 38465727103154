import React, { Fragment, useEffect } from "react";

import "./authLayout.scss";

const AuthLayout = ({ children }) => {
	// useEffect(() => {
	// 	document.body.classList.add("login");

	// 	return () => {
	// 		document.body.classList.remove("login");
	// 	};
	// }, []);

	return (
		<Fragment>
			<div className="auth-layout">
				<div className="wrapper">
					<div className="test">
						<div className="main-content">{children}</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default AuthLayout;