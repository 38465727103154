import React, { useState } from 'react'
import { connect } from 'react-redux'
import "react-datepicker/dist/react-datepicker.css";
import LineRedImg from '../../../assets/images/Line-red.svg';
import calendarImg from '../../../assets/images/calendar.svg';
import memberActions from '../../../redux/actions/membersAction';
import './popup.css';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import DatePickerComponent from '../../DatePickerComponent/DatePickerComponent';

export const AddVoucher = (props) => {
	const [formData, setFormData] = useState({
		percent: '',
		expirationDate: moment().toDate(),
		desc: '',
		parentId: props.popupData.parent,
		childId: props.popupData.child

	});
	const [isOpen, setIsOpen] = useState(false);

	const handleSubmit = () => {
		let finalForm = {
			amount: formData.percent,
			expiration_date: moment(formData.expirationDate).format("YYYY-MM-DD"),
			message: formData.desc
		}
		props.generateVoucher({
			parent: formData.parentId,
			child: formData.childId,
			data: finalForm
		})

	}

	return (
		<div>
			<div style={styles.headerContainer}>
				<img src={LineRedImg} alt='line Red' style={styles.lineRed} />
				<span style={styles.title}>CUSTOM VOUCHER</span>
			</div>
			<div style={styles.percDateContainer}>
				<div style={styles.expDateContainer}>
					<label style={styles.label}></label>
					<input
						style={styles.inputTitle}
						placeholder={'$'}
						className='popup_Input'
						value={formData.percent}
						type='number'
						max="100"
						min="0"
						onChange={(e) => {
							// let data = e.target.value;
							setFormData({ ...formData, percent: e.target.value })
							// if (data >= 0 && data <= 100) setFormData({ ...formData, percent: e.target.value });
							// else if (data < 0) setFormData({ ...formData, percent: 0 })
							// else if (data > 100) setFormData({ ...formData, percent: 100 })
						}}
					></input>
				</div>
				{/* <div className='add_voucher_input' style={{background:`url("${calendarImg}") no-repeat 90% #f2f2f2`}}>
					<DatePicker
						name='expirationDate'
						selected={formData.expirationDate}
						dateFormat="yyyy-MM-dd"
						minDate={moment().toDate()}
						onChange={(e) => {
							setFormData({ ...formData, expirationDate: e});
						}}
						open={isOpen}
						onCalendarOpen={() => setIsOpen(true)}
						onCalendarClose={() => setIsOpen(false)}
					/>
					{/* <img src={calendarImg} alt="calendar" className='calImg' onClick={() => handleOpenCalendar()} /> 
				</div> */}
				<div style={styles.expDateContainer}>
					<label style={styles.label}>Expiry date</label>
					<DatePickerComponent
						containerClass="add_voucher_input"
						name='expirationDate'
						selected={formData.expirationDate}
						minDate={moment().toDate()}
						handleDateChange={(e) => {
							setFormData({ ...formData, expirationDate: e });
						}}
						open={isOpen}
						setIsOpenTrue={() => setIsOpen(true)}
						setIsOpenFalse={() => setIsOpen(false)}
					/>
				</div>
			</div>
			<textarea
				style={styles.textArea2}
				placeholder={'Message'}
				className='popup_Input'
				value={formData.desc}
				onChange={(e) => {
					setFormData({ ...formData, desc: e.target.value });
				}}
			></textarea>
			<button
				className='popup_button'
				onClick={() => {
					handleSubmit()
				}}
			>
				{props.customVoucherLoading === true ? <div className='text-center mx-auto mt-4'>
					<CircularProgress color='primary' size={30} />
				</div> : "SEND VOUCHER"}
			</button>
		</div>
	)
}

const styles = {
	title: {
		color: '#262262',
		fontSize: '36px',
		fontStyle: 'bold',
		marginTop: '5px',
	},
	percDateContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: '12px',
		alignItems: 'flex-end'
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	lineRed: {
		width: '88px',
	},
	inputTitle: {
		width: '100%',
		// marginTop: '29px',
		margin: 0,
		fontSize: '16px',
	},
	textArea2: {
		width: '100%',
		height: '300px',
		fontSize: '16px',
	},
	label: {
		fontSize: '12px',
		margin: 0
	},

	expDateContainer: {
		display: 'flex',
		flexDirection: 'column',
		// alignItems: 'center',
		width: '45%',
	}
};

const mapStateToProps = (state) => ({
	popupData: state.postReducer.selectedPost,
	customVoucherLoading: state.membersReducers.customVoucherLoading
})

const mapDispatchToProps = (dispatch) => ({
	generateVoucher: (data) => dispatch(memberActions.generateVoucher(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddVoucher)
