import React, { useState } from "react";
import ForgotPasswordView from "./ForgotPassword.view";
import "./forgotPassword.scss";
import { ResetPasswordLink } from "../../actions/auth";
import { connect } from "react-redux";
const ForgotPassword = ({ ResetPasswordLink }) => {
  //   const history = useHistory();
  const [formData, setFormData] = useState({
    email: "",
  });

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    // console.log("test");
    e.preventDefault();
    // console.log("test1");

    //setLoading(true);
    ResetPasswordLink(formData);
    // setLoading(false);
  };

  return (
    <ForgotPasswordView
      formData={formData}
      onChange={onChange}
      onSubmit={onSubmit}
    />
  );
};

const mapStateToProps = (state) => ({
  auth: state.register,
});

export default connect(mapStateToProps, { ResetPasswordLink })(ForgotPassword);
