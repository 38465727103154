import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LineRedImg from "../../../assets/images/Line-red.svg";
import "./popup.css";
import { CircularProgress } from "@material-ui/core";
import checkedBox from "../../../assets/images/CheckBoxChecked.png";
import uncheckedBox from "../../../assets/images/CheckBoxUnchecked.png";
import postActions from "../../../redux/actions/postsActions";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import BlackX from "../../../assets/images/x-Black.png";
import eventActions from "../../../redux/actions/eventsActions";
import coachActions from "../../../redux/actions/coachPortalActions";

export const CustomEmailEvent = (props) => {
  console.log(props.popupData);
  const [formData, setFormData] = useState({
    to: props.popupData.data,
    body: "",
    include: true,
  });

  const handleSend = () => {
    let emailRecipients = "";
    formData.to.map((zone, i) => {
      if (i === 0) {
        emailRecipients += `${zone.user_id}`;
      } else {
        emailRecipients += `,${zone.user_id}`;
      }
    });
    let dataToSend = {
      members: emailRecipients,
      body: formData.body,
      include: formData.include,
    };
    if (props?.popupState === "CUSTOM_EMAIL_EVENT_COACH") {
      console.log(
        "%cEMAIL_COACH",
        "background-color:black;color:white",
        dataToSend
      );
      props.sendEmailCoach({
        id: props.popupData.event_id,
        data: dataToSend,
      });
    } else {
      props.sendInvitationToAllMembers({
        id: props.popupData.event_id,
        data: dataToSend,
      });
    }
  };
  useEffect(() => {
    if (formData.to.length === 0) {
      props.popupHandler();
    }
  }, [formData.to]);
  return (
    <div>
      <div style={styles.headerContainer}>
        <img src={LineRedImg} alt="line Red" style={styles.lineRed} />
        <span style={styles.title}>EDIT EMAIL CONTENT</span>
      </div>
      <div style={styles.inputTitle}>EMAIL RECIPIENTS</div>
      <div style={styles.recipientsContainer}>
        {formData?.to?.map((zone, i) => {
          return (
            <div key={`email-${i}`} style={styles.recipientBox}>
              <div style={styles.recipientEmail}>{zone?.email}</div>
              <div
                onClick={() => {
                  setFormData({
                    ...formData,
                    to: [
                      ...formData.to.filter((x) => x.user_id !== zone.user_id),
                    ],
                  });
                }}
              >
                <img style={styles.blackX} src={BlackX} width="10px" alt="" />
              </div>
            </div>
          );
        })}
      </div>
      <div style={styles.inputTitle}>EMAIL BODY</div>
      <CKEditor
        style={styles.textArea2}
        className="popup_Input"
        editor={ClassicEditor}
        data={formData.body}
        config={{
          toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setFormData({ ...formData, body: data });
        }}
      />
      <div
        style={styles.checkBoxContainer}
        onClick={() => {
          setFormData({ ...formData, include: !formData.include });
        }}
      >
        <img
          src={!formData.include ? uncheckedBox : checkedBox}
          alt="check"
          className="mr-1"
        />
        <div>INCLUDE LINK TO EVENT DETAILS</div>
      </div>
      <button
        className="popup_button"
        onClick={() => {
          handleSend();
        }}
      >
        {
          /* dispatch Loading */ false === true ? (
            <div className="text-center mx-auto mt-4">
              <CircularProgress color="primary" size={30} />
            </div>
          ) : (
            "SEND EMAIL"
          )
        }
      </button>
    </div>
  );
};

const styles = {
  title: {
    color: "#262262",
    fontSize: "36px",
    fontStyle: "bold",
    marginTop: "5px",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
  },
  lineRed: {
    width: "88px",
  },
  inputTitle: {
    fontWeight: "bold",
    width: "100%",
    color: "#262262",
    marginTop: "29px",
    fontSize: "16px",
  },
  input: {
    width: "100%",
    fontSize: "16px",
  },
  recipientsContainer: {
    display: "flex",
    width: "100%",
    overflowX: "auto",
    padding: ".5rem",
    backgroundColor: "#f2f2f2",
    fontSize: "12px",
  },
  recipientBox: {
    display: "flex",
    border: "1px solid #000",
    boxShadow: "1px 1px 4px 0 #000",
    margin: ".25rem .5rem",
    padding: "0 .5rem",
  },
  recipientEmail: {
    marginRight: ".25rem",
  },
  textArea2: {
    width: "100%",
    height: "300px",
    fontSize: "16px",
  },
  blackX: {
    cursor: "pointer",
  },
  checkBoxContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    fontSize: "16px",
    color: "#d91c24",
    cursor: "pointer",
  },
};

const mapStateToProps = (state) => ({
  popupData: state.postReducer.selectedPost,
  popupState: state.postReducer.popupState,
});

const mapDispatchToProps = (dispatch) => ({
  popupHandler: () => dispatch(postActions.popupHandler({ type: "CLOSE" })),
  sendInvitationToAllMembers: (data) =>
    dispatch(eventActions.sendInvitationToAllMembers(data)),
  sendEmailCoach: (data) => dispatch(coachActions.sendEmailCoach(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomEmailEvent);
