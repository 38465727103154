import {
  getPosts,
  createPost,
  editPost,
  deletePost,
} from "../../config/config";
import { toast } from "react-toastify";

export const LIST_POSTS_REQUEST = "LIST_POSTS_REQUEST";
export const LIST_POSTS_SUCCESS = "LIST_POSTS_SUCCESS";
export const LIST_POSTS_ERROR = "LIST_POSTS_ERROR";

export const CREATE_POST_REQUEST = "CREATE_POST_REQUEST";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const CREATE_POST_ERROR = "CREATE_POST_ERROR";

export const EDIT_POST_REQUEST = "EDIT_POST_REQUEST";
export const EDIT_POST_SUCCESS = "EDIT_POST_SUCCESS";
export const EDIT_POST_ERROR = "EDIT_POST_ERROR";

export const DELETE_POST_REQUEST = "DELETE_POST_REQUEST";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_ERROR = "DELETE_POST_ERROR";

export const POPUP_STATE = "POPUP_STATE";

const actions = {
  listPosts: (data) => async (dispatch) => {
    try {
      dispatch({
        type: LIST_POSTS_REQUEST,
      });
      await getPosts(data, "Bearer " + localStorage.access_token).then( 
        (response) => {
          if (response.data.code === 200) {
            dispatch({
              type: LIST_POSTS_SUCCESS,
              payload: {
                data: response.data.data,
              },
            });
          } else {
            dispatch({
              type: LIST_POSTS_ERROR,
              payload: {
                message: response.message,
              },
            });
          }
        }
      );
    } catch (error) {
      dispatch({
        type: LIST_POSTS_ERROR,
        payload: {
          message: "Error Loading Posts",
        },
      });
    }
  },

  createPost: (data) => async (dispatch) => {
    try {
      dispatch({
        type: CREATE_POST_REQUEST,
      });
      await createPost(data).then((response) => {
        if (response.data.code === 200) {
          toast.success("Post Created");
          dispatch({
            type: CREATE_POST_SUCCESS,
            payload: {
              data: response.data.data.data,
            },
          });
          dispatch(actions.listPosts({ thisMonth: 9, previous: 9 }));
        } else {
         // toast.error("Error creating post");
          dispatch({
            type: CREATE_POST_ERROR,
          });
        }
      });
    } catch (error) {
     // toast.error("Error creating post");
      dispatch({
        type: CREATE_POST_ERROR,
      });
    }
  },

  editPost: (data) => async (dispatch) => {
    try {
      dispatch({
        type: EDIT_POST_REQUEST,
      });
      await editPost(data, "Bearer " + localStorage.access_token).then(
        (response) => {
          if (response?.data?.code === 200) {
            toast.success("Post Updated");
            dispatch(actions.listPosts({ thisMonth: 9, previous: 9 }));
            dispatch({
              type: EDIT_POST_SUCCESS,
            });
          } else {
            toast.error("Error: " + response?.data?.message);
            dispatch({
              type: EDIT_POST_ERROR,
            });
          }
        }
      );
    } catch (error) {
      toast.error("Error editing post");
      dispatch({
        type: EDIT_POST_ERROR,
      });
    }
  },

  deletePost: (data) => async (dispatch) => {
    try {
      dispatch({
        type: DELETE_POST_REQUEST,
      });
      await deletePost(data).then((response) => {
        if (response.data.code === 200) {
          dispatch(actions.listPosts({ thisMonth: 9, previous: 9 }));
          toast.success("Post Deleted");
          dispatch(actions.popupHandler({ type: 'CLOSE' }))
          dispatch({
            type: DELETE_POST_SUCCESS,
            payload: {
              data: response.data.data.data,
            },
          });
        } else {
          toast.error("Error: " + response.message);
          dispatch({
            type: DELETE_POST_ERROR,
          });
        }
      });
    } catch (error) {
      toast.error(error.message);
      dispatch({
        type: DELETE_POST_ERROR,
      });
    }
  },

  popupHandler: (data) => async (dispatch) => {
    try {
      if (data.type === "ADD") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "ADD",
            selectedData: null,
          },
        });
      } else if (data.type === "EDIT") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "EDIT",
            selectedData: data.data,
          },
        });
      } else if (data.type === "DELETE") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "DELETE",
            selectedData: data.data,
          },
        });
      } else if (data.type === "ADD_SCHOOL") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "ADD_SCHOOL",
            selectedData: null,
          },
        });
      } else if (data.type === "EDIT_SCHOOL") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "EDIT_SCHOOL",
            selectedData: data.data,
          },
        });
      } else if (data.type === "DELETE_SCHOOL") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "DELETE_SCHOOL",
            selectedData: data.data,
          },
        });
      } else if (data.type === "DELETE_COACH") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "DELETE_COACH",
            selectedData: data.data,
          },
        });
      } else if (data.type === "DELETE_MEMBERS") {
          dispatch({
            type: POPUP_STATE,
            payload: {
              data: "DELETE_MEMBERS",
              selectedData: data.data,
            },
        });
      }else if (data.type === "DELETE_PRODUCTS") {
          dispatch({
            type: POPUP_STATE,
            payload: {
              data: "DELETE_PRODUCTS",
              selectedData: data.data,
            },
          });
        }
      else if (data.type === "VIEW_ORDER_ITEMS") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "VIEW_ORDER_ITEMS",
            selectedData: data.data,
          },
        });
      }else if (data.type === "ADD_EVENT_CATEGORY") {
          dispatch({
            type: POPUP_STATE,
            payload: {
              data: "ADD_EVENT_CATEGORY",
              selectedData: null,
            },
          });
      }else if (data.type === "EDIT_EVENT_CATEGORY") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "EDIT_EVENT_CATEGORY",
            selectedData: data.data,
          },
        });
    } else if (data.type === "CUSTOMREC") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "CUSTOMREC",
            selectedData: null,
          },
        });
      } else if (data.type === "ADDPROG") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "ADDPROG",
            selectedData: null,
          },
        });
      } else if (data.type === "CUSTOMREC_EDIT") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "CUSTOMREC_EDIT",
            selectedData: data.data,
          },
        });
      } else if (data.type === "ADDPROG_EDIT") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "ADDPROG_EDIT",
            selectedData: data.data,
          },
        });
      } else if (data.type === "CONFIRMDELEVENT") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "CONFIRMDELEVENT",
            selectedData: data.data,
          },
        });
      } else if (data.type === "CLOSE") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "CLOSED",
            selectedData: null,
          },
        });
      } else if (data.type === "VOUCHER") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "VOUCHER",
            selectedData: data.data,
          },
        });
      } else if (data.type === "EDIT_EVENT_PROG_ADD") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "EDIT_EVENT_PROG_ADD",
            selectedData: data.data,
          },
        });
      } else if (data.type === "EDIT_EVENT_PROG_EDIT") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "EDIT_EVENT_PROG_EDIT",
            selectedData: data.data,
          },
        });
      } else if (data.type === "ADD_CAROUSEL_ITEM") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "ADD_CAROUSEL_ITEM",
            selectedData: null,
          },
        });
      } else if (data.type === "EDIT_CAROUSEL_ITEM") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "EDIT_CAROUSEL_ITEM",
            selectedData: data.data,
          },
        });
      } else if (data.type === "DELETE_CAROUSEL_ITEM") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "DELETE_CAROUSEL_ITEM",
            selectedData: data.data,
          },
        });
      } else if (data.type === "CUSTOM_EMAIL_EVENT") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "CUSTOM_EMAIL_EVENT",
            selectedData: data,
          },
        });
      }else if (data.type === "ADD_STORE_CATEGORY") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "ADD_STORE_CATEGORY",
            selectedData: null,
          },
        }); 
      } else if (data.type === "CUSTOM_EMAIL_EVENT_COACH") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "CUSTOM_EMAIL_EVENT_COACH",
            selectedData: data,
          },
        });
      } else if (data.type === "CLONE_EVENTS") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "CLONE_EVENTS",
            selectedData: data,
            postAction: data.action
          },
        });
      } else if (data.type === "ADD_NEW_COUPON") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "ADD_NEW_COUPON",
            selectedData: null,
          },
        });
      } else if (data.type === "DEACTIVATE_COUPONS") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "DEACTIVATE_COUPONS",
            selectedData: data,
            postAction: data.action
          },
        });
      }
      else if (data.type === "VIEW_BOOKED_ITEMS") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "VIEW_BOOKED_ITEMS",
            selectedData: data,
          },
        });
      } else if (data.type === "INFO") {
        dispatch({
          type: POPUP_STATE,
          payload: {
            data: "INFO",
            selectedData: data
          },
        });
      }
    } catch (error) {
      dispatch({
        type: POPUP_STATE,
        payload: {
          data: "CLOSED",
        },
      });
    }
  },
};

export default actions;
