import {
  STORE_CATEGORY_LIST_SUCCESS,
  STORE_CATEGORY_LIST_LOADING,
  STORE_CATEGORY_LIST_FAILURE,
  STORE_PRODUCTS_LIST_SUCCESS,
  STORE_PRODUCTS_LIST_LOADING,
  STORE_PRODUCTS_LIST_FAILURE,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_LOADING,
  CATEGORY_LIST_FAILURE,
  RELATED_PRODUCT_LIST_SUCCESS,
  RELATED_PRODUCT_LIST_LOADING,
  RELATED_PRODUCT_LIST_FAILURE,
  CREATE_CATEGORIES_SUCCESS,
  STORE_PRODUCTS_DETAIL_SUCCESS,
  STORE_PRODUCTS_DETAIL_LOADING,
  STORE_PRODUCTS_DETAIL_FAILURE,
  STORE_PRODUCTS_ADD_SUCCESS,
  STORE_PRODUCTS_ADD_LOADING,
  STORE_PRODUCTS_ADD_FAILURE,
  STORE_PRODUCTS_EDIT_SUCCESS,
  STORE_PRODUCTS_EDIT_LOADING,
  STORE_PRODUCTS_EDIT_FAILURE,
  STORE_PRODUCTS_DELETE_SUCCESS,
  STORE_PRODUCTS_DELETE_LOADING,
  STORE_PRODUCTS_DELETE_FAILURE,
  CATEGORY_DETAIL,
} from "./types";
import { getValue } from "../utils/app";
import apiService from "../services/apiService";
import { toast } from "react-toastify";
import history from "../history";
export const fetchStoreCategories = (page, name) => async (dispatch) => {
  dispatch({
    type: STORE_CATEGORY_LIST_LOADING,
  });
  let url = `api/admin/store/categories?item_per_page=10`;
  if (page) {
    url += `&page=${page}`;
  }
  if (name) {
    url += `&name=${name}`;
  }
  apiService.get(url).then(
    (response) => {
      dispatch({
        type: STORE_CATEGORY_LIST_SUCCESS,
        payload: getValue(response, "response.data.data"),
      });
    },
    (error) => {
      dispatch({
        type: STORE_CATEGORY_LIST_FAILURE,
      });
    }
  );
};

export const fetchStoreProducts = (category_id, page, name) => async (
  dispatch
) => {
  dispatch({
    type: STORE_PRODUCTS_LIST_LOADING,
  });
  let url = `api/admin/store/categories/${category_id}/products`;
  if (page) {
    url += `?page=${page}`;
  }
  if (name) {
    url += `&name=${name}`;
  }
  apiService.get(url).then(
    (response) => {
      dispatch({
        type: STORE_PRODUCTS_LIST_SUCCESS,
        payload: getValue(response, "response.data.data"),
      });
    },
    (error) => {
      dispatch({
        type: STORE_PRODUCTS_LIST_FAILURE,
      });
    }
  );
};

export const createEditCategory = (name, id) => async (dispatch) => {
  let url;
  if (id) {
    url = "api/admin/store/categories/" + id;
  } else {
    url = "api/admin/store/categories";
  }

  let data = {
    name: name,
  };

  try {
    apiService.post(url, data).then(
      (response) => {
        toast.success("Your Store Category Saved Successfully!");
        dispatch({
          type: CREATE_CATEGORIES_SUCCESS,
          payload: getValue(response, "response.data.data.id"),
        });
        // history.push("/admin/stores");
      },
      (error) => {
        toast.error("Your Store Category Saved Failed!");
      }
    );
  } catch { }
};

export const fetchListOfCategory = () => async (dispatch) => {
  dispatch({
    type: CATEGORY_LIST_LOADING,
  });
  let url = `api/admin/store/categories`;

  apiService.get(url).then(
    (response) => {
      dispatch({
        type: CATEGORY_LIST_SUCCESS,
        payload: getValue(response, "response.data.data"),
      });
    },
    (error) => {
      dispatch({
        type: CATEGORY_LIST_FAILURE,
      });
    }
  );
};

export const fetchListOfRelatedProducts = () => async (dispatch) => {
  dispatch({
    type: RELATED_PRODUCT_LIST_LOADING,
  });
  let url = `api/admin/store/products`;

  apiService.get(url).then(
    (response) => {
      let data = [];
      getValue(response, "response.data.data").map((product) => {
        // console.log(product.name);
        data.push({ value: product.id, label: product.name });
      });
      dispatch({
        type: RELATED_PRODUCT_LIST_SUCCESS,
        payload: data,
      });
    },
    (error) => {
      dispatch({
        type: RELATED_PRODUCT_LIST_FAILURE,
      });
    }
  );
};

export const createProduct = (formData, category_id) => async (dispatch) => {
  dispatch({
    type: STORE_PRODUCTS_ADD_LOADING
  })
  let url = `api/admin/store/products`;

  apiService.post(url, formData).then(
    (response) => {
      if (response.data.code === 200) {
        dispatch({
          type: STORE_PRODUCTS_ADD_SUCCESS
        })
        toast.success("Your Product created successfully!");
        // history.push(`/admin/stores/${category_id}/products`);
      } else {
        toast.error(response.message)
        dispatch({
          type: STORE_PRODUCTS_ADD_FAILURE
        })
      }

    },
    (error) => {
      // toast.error(error.message);
      dispatch({
        type: STORE_PRODUCTS_ADD_FAILURE
      })
    }
  );
};


export const updateProduct = (product_id, formData, category_id) => async (
  dispatch
) => {
  dispatch({
    type: STORE_PRODUCTS_EDIT_LOADING
  })
  let url = `api/admin/store/products/${product_id}`;

  apiService.post(url, formData).then(
    (resp) => {
      if (resp.data.code === 200) {
        dispatch({
          type: STORE_PRODUCTS_EDIT_SUCCESS
        })
        toast.success("Your Product updated successfully!");
        // history.push(`/admin/stores/${category_id}/products`);
      }
      else {
        toast.error(resp?.message || 'Validation Error')
        dispatch({
          type: STORE_PRODUCTS_EDIT_FAILURE
        })
      }
    }).catch(
      (error) => {
        dispatch({
          type: STORE_PRODUCTS_EDIT_FAILURE
        })
      }
    );
};

export const fetchProduct = (product_id) => async (dispatch) => {
  dispatch({
    type: STORE_PRODUCTS_DETAIL_LOADING,
  });
  let url = `api/admin/store/products/${product_id}`;

  apiService.get(url).then(
    (response) => {
      // console.log('api req product details', response)
      dispatch({
        type: STORE_PRODUCTS_DETAIL_SUCCESS,
        payload: getValue(response, "response.data.data"),
      });
    },
    (error) => {
      dispatch({
        type: STORE_PRODUCTS_DETAIL_FAILURE,
      });
    }
  );
};


export const deleteProduct = (product_id, category_id) => async (dispatch) => {
  dispatch({
    type: STORE_PRODUCTS_DELETE_LOADING
  })
  let url = `api/admin/store/products/${product_id}`;

  apiService.deleteCall(url).then(
    (response) => {
      if (response.data.code === 200) {
        dispatch(fetchStoreProducts(category_id), { type: STORE_PRODUCTS_DELETE_SUCCESS });
        toast.success("Your product delete successfully!");

      }
      else {
        toast.error(response.message)
        dispatch({
          type: STORE_PRODUCTS_DELETE_FAILURE
        })
      }
      // history.push(`/admin/stores/${category_id}/products`);
    },
    (error) => {
      toast.success(error.message);
      dispatch({
        type: STORE_PRODUCTS_DELETE_FAILURE
      })
    }
  );
};

export const setCategoryDetail = (data) => async (dispatch) => {
  dispatch({
    type: CATEGORY_DETAIL,
    payload: data,
  });
};
