import React , {useEffect} from 'react'
import { connect } from 'react-redux'

import profileActions from '../../../redux/actions/profileActions'

import './schoolStyle.scss'

export const EditSchool = (props) => {

    const [school, setSchool] = React.useState({school_name: '',suburb:''});

    const handleDataChange = (e,name) => {
        setSchool({...school,
            [name]: e.target.value
        })
    }

    const editSchool = (e) => {
        e.preventDefault();

        let finalData={
            school_name:school.school_name,
            suburb:school.suburb,
            id:school.id? school?.id : null
        }
        props.dispatch(profileActions.editSchool({ data: finalData }));
        
        
    }

    useEffect(() => {
        setSchool({
            school_name:props?.selectedPost?.school_name ? props?.selectedPost?.school_name : '' ,
            suburb:props?.selectedPost?.suburb ? props?.selectedPost?.suburb: '', 
            id:props?.selectedPost?.id? props?.selectedPost?.id : null
        })
    }, [props.selectedPost])
    return (
        <div>
        <h3 className='blue bold'>Edit School</h3>
        <form onSubmit={editSchool} id="edit_school_form">
            <div className='event_edit_input school_div w-50 position-relative blue regular font-16'>
                <label className='bold text-upper blue font-14'>
                    School Name *
                </label>
                <input
                    required
                    type='text'
                    placeholder='School'
                    name='school_name'
                    className='school_input'
                    value={school.school_name}
                    onChange={(e) => {
                        handleDataChange(e,'school_name');
                    }}
                ></input>
                <label className='bold text-upper blue font-14'>
                    Suburb *
                </label>
                <input
                    type='text'
                    placeholder='Suburb'
                    name='suburb'
                    className='school_input'
                    value={school.suburb}
                    onChange={(e) => {
                        handleDataChange(e,'suburb');
                    }}
                    required
                ></input>
            </div>
            <button className='white  bold blue-bg add_school_btn_popup'
            type="submit" 
                >
                    Update School
            </button>
        </form>
    </div>
    )
}

const mapStateToProps = (state) => ({
	selectedPost: state.postReducer?.selectedPost,
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps)(EditSchool)
