import React, { Fragment } from "react";
import { Router, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "../src/redux/store";
import "./App.scss";
import Routes from "./Routes";
import history from "./history";
import Add from "./Components/PopUps/Posts/PopUp"
function App() {
  return (
    <Fragment>
      <Provider store={store}>
        <Router history={history}>
          <Route component={Routes} />
          <Add />

          <ToastContainer />
        </Router>
      </Provider>
    </Fragment>
  );
}

export default App;
