import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

//CSS
import '../Events/EventsListRow.css';

//Images
import arrow from '../../assets/images/right-arrow-blck.svg';

function EventsListRow(props) {
	return (
		<div className='events_row_table'>
			<div
				className={
					props?.status === 'canceled'
						? 'events_row_row_canceled events_row_row'
						: 'events_row_row'
				}
			>
				<div className='wi-16 events_row_col'>{props?.title}</div>
				<div className='wi-10 events_row_col'>{props?.subtitle}</div>
				<div className='wi-16 events_row_col event_date_col'>
					<div className='d-flex justify-content-between w-100'>
						<span className='blue bold text-upper'>START</span>
						<span className='mr-2'>{moment(props?.start_date).format('DD/MM/YYYY')}</span>
					</div>
					<div className='d-flex justify-content-between w-100'>
						<span className='blue bold text-upper'>END</span>
						<span className='mr-2'>{moment(props?.end_date).format('DD/MM/YYYY')}</span>
					</div>
					<div className='w-100'>
						<span>
							{props?.time_start} - {props?.time_end}
						</span>
					</div>
				</div>
				<div className='wi-16 events_row_col'>{props?.location}</div>
				<div className='wi-10 events_row_col'> {props?.available_space}</div>
				<div className='wi-8 events_row_col'> {props?.vacant}</div>
				<div className='wi-8 events_row_col'> {props?.occupied}</div>
				<div className='wi-8 events_row_col'> {props?.status}</div>
				<div className='wi-8 events_edit_link'>
					{props?.status === 'canceled' ? null : (
						<Link to={`/coach/events/${props?.id}`}>
							<div className='arrow_div'>
								<img src={arrow} alt='edit' />
							</div>
						</Link>
					)}
				</div>
			</div>
		</div>
	);
}

export default EventsListRow;
