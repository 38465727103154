import React, { useState } from 'react';
import { Link } from 'react-router-dom';

//Config
import { baseURL } from '../../config/config';

//CSS
import './MembersRow.css';

//Images
import arrow from '../../assets/images/right-arrow-blck.svg';
import Checked from '../../assets/images/CheckBoxChecked.png';
import Unchecked from '../../assets/images/CheckBoxUnchecked.png';
import brokenIMG from '../../assets/images/brokenIMG.png';
import moment from 'moment';

function MembersRow(props) {
	const date = props?.register_date ? moment(props?.register_date).format('DD/MM/YYYY') : 'N/A';
	const dateBooking = props?.last_booking;

	const changeImageToDefault = (e) => {
		e.target.src = brokenIMG
	}

	// console.log(props?.register_date);

	return (
		<div className='coach_row_table'>
			<div className='coach_row_row'>
				<div className='coach_row_col check_col wi-5'>
					<img
						src={!props.checked ? Unchecked : Checked}
						alt=''
						onClick={() => {
							props.handleChecked(props.link);
						}}
						className='check_box_member'
					/>
				</div>
				<div className='coach_row_col member_img wi-10'>
					<img
						src={baseURL + '/image/' + props?.profile_img}
						alt=''
						className='coach_img'
						onError={changeImageToDefault}
					/>
				</div>
				<div className='coach_row_col wi-10'>{props?.first_name}</div>
				<div className='coach_row_col wi-10'>{props.last_name}</div>
				<div className='coach_row_col wi-10'>
						{props.mobile_number ? props.mobile_number : 'N/A'}
				</div>
				<div className='coach_row_col wi-10'>
					{props.email}
				</div>
				<div className='coach_row_col wi-13 pt-2 pb-2'>
					{props.street === `${null}, ${null}`  ? `N/A` : `${props.street}, ${props.suburb}, ${props.state}, ${props.postal_code}`}
					
				</div>
				<div className='coach_row_col wi-10'>
					{date}
				</div>
				<div className='coach_row_col wi-10'>
					{dateBooking}
				</div>
				<div className='coach_row_col wi-8'>{props.number_kids}</div>
				<div className='coach_row_col coach_detail_link wi-8'>
					<Link to={`/admin/members/${props.link}`}>
						<div className='arrow_div'>
							<img src={arrow} alt='view/edit' />
						</div>
					</Link>
				</div>
			</div>
		</div>
	);
}

export default MembersRow;
