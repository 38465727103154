import React from "react";
import { Link } from "react-router-dom";

import back from "../../assets/images/back.svg";
import logo from "../../assets/images/no1dtraft-img.svg";
import redLine from "../../assets/images/Line-red.svg";

const ResetPasswordView = ({ onSubmit, onChange, formData }) => (
  <div className="reset-password">
    <div className="container">
      <div className="center_part d-inline-block w-100 text-left">
        <div className="center_part_container">
          <div className="no1draft_logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="form_part">
            <div className="form_style">
              <img src={redLine} alt="red_line" />
            </div>
            <div className="form_title white font-36 bold">
              CREATE A NEW PASSWORD
            </div>
            <form onSubmit={(e) => onSubmit(e)}>
              <div className="row row-10 mt-4">
                <div className="text_one_form white regular font-16 mt-2 col-md-4">
                  <input
                    type="password"
                    className="white"
                    placeholder="New password"
                    value={formData.new_password}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    name="new_password"
                  ></input>
                  the password should be more than 6 characters long, at least one numeric value, one lower case letter one upper case letter, and inclusion of at least one special character, e.g., ! @ # ?

                </div>
                <div className="text_one_form white regular font-16  mt-2 col-md-4">
                  <input
                    className="white"
                    type="password"
                    placeholder="Confirm Password"
                    value={formData.confirm_password}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    name="confirm_password"
                  ></input>
                </div>
                <div className="mt-2 col-md-4">
                  <input
                    type="submit"
                    className="button_edit bold font-14 white red-bg border-red"
                    value="SAVE PASSWORD"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ResetPasswordView;
