import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  USER_LOADED,
  AUTH_FAILED,
  AUTH_REQUEST,
  LOGOUT,
  SET_ALERT,
  EDIT_PROFILE_REQUEST,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_ERROR,
} from "./types";
import axios from "axios";
import { getValue } from "../utils/app";
import apiService from "../services/apiService";
import history from "../history";
import { toast } from 'react-toastify';
export const loadUser = () => async (dispatch) => {
  //({ type: LOGOUT });

  let portal =
    localStorage.portal === undefined ? "companyportal" : localStorage.portal;
  if (localStorage.portal === "assessorportal") {
    portal = "assessor";
  }
  apiService
    .get("api/" + portal + "/userdetail", { subdomain: localStorage.subdomain })
    .then(
      (response) => {
        /*
        A user detail will get saved in reducer
      */
        dispatch({
          type: USER_LOADED,
          payload: response.data.result,
        });
      },
      (error) => {
        const status = getValue(error, "error.response.status");
        const errorCode = getValue(error, "error.response.data.error");

        // dispatch({
        //   type: SET_ALERT,
        //   payload: error,
        // });
      }
    );
};

export const register = ({ name, email, password }) => async (dispatch) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ name, email, password });

  try {
    const res = await axios.post("/api/auth/register", body, options);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      dispatch({
        type: SET_ALERT,
        payload: errors,
      });
    }
    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

export const login = (formData) => async (dispatch) => {
  try {
    dispatch({ type: AUTH_REQUEST });
    apiService.post("user/login", formData).then(
      (response) => {
        // console.log('LOGIN RESPONSE', response)
        dispatch({
          type: SET_ALERT,
          payload: { general: "" },
        });

        dispatch({
          type: LOGIN_SUCCESS,
          payload: { ...getValue(response, "response.data.data.user") },
        });

        dispatch({
          type: USER_LOADED,
          payload: response?.data?.data?.user,
        });
      },
      (error) => {
        // toast.error(error?.data?.message)
        const status = getValue(error, "error.response.status");
        const errorCode = getValue(error, "error.response.data.error");
        const errorDescription = getValue(
          error,
          "error.response.data.error_description"
        );
        if (status === 404) {
          // dispatch(
          //   setUserLoggedIn({
          //     invalidLogin: true,
          //     errorDescription: errorDescription,
          //   })
          // );
          // return;
        }
        dispatch({
          type: AUTH_FAILED,
        });
        dispatch({
          type: SET_ALERT,
          payload: { general: { msg: errorCode } },
        });
      }
    );
  } catch (err) {
    console.log('ERROR SIGN IN', err.response)
    dispatch({
      type: AUTH_FAILED,
    });
  }
};

export const ResetPasswordLink = (formData) => async (dispatch) => {
  dispatch({ type: AUTH_REQUEST });
  apiService.post("user/forgot-password", formData).then(
    (response) => {
      // dispatch({ type: LOGOUT });
      dispatch({
        type: SET_ALERT,
        payload: {
          general: { msg: "Password reset sent successfully", type: "success" },
        },
      });
      if (response.data.data) {
        toast.success(response.data.data[0]);
      } else {
        toast.success('an email was sent please check your inbox in order to reset your password');
      }

      // console.log("forgot password", response);
      history.push("/login");
    },
    (error) => {
      // toast.error(error.message)
      const status = getValue(error, "error.response.status");
      const errorCode = getValue(error, "error.response.data.error");
      const errorDescription = getValue(
        error,
        "error.response.data.error_description"
      );

      dispatch({
        type: AUTH_FAILED,
      });

      if (status === 404) {
        // dispatch(
        //   setUserLoggedIn({
        //     invalidLogin: true,
        //     errorDescription: errorDescription,
        //   })
        // );
        // return;
      }
      dispatch({
        type: SET_ALERT,
        payload: { general: { msg: errorCode } },
      });
    }
  );
};

export const SubmitPassword = (formData) => async (dispatch) => {
  let data = {
    confirm_password: formData.confirm_password,
    new_password: formData.new_password,
  };
  console.log("formData", data);
  dispatch({ type: AUTH_REQUEST });
  apiService
    .put(
      `user/${formData.uuid}/create-password?signature=${formData.signature}`,
      data,
      {
        headers: {
          'Access-Control-Allow-Origin': 'http://adfad',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      },
    )
    .then(
      (response) => {
        // console.log('heyyyyd done', response)
        dispatch({ type: LOGOUT });

        dispatch({
          type: SET_ALERT,
          payload: {
            general: { msg: "Password reset successfully", type: "success" },
          },
        });
        history.push("/login");
      },
      (error) => {
        toast.error(error.response.data.errors)
        const status = getValue(error, "error.response.status");
        const errorCode = getValue(error, "error.response.data.error");
        const errorDescription = getValue(
          error,
          "error.response.data.error_description"
        );

        dispatch({
          type: AUTH_FAILED,
        });

        if (status === 404) {
          // dispatch(
          //   setUserLoggedIn({
          //     invalidLogin: true,
          //     errorDescription: errorDescription,
          //   })
          // );
          // return;
        }
        dispatch({
          type: SET_ALERT,
          payload: { general: { msg: errorCode } },
        });
      }
    );
};

export const logout = () => (dispatch) => {
  console.log("test1");
  dispatch({ type: LOGOUT });
  // dispatch({ type: CLEAR_PROFILE });
};
