import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import NumberFormat from 'react-number-format';

//REDUX
import coachPortalActions from "../../../../redux/actions/coachPortalActions";
import postActions from "../../../../redux/actions/postsActions";
//CSS
import "../../Events/EventList.css";

//Components
import CoachEventPurchaserRow from "../../../../Components/Coach Portal/CoachEventPurchaserRow";

//Images
import backArrow from "../../../../assets/images/arrow-red-left.svg";
import calendar from "../../../../assets/images/calendar.svg";
import editPen from "../../../../assets/images/edit-pen.svg";
import plane from "../../../../assets/images/paper_plane.svg";

//globals
import { adminHandle } from "../../../../utils/global";
import moment from "moment";

function EventsDetail(props) {
  const params = useParams();
  const [purchasers, setPurchasers] = useState([]);
  useEffect(() => {
    props.dispatch(
      coachPortalActions.viewEventDetails({
        coachId: props.user.id,
        eventId: params.event_id,
      })
    );
    adminHandle(
      props.user?.roles?.length > 0 ? props.user?.roles[0]?.name : null
    );
  }, []);
  useEffect(() => {
    let finalData = [];
    props?.assignedCoachEventsDetails?.purchaser?.data?.map((zone, i) => {
      finalData.push({
        id: zone.id,
        first_name: zone.first_name,
        last_name: zone.last_name,
        email: zone.email,
        mobile_phone: zone.mobile,
        date: zone.booking_date,
        status: "Paid",
        price: zone.sub_total,
        checked: false,
      });
    });
    setPurchasers(finalData);
  }, [props?.assignedCoachEventsDetails]);

  const handleCheckedUsers = (id, status) => {
    let finalData = [];
    purchasers.map((zone) => {
      if (zone.id === id) {
        finalData.push({
          id: zone.id,
          first_name: zone.first_name,
          last_name: zone.last_name,
          email: zone.email,
          mobile_phone: zone.mobile_phone,
          date: zone.date,
          status: "Paid",
          price: zone.price,
          checked: !status,
        });
      } else {
        finalData.push({
          id: zone.id,
          first_name: zone.first_name,
          last_name: zone.last_name,
          email: zone.email,
          mobile_phone: zone.mobile_phone,
          date: zone.date,
          status: "Paid",
          price: zone.price,
          checked: zone.checked,
        });
      }
    });
    setPurchasers(finalData);
  };
  const handleSendMail = () => {
    let members = [];
    purchasers.map((zone) => {
      if (zone.checked === true) {
        members.push(zone);
      }
    });
    props.dispatch(
      postActions.popupHandler({
        type: "CUSTOM_EMAIL_EVENT_COACH",
        data: members,
        event_id: params.event_id,
      })
    );
  };
  // const params = useParams();
  // useEffect(() => {
  // 	props.dispatch(eventActions.getEventDetailsById({ id: params.id }));
  // }, []);

  // const handleSendEmail = () => {
  // 	props.dispatch(eventActions.sendInvitationToAllMembers({ id: params.id }));
  // };

  // const HandlePublish = () => {
  // 	props.dispatch(eventActions.setPublished({ id: params.id }));
  // };
  console.log(props?.assignedCoachEventsDetails);
  return (
    <section>
      <div className="event_detail_top_div">
        <div className="left_detail_top">
          <Link to="/coach/events">
            <div className="go_back_arrow">
              <img src={backArrow} alt="Go Back" />
            </div>
          </Link>
          <div className="bold blue font-36 text-upper float-left">
            {props?.eventDetails?.status !== "public"
              ? `${props?.assignedCoachEventsDetails?.event_details?.title}`
              : `${props?.assignedCoachEventsDetails?.event_details?.title}`}
            {/* {props?.eventDetails?.status !== 'public'
							? `${props?.assignedCoachEventsDetails?.event_details?.title} - ${props?.eventDetails?.status}`
							: `${props?.assignedCoachEventsDetails?.event_details?.title}`} */}
          </div>
        </div>
        <div>
          {props?.assignedCoachEventsDetailsLoading && (
            <div className="text-center mx-auto mt-4">
              <CircularProgress color="primary" size={30} />
            </div>
          )}
        </div>
      </div>
      <div className="event_detail_mainbody">
        <span className="blue bold text-upper font-20">event DETAILS</span>
        <div className="event_detail_mainrow">
          <div className="event_detail_mainrow_col">
            <span className="bold blue font-16 text-upper mb-2">
              EVENT VENUE
            </span>
            <span className="blue font-16 text-upper">
              {props?.assignedCoachEventsDetails?.event_details?.location}
            </span>
          </div>
          <div className="event_detail_mainrow_col">
            <span className="bold blue font-16 text-upper mb-2">
              EVENT DATE
            </span>
            <span className="blue font-16 text-upper">
              {props?.assignedCoachEventsDetails?.event_details?.start_date
                ? moment(
                    props?.assignedCoachEventsDetails?.event_details?.start_date
                  ).format("DD/MM/YYYY")
                : ""}
            </span>
            <span className="blue font-16 text-upper">
              {props?.assignedCoachEventsDetails?.event_details?.end_date
                ? moment(
                    props?.assignedCoachEventsDetails?.event_details?.end_date
                  ).format("DD/MM/YYYY")
                : ""}
            </span>
          </div>
          <div className="event_detail_mainrow_col">
            <span className="bold blue font-16 text-upper mb-2">
              Total BOOKING
            </span>
            <span className="blue font-16 text-upper">
            <NumberFormat value={props?.assignedCoachEventsDetails?.booking_count} displayType={'text'} thousandSeparator={true}/>
              {/*props?.assignedCoachEventsDetails?.booking_count*/}
              {/* {props?.assignedCoachEventsDetails?.booking_count} OUT OF{' '} */}
              {/* need to add all booking */}
              {/* {props?.eventDetails?.} */}
            </span>
          </div>
          {/* <div className='event_detail_mainrow_col'>
						<span className='bold blue font-16 text-upper mb-2'>
							TOTAL EVENT SALES
						</span>
						<div className='detail_total_event_sale'>
							<p className='font-16 blue'>$500.00</p>
						</div>
					</div> */}
          <div className="button_main_row_col">
            {/* {props?.eventDetails?.status !== 'public' ? (
							props?.eventDetails?.status === 'draft' ? (
								<div>
									<div
										// onClick={() => {
										// 	HandlePublish();
										// }}
										className='publish_Event_draft_btn'
									>
										<div>
											<img src={calendar} alt='cal' />
										</div>
										<div className='bold blue font-16 publish_button_detail_div'>
											PUBLISH EVENT
										</div>
									</div>
									{props.PublishEventLoading === true ? (
										<div className='text-center mx-auto mt-4'>
											<CircularProgress color='primary' size={30} />
										</div>
									) : null}
								</div>
							) : null
						) : ( */}
            <div className="published_event_btn_div">
              <div className="upper_btn_div">
                {/* <Link
										to={`/admin/events/${params.id}/edit`}
										className='edit_event_detail bold'
									>
										<img src={editPen} alt='' />
										<div className='link_text_div'>
											<span>EDIT</span>
										</div>
									</Link> */}
                <div
                  onClick={() => {
                    handleSendMail();
                  }}
                  className="email_all_btn_detail"
                >
                  <img src={plane} alt="" />
                  <div>
                    <span className="bold white text-upper">
                      EMAIL SELECTED
                    </span>
                  </div>
                </div>
                {props.sendMailCoachLoading}
              </div>
              <div className="lower_btn_div">
                {props.SendMailLoading === true ? (
                  <div className="text-center mx-auto mt-4">
                    <CircularProgress color="primary" size={30} />
                  </div>
                ) : null}
                <Link
                  to={`/coach/events/${params.event_id}/programs`}
                  // to={`/coach/events/${props?.eventDetails?.id}/programs`}
                  className="view_programs_btn"
                >
                  <div className="bold white text-upper">VIEW SESSIONS</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* {props?.eventDetails?.status === 'public' ? ( */}
        <div className="event_detail_purchase_list">
          <div className="events_detail_list_top">
            <div className="events_detail_table_header wi-16">Select All</div>
            <div className="events_detail_table_header wi-21">Purchaser</div>
            <div className="events_detail_table_header wi-21">email</div>
            <div className="events_detail_table_header wi-21">phone</div>
            <div className="events_detail_table_header wi-21">booking date</div>
            {/* <div className='events_detail_table_header wi-13'>STATUS</div> */}
            {/* <div className='events_detail_table_header wi-13'>total</div> */}
          </div>
          <div className="event_purchaser_container">
            {props?.assignedCoachEventsDetailsLoading ? (
              <div className="text-center mx-auto mt-4">
                <CircularProgress color="primary" size={30} />
              </div>
            ) : (
              purchasers.map((zone, i) => {
                let data = (
                  <CoachEventPurchaserRow
                    key={i}
                    first_name={zone.first_name}
                    last_name={zone.last_name}
                    email={zone.email}
                    mobile_phone={zone.mobile_phone}
                    date={
                      zone.date ? moment(zone.date).format("DD/MM/YYYY") : "N/A"
                    }
                    status="Paid"
                    price={zone.price}
                    checked={zone.checked}
                    userId={zone.id}
                    handleCheckedUsers={handleCheckedUsers}
                  />
                );
                return data;
              })
            )}
          </div>
        </div>
        {/* ) : null} */}
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  user: state.register.user,
  assignedCoachEventsDetails: state.coachPortal.assignedCoachEventsDetails,
  assignedCoachEventsDetailsLoading:
    state.coachPortal.assignedCoachEventsDetailsLoading,

  sendMailCoachLoading: state.coachPortal.sendMailCoachLoading,
});

export default connect(mapStateToProps)(EventsDetail);
