import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import logo from '../../../assets/images/logo.svg';

import { connect } from 'react-redux';
import eventActions from '../../../redux/actions/eventsActions'
import membersAction from '../../../redux/actions/membersAction'
import ordersActions from '../../../redux/actions/ordersActions'
import couponActions from '../../../redux/actions/couponsActions'

import orders from '../../../assets/images/orders.svg';

const Sidebar = (props) => {
	const setEventPage = () => {
		props.dispatch(eventActions.changePageState(1));
	}
	const setMemberPage = () => {
		props.dispatch(membersAction.changePageState(1));
	}
	const setOrderPage = () => {
		props.dispatch(ordersActions.handlePagination(1));
	}
	const setCouponPage = () => {
		props.dispatch(couponActions.changePageState(1));
	}
	return (
		<div
			className={`left-panel blue-bg ${props.isSidebarMenuOpen ? '' : 'hide-1024'
				}`}
		>

			<div className='logo'>
				<Link to="/">
					<img src={logo} alt='logo' />
				</Link>
			</div>

			<div className='panel-menu'>
				<ul className='list-unstyled'>

					<NavLink to="/admin/homePage" className="menu_item" activeClassName="menu_item_active">

						<span className='menu-icon'>
						<svg xmlns="http://www.w3.org/2000/svg" width="22" height="18.9" viewBox="0 0 22 18.9">
							<path id="icons8-home" d="M12,2.1,1,12H4v9h7V15h2v6h7V12h3Zm0,2.691,6,5.4V19H15V13H9v6H6V10.191Z" transform="translate(-1 -2.1)" style={{ fill: '#686692' }}/>
						</svg>

						</span>
						Home Page

					</NavLink>
					<NavLink to="/admin/dashboard" className="menu_item" activeClassName="menu_item_active">

						<span className='menu-icon'>
							<svg
								id='Layer_1'
								data-name='Layer 1'
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 16 16'
							>
								<title>dashboard</title>
								<g id='dashboard'>
									<rect
										id='Rectangle_2785'
										data-name='Rectangle 2785'
										x='12'
										y='5'
										width='4'
										height='11'
										style={{ fill: '#686692' }}
									/>
									<rect
										id='Rectangle_2786'
										data-name='Rectangle 2786'
										x='6'
										width='4'
										height='16'
										style={{ fill: '#686692' }}
									/>
									<rect
										id='Rectangle_2787'
										data-name='Rectangle 2787'
										y='10'
										width='4'
										height='6'
										style={{ fill: '#686692' }}
									/>
								</g>
							</svg>
						</span>
						Dashboard

					</NavLink>

					<NavLink to="/admin/noticeboard" className="menu_item" activeClassName="menu_item_active">

						<span className='menu-icon'>
							<svg
								id='Layer_1'
								data-name='Layer 1'
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 10.17 18.94'
							>
								<title>notice</title>
								<g id='notice'>
									<g id='Group_7515' data-name='Group 7515'>
										<g id='Group_7514' data-name='Group 7514'>
											<path
												id='Path_19863'
												data-name='Path 19863'
												d='M11.22,5.81A1.79,1.79,0,0,1,9.45,7.6H2.87A1.79,1.79,0,1,1,2.87,4H9.43a1.75,1.75,0,0,1,1.79,1.72Z'
												transform='translate(-1.07 -4.03)'
												style={{ fill: '#686692' }}
											/>
											<path
												id='Path_19864'
												data-name='Path 19864'
												d='M10.17,13.75a1.08,1.08,0,0,1,1.06,1.06,1,1,0,0,1-1.05,1.06h0c-4.27,0-8,0-8,0a1.06,1.06,0,0,1-1.06-1h0a1,1,0,0,1,1-1.06h8.1Z'
												transform='translate(-1.07 -4.03)'
												style={{ fill: '#686692' }}
											/>
										</g>
									</g>
									<g id='Group_7516' data-name='Group 7516'>
										<path
											id='Path_19865'
											data-name='Path 19865'
											d='M9.4,8.1l.66,5.14H2.24L2.9,8.1Z'
											transform='translate(-1.07 -4.03)'
											style={{ fill: '#686692' }}
										/>
									</g>
									<g id='Group_7517' data-name='Group 7517'>
										<path
											id='Path_19866'
											data-name='Path 19866'
											d='M7.41,16.4l-1,6.34a.25.25,0,0,1-.5,0l-1-6.34C5.63,16.38,6.47,16.4,7.41,16.4Z'
											transform='translate(-1.07 -4.03)'
											style={{ fill: '#686692' }}
										/>
									</g>
								</g>
							</svg>
						</span>
						Noticeboard

					</NavLink>

					<NavLink to="/admin/events" className="menu_item" activeClassName="menu_item_active" onClick={setEventPage}>


						<span className='menu-icon'>
							<svg
								id='Layer_1'
								data-name='Layer 1'
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 16 16'
							>
								<defs>
									<clipPath id='clip-path' transform='translate(0 0)'>
										<rect width='16' height='16' style={{ fill: 'none' }} />
									</clipPath>
								</defs>
								<title>calendar</title>
								<g style={{ clipPath: 'url(#clip-path)' }}>
									<g id='calendar'>
										<rect
											id='Rectangle_557'
											data-name='Rectangle 557'
											width='16'
											height='16'
											style={{ fill: 'none' }}
										/>
										<path
											id='Path_149'
											data-name='Path 149'
											d='M2,5v9H14V5ZM13,2h2a1,1,0,0,1,1,.89V15a1,1,0,0,1-.89,1H1a1,1,0,0,1-1-.89V3A1,1,0,0,1,.89,2H3V1a1,1,0,0,1,.89-1H4A1,1,0,0,1,5,.89V2h6V1a1,1,0,0,1,2,0ZM12,12H10V10h2ZM9,12H7V10H9Zm3-3H10V7h2ZM9,9H7V7H9ZM6,12H4V10H6Z'
											transform='translate(0 0)'
											style={{ fill: '#686692', fillRule: 'evenodd' }}
										/>
									</g>
								</g>
							</svg>
						</span>
						Events

					</NavLink>

					<NavLink to="/admin/orders" className="menu_item" activeClassName="menu_item_active" onClick={setOrderPage}>

						<span className='menu-icon'>
							<img src={orders} alt="Orders" style={{paddingRight: '15px'}}/>
						</span>
						Orders

					</NavLink>

					<NavLink to="/admin/coupons" className="menu_item" activeClassName="menu_item_active" onClick={setCouponPage}>
					    
						<span className='menu-icon'>
						<svg fill="#686692" height="64px" width="64px" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" ><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M395.13,306.087c-9.206,0-16.696,7.49-16.696,16.696c0,9.206,7.49,16.696,16.696,16.696s16.696-7.49,16.696-16.696 C411.826,313.577,404.336,306.087,395.13,306.087z"></path> </g> </g> <g> <g> <path d="M261.565,172.522c-9.206,0-16.696,7.49-16.696,16.696s7.49,16.696,16.696,16.696c9.206,0,16.696-7.49,16.696-16.696 S270.771,172.522,261.565,172.522z"></path> </g> </g> <g> <g> <path d="M495.304,72.348H144.696v50.087c0,9.217-7.479,16.696-16.696,16.696s-16.696-7.479-16.696-16.696V72.348H16.696 C7.479,72.348,0,79.826,0,89.044v333.913c0,9.217,7.479,16.696,16.696,16.696h94.609v-50.087c0-9.217,7.479-16.696,16.696-16.696 s16.696,7.479,16.696,16.696v50.087h350.609c9.217,0,16.696-7.479,16.696-16.696V89.044C512,79.826,504.521,72.348,495.304,72.348 z M144.696,322.783c0,9.217-7.479,16.696-16.696,16.696s-16.696-7.479-16.696-16.696v-33.391c0-9.217,7.479-16.696,16.696-16.696 s16.696,7.479,16.696,16.696V322.783z M144.696,222.609c0,9.217-7.479,16.696-16.696,16.696s-16.696-7.479-16.696-16.696v-33.391 c0-9.217,7.479-16.696,16.696-16.696s16.696,7.479,16.696,16.696V222.609z M211.478,189.217c0-27.619,22.468-50.087,50.087-50.087 c27.619,0,50.087,22.468,50.087,50.087c0,27.619-22.468,50.087-50.087,50.087C233.946,239.304,211.478,216.836,211.478,189.217z M257.512,343.544c-4.271,0-8.544-1.631-11.804-4.892c-6.521-6.521-6.521-17.087,0-23.609L387.37,173.37 c6.521-6.522,17.086-6.522,23.608,0c6.521,6.521,6.521,17.087,0,23.609L269.315,338.652 C266.054,341.914,261.782,343.544,257.512,343.544z M395.13,372.87c-27.619,0-50.087-22.468-50.087-50.087 c0-27.619,22.468-50.087,50.087-50.087s50.087,22.468,50.087,50.087C445.217,350.402,422.75,372.87,395.13,372.87z"></path> </g> </g> </g></svg>
					    </span>
						Coupons

					</NavLink>

					<NavLink to="/admin/members" className="menu_item" activeClassName="menu_item_active" onClick={setMemberPage}>

						<span className='menu-icon'>
							<svg
								id='Layer_1'
								data-name='Layer 1'
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 19.25 14.01'
							>
								<title>members</title>
								<g id='members'>
									<path
										id='Path_19871'
										data-name='Path 19871'
										d='M0,11.2V14H12.44V11.2a3,3,0,0,0-2.92-3H3A3,3,0,0,0,0,11.2Z'
										transform='translate(0 0)'
										style={{ fill: '#686692' }}
									/>
									<path
										id='Path_19872'
										data-name='Path 19872'
										d='M6.21,7A3.48,3.48,0,1,0,2.74,3.47h0A3.48,3.48,0,0,0,6.21,7Z'
										transform='translate(0 0)'
										style={{ fill: '#686692' }}
									/>
									<path
										id='Path_19873'
										data-name='Path 19873'
										d='M14.35,7.45a2.72,2.72,0,0,0,2.72-2.71A2.76,2.76,0,0,0,14.35,2a2.72,2.72,0,0,0,0,5.43Z'
										transform='translate(0 0)'
										style={{ fill: '#686692' }}
									/>
									<path
										id='Path_19874'
										data-name='Path 19874'
										d='M16.86,8.44H12.17a3.67,3.67,0,0,1,1.26,2.78L13.55,14h5.7v-3.2a2.38,2.38,0,0,0-2.38-2.37Z'
										transform='translate(0 0)'
										style={{ fill: '#686692' }}
									/>
								</g>
							</svg>
						</span>
						Members

					</NavLink>

					
					<NavLink to="/admin/children" className="menu_item" activeClassName="menu_item_active" onClick={setMemberPage}>

						<span className='menu-icon'>
							<svg
								id='Layer_1'
								data-name='Layer 1'
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 19.25 14.01'
							>
								<title>children</title>
								<g id='children'>
									<path
										id='Path_20009'
										data-name='Path 20009'
										d='M0,11.2V14H12.44V11.2a3,3,0,0,0-2.92-3H3A3,3,0,0,0,0,11.2Z'
										transform='translate(0 0)'
										style={{ fill: '#686692' }}
									/>
									<path
										id='Path_20010'
										data-name='Path 20010'
										d='M6.21,7A3.48,3.48,0,1,0,2.74,3.47h0A3.48,3.48,0,0,0,6.21,7Z'
										transform='translate(0 0)'
										style={{ fill: '#686692' }}
									/>
									<path
										id='Path_20011'
										data-name='Path 20011'
										d='M14.35,7.45a2.72,2.72,0,0,0,2.72-2.71A2.76,2.76,0,0,0,14.35,2a2.72,2.72,0,0,0,0,5.43Z'
										transform='translate(0 0)'
										style={{ fill: '#686692' }}
									/>
									<path
										id='Path_20012'
										data-name='Path 20012'
										d='M16.86,8.44H12.17a3.67,3.67,0,0,1,1.26,2.78L13.55,14h5.7v-3.2a2.38,2.38,0,0,0-2.38-2.37Z'
										transform='translate(0 0)'
										style={{ fill: '#686692' }}
									/>
								</g>
							</svg>
						</span>
						Children

					</NavLink>

					<NavLink to="/admin/coaches" className="menu_item" activeClassName="menu_item_active" onClick={setMemberPage}>
						<span className='menu-icon'>
							<svg
								id='Layer_1'
								data-name='Layer 1'
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 19.25 20.05'
							>
								<title>coaches</title>
								<g id='coaches'>
									<path
										id='Path_20006'
										data-name='Path 20006'
										d='M6,16a4.73,4.73,0,0,1,4.82-4.23h1.76V3.69a1.6,1.6,0,0,0-1.59-1.6h-.55v1A1.29,1.29,0,0,1,9.14,4.4H3.31A1.28,1.28,0,0,1,2.05,3.14v-1h-.5A1.58,1.58,0,0,0,0,3.69V18.41A1.59,1.59,0,0,0,1.59,20H7.88A4.57,4.57,0,0,1,6,16ZM2.68,6.15H9.22a.42.42,0,0,1,0,.84H2.68a.42.42,0,0,1-.42-.42A.47.47,0,0,1,2.68,6.15Zm0,2.3H7.16a.42.42,0,0,1,.42.42.4.4,0,0,1-.38.42H2.68a.4.4,0,0,1-.42-.38v0a.47.47,0,0,1,.42-.42Zm0,2.35H9.22a.41.41,0,0,1,.42.41.45.45,0,0,1-.42.42H2.68a.42.42,0,1,1,0-.83Zm2.68,3.14H2.68a.42.42,0,1,1,0-.84H5.36a.42.42,0,0,1,.42.42.45.45,0,0,1-.42.42Z'
										transform='translate(0 0)'
										style={{ fill: '#686692' }}
									/>
									<path
										id='Path_20007'
										data-name='Path 20007'
										d='M3.31,3.6H9.14a.41.41,0,0,0,.41-.42V1.55a.4.4,0,0,0-.38-.42H8A1.79,1.79,0,0,0,6.24,0,1.79,1.79,0,0,0,4.52,1.17H3.31a.42.42,0,0,0-.42.42V3.18A.46.46,0,0,0,3.31,3.6ZM6.24.88a.46.46,0,0,1,.46.46.45.45,0,0,1-.46.46.46.46,0,0,1-.46-.46A.53.53,0,0,1,6.24.88Z'
										transform='translate(0 0)'
										style={{ fill: '#686692' }}
									/>
									<path
										id='Path_20008'
										data-name='Path 20008'
										d='M18.83,12.63H10.74a3.9,3.9,0,0,0-4,3.43,3.79,3.79,0,0,0,3.59,4h.27a2.12,2.12,0,0,0,.5-.05,3.83,3.83,0,0,0,3.35-3.18,1.92,1.92,0,0,1,1.68-1.55L18.87,15a.46.46,0,0,0,.38-.42V13A.42.42,0,0,0,18.83,12.63Zm-8,2.18a1.4,1.4,0,0,0-1.42,1.4v0a.45.45,0,0,1-.42.42.42.42,0,0,1-.42-.42A2.26,2.26,0,0,1,10.77,14h0a.4.4,0,0,1,.42.38v0A.42.42,0,0,1,10.78,14.81Z'
										transform='translate(0 0)'
										style={{ fill: '#686692' }}
									/>
								</g>
							</svg>
						</span>
						Coaches

					</NavLink>

					<NavLink to="/admin/stores" className="menu_item" activeClassName="menu_item_active">

						<span className='menu-icon'>
							<svg
								id='Layer_1'
								data-name='Layer 1'
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 16 16'
							>
								<title>store</title>
								<path
									id='store'
									d='M15.22,3.3,12.7.78A2.67,2.67,0,0,0,10.82,0H5.18A2.67,2.67,0,0,0,3.3.78L.78,3.3A2.67,2.67,0,0,0,0,5.18v8.15A2.66,2.66,0,0,0,2.67,16H13.33A2.66,2.66,0,0,0,16,13.33V5.18A2.67,2.67,0,0,0,15.22,3.3ZM8,11.56A3.57,3.57,0,0,1,4.44,8h0A.89.89,0,0,1,6.22,8,1.78,1.78,0,1,0,9.78,8a.89.89,0,0,1,1.78,0A3.57,3.57,0,0,1,8,11.56Zm-5-8L4.55,2a.89.89,0,0,1,.63-.26h5.64a.89.89,0,0,1,.63.26L13,3.56Z'
									style={{ fill: '#686692' }}
								/>
							</svg>
						</span>
						Store

					</NavLink>

					<NavLink to="/admin/settings" className="menu_item" activeClassName="menu_item_active">

						<span className='menu-icon'>
							<svg  width="16" height="16" viewBox="0 0 16 16">
								<defs>
									<clipPath id="clip-path">
									<rect width="16" height="16" fill="none"/>
									</clipPath>
								</defs>
								<g id="icon_setting" clipPath="url(#clip-path)">
									<path id="Path_108" data-name="Path 108" d="M13.3,5.2l1.1-2.1L13,1.7,10.9,2.8a3.582,3.582,0,0,0-1.1-.4L9,0H7L6.2,2.3a4.179,4.179,0,0,0-1,.4L3.1,1.6,1.6,3.1,2.7,5.2a4.179,4.179,0,0,0-.4,1L0,7V9l2.3.8c.1.4.3.7.4,1.1L1.6,13,3,14.4l2.1-1.1a3.582,3.582,0,0,0,1.1.4L7,16H9l.8-2.3c.4-.1.7-.3,1.1-.4L13,14.4,14.4,13l-1.1-2.1a3.582,3.582,0,0,0,.4-1.1L16,9V7l-2.3-.8A4.179,4.179,0,0,0,13.3,5.2ZM8,11A2.946,2.946,0,0,1,5,8,2.946,2.946,0,0,1,8,5a2.946,2.946,0,0,1,3,3A2.946,2.946,0,0,1,8,11Z" fill="#686692"/>
								</g>
							</svg>
						</span>
						Settings

					</NavLink>

				</ul>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	selectedPage: state.eventReducer.selectedPage,
	selectedPage: state.membersReducers.selectedPage,
	selectedPage: state.couponReducer.selectedPage,
	page: state.order.page,
});
export default connect(mapStateToProps)(Sidebar);