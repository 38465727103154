import React from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/images/no1dtraft-img.svg";
import redLine from "../../assets/images/Line-red.svg";


const LoginView = ({ onSubmit, formData, onChange, loading}) => (

  <div className="login">
    <div className="d-sm-flex align-items-center">
      <div className="no1draft_logo">
        <img src={logo} />
      </div>
      <div className="form_part">
        <form onSubmit={onSubmit}>
          <div className="form_style">
            <img src={redLine} />
          </div>
          <div className="form_title white font-36 bold">WELCOME BACK</div>
          <div className="text_one_form white regular font-16 pt-40">
            <input
              className="white"
              placeholder="Email address"
              type="email"
              value={formData.email}
              onChange={(e) => {
                onChange(e);
              }}
              name="email"
              required
            ></input>
          </div>
          <div className="text_one_form white regular font-16 padding-top-10">
            <input
              type="password"
              className="white"
              placeholder="Your password"
              name="password"
              minLength={4}
              onChange={(e) => {
                onChange(e);
              }}
              value={formData.password}
              required
            ></input>
          </div>
          <Link
            to="/forgot-password"
            className="white font-13 regular padding-top-10"
          >
            Forgot your password?
          </Link>
          <input
            type="submit"
            className="button_edit bold font-16 white red-bg text-center border-red w-100 margin-top-20"
            disabled={loading}
            value="Login To Your Account"
          />
          {/* <button className="button_edit bold font-16 white red-bg text-center border-red w-100 margin-top-20">
            LOGIN
          </button> */}
        </form>
      </div>
    </div>
  </div>
);

export default LoginView;
