import React, { useEffect, useState } from 'react';
import EditProductComponent from '../../../Components/store/EditProduct';
import { connect } from 'react-redux';
import { coachHandle } from '../../../utils/global';
import { toast } from 'react-toastify';

import {
	fetchListOfCategory,
	fetchListOfRelatedProducts,
	updateProduct,
	fetchProduct,
} from '../../../actions/store';
import { getValue } from '../../../utils/app';

const EditProduct = (props) => {
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		props.fetchProduct(getValue(props, 'props.match.params.product_id'));
	}, [getValue(props, 'props.match.params.product_id')]);

	useEffect(() => {
		coachHandle(props.user?.roles?.length > 0 ?
			props.user?.roles[0]?.name : null);
		props.fetchListOfCategory();
		props.fetchListOfRelatedProducts();
		// setCategoryValueData(props.eventDetail.category_id);
	}, []);

	useEffect(() => {
		let data = [];
		if (props.categories) {
			props.categories.map((category) => {
				data.push({ value: category.id, label: category.name });
			});
			setCategories(data);
		}
	}, [getValue(props, 'props.categories')]);

	const onCallApi = (formData) => {
		if (formData.item_id_in_business_center) {
			props.updateProduct(
				formData.id,
				formData,
				getValue(props, 'props.match.params.id')
			);
		} else {
			toast.error('Item Id In Business Central field is required')
		}
	};
	// console.log('product detailss', props.productDetail)
	return (
		<EditProductComponent
			propsData={props}
			categories={categories}
			relatedProducts={props.relatedProducts}
			productDetail={props.productDetail}
			onCallApi={onCallApi}
		/>
	);
};

const mapStateToProps = (state) => ({
	categories: state.storeReducer.categories,
	relatedProducts: state.storeReducer.relatedProducts,
	productDetail: state.storeReducer.productDetail,
	user: state.register.user
});

export default connect(mapStateToProps, {
	fetchListOfCategory,
	fetchListOfRelatedProducts,
	updateProduct,
	fetchProduct,
})(EditProduct);
