import history from "../history"
// const user = JSON.parse(localStorage.getItem("UserInfo"))


const coachHandle = (role) => {
    // alert(role)
    if (role === 'coach' && role !== null) {
        history.push("/coach/events")
    }
}

const adminHandle = (role) => {
    if (role === 'admin' && role != null) {
        // alert('i trigered')
        // console.log(user?.roles[0]?.name)
        history.push("/admin/dashboard")
    }
}

export {
    coachHandle,
    adminHandle,
}