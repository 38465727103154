import React, { Fragment, useState } from "react";
import { connect } from 'react-redux'

import "./defaultLayout.scss";
import Header from "./Header";
import Sidebar from "./Sidebar";
import CoachSidebar from "./CoachSidebar"


const DefaultLayout = (props) => {
  const [isSidebarMenuOpen, setIsSidebarMenuOpen] = useState(false);

  const toggleSidebarMenu = () => {
    setIsSidebarMenuOpen(!isSidebarMenuOpen);
  };

  return (
    <Fragment>
      <div className="wrapper">
        <div className="default-layout">
          <Header toggleSidebarMenu />
          {/* <Sidebar isSidebarMenuOpen={isSidebarMenuOpen} {...props} /> */}
          {
            props.user?.roles?.length > 0 ?
              <>
                {
                  props.user?.roles[0]?.name === 'admin' ? <Sidebar isSidebarMenuOpen={isSidebarMenuOpen} {...props} /> : <CoachSidebar isSidebarMenuOpen={isSidebarMenuOpen} {...props} />
                }
              </>
              : <Sidebar isSidebarMenuOpen={isSidebarMenuOpen} {...props} />}
          <div className="main-content">{props.children}</div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.register.user
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout)
