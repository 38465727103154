import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
//redux
import { connect } from 'react-redux'
import { logout } from "../../../actions/auth"
import { baseURL } from '../../../config/config'
// Css
import './header.scss'
//Images
import logo from "../../../assets/images/logo.svg";
import searchIcon from "../../../assets/images/Search.svg";

//material ui components
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Avatar from '@material-ui/core/Avatar'
import profileActions from '../../../redux/actions/profileActions';
import coachActions from '../../../redux/actions/coachActions';
import homeActions from '../../../redux/actions/homeActions';

const Header = (props) => {
	useEffect(() => {
		if(props?.userInfo?.roles[0]?.id===2){
			props.dispatch(coachActions.getCoachDetails({id:props?.userInfo?.uuid}))
		}else{
			props.dispatch(profileActions.getSuperProfile(props?.userInfo?.uuid))
		}
		// <script data-jsd-embedded="" data-key="a12d9d05-cd12-4f5d-9f18-5ce9a74e52da" data-base-url="https://jsd-widget.atlassian.com" src="https://jsd-widget.atlassian.com/assets/embed.js"></script>
		// <script data-jsd-embedded="" data-key="a12d9d05-cd12-4f5d-9f18-5ce9a74e52da" data-base-url="https://jsd-widget.atlassian.com" src="https://jsd-widget.atlassian.com/assets/embed.js"></script>
		// let head = document.getElementsByTagName('head').item(0)
		// let script = document.createElement("script")
		// script.setAttribute("data-jsd-embedded",'')
		// script.setAttribute("data-key","a12d9d05-cd12-4f5d-9f18-5ce9a74e52da")
		// script.setAttribute("data-base-url","https://jsd-widget.atlassian.com")
		// script.setAttribute("src","https://jsd-widget.atlassian.com/assets/embed.js")
		
		// head.appendChild(script);
		// return ()=>{
		// 	document.body.removeChild(script);
		// }
	},[])
	const user = JSON.parse(localStorage.getItem("UserInfo"));
	const [anchorEl, setAnchorEl] = useState(null);
	const [menuItemHover, setMenuItemHover] = useState('');
	const [search, setSearch] = useState('');
	const [searchResult, setSearchResult] = useState([]);
	const [isSearch, setIsSearch] = useState(false);
	const history = useHistory();
	const handleAvatarClick = (e) => {
		setAnchorEl(e.currentTarget);
	}
	const handleAvatarClose = () => {
		setAnchorEl(null);
	}
	const handleEditProfile = () => {
		setAnchorEl(null);
		history.push('/admin/profile')
	}
	const handleLogout = () => {
		props.dispatch(logout({}))
	}
	
	useEffect(() => {
		let myTimeout
		if(isSearch){
			 myTimeout = setTimeout(()=>props.dispatch(homeActions.masterSearch(search)),1000);
		}
		return ()=>clearTimeout(myTimeout)
	}, [search])

	const handleSearch=(e)=>{
		setSearch(e.target.value);
		if(e.target.value?.length>2){
			setIsSearch(true);
		}else{
			setIsSearch(false);
			props.dispatch(homeActions.clearMasterSearch())
		}
	}
	const handleSearchClick=(data)=>{
		console.log(data)
		switch (data.model) {
			case "Events":
				setSearch('');
				history.push(`/admin/events/${data.id}`);

				break;
			case "Parents":
				setSearch('');
				history.push(`/admin/members/${data.id}`);
				break;
			case "Childrens":
				setSearch('');
				history.push(`/admin/members/${data.parent_id_in_children}/children`);
				break;
			case "User":
				if(data.role[0].name==="coach"){
					setSearch('');
					history.push(`/admin/coaches/coach-detail/${data.uuid}`);
					break;
				}else{
					break;
				}
			case "Products":
				setSearch('');
				history.push(`/admin/stores/${data.category_id}/products/edit/${data.id}`);
				break;
		
			default:
				break;
		}
		setIsSearch(false)
		props.dispatch(homeActions.clearMasterSearch())
	}
	useEffect(() => {
		let finalData = [{model:"Coach",data:[]}];
		props?.masterSearch?.map((zone,i)=>{
			if(i===0){
				if(zone?.model==="User"){
					if(zone?.role[0].name==="coach"){
						finalData[0].data.push(zone)
					}
				}else{
					finalData.push({model:zone?.model,data:[zone]})
				}
			}else{
				let exist = false;
				let indexOfExistence = 0;
				for(let j=0;j<finalData.length;j++){
					if(zone?.model==="User"){
						if(zone?.role[0].name==="coach"){
							exist= true;
							indexOfExistence=0;
							break;
						}else{
							break;
						}
					}
					if(zone?.model===finalData[j].model){
						exist = true;
						indexOfExistence=j;
						break;
					}
				}
				if(zone?.model!=="User"){
					if(exist){
						finalData[indexOfExistence].data.push(zone)
					}else{
						finalData.push({model:zone?.model,data:[zone]})
					}
				}
			}
		})
		let coachExist=true;
		if(finalData[0].data?.length===0){
			coachExist=false;
		}
		if(!coachExist){
			finalData = finalData.filter(zone=>zone.model!=="Coach")
		}
		setSearchResult(finalData);
	}, [props?.masterSearch])
	return (
		<div className="header">
			{/* {console.log("%cResults","background-color:black;color:white",searchResult)} */}
			<div className="container-fluid">
				<div className="logo-menu show-1024">
					<div className="logo">
						<a href="">
							<img src={logo} alt="logo" />
						</a>
					</div>
				</div>
				<div className="search-user d-flex justify-content-end align-items-center" >
							{isSearch&&<div className="red bold pointer" onClick={()=>{setIsSearch(false);props.dispatch(homeActions.clearMasterSearch()); setSearch('')}}>Close Search</div>}
							<div className="master_search_container">
								<div><img src={searchIcon} alt="" width="15px" /></div>
								<div><input className="master_search_input" value={search} onChange={handleSearch} placeholder="Search..." type="text"/></div>
								<div className="master_search_popup" style={{right:`${isSearch?'0':'100%'}`}}>
									{props?.masterSearchLoading?<div>loading...</div>
									:isSearch&&searchResult?.map((zone,i)=>{
									return <div className="w-100" key={`model-${i}`}>
									<div className="master_search_popup_title">{zone?.model}</div>
									<div className="master_search_popup_body">
										{zone?.data?.map((zone2,i2)=>{
										return <div key={`match-${i2}`} className="master_search_popup_body_item" onClick={()=>{console.log("Hello");handleSearchClick(zone2)}}>
											{zone2.title?zone2.title:zone2.match}
										</div>})}
									</div>
									</div>})}
								</div>
							</div>
					{/* <a href="" className="rwd-search show-1024">
								
					</a>
					<div className="search-div hide-1024">
							<div className="search-field position-relative">
								
							</div>
					</div> */}
					<div className="user-profile hide-1024">
						<Avatar className="Avatar"  style={{cursor: 'pointer'}} alt={`${user?.first_name}`} src={user?.profile_picture ? `${baseURL}/image/${user.profile_picture}` : null} onClick={handleAvatarClick} aria-controls="menu" />
						<Menu
							id="menu"
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={handleAvatarClose}
							keepMounted
						>
							<div className="Header_Menu_Container">
								<div className="bold blue p-1">
									{`${props?.userProfileData?.first_name ? props?.userProfileData?.first_name : user?.first_name} ${props?.userProfileData?.last_name ? props?.userProfileData?.last_name : user?.last_name}`}
									</div>
								<div className="blue p-1">{props?.userProfileData?.email ? props?.userProfileData?.email : user?.email}</div>
								<MenuItem className="align-self-start w-100" onMouseEnter={() => { setMenuItemHover('1') }} onMouseLeave={() => { setMenuItemHover('') }} onClick={handleEditProfile} >
									<svg id="Group_3178" data-name="Group 3178" xmlns="http://www.w3.org/2000/svg" width="13.939" height="13.939" viewBox="0 0 13.939 13.939">
										<path fill={menuItemHover === '1' ? '#D91C24' : '#262262'} id="Path_3882" data-name="Path 3882" d="M18.9,32.158H31.478a.682.682,0,0,0,.681-.681v-2.7a5.151,5.151,0,0,0-2.833-4.608l-.171-.087.064-.181a4.274,4.274,0,1,0-8.059,0l.064.181-.171.087a5.149,5.149,0,0,0-2.833,4.608v2.7A.681.681,0,0,0,18.9,32.158Zm6.289-12.581a2.915,2.915,0,1,1-2.915,2.915A2.918,2.918,0,0,1,25.189,19.578Zm-5.611,9.2a3.828,3.828,0,0,1,2.21-3.455l.146-.068.109.118a4.258,4.258,0,0,0,6.293,0l.109-.118.146.068a3.829,3.829,0,0,1,2.21,3.455V30.8H19.578Z" transform="translate(-18.22 -18.219)" />
									</svg>
									<div className={`${menuItemHover === '1' ? "red" : 'blue'} pl-1 bold`}>Profile</div>
								</MenuItem>
								<MenuItem className="align-self-start w-100" onMouseEnter={() => { setMenuItemHover('2') }} onMouseLeave={() => { setMenuItemHover('') }} onClick={handleLogout}>
									<svg xmlns="http://www.w3.org/2000/svg" width="16.2" height="14.607" viewBox="0 0 16.2 14.607">
										<g id="Group_7629" data-name="Group 7629" transform="translate(-14.9 -38.9)">
											<path fill={menuItemHover === '2' ? '#D91C24' : '#262262'} id="Path_3767" data-name="Path 3767" d="M22.524,53.4a1.89,1.89,0,0,0,1.9-1.878V50.135a.564.564,0,0,0-1.128,0v1.391a.763.763,0,0,1-.773.75H16.892a.752.752,0,0,1-.763-.75V40.878a.752.752,0,0,1,.763-.75h5.632a.761.761,0,0,1,.773.75v1.616a.564.564,0,0,0,1.128,0V40.878A1.892,1.892,0,0,0,22.524,39H16.892A1.882,1.882,0,0,0,15,40.878v10.65a1.884,1.884,0,0,0,1.892,1.878h5.632Z" stroke="#abadc1" strokeWidth="0.2" />
											<path fill={menuItemHover === '2' ? '#D91C24' : '#262262'} id="Path_3768" data-name="Path 3768" d="M140.334,163.138a.563.563,0,0,0-.8.04.555.555,0,0,0,.04.79l1.829,1.646h-9.542a.564.564,0,1,0,0,1.128h9.545L139.58,168.4a.569.569,0,0,0,.378.989.554.554,0,0,0,.378-.146l2.917-2.635a.563.563,0,0,0,0-.836Z" transform="translate(-112.44 -119.877)" stroke="#abadc1" strokeWidth="0.2" />
										</g>
									</svg>
									<div className={`${menuItemHover === '2' ? "red" : 'blue'} pl-1 bold`}>Logout</div>
								</MenuItem>
							</div>
						</Menu>
					</div>
					{/* <div className="hamburger-menu" onClick={toggleSidebarMenu}>
					<span></span>
					<span></span>
					<span></span>
				</div> */}
				</div>
			</div>
		</div>
	)
};

const mapStateToProps = (state) => ({
	userInfo: state.register.user,
	userProfileData: state.profileReducer.userProfileData,
	editProfileData: state.profileReducer.editProfileData,

	masterSearch:state.homeReducer.masterSearch,
	masterSearchLoading:state.homeReducer.masterSearchLoading,
})
export default connect(mapStateToProps)(Header);