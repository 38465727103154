import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

//redux
import eventActions from '../../redux/actions/eventsActions';

//images
import greenCheck from '../../assets/images/green-tick.svg';
import ArrowBlueImg from '../../assets/images/arrow_blue_down.svg';

function 	EventProgramRow(props) {
	const params = useParams();
	const [isDone, setIsDone] = useState(false);
	const [selectVal, setSelectVal] = useState(props.status);
	const [dateHover, setDateHover] = useState(false);

	const handleValue = (e) => {
		setSelectVal(e.target.value);
		let data = {
			status: e.target.value
		}
		props.dispatch(eventActions.setProgramStatusAdmin({ programId: props.id, eventId: params.id, data: data }))
	};

	return (
		<div className='event_purchaser_row'>
			<div className='event_purchaser_row_col wi-17' onMouseEnter={()=>{setDateHover(true)}} onMouseLeave={()=>{setDateHover(false)}}>
				<Link
					to={`/admin/events/${params.id}/program/${props.id}`}
					className='blue'
					style={{textDecoration:'underline'}}
				>
					{props.start_date}
					{dateHover&&<div className="programHover">Check in students</div>}
				</Link>
			</div>
			<div className='event_purchaser_row_col wi-17'>{props.end_date}</div>
			{/* <div className='event_purchaser_row_col wi-21'>{props.title}</div> */}
			<div className='event_purchaser_row_col wi-17'>{props.coach_name}</div>
			<div className='event_purchaser_row_col wi-10'>{props.booking_count}</div>
			<div className='event_purchaser_row_col wi-16'>
				{props.attended} Attended
			</div>
			<div className='event_purchaser_row_col wi-16'>
				{selectVal === 'completed' ? <img src={greenCheck} alt='' /> : ''}
				<select
					name=''
					id=''
					style={styles.dropDown}
					value={selectVal}
					onChange={(e) => handleValue(e)}
				>
					<option value='pending'>Pending</option>
					<option value='completed'>Completed</option>
				{/* <img src={ArrowBlueImg} alt='' /> */}
				</select>
			</div>
		</div>
	);
}

const styles = {
	dropDown: {
		border: 'none',
		color: '#262262',
		width: '150px',
		padding: '10px',
		background: `url(${ArrowBlueImg}) no-repeat right #fff`,
	},
};

const mapStateToProps = (state) => ({
	eventDetails: state.eventReducer.eventDetails,
	eventDetailsLoading: state.eventReducer.eventDetailsLoading,
});

export default connect(mapStateToProps)(EventProgramRow);
