import React from 'react';
import { connect } from 'react-redux';

import { baseURL } from '../../config/config';
import postActions from '../../redux/actions/postsActions';
import moment from 'moment-timezone';

let now = moment().tz('Australia/Sydney');
const handleTimeDiffrence = (incomingDate) => {
	// const seconds = now.diff(incomingDate, 'seconds');
	// const minutes = now.diff(incomingDate, 'minutes');
	// const hours = now.diff(incomingDate, 'hours');
	// const days = now.diff(incomingDate, 'days');
	// if (seconds <= 60) {
	// 	return seconds + ' sec';
	// } else if (minutes <= 60) {
	// 	return minutes + ' min';
	// } else if (hours <= 24) {
	// 	return hours + ' h ago';
	// } else {
	// 	return days + ' d ago';
	// }
	return moment(incomingDate).fromNow();
};
function Post(props) {
	return (
		<div style={styles.card}>
			{props.type === 1 ? (
				<div style={styles.image_div}>
					<img
						src={baseURL + '/image/' + props?.all?.image}
						style={styles.image}
						alt=''
					/>
				</div>
			) : null}
			<div style={styles.textContainer}>
				<span style={styles.title}>{props?.all?.title}</span>
				{/* Add the string here */}
				<p style={styles.paragraph}>
					{props?.all?.sub_title != null
						? `${props?.all?.sub_title?.substring(0, 80)}...`
						: ''}
				</p>
				<div style={styles.label}>
					{handleTimeDiffrence(props?.all?.created_at)}
					{props?.delete ? null :
						<div>
							<span className='mr-2' style={styles.control}
								onClick={() => {
									props.dispatch(
										postActions.popupHandler({ type: 'EDIT', data: props.all })
									);
								}}
							>
								Edit</span>
						</div>
					}
				</div>
			</div>
		</div>
	);
}

const styles = {
	card: {
		width: '440px',
		height: '150px',
		backgroundColor: '#E6E6F3',
		color: '#262262',
		display: 'flex',
		flexDirection: 'row',
		marginRight: '42px',
		marginTop: '20px',
	},
	image_div: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',
		width: '30%',
		height: '100%',
	},
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	title: {
		fontSize: '15px',
		fontWeight: 'bold',
		lineHeight: '1rem',
		margin: '7px 0px',
	},
	paragraph: {
		fontSize: '13px',
		lineHeight: '1rem',
	},
	label: {
		position: 'absolute',
		display: 'flex',
		fontSize: '11px',
		bottom: 21,
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '90%'
	},
	textContainer: {
		width: '70%',
		position: 'relative',
		padding: '15px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	control:{
		fontWeight: 'bold',
		fontSize: '16px',
		textDecoration: 'underline',
		cursor: 'pointer',
	}
};

const mapStateToProps = (state) => ({
	selectedPost: state.postReducer?.selectedPost,
});

export default connect(mapStateToProps)(Post);
