import moment from "moment";

export const getValue = (obj, expression) => {
  try {
    return expression.split(".").reduce((o, x, i) => {
      if (i === 0) {
        return o;
      }
      return typeof o === "undefined" || o === null ? undefined : o[x];
    }, obj);
  } catch (e) {
    console.error("getValue => " + e);
    return undefined;
  }
};

export const calculateAge = (birth) => {
  let target = moment();
  let months = target.diff(birth, 'months', true)
  let birthSpan = {year: Math.floor(months/12), month: Math.floor(months)%12, day: Math.round((months%1)*target.daysInMonth(),0)}
  // you can adjust below logic as your requirements by yourself
  if (birthSpan.year < 1 && birthSpan.month < 1) {
    return birthSpan.day + ' day' + (birthSpan.day > 1 ? 's' : '')
  } else if (birthSpan.year < 1) {
    return birthSpan.month + ' month' + (birthSpan.month > 1 ? 's ' : ' ') //+ birthSpan.day + ' day' + (birthSpan.day > 1 ? 's' : '')
  } else if (birthSpan.year < 2) {
    return birthSpan.year + ' year' + (birthSpan.year > 1 ? 's ' : ' ') //	+ birthSpan.month + ' month' + (birthSpan.month > 1 ? 's ' : '')
  } else {
    return birthSpan.year + ' year' + (birthSpan.year > 1 ? 's' : '')
  }
  }

// export const STATUS_FOR_ALL = {
//   waiting: {
//     lable: "Waiting",
//     image: waitingImg,
//   },
//   in_progress: {
//     lable: "In Progress",
//     image: inProgreeImg,
//   },
//   not_passed: {
//     lable: "Not Passed",
//     image: notPassedImg,
//   },
//   passed: {
//     lable: "Passed",
//     image: passedImg,
//   },
//   submitted: {
//     lable: "Submitted",
//     image: submittedImg,
//   },
//   enrolled: {
//     lable: "Enrolled",
//     image: enrolledImg,
//   },
//   active: {
//     lable: "Active",
//     image: activeImg,
//   },
//   not_active: {
//     lable: "Not Active",
//     image: deactiveImg,
//   },
//   "not active": {
//     lable: "Not Active",
//     image: deactiveImg,
//   },
//   satisfied: {
//     lable: "Satisfied",
//     image: passedImg,
//   },
//   not_satisfied: {
//     lable: "Not Yet Satisfactory",
//     image: notPassedImg,
//   },
// };

// export const MONTH_ARRAY = [
//   "Jan",
//   "Feb",
//   "Mar",
//   "Apr",
//   "May",
//   "Jun",
//   "Jul",
//   "Aug",
//   "Sep",
//   "Oct",
//   "Nov",
//   "Dec",
// ];

// export const getDomain = () => {
//   return process.env.REACT_APP_SUB_DOMAIN;
// };

// export const fileToBase64 = (filename, filepath) => {
//   return new Promise((resolve) => {
//     var file = new File([filename], filepath);
//     var reader = new FileReader();
//     // Read file content on file loaded event
//     reader.onload = function (event) {
//       resolve(event.target.result);
//     };

//     // Convert data to base64
//     reader.readAsDataURL(file);
//   });
// };

// export const returnDateFormat = (date) => {
//   if (!date) return "";
//   return moment(date, "DD-MM-Y").format("Y-MM-DD");
// };

// export const returnDateFormatToDate = (date) => {
//   if (!date) return "";
//   return moment(date, "DD-MM-Y");
// };

// export const IMAGE_EXTENSION = "image/jpg,image/png,image/jpeg";
// export const IMAGE_EXTENSION_ARRAY = ["image/jpg", "image/png", "image/jpeg"];
export const toDataURL = (url) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );
