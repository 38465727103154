import {
  editProfile,
  getSettings,
  setSettings,
  getSetingsById,
  getSchools,
  addSchool,
  editSchool,
  deleteSchool,
  getSuperAdminProfile,
} from "../../config/config";
import { toast } from "react-toastify";
import history from "../../history";
import postsActions from "./postsActions";

export const EDIT_PROFILE_REQUEST = "EDIT_PROFILE_REQUEST";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_ERROR = "EDIT_PROFILE_ERROR";
export const LOAD_PROFILE_DATA = "LOAD_PROFILE_DATA";

export const GET_SETTINGS_REQUEST = "GET_SETTINGS_REQUEST";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_ERROR = "GET_SETTINGS_ERROR";

export const SET_SETTINGS_REQUEST = "SET_SETTINGS_REQUEST";
export const SET_SETTINGS_SUCCESS = "SET_SETTINGS_SUCCESS";
export const SET_SETTINGS_ERROR = "SET_SETTINGS_ERROR";

export const GET_SCHOOLS_REQUEST = "GET_SCHOOLS_REQUEST";
export const GET_SCHOOLS_SUCCESS = "GET_SCHOOLS_SUCCESS";
export const GET_SCHOOLS_ERROR = "GET_SCHOOLS_ERROR";

export const GET_SUPER_PROFILE_REQUEST = "GET_SUPER_PROFILE_REQUEST";
export const GET_SUPER_PROFILE_SUCCESS = "GET_SUPER_PROFILE_SUCCESS";
export const GET_SUPER_PROFILE_ERROR = "GET_SUPER_PROFILE_ERROR";

const actions = {
  loadProfileData: (data) => (dispatch) => {
    dispatch({
      type: LOAD_PROFILE_DATA,
      payload: {
        data: data,
      },
    });
  },

  getSuperProfile: (uuid) => async (dispatch) => {
    try {
      dispatch({
        type: GET_SUPER_PROFILE_REQUEST,
      });
      await getSuperAdminProfile(
        uuid,
        "Bearer " + localStorage.access_token
      ).then((response) => {
        if (response.data.code === 200) {
          dispatch({
            type: GET_SUPER_PROFILE_SUCCESS,
            payload: {
              data: response.data.data,
            },
          });
        } else {
          dispatch({
            type: GET_SUPER_PROFILE_ERROR,
          });
        }
      });
    } catch (error) {
      dispatch({
        type: GET_SUPER_PROFILE_ERROR,
      });
    }
  },

  editProfile: (data, uuid) => async (dispatch) => {
    try {
      dispatch({
        type: EDIT_PROFILE_REQUEST,
      });
      await editProfile(data, uuid, "Bearer " + localStorage.access_token).then(
        (response) => {
          if (response?.data?.code === 200) {
            toast.success("Edited Succesfully");
            dispatch({
              type: EDIT_PROFILE_SUCCESS,
              payload: {
                data: response.data.data,
              },
            });
            // history.push("/admin/profile")
          } else {
            toast.error(response.response.data.errors);
            dispatch({
              type: EDIT_PROFILE_ERROR,
            });
          }
        }
      );
    } catch (error) {
      toast.error(error.message);
      dispatch({
        type: EDIT_PROFILE_ERROR,
      });
    }
  },

  getSettings: () => async (dispatch) => {
    try {
      dispatch({
        type: GET_SETTINGS_REQUEST,
      });
      await getSettings("Bearer " + localStorage.access_token)
        .then((response) => {
          if (response.data.code === 200) {
            if (response.data.data.length > 0) {
              let finalData = [];
              Promise.all(
                response.data.data.map(async (zone) => {
                  let data = {
                    settings_id: zone.id,
                    type: zone.type,
                  };
                  await getSetingsById(data).then((resp) => {
                    if (resp.data.code === 200) {
                      finalData.push({
                        value: resp.data.data,
                        type: zone.type,
                        settings_id: zone.id,
                      });
                    }
                  });
                })
              ).then(() => {
                dispatch({
                  type: GET_SETTINGS_SUCCESS,
                  payload: finalData,
                });
              });
            } else {
              dispatch({
                type: GET_SETTINGS_ERROR,
              });
            }
          } else {
            dispatch({
              type: GET_SETTINGS_ERROR,
            });
          }
        })
        .catch((err) => {
          toast.error(err);
          dispatch({
            type: GET_SETTINGS_ERROR,
          });
        });
    } catch (err) {
      toast.error(err.message);
      dispatch({
        type: GET_SETTINGS_ERROR,
      });
    }
  },

  editSettings: (data, uuid) => async (dispatch) => {
    try {
      dispatch({
        type: SET_SETTINGS_REQUEST,
      });
      let headers = {
        Authorization: "Bearer " + localStorage.access_token,
        "Content-type": "application/x-www-form-urlencoded",
        // 'Cookie': 'no1draft_session=' + localStorage.access_token
      };
      await setSettings(data, uuid, headers).then((response) => {
        if (response.data.code === 200) {
          toast.success("Settings Updated");
          dispatch({
            type: SET_SETTINGS_SUCCESS,
          });
          dispatch(actions.getSettings());
        } else {
          toast.error(response.message);
          dispatch({
            type: SET_SETTINGS_ERROR,
          });
        }
      });
    } catch (error) {
      toast.error(error.message);
      dispatch({
        type: SET_SETTINGS_ERROR,
      });
    }
  },

  getSchools: () => async (dispatch) => {
    try {
      dispatch({
        type: GET_SCHOOLS_REQUEST,
      });

      await getSchools().then((response) => {
        if (response.data.code === 200) {
          dispatch({
            type: GET_SCHOOLS_SUCCESS,
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: GET_SCHOOLS_ERROR,
          });
        }
      });
    } catch (err) {
      dispatch({
        type: GET_SCHOOLS_ERROR,
      });
    }
  },

  addSchool: (data) => async (dispatch) => {
    try {
      await addSchool(data).then((response) => {
        if (response.data.code === 200) {
          dispatch(actions.getSchools());
          // dispatch(postsActions.popupHandler({ type: 'CLOSE' }))
          toast.success("School Added");
        } else {
          toast.error("Error: " + response?.data?.message);
        }
      });
    } catch (err) {
      toast.error("Error");
    }
  },

  editSchool: (data) => async (dispatch) => {
    try {
      let headers = {
        Authorization: "Bearer " + localStorage.access_token,
        "Content-type": "application/x-www-form-urlencoded",
        // 'Cookie': 'no1draft_session=' + localStorage.access_token
      };
      await editSchool(data, headers).then((response) => {
        if (response.data.code === 200) {
          dispatch(actions.getSchools());
          // dispatch(postsActions.popupHandler({ type: 'CLOSE' }))
          toast.success("School Edited");
        } else {
          toast.error("Error: " + response?.data?.message);
        }
      });
    } catch (err) {
      toast.error("Error" + err);
    }
  },

  deleteSchool: (data) => async (dispatch) => {
    try {
      await deleteSchool(data).then((response) => {
        if (response.data.code === 200) {
          dispatch(actions.getSchools());
          dispatch(postsActions.popupHandler({ type: 'CLOSE' }))
          toast.success("School Deleted");
        } else {
          toast.error("Error: " + response?.data?.message);
        }
      });
    } catch (err) {
      toast.error("Error");
    }
  },
};

export default actions;
