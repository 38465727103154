import {
	getEventCategories,
	getEventByCategoryId,
	createEvent,
	cloneEvent,
	getEventDetails,
	publishEvent,
	cancelEvent,
	sendInvitationToAllMembers,
	editEvent,
	getEventBookingsAdmin,
	getEventRelatedProgramsAdmin,
	getEventRelatedProgramsBookingAdmin,
	setChildrenAttendanceAdmin,
	setProgramCompletedAdmin,
	deleteProgram,
	getEvents,
	addEventCategory,
	editEventCategory,
	updateEventCategoryOrder,
	sendBulkInvitationToAllMembers,
	eventSearch,
	deleteEventCanceled,
} from '../../config/config';
import postsActions from './postsActions'

import { toast } from 'react-toastify';

export const GET_EVENT_CATEGORIES_REQUEST = 'GET_EVENT_CATEGORIES_REQUEST';
export const GET_EVENT_CATEGORIES_SUCCESS = 'GET_EVENT_CATEGORIES_SUCCESS';
export const GET_EVENT_CATEGORIES_ERROR = 'GET_EVENT_CATEGORIES_ERROR';

export const GET_EVENT_BYCATEGORYID_REQUEST = 'GET_EVENT_BYCATEGORYID_REQUEST';
export const GET_EVENT_BYCATEGORYID_SUCCESS = 'GET_EVENT_BYCATEGORYID_SUCCESS';
export const GET_EVENT_BYCATEGORYID_ERROR = 'GET_EVENT_BYCATEGORYID_ERROR';

export const HANDLE_ADD_EVENT = 'HANDLE_ADD_EVENT';
export const HANDLE_EVENT_REC = 'HANDLE_EVENT_REC';

export const HANDLE_ADD_EVENT_PROGRAM = 'HANDLE_ADD_EVENT_PROGRAM';
export const HANDLE_EDIT_EVENT_PROGRAM = 'HANDLE_EDIT_EVENT_PROGRAM';
export const HANDLE_DELETE_EVENT_PROGRAM = 'HANDLE_EDIT_EVENT_PROGRAM';

export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_ERROR = 'CREATE_EVENT_ERROR';

export const CLONE_EVENT_REQUEST = 'CLONE_EVENT_REQUEST';
export const CLONE_EVENT_SUCCESS = 'CLONE_EVENT_SUCCESS';
export const CLONE_EVENT_ERROR = 'CLONE_EVENT_ERROR';

export const GET_EVENT_DETAILS_REQUEST = 'GET_EVENT_DETAILS_REQUEST';
export const GET_EVENT_DETAILS_SUCCESS = 'GET_EVENT_DETAILS_SUCCESS';
export const GET_EVENT_DETAILS_ERROR = 'GET_EVENT_DETAILS_ERROR';

export const PUBLISH_EVENT_REQUEST = 'PUBLISH_EVENT_REQUEST';
export const PUBLISH_EVENT_SUCCESS = 'PUBLISH_EVENT_SUCCESS';
export const PUBLISH_EVENT_ERROR = 'PUBLISH_EVENT_ERROR';

export const CANCEL_EVENT_REQUEST = 'CANCEL_EVENT_REQUEST';
export const CANCEL_EVENT_SUCCESS = 'CANCEL_EVENT_SUCCESS';
export const CANCEL_EVENT_ERROR = 'CANCEL_EVENT_ERROR';

export const ASSIGN_INCOMING_DATA = 'ASSIGN_INCOMING_DATA';

export const HANDLE_ADD_EVENT_TYPE = 'HANDLE_ADD_EVENT_TYPE';

export const SEND_EMAIL_TO_MEMBERS_REQUEST = 'SEND_EMAIL_TO_MEMBERS_REQUEST';
export const SEND_EMAIL_TO_MEMBERS_SUCCESS = 'SEND_EMAIL_TO_MEMBERS_SUCCESS';
export const SEND_EMAIL_TO_MEMBERS_ERROR = 'SEND_EMAIL_TO_MEMBERS_ERROR';

export const EDIT_EVENT_REQUEST = 'EDIT_EVENT_REQUEST';
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS';
export const EDIT_EVENT_ERROR = 'EDIT_EVENT_ERROR';

export const GET_BOOKINGS_EVENT_REQUEST = 'GET_BOOKINGS_EVENT_REQUEST';
export const GET_BOOKINGS_EVENT_SUCCESS = 'GET_BOOKINGS_EVENT_SUCCESS';
export const GET_BOOKINGS_EVENT_ERROR = 'GET_BOOKINGS_EVENT_ERROR';

export const GET_EVENT_RELATED_PROGRAMS_REQUEST = 'GET_EVENT_RELATED_PROGRAMS_REQUEST';
export const GET_EVENT_RELATED_PROGRAMS_SUCCESS = 'GET_EVENT_RELATED_PROGRAMS_SUCCESS';
export const GET_EVENT_RELATED_PROGRAMS_ERROR = 'GET_EVENT_RELATED_PROGRAMS_ERROR';

export const GET_EVENT_RELATED_PROGRAMS_BOOKING_REQUEST = 'GET_EVENT_RELATED_PROGRAMS_BOOKING_REQUEST';
export const GET_EVENT_RELATED_PROGRAMS_BOOKING_SUCCESS = 'GET_EVENT_RELATED_PROGRAMS_BOOKING_SUCCESS';
export const GET_EVENT_RELATED_PROGRAMS_BOOKING_ERROR = 'GET_EVENT_RELATED_PROGRAMS_BOOKING_ERROR';

export const CHILD_ATTENDANCE_ADMIN_REQUEST = 'CHILD_ATTENDANCE_ADMIN_REQUEST';
export const CHILD_ATTENDANCE_ADMIN_SUCCESS = 'CHILD_ATTENDANCE_ADMIN_SUCCESS';
export const CHILD_ATTENDANCE_ADMIN_ERROR = 'CHILD_ATTENDANCE_ADMIN_ERROR';

export const PROGRAM_ATTENDANCE_ADMIN_REQUEST = 'PROGRAM_ATTENDANCE_ADMIN_REQUEST';
export const PROGRAM_ATTENDANCE_ADMIN_SUCCESS = 'PROGRAM_ATTENDANCE_ADMIN_SUCCESS';
export const PROGRAM_ATTENDANCE_ADMIN_ERROR = 'PROGRAM_ATTENDANCE_ADMIN_ERROR';

export const SEND_BULK_EMAIL_TO_MEMBERS_REQUEST = 'SEND_BULK_EMAIL_TO_MEMBERS_REQUEST'
export const SEND_BULK_EMAIL_TO_MEMBERS_SUCCESS = 'SEND_BULK_EMAIL_TO_MEMBERS_SUCCESS'
export const SEND_BULK_EMAIL_TO_MEMBERS_ERROR = 'SEND_BULK_EMAIL_TO_MEMBERS_ERROR'


export const CLEAR_FORM = 'CLEAR_FORM';
export const CLEAR_EVENT_DETAILS = 'CLEAR_EVENT_DETAILS';

export const CHANGE_CATEGORIES_ORDER = 'CHANGE_CATEGORIES_ORDER';

export const EVENT_PAGE_STATE = 'EVENT_PAGE_STATE';

export const HANDLE_REC_DATA = 'HANDLE_REC_DATA';

const actions = {
	getEventCategories: (data) => async (dispatch) => {
		try {
			dispatch({
				type: GET_EVENT_CATEGORIES_REQUEST,
			});
			await getEventCategories().then((response) => {
				if (response.data.code === 200) {
					dispatch({
						type: GET_EVENT_CATEGORIES_SUCCESS,
						payload: {
							data: response.data.data,
						},
					});
				} else {
					dispatch({
						type: GET_EVENT_CATEGORIES_ERROR,
						payload: {
							message: response.message,
						},
					});
				}
			});
		} catch (error) {
			dispatch({
				type: GET_EVENT_CATEGORIES_ERROR,
				payload: {
					message: 'Error Loading Posts',
				},
			});
		}
	},

	searchEventsByQuery: (data, categorie_id) => async (dispatch) => {
		try {
			dispatch({
				type: GET_EVENT_BYCATEGORYID_REQUEST,
			});
			await eventSearch(data, categorie_id).then((response) => {
				if (response.data.code === 200) {
					dispatch({
						type: GET_EVENT_BYCATEGORYID_SUCCESS,
						payload: {
							data: response.data.data,
						},
					});
				} else {
					dispatch({
						type: GET_EVENT_BYCATEGORYID_ERROR,
						payload: {
							message: response.message,
						},
					});
				}
			});
		} catch (error) {
			dispatch({
				type: GET_EVENT_BYCATEGORYID_ERROR,
				payload: {
					message: 'Error Loading Posts',
				},
			});
		}
	},

	getEventsByCategoryId: (data, type) => async (dispatch) => {
		try {
			dispatch({
				type: GET_EVENT_BYCATEGORYID_REQUEST,
			});
			if (type?.type === 1) {
				await getEvents(data).then((response) => {
					if (response.data.code === 200) {
						dispatch({
							type: GET_EVENT_BYCATEGORYID_SUCCESS,
							payload: {
								data: response.data.data,
							},
						});
					} else {
						dispatch({
							type: GET_EVENT_BYCATEGORYID_ERROR,
							payload: {
								message: response.message,
							},
						});
					}
				});
			} else {
				await getEventByCategoryId(data).then((response) => {
					if (response.data.code === 200) {
						dispatch({
							type: GET_EVENT_BYCATEGORYID_SUCCESS,
							payload: {
								data: response.data.data,
							},
						});
					} else {
						dispatch({
							type: GET_EVENT_BYCATEGORYID_ERROR,
							payload: {
								message: response.message,
							},
						});
					}
				});
			}

		} catch (error) {
			dispatch({
				type: GET_EVENT_BYCATEGORYID_ERROR,
				payload: {
					message: 'Error Loading Posts',
				},
			});
		}
	},

	handleAddEventFormData: (data) => async (dispatch) => {
		let formData = data.formData;
		let finalData = {
			...formData,
			[data.name]: data.data,
		}
		dispatch({
			type: HANDLE_ADD_EVENT,
			payload: finalData,
		});
	},

	handleAddEventFormDataType: (data) => async (dispatch) => {
		let formData = data.formData;
		dispatch({
			type: HANDLE_ADD_EVENT_TYPE,
			payload: {
				...formData,
				[data.name]: data.data,
				[data.name2]: data.data2,
			},
		});
	},

	handleAddEventRecurenceData: (data) => async (dispatch) => {
		let formData = data.formData;
		dispatch({
			type: HANDLE_ADD_EVENT,
			payload: {
				...formData,
				[data.name]: data.data,
				[data.name2]: data.data2,
				[data.name3]: data.data3,
				[data.name4]: data.data4,
			},
		});
	},

	handleNewRecData: (data) => async (dispatch) => {
		dispatch({
			type: HANDLE_REC_DATA,
			payload: {
				count: data.count,
				dates: data.dates,
				text: data.text
			},
		});
	},

	handleAddEventProgram: (data) => async (dispatch) => {
		let formData = data.formData;
		let newPrograms = data.formData.programs;
		newPrograms.push(data.program);
		dispatch({
			type: HANDLE_ADD_EVENT_PROGRAM,
			payload: {
				...formData,
				programs: newPrograms,
			},
		});
	},

	handleEditEventProgram: (data) => (dispatch) => {
		let formData = data.formData;
		let newPrograms = data.formData.programs;
		let FinalData = [];
		newPrograms.map((zone, i) => {
			if (data.type === 'edit') {
				if (zone.id === data.id) {
					FinalData.push(data.program);
				} else {
					FinalData.push({
						title: zone.title,
						ticket_price: zone.ticket_price,
						name: zone.name,
						number_of_days: zone.number_of_days,
						event_id: zone.event_id,
						id: zone.id,
						is_akv_allowed: zone.is_akv_allowed,
						discount: zone?.discount,
						from: zone?.from,
						to: zone?.to,
						ptype: zone.ptype,
					});
				}
			} else {
				if (i === data.id) {
					FinalData.push(data.program);
				} else {
					FinalData.push({
						title: zone.title,
						ticket_price: zone.ticket_price,
						name: zone.name,
						number_of_days: zone.number_of_days,
						is_akv_allowed: zone.is_akv_allowed,
					});
				}
			}


		});
		dispatch({
			type: HANDLE_EDIT_EVENT_PROGRAM,
			payload: {
				...formData,
				programs: FinalData,
			},
		});
	},

	handleDeleteEventProgram: (data) => (dispatch) => {
		let formData = data.formData;
		let newPrograms = data.formData.programs;
		let finalPrograms = [];
		newPrograms.map(async (zone, i) => {
			if (data.type === 1) {
				if (i !== data.id) {

					finalPrograms.push({
						title: zone.title,
						ticket_price: zone.ticket_price,
						name: zone.name,
						number_of_days: zone.number_of_days,
						event_id: zone.event_id,
						id: zone.id,
						ptype: zone.ptype,
					});
				} else if (i === data.id) {
					let deleteProgramData = {
						eventId: zone.event_id,
						programId: zone.id
					}


					await deleteProgram(deleteProgramData, 'Bearer ' + localStorage.access_token).then((response) => {
						if (response.data.code == "200") {
							actions.getEventDetailsById({ id: zone.event_id })
							// toast.success("Program Deleted")
						} else {
							finalPrograms.push({
								title: zone.title,
								ticket_price: zone.ticket_price,
								name: zone.name,
								number_of_days: zone.number_of_days,
								event_id: zone.event_id,
								id: zone.id,
								ptype: zone.ptype,
							})
							// toast.error("Program not deleted")
						}

					})
				}
			} else {
				if (i !== data.id) {
					finalPrograms.push({
						title: zone.title,
						ticket_price: zone.ticket_price,
						name: zone.name,
						number_of_days: zone.number_of_days,
					});
				} else {
					// toast.success("Program Deleted")
				}
			}

		});
		dispatch({
			type: HANDLE_DELETE_EVENT_PROGRAM,
			payload: {
				...formData,
				programs: finalPrograms,
			},
		});
	},

	createEvent: (data) => async (dispatch) => {
		try {
			dispatch({
				type: CREATE_EVENT_REQUEST,
			});
			await createEvent(data).then((response) => {
				if (response.data.code === 200) {
					toast.success('EVENT Created');
					dispatch({
						type: CREATE_EVENT_SUCCESS,
						payload: {
							data: response.data.data.data,
						},
					});
				} else {
					toast.error(response.data.message + ' ' + response.data.errors);
					dispatch({
						type: CREATE_EVENT_ERROR,
					});
				}
			});
		} catch (error) {
			toast.error(error.message);
			dispatch({
				type: CREATE_EVENT_ERROR,
			});
		}
	},

	cloneEvents: (data) => async (dispatch) => {
		try {
			dispatch({
				type: CLONE_EVENT_REQUEST,
			});
			await cloneEvent(data).then((response) => {
				if (response.data.code === 200) {
					toast.success('EVENTS Cloned');
					dispatch({
						type: CLONE_EVENT_SUCCESS,
						payload: {
							data: response.data.data.data,
						},
					});
				} else {
					toast.error(response.data.message + ' ' + response.data.errors);
					dispatch({
						type: CLONE_EVENT_ERROR,
					});
				}
			});
		} catch (error) {
			toast.error(error.message);
			dispatch({
				type: CLONE_EVENT_ERROR,
			});
		}
	},

	getEventDetailsById: (data) => async (dispatch) => {
		try {
			dispatch({
				type: GET_EVENT_DETAILS_REQUEST,
			});
			await getEventDetails(data).then((response) => {
				if (response.data.code === 200) {
					dispatch({
						type: GET_EVENT_DETAILS_SUCCESS,
						payload: {
							data: response.data.data,
						},
					});
				} else {
					dispatch({
						type: GET_EVENT_DETAILS_ERROR,
						payload: {
							message: response.message,
						},
					});
				}
			});
		} catch (error) {
			dispatch({
				type: GET_EVENT_DETAILS_ERROR,
				payload: {
					message: 'Error Loading Posts',
				},
			});
		}
	},

	assignIncomingData: (data) => async (dispatch) => {
		dispatch({
			type: ASSIGN_INCOMING_DATA,
			payload: {
				data: data.formData,
			},
		});
	},

	setPublished: (data) => async (dispatch) => {
		try {
			dispatch({
				type: PUBLISH_EVENT_REQUEST,
			});
			await publishEvent(data).then((response) => {
				if (response.data.code === 200) {
					toast.success('EVENT PUBLISHED');
					dispatch({
						type: PUBLISH_EVENT_SUCCESS,
						payload: {
							data: response.data.data.data,
						},
					});
				} else {
					toast.error('Error: EVENT not PUBLISHED');
					dispatch({
						type: PUBLISH_EVENT_ERROR,
						payload: {
							message: response.message,
						},
					});
				}
			});
		} catch (error) {
			toast.error('Error: EVENT not PUBLISHED');
			dispatch({
				type: PUBLISH_EVENT_ERROR,
				payload: {
					message: 'Error EVENT not PUBLISHED',
				},
			});
		}
	},

	cancelEvent: (data) => async (dispatch) => {
		try {
			dispatch({
				type: CANCEL_EVENT_REQUEST,
			});
			await cancelEvent(data).then((response) => {

				if (response.data.code === 200) {
					toast.success('EVENT Canceled');
					dispatch({
						type: CANCEL_EVENT_SUCCESS,
						payload: {
							data: response?.data?.data?.data,
						},
					});
				} else {
					toast.error(response.message);
					dispatch({
						type: CANCEL_EVENT_ERROR,
					});
				}
			});
		} catch (error) {
			toast.error(error.message);
			dispatch({
				type: CANCEL_EVENT_ERROR,
			});
		}
	},

	sendInvitationToAllMembers: (data) => async (dispatch) => {
		try {
			dispatch({
				type: SEND_EMAIL_TO_MEMBERS_REQUEST,
			});
			await sendInvitationToAllMembers(data).then((response) => {
				if (response.data.code === 200) {
					toast.success('Mail Sent');
					dispatch({
						type: SEND_EMAIL_TO_MEMBERS_SUCCESS,
						payload: {
							data: response.data.data.data,
						},
					});
				} else {
					toast.error('Error: Mail not Sent');
					dispatch({
						type: SEND_EMAIL_TO_MEMBERS_ERROR,
						payload: {
							message: response.message,
						},
					});
				}
			});
		} catch (error) {
			toast.error('Error: Mail not Sent');
			dispatch({
				type: SEND_EMAIL_TO_MEMBERS_ERROR,
				payload: {
					message: 'Error Mail not Sent',
				},
			});
		}
	},
	sendBulkInvitationToAllMembers: (data) => async (dispatch) => {
		try {
			dispatch({
				type: SEND_BULK_EMAIL_TO_MEMBERS_REQUEST,
			});
			await sendBulkInvitationToAllMembers(data).then((response) => {
				if (response.data.code === 200) {
					toast.success('Mail Sent');
					dispatch({
						type: SEND_BULK_EMAIL_TO_MEMBERS_SUCCESS,
						payload: {
							data: response.data.data.data,
						},
					});
				} else {
					toast.error(response.message);
					dispatch({
						type: SEND_BULK_EMAIL_TO_MEMBERS_ERROR,
						payload: {
							message: response.message,
						},
					});
				}
			});
		} catch (error) {
			if (error.response?.data?.message) {
				toast.error(error.response?.data?.message);
			} else {
				toast.error(error)
			}
			dispatch({
				type: SEND_BULK_EMAIL_TO_MEMBERS_ERROR,
				payload: {
					message: 'Error Mail not Sent',
				},
			});
		}
	},
	clearForm: () => async (dispatch) => {
		dispatch({
			type: CLEAR_FORM,
		});
	},

	clearEventDetails: () => (dispatch) => {
		dispatch({
			type: CLEAR_EVENT_DETAILS
		})
	},

	editEvent: (data) => async (dispatch) => {
		try {
			dispatch({
				type: EDIT_EVENT_REQUEST,
			});
			await editEvent(data).then((response) => {
				if (response.data.code === 200) {
					toast.success('EVENT Edited');
					dispatch({
						type: EDIT_EVENT_SUCCESS,
						payload: {
							data: response.data.data.data,
						},
					});
				} else {
					toast.error("ERROR: " + response?.data?.message);
					dispatch({
						type: EDIT_EVENT_ERROR,
					});
				}
			});
		} catch (error) {
			toast.error(error.message);
			dispatch({
				type: EDIT_EVENT_ERROR,
			});
		}
	},


	getEventBookings: (data) => async (dispatch) => {
		try {
			dispatch({
				type: GET_BOOKINGS_EVENT_REQUEST,
			});
			await getEventBookingsAdmin(data, 'Bearer ' + localStorage.access_token).then((response) => {
				if (response.data.code === 200) {
					dispatch({
						type: GET_BOOKINGS_EVENT_SUCCESS,
						payload: {
							data: response.data.data.event,
							totalSales: response.data.data.total_event_sales.total_event_sales,
							total_booking: response.data.data.total_booking
						},
					});
				} else {
					dispatch({
						type: GET_BOOKINGS_EVENT_ERROR,
						payload: {
							message: response.message,
						},
					});
				}
			});
		} catch (error) {
			dispatch({
				type: GET_BOOKINGS_EVENT_ERROR,
				payload: {
					message: 'Error Loading bookings',
				},
			});
		}
	},


	getEventRelatedPrograms: (data) => async (dispatch) => {
		try {
			dispatch({
				type: GET_EVENT_RELATED_PROGRAMS_REQUEST,
			});
			await getEventRelatedProgramsAdmin(data, 'Bearer ' + localStorage.access_token).then((response) => {
				if (response.data.code === 200) {
					dispatch({
						type: GET_EVENT_RELATED_PROGRAMS_SUCCESS,
						payload: {
							data: response.data.data,
						},
					});
				} else {
					dispatch({
						type: GET_EVENT_RELATED_PROGRAMS_ERROR,
						payload: {
							message: response.message,
						},
					});
				}
			});
		} catch (error) {
			dispatch({
				type: GET_EVENT_RELATED_PROGRAMS_ERROR,
				payload: {
					message: 'Error Loading bookings',
				},
			});
		}
	},


	getEventRelatedProgramsBooking: (data) => async (dispatch) => {
		try {
			dispatch({
				type: GET_EVENT_RELATED_PROGRAMS_BOOKING_REQUEST,
			});
			await getEventRelatedProgramsBookingAdmin(data, 'Bearer ' + localStorage.access_token).then((response) => {
				if (response.data.code === 200) {
					dispatch({
						type: GET_EVENT_RELATED_PROGRAMS_BOOKING_SUCCESS,
						payload: {
							data: response.data.data,
						},
					});
				} else {
					dispatch({
						type: GET_EVENT_RELATED_PROGRAMS_BOOKING_ERROR,
						payload: {
							message: response.message,
						},
					});
				}
			});
		} catch (error) {
			dispatch({
				type: GET_EVENT_RELATED_PROGRAMS_BOOKING_ERROR,
				payload: {
					message: 'Error Loading bookings',
				},
			});
		}
	},


	setChildrenAttendanceAdmin: (data) => async (dispatch) => {
		try {
			dispatch({
				type: CHILD_ATTENDANCE_ADMIN_REQUEST,
			});
			await setChildrenAttendanceAdmin(data, 'Bearer ' + localStorage.access_token).then((response) => {
				if (response.data.code === 200) {
					toast.success('Attendance Status Updated');
					dispatch({
						type: CHILD_ATTENDANCE_ADMIN_SUCCESS,
						payload: {
							data: response.data.data.data,
						},
					});
					dispatch(actions.getEventRelatedProgramsBooking({ eventId: data.eventId, programId: data.event_recurrence_id, page: 1 }))
				} else {
					toast.error('Error: ' + response?.data?.message);
					dispatch({
						type: CHILD_ATTENDANCE_ADMIN_ERROR,
					});
				}
			});
		} catch (error) {
			toast.error(error.message);
			dispatch({
				type: CHILD_ATTENDANCE_ADMIN_ERROR,
			});
		}
	},

	deleteEvent: (id,history) => async (dispatch) => {
		try {
			let headers = {
				'Authorization': 'Bearer ' + localStorage.access_token,
				'Content-type': 'application/x-www-form-urlencoded',
			}
			await deleteEventCanceled(id, headers).then((response) => {
				console.log('RESPONSE', response)
				history.push('/admin/events')
			});
		} catch (error) {
			console.log('ERROR delete event', error)
		}
	},

	setProgramStatusAdmin: (data) => async (dispatch) => {
		try {
			dispatch({
				type: PROGRAM_ATTENDANCE_ADMIN_REQUEST,
			});
			await setProgramCompletedAdmin(data, 'Bearer ' + localStorage.access_token).then((response) => {
				if (response.data.code === 200) {
					toast.success('Program Status Updated');
					dispatch({
						type: PROGRAM_ATTENDANCE_ADMIN_SUCCESS,
						payload: {
							data: response.data.data.data,
						},
					});
				} else {
					toast.error(response.message);
					dispatch({
						type: PROGRAM_ATTENDANCE_ADMIN_ERROR,
					});
				}
			});
		} catch (error) {
			toast.error(error.message);
			dispatch({
				type: PROGRAM_ATTENDANCE_ADMIN_ERROR,
			});
		}
	},

	createEventCategory: (data) => async (dispatch) => {
		try {
			await addEventCategory(data).then((response) => {
			  if (response.data.code === 200) {
				dispatch(actions.getEventCategories());
				dispatch(postsActions.popupHandler({ type: 'CLOSE' }))
				toast.success("New Category Added");
			  } else {
				toast.error("Error: " + response?.data?.message);
			  }
			});
		  } catch (err) {
			toast.error("Error"+err);
		  }
	},

	editEventCategory: (data) => async (dispatch) => {
		try {
			await editEventCategory(data).then((response) => {
			  if (response.data.code === 200) {
				dispatch(actions.getEventCategories());
				dispatch(postsActions.popupHandler({ type: 'CLOSE' }))
				toast.success("Category Edited");
			  } else {
				toast.error("Error: " + response?.data.message);
			  }
			});
		  } catch (err) {
			toast.error("Error"+err);
		  }
	},

	editCategoryOrder: (data) => async (dispatch) => {
		try {
			await updateEventCategoryOrder(data).then((response) => {
			  if (response.data.code === 200) {
				dispatch(actions.getEventCategories())
				toast.success("Categories Arranged");
			  } else {
				toast.error("Error: " + response?.data?.message);
			  }
			});
		  } catch (err) {
			toast.error("Error"+err);
		  }
	},

	changeCategoriesOrder: (data) => (dispatch) => {
		dispatch({
		  type: CHANGE_CATEGORIES_ORDER,
		  data: data,
		})
	},

	changePageState: (pageOn) => (dispatch) => {
		dispatch({
		  type: EVENT_PAGE_STATE,
		  pageOn: pageOn,
		})
	},
};

export default actions;
