import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import PlacesAutocomplete from "react-places-autocomplete";
// import { Col, Row } from 'react-bootstrap'
import { InputAdornment, TextField, Card,  } from "@material-ui/core";
import { LocationOn } from "@material-ui/icons";
import { geocodeByAddress } from "react-places-autocomplete";

import './AddressAuto.scss';

export const AddressAuto = ({
    addressInfo,
    setAddressInfo,
    styling,
    inputStyling,

  }) => {

    var options = {
        componentRestrictions: { country: 'au' }
    };

    const handlePlaceChange = (name) => (e) => {
        setAddressInfo({...addressInfo, [name]: e.target.value})
	}


      const handlePlaceSelect = selected => {
        let selectedArray = selected.split(",")
        geocodeByAddress(selected).then((results) => {
            console.log('Address selected', results)

            let streetNumber=''
            let streetName=''
            let address_1 = ''
            let address_2 = ''
            let suburb = ''
            let state = ''
            let postal_code = ''
    
            results[results.length-1].address_components.map((zone) => {
                switch(zone.types[0]){
                    case 'street_number':
                        streetNumber= zone.long_name;
                        break;
                    case 'route':
                        streetName = zone.long_name;
                        break;
                    case 'locality':
                        suburb = zone.long_name;
                        break;
                    case 'administrative_area_level_1':
                        state = zone.short_name;
                        break;
                    case 'postal_code':
                        postal_code = zone.short_name;
                        break;

                    default:
                        break;
                }
            })
            if(selectedArray.length>=4){
                address_1=selectedArray[0]
                address_2=`${streetNumber&&streetNumber+' '}${streetName}`
            }else{
                address_1=`${streetNumber&&streetNumber+' '}${streetName}`
            }
            setAddressInfo({...addressInfo, address_1, address_2, suburb, state, postal_code});          
        
        })
	}

    return (
        <>
        <div className="edit_form_row">
             <div className='coach_edit_input_div font-18 '>
                <label className='bold blue text-upper'>Street Address</label>
            <PlacesAutocomplete
                value={addressInfo.address_1 || ''}
                onChange={(e) => {setAddressInfo({...addressInfo, address_1: e})}}
                onSelect={handlePlaceSelect}
                highlightFirstSuggestion={true}
                searchOptions={options}
            >
                {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
                }) => {
                return (
                    <div key={'a'} >
                    <TextField 
                        {...getInputProps({
                        placeholder: "Address",
                        })}
                        className={`${styling}-auto-address-textfield`}
                        fullWidth
                        InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                            <LocationOn className='address-icon'/>
                            </InputAdornment>
                        ),
                        }}
                    />
                    {suggestions && suggestions.length ? (
                        <Card className='address-auto-container'>
                        {loading && <div key={'Loading'}>Loading...</div>}
                        
                        {suggestions?.map((suggestion, i) => {
                        
                            const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
        
                            const style = suggestion.active
                            ? { color: "#000", cursor: "pointer", lineHeight: '30px', fontWeight: 'bold' }
                            : { color: "#000", cursor: "pointer", lineHeight: '30px' };
                        
                            return (
                            
                            <div  
                                {...getSuggestionItemProps(suggestion, {
                                className,
                                style
                                })}
                                key={i}
                            >
                                <span className="suggestions">
                                {suggestion.description}
                                </span>
                            </div>
                            )
                        })}
                        </Card>
                    ) : null}
                    </div>
                )
                }}
            </PlacesAutocomplete>
            </div>
            <div className='coach_edit_input_div font-18 '>
                <label className='bold blue text-upper'>Unit / Apartment no.</label>
                <input        
                    className={inputStyling}
                    type="text"
                    placeholder="Unit / Apartment no."
                    name="address_2"
                    value={addressInfo.address_2}
                    onChange={handlePlaceChange('address_2')}
                /> 
            </div>
            </div>
            <div className="edit_form_row">
            <div className='coach_edit_input_div font-18 '>
                <label className='bold blue text-upper'>Suburb</label>
                <input
                    className={inputStyling}
                    type="text"
                    placeholder="Suburb"
                    name="suburb"
                    value={addressInfo.suburb}
                    onChange={handlePlaceChange('suburb')}
                />
            </div>
            <div className='coach_edit_input_div font-18 '>
                <label className='bold blue text-upper'>State</label>
                <select 
                    name="state"
                    className="states_shipping" 
                    value={addressInfo.state}
                    onChange={handlePlaceChange('state')}
                >
                    <option value="NSW">NSW</option>
                    <option value="ACT">ACT</option>
                    <option value="NT">NT</option>
                    <option value="QLD">QLD</option>
                    <option value="SA">SA</option>
                    <option value="TAS">TAS</option>
                    <option value="VIC">VIC</option>
                    <option value="WA">WA</option>
                </select>
            </div>
            </div>
            <div className="edit_form_row">
            <div className='coach_edit_input_div font-18 '>
                <label className='bold blue text-upper'>Postal Code</label>
                <input
                    type="text"
                    className={inputStyling}
                    placeholder="Postal Code"
                    name="postal_code"
                    value={addressInfo.postal_code}
                    onChange={handlePlaceChange('postal_code')}
                />
            </div>
            <div className='coach_edit_input_div font-18 '></div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressAuto)
