import React, { useState } from "react";
import { login } from "../../actions/auth";
import { connect } from "react-redux";
import LoginView from "./Login.view";
import "./login.scss";

const Login = ({ auth: { loading }, login }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [checked, setChecked] = useState("3");


  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "type") {
      setChecked(e.target.value);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    login(formData);
  };

  return (
    <LoginView
      onSubmit={onSubmit}
      formData={formData}
      onChange={onChange}
      loading={loading}
    />
  );
};

const mapStateToProps = (state) => ({
  auth: state.register,
});

export default connect(mapStateToProps, { login })(Login);
