import React from 'react';
import Select from 'react-select';
import insertPhotoimg from '../../assets/images/insert-photo-block.png';
import CameraImg from '../../assets/images/camera.svg';
import plusRedeImg from '../../assets/images/plus-rede.svg';
import whiteX from '../../assets/images/x-white.svg';
import './AddProduct.scss';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { baseURL } from '../../config/config';

const ProductFormView = ({
	formData,
	errors,
	categoryValue,
	onChangeCategory,
	handleOnChange,
	handleProductMetaChanges,
	AddProductMeta,
	handleOnChangeImage,
	categories,
	relatedProducts,
	handleOnChangeRelatedProduct,
	relatedProduct,
	onSubmit,
	activeSize,
	handleSizeSelection,
	handleSingleQuantity,
	getPhoto,
	handleRemoveSingleSize
}) => {
	const sizesClothes = [
		{ value: 'XXS', label: 'XXS', type: 'size' },
		{ value: 'XS', label: 'XS', type: 'size' },
		{ value: 'S', label: 'S', type: 'size' },
		{ value: 'M', label: 'M', type: 'size' },
		{ value: 'L', label: 'L', type: 'size' },
		{ value: 'XL', label: 'XL', type: 'size' },
		{ value: 'XXL', label: 'XXL', type: 'size' },
		{ value: 'XXXL', label: 'XXXL', type: 'size' },
	];

	return (
		<div className='dashboard'>
			<div className='container-fluid'>
				<div className='new_prod pt-3'>
					<div className='text_one_area position-relative blue regular font-16'>
						<label className='text-upper  bold font-14'>Category</label>
						<Select
							isDisabled={true}
							options={categories}
							value={categoryValue}
							selectedValue={formData && formData.category_id}
							onChange={onChangeCategory}

						/>
					</div>
					<div className='d-inline-block w-100 pt-5'>
						<div className='font-14 bold blue pb-2 text-upper'>
							Product Photos
						</div>
						{formData.photos &&
							formData.photos.length > 0 &&
							formData.photos.map((photo, index) => {
								return (
									<div key={index}>
										<div className='circle_img position-relative d-inline-block'>
											<div className='danny_profile'>
												<img
													src={
														photo.photo
															? `${baseURL}/${photo.photo}`
															: photo
													}
													alt=''
												/>
											</div>
										</div>
										<div className='padding-left-20 pt-3'>
											<div className='phone_number'>
												<span className='edit-pen-img'>
													<a href='#'>
														<img src={CameraImg} alt='' />
													</a>
												</span>
												<input
													type='file'
													name='photos'
													onChange={(e) => handleOnChangeImage(e, index)}
												/>
												<span className='blue bold font-14 padding-left-10 change text-upper text-underline'>
													Upload photo
												</span>
											</div>
										</div>
									</div>
								);
							})}
						<div className='circle_img position-relative d-inline-block'>
							<div className='danny_profile'>
								<img src={insertPhotoimg} alt='' />
							</div>
						</div>

						<div className='padding-left-20 pt-3'>
							<div className='phone_number'>
								<span className='edit-pen-img'>
									<a href='#'>
										<img src={CameraImg} alt='' />
									</a>
								</span>
								<input
									type='file'
									name='photos'
									onChange={(e) => handleOnChangeImage(e)}
								/>
								<span className='blue bold font-14 padding-left-10 change text-upper text-underline'>
									Upload photo
								</span>
							</div>
						</div>
					</div>
				</div>
				<div style={{ display: 'flex', }}>
					<div className='text_all_area w-50 pt-5 blue regular font-16'>
						<label className='text-upper  bold font-14'>Product Name *</label>
						<input
							type='text'
							name='name'
							value={formData.name}
							onChange={(e) => handleOnChange(e)}
							placeholder='Product Name'
							required
						></input>
					</div>
					<div className='text_all_area w-50 pt-5 blue regular font-16'>
						<label className='text-upper  bold font-14'>Item Id In Business Centeral *</label>
						<input
							type='text'
							name='item_id_in_business_center'
							value={formData.item_id_in_business_center}
							onChange={(e) => handleOnChange(e)}
							placeholder='Product Name'
							required
						></input>
					</div>
				</div>
				<div style={{ display: 'flex', }}>
					<div className='text_all_area w-30 pt-5 blue regular font-16'>
						<label className='text-upper  bold font-14'>Meta Title</label>
						<input
							type='text'
							name='meta_title'
							value={formData.meta_title}
							onChange={(e) => handleOnChange(e)}
							placeholder='Meta Title'
						></input>
					</div>
					<div className='text_all_area w-30 pt-5 blue regular font-16'>
						<label className='text-upper  bold font-14'>Meta Description</label>
						<input
							type='text'
							name='meta_description'
							value={formData.meta_description}
							onChange={(e) => handleOnChange(e)}
							placeholder='Meta Description'
						></input>
					</div>
					<div className='text_all_area w-30 pt-5 blue regular font-16'>
						<label className='text-upper  bold font-14'>Meta Keywords</label>
						<input
							type='text'
							name='meta_keywords'
							value={formData.meta_keywords}
							onChange={(e) => handleOnChange(e)}
							placeholder='Meta Keywords'
						></input>
					</div>
				</div>

				<div className='text_all_area  pt-5 blue regular font-16'>
					<label className='text-upper  bold font-14'>Description *</label>
					<CKEditor
						editor={ClassicEditor}
						data={formData.description ? formData.description : ''}
						config={{
							toolbar: [
								'heading',
								'|',
								'bold',
								'italic',
								'link',
								'bulletedList',
								'numberedList',
								'blockQuote',
							],
							heading: {
								options: [
									{
										model: 'paragraph',
										title: 'Paragraph',
										class: 'ck-heading_paragraph',
									},
									{
										model: 'heading1',
										view: 'h1',
										title: 'Heading 1',
										class: 'ck-heading_heading1',
									},
									{
										model: 'heading2',
										view: 'h2',
										title: 'Heading 2',
										class: 'ck-heading_heading2',
									},
									{
										model: 'heading3',
										view: 'h3',
										title: 'Heading 3',
										class: 'ck-heading_heading3',
									},
									{
										model: 'heading4',
										view: 'h4',
										title: 'Heading 4',
										class: 'ck-heading_heading4',
									},
									{
										model: 'heading5',
										view: 'h5',
										title: 'Heading 5',
										class: 'ck-heading_heading5',
									},
									{
										model: 'heading6',
										view: 'h6',
										title: 'Heading 6',
										class: 'ck-heading_heading6',
									},
								],
							},
						}}
						onChange={(event, editor) => {
							const data = editor.getData();
							let j = {
								target: {
									value: data,
									name: 'description',
								},
							};
							handleOnChange(j);
						}}
					/>
					<p className="error">{errors["description"]}</p>
				</div>

				<div className='w-100 d-flex align-items-center pt-2'>
					<div className='text_all_area responsive pt-5 blue regular font-16'>
						<label className='text-upper parent bold font-14'>SKU</label>
						<input
							type='text'
							name='sku'
							value={formData.sku}
							onChange={(e) => handleOnChange(e)}
							placeholder='sku'
						></input>
					</div>
				</div>

				<div className='w-100 d-flex  pt-2'>
					<div className='text_all_area responsive pt-5 blue regular font-16'>
						<label className='text-upper parent bold font-14'>
							Add Price($) *
						</label>
						<input
							type='text'
							name='price'
							value={formData.price}
							onChange={(e) => handleOnChange(e)}
							placeholder='AU$ 00.00'
							required
						></input>
					</div>
					<div className='text_all_area responsive  pt-5 blue regular font-16'>
						<label className='bold text-upper parent font-14'>
							Add Sales Offer ($)
						</label>
						<input
							type='text'
							name='discount'
							value={formData.discount || ''}
							onChange={(e) => handleOnChange(e)}
							placeholder='AU$ 00.00'
						></input>
						<p className="error">{errors["discount"]}</p>
					</div>
				</div>
				<div className='pt-2 d-inline-block stocks'>

					<div className='mt-2'>
						<span className='blue bold'>Active Size</span>
						<div className="profileCheckBox">
							<input id='sunday' type="checkbox" className="switch" checked={activeSize} onClick={() => handleSizeSelection()} />
						</div>
					</div>

					{activeSize ?
						<>
							{formData.product_meta &&
								<table className='add_size_product_table'>
									<thead>
										<tr>
											{categoryValue.label === 'Gift Vouchers' ? null : <td>Size*</td>}
											<td>Quantity *</td>
										</tr>
									</thead>
									<tbody>
										{formData.product_meta.map((meta, index) => {
											return (
												<tr >
													{categoryValue.label === 'Gift Vouchers' ? null :
														<td width='250'>
															{/* <Creatable
																className='size_selector'
																isClearable
																onChange={(e) => handleProductMetaChanges(index, e, 1)}
																onInputChange={(e) => handleProductMetaChanges(index, e, 2)}
																options={sizesClothes}
																placeholder='Select size'
															/> */}
															<div className='text_all_area d-inline-block pt-1 blue regular w-100 font-16'>
																<input
																	type='text'
																	required
																	className='qty_input_store'
																	name="size"
																	placeholder='Size'
																	value={meta.size}
																	onChange={(e) => handleProductMetaChanges(index, e)}
																></input>
															</div>
														</td>
													}
													<td className='position-relative' width='250'>
														<div className='text_all_area d-inline-block pt-1 blue regular w-100 font-16'>
															<input
																type='number'
																name='quantity_in_stock'
																className='qty_input_store'
																placeholder='Quantity'
																required
																value={meta.quantity_in_stock}
																onChange={(e) => handleProductMetaChanges(index, e)}
															></input>
														</div>
														<div className='delete_size_store pointer' onClick={() => handleRemoveSingleSize(index)}>
															<img src={whiteX} alt="delete size" />
														</div>
													</td>
												</tr>
											);
										})
										}

									</tbody>
								</table>
							}
							<p className="error">{errors["meta"]}</p>

							{categoryValue.label === 'Gift Vouchers' ? null :
								<div className='d-inline-block pl-2 mt-2 pointer'>
									<span className='insert'>
										<span onClick={(e) => AddProductMeta()}>
											<img src={plusRedeImg} alt='' />
										</span>
									</span>
								</div>}
						</>
						:
						<div className='text_all_area d-inline-block pt-1 blue regular font-16'>
							<label className='text-upper  bold font-14'>
								Quantity in stock *
							</label>
							<input
								type='number'
								required
								name='quantity_in_stock'
								className='qty_input_store'
								value={formData.single_quantity_in_stock}
								onChange={(e) => handleSingleQuantity(e)}
								placeholder='00'
							></input>
						</div>
					}


				</div>
				<div className='pt-2 d-inline-block stocks w-100'>
					<div className='text_all_area  d-inline-block pt-5 blue regular font-16'>
						<label className='text-upper  bold font-14'>Related products</label>
						<Select
							isMulti={true}
							options={relatedProducts}
							value={relatedProduct}
							selectedValue={relatedProduct}
							onChange={handleOnChangeRelatedProduct}
							styles={{
								input: styles => ({ ...styles, minWidth: 220 }),
							}}
						/>
					</div>
				</div>

				<div className='pt-2 d-inline-block stocks w-100'>
					<div className='text_all_area  d-inline-block pt-5 blue regular font-16'>
						<label className='text-upper  bold font-14'>Is Freight Applicable?</label>
						<div style={{display:'flex', flexDirection:'row'}}>
							

						<div className="pt-5 d-inline-block stocks w-100">

					<div className="active_boxes pl-3 d-inline-block">
						<label className="input-container input_container_new input-container_two">
							<input
								type="radio"
								name="is_freight_applicable"
								value={1}
								checked={
									formData.is_freight_applicable === 1 || formData.is_freight_applicable === "1"
								}
								onChange={(e) => handleOnChange(e)}
							/>
							<span className="checkmark checkmark_new"></span>
						</label>
					</div>
					<div className="d-inline-block  vertical-align-super bold font-14 blue text-upper">
						Yes
					</div>
				</div>

				<div className="pt-5 d-inline-block stocks w-100">

					<div className="active_boxes pl-3 d-inline-block">
						<label className="input-container input_container_new input-container_two">
							<input
								type="radio"
								name="is_freight_applicable"
								value={0}
								checked={
									formData.is_freight_applicable === 0 || formData.is_freight_applicable === "0"
								}
								onChange={(e) => handleOnChange(e)}
							/>
							<span className="checkmark checkmark_new"></span>
						</label>
					</div>
					<div className="d-inline-block  vertical-align-super bold font-14 blue text-upper">
						No
					</div>
				</div>

						</div>
					</div>
				</div>

				<div className="pt-5 d-inline-block stocks w-100">
					<div className="d-inline-block  vertical-align-super bold font-14 blue text-upper">
						Active
					</div>
					<div className="active_boxes pl-3 d-inline-block">
						<label className="input-container input_container_new input-container_two">
							<input
								type="radio"
								name="is_active"
								value={1}
								checked={
									formData.is_active === "1" || formData.is_active === 1
								}
								onChange={(e) => handleOnChange(e)}
							/>
							<span className="checkmark checkmark_new"></span>
						</label>
					</div>
				</div>


				<div className="d-inline-block stocks w-100">
					<div className="d-inline-block  vertical-align-super bold font-14 blue text-upper">
						Not Active
					</div>
					<div className="active_boxes pl-3 d-inline-block">
						<label className="input-container input_container_new input-container_two">
							<input
								type="radio"
								name="is_active"
								value={0}
								checked={
									formData.is_active === "0" || formData.is_active === 0
								}
								onChange={(e) => handleOnChange(e)}
							/>
							<span className="checkmark checkmark_new"></span>
						</label>
					</div>
				</div>
				<p className="error">{errors["general"]}</p>				
				<div className='edit-pen white blue-bg text-center bold font-14 red-bg pointer'>
					<div onClick={(e) => onSubmit()}>
						<span className='edit-class'>PUBLISH</span>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ProductFormView;
