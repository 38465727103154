import {
    getEventsBookingRevenue, getMembersDashboard, getShopRevenue, getProductsSalesbyQuarters, getPrograms
} from '../../config/config';
import { toast } from 'react-toastify';

export const GET_MEMBERS_DASHBOARD_REQUEST = 'GET_MEMBERS_DASHBOARD_REQUEST';
export const GET_MEMBERS_DASHBOARD_SUCCESS = 'GET_MEMBERS_DASHBOARD_SUCCESS';
export const GET_MEMBERS_DASHBOARD_ERROR = 'GET_MEMBERS_DASHBOARD_ERROR';

export const GET_EVENTS_BOOKING_REVENUE_REQUEST = 'GET_EVENTS_BOOKING_REVENUE_REQUEST';
export const GET_EVENTS_BOOKING_REVENUE_SUCCESS = 'GET_EVENTS_BOOKING_REVENUE_SUCCESS';
export const GET_EVENTS_BOOKING_REVENUE_ERROR = 'GET_EVENTS_BOOKING_REVENUE_ERROR';

export const GET_SHOP_REVENUE_REQUEST = 'GET_SHOP_REVENUE_REQUEST';
export const GET_SHOP_REVENUE_SUCCESS = 'GET_SHOP_REVENUE_SUCCESS';
export const GET_SHOP_REVENUE_ERROR = 'GET_SHOP_REVENUE_ERROR';

export const GET_PRODUCT_SALES_REQUEST = 'GET_PRODUCT_SALES_REQUEST';
export const GET_PRODUCT_SALES_SUCCESS = 'GET_PRODUCT_SALES_SUCCESS';
export const GET_PRODUCT_SALES_ERROR = 'GET_PRODUCT_SALES_ERROR';

export const GET_PROGRAMS_REQUEST = 'GET_PROGRAMS_REQUEST';
export const GET_PROGRAMS_SUCCESS = 'GET_PROGRAMS_SUCCESS';
export const GET_PROGRAMS_ERROR = 'GET_PROGRAMS_ERROR';

const actions = {
    getMembers: (data) => async (dispatch) => {
        try {
            dispatch({
                type: GET_MEMBERS_DASHBOARD_REQUEST,
            });
            await getMembersDashboard(data, 'Bearer ' + localStorage.access_token).then((response) => {
                if (response.data.code === 200) {
                    dispatch({
                        type: GET_MEMBERS_DASHBOARD_SUCCESS,
                        payload: {
                            data: response.data.data,
                        },
                    });
                } else {
                    dispatch({
                        type: GET_MEMBERS_DASHBOARD_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                }
            });
        } catch (error) {
            dispatch({
                type: GET_MEMBERS_DASHBOARD_ERROR,
                payload: {
                    message: 'Error Loading Events',
                },
            });
        }
    },


    getEventsBookingRevenue: (data) => async (dispatch) => {
        try {
            dispatch({
                type: GET_EVENTS_BOOKING_REVENUE_REQUEST,
            });
            await getEventsBookingRevenue(data, 'Bearer ' + localStorage.access_token).then((response) => {
                if (response.data.code === 200) {
                    dispatch({
                        type: GET_EVENTS_BOOKING_REVENUE_SUCCESS,
                        payload: {
                            data: response.data.data,
                        },
                    });
                } else {
                    dispatch({
                        type: GET_EVENTS_BOOKING_REVENUE_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                }
            });
        } catch (error) {
            dispatch({
                type: GET_EVENTS_BOOKING_REVENUE_ERROR,
                payload: {
                    message: 'Error Loading Events',
                },
            });
        }
    },



    getShopRevenue: (data) => async (dispatch) => {
        try {
            dispatch({
                type: GET_SHOP_REVENUE_REQUEST,
            });
            await getShopRevenue(data, 'Bearer ' + localStorage.access_token).then((response) => {
                if (response.data.code === 200) {
                    dispatch({
                        type: GET_SHOP_REVENUE_SUCCESS,
                        payload: {
                            data: response.data.data,
                        },
                    });
                } else {
                    dispatch({
                        type: GET_SHOP_REVENUE_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                }
            });
        } catch (error) {
            dispatch({
                type: GET_SHOP_REVENUE_ERROR,
                payload: {
                    message: 'Error Loading Events',
                },
            });
        }
    },



    getProductsSalesbyQuarters: (data) => async (dispatch) => {
        try {
            dispatch({
                type: GET_PRODUCT_SALES_REQUEST,
            });
            await getProductsSalesbyQuarters(data, 'Bearer ' + localStorage.access_token).then((response) => {
                if (response.data.code === 200) {
                    dispatch({
                        type: GET_PRODUCT_SALES_SUCCESS,
                        payload: {
                            data: response.data.data,
                        },
                    });
                } else {
                    dispatch({
                        type: GET_PRODUCT_SALES_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                }
            });
        } catch (error) {
            dispatch({
                type: GET_PRODUCT_SALES_ERROR,
                payload: {
                    message: 'Error Loading Events',
                },
            });
        }
    },

    getPrograms: () => async (dispatch) => {
        try {
            dispatch({
                type: GET_PROGRAMS_REQUEST,
            });
            await getPrograms('Bearer ' + localStorage.access_token).then((response) => {
                if (response.data.code === 200) {
                    dispatch({
                        type: GET_PROGRAMS_SUCCESS,
                        payload: {
                            data: response.data.data,
                        },
                    });
                } else {
                    dispatch({
                        type: GET_PROGRAMS_ERROR,
                        payload: {
                            message: response.message,
                        },
                    });
                }
            });
        } catch (error) {
            dispatch({
                type: GET_PROGRAMS_ERROR,
                payload: {
                    message: 'Error Loading Programs',
                },
            });
        }
    },

};

export default actions;
