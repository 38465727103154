import {
	getMembersList, getMemberDetail, generateVoucher, getKidDetail, editKidDetail, editMemberDetail, deleteMembers
	, getMembersInvoices, deleteChildrens, getAllSchools, membersSearch, getChildrenList, childrenSearch,getMemberTransactions,
} from '../../config/config';
import { toast } from 'react-toastify';
import postsActions from './postsActions';

export const LIST_MEMBERS_REQUEST = 'LIST_MEMBERS_REQUEST';
export const LIST_MEMBERS_SUCCESS = 'LIST_MEMBERS_SUCCESS';
export const LIST_MEMBERS_ERROR = 'LIST_MEMBERS_ERROR';

export const LIST_CHILDREN_REQUEST = 'LIST_CHILDREN_REQUEST';
export const LIST_CHILDREN_SUCCESS = 'LIST_CHILDREN_SUCCESS';
export const LIST_CHILDREN_ERROR = 'LIST_CHILDREN_ERROR';

export const DETAIL_MEMBERS_REQUEST = 'DETAIL_MEMBERS_REQUEST';
export const DETAIL_MEMBERS_SUCCESS = 'DETAIL_MEMBERS_SUCCESS';
export const DETAIL_MEMBERS_ERROR = 'DETAIL_MEMBERS_ERROR';
export const SET_MEMBER = 'SET_MEMBER'

export const DETAIL_KID_REQUEST = 'DETAIL_KID_REQUEST';
export const DETAIL_KID_SUCCESS = 'DETAIL_KID_SUCCESS';
export const DETAIL_KID_ERROR = 'DETAIL_KID_ERROR';
export const SET_KID = 'SET_KID'

export const SEND_VOUCHER_REQUEST = 'SEND_VOUCHER_REQUEST';
export const SEND_VOUCHER_SUCCESS = 'SEND_VOUCHER_SUCCESS';
export const SEND_VOUCHER_ERROR = 'SEND_VOUCHER_ERROR';

export const DELETE_MEMBERS_REQUEST = 'DELETE_MEMBERS_REQUEST';
export const DELETE_MEMBERS_SUCCESS = 'DELETE_MEMBERS_SUCCESS';
export const DELETE_MEMBERS_ERROR = 'DELETE_MEMBERS_ERROR';

export const DELETE_CHILDRENS_REQUEST = 'DELETE_CHILDRENS_REQUEST';
export const DELETE_CHILDRENS_SUCCESS = 'DELETE_CHILDRENS_SUCCESS';
export const DELETE_CHILDRENS_ERROR = 'DELETE_CHILDRENS_ERROR';

export const MEMBER_INVOICES_REQUEST = 'MEMBER_INVOICES_REQUEST';
export const MEMBER_INVOICES_SUCCESS = 'MEMBER_INVOICES_SUCCESS';
export const MEMBER_INVOICES_ERROR = 'MEMBER_INVOICES_ERROR';

export const MEMBER_SEARCH_REQUEST = 'MEMBER_SEARCH_REQUEST';
export const MEMBER_SEARCH_SUCCESS = 'MEMBER_SEARCH_SUCCESS';
export const MEMBER_SEARCH_ERROR = 'MEMBER_SEARCH_ERROR';

export const CHILDREN_SEARCH_REQUEST = 'CHILDREN_SEARCH_REQUEST';
export const CHILDREN_SEARCH_SUCCESS = 'CHILDREN_SEARCH_SUCCESS';
export const CHILDREN_SEARCH_ERROR = 'CHILDREN_SEARCH_ERROR';

export const SCHOOLS_SUCCESS = 'SCHOOLS_SUCCESS'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'
export const CLEAR_CHILDREN_SEARCH = 'CLEAR_CHILDREN_SEARCH'

export const MEMBER_PAGE_STATE = 'MEMBER_PAGE_STATE';

export const MEMBER_TRANSACTIONS_REQUEST = 'MEMBER_TRANSACTIONS_REQUEST';
export const MEMBER_TRANSACTIONS_SUCCESS = 'MEMBER_TRANSACTIONS_SUCCESS';
export const MEMBER_TRANSACTIONS_ERROR = 'MEMBER_TRANSACTIONS_ERROR';

const actions = {
	listMembers: (data) => async (dispatch) => {
		try {
			dispatch({
				type: LIST_MEMBERS_REQUEST,
			});
			await getMembersList(data).then((response) => {
				if (response.data.code === 200) {
					dispatch({
						type: LIST_MEMBERS_SUCCESS,
						payload: {
							data: response.data.data,
						},
					});
				} else {
					dispatch({
						type: LIST_MEMBERS_ERROR,
						payload: {
							message: response.message,
						},
					});
				}
			});
		} catch (error) {
			dispatch({
				type: LIST_MEMBERS_ERROR,
				payload: {
					message: 'Error Loading Members',
				},
			});
		}
	},

	listChildren: (data) => async (dispatch) => {
		try {
			dispatch({
				type: LIST_CHILDREN_REQUEST,
			});
			await getChildrenList(data).then((response) => {
				if (response.status === 200) {
					dispatch({
						type: LIST_CHILDREN_SUCCESS,
						payload: {
							data: response?.data?.data,
						},
					});
				} else {
					dispatch({
						type: LIST_CHILDREN_ERROR,
						payload: {
							message: response.message,
						},
					});
				}
			});
		} catch (error) {
			dispatch({
				type: LIST_CHILDREN_ERROR,
				payload: {
					message: 'Error Loading Members',
				},
			});
		}
	},

	getMemberDetail: (data) => async (dispatch) => {
		try {
			dispatch({
				type: DETAIL_MEMBERS_REQUEST,
			});
			await getMemberDetail(data).then((response) => {
				if (response.data.code === 200) {
					dispatch({
						type: DETAIL_MEMBERS_SUCCESS,
						payload: {
							data: response.data.data.parents,
							nbrChildren: response.data.data['numberof-children'],
							last_boking_transaction: response.data.data['last-boking_transaction']
						},
					});
				} else {
					dispatch({
						type: DETAIL_MEMBERS_ERROR,
						payload: {
							message: response.message,
						},
					});
				}
			});
		} catch (error) {
			dispatch({
				type: DETAIL_MEMBERS_ERROR,
				payload: {
					message: 'Error Loading Member detail',
				},
			});
		}
	},

	getMemberTransactions: (data) => async (dispatch) => {
		try {
			dispatch({
				type: MEMBER_TRANSACTIONS_REQUEST,
			});
			await getMemberTransactions(data).then((response) => {
				if (response.data.code === 200) {
					const getTotal = ()=>{
						let total = 0;
						response?.data?.data?.map((zone)=>{
							total+=zone.total;
						});
						return Math.round(total);
					}
					dispatch({
						type: MEMBER_TRANSACTIONS_SUCCESS,
						payload: {
							count: response?.data?.data?.length,
							total: getTotal(),
							transactions: response?.data?.data,
						},
					});
				} else {
					dispatch({
						type: MEMBER_TRANSACTIONS_ERROR,
					});
				}
			});
		} catch (error) {
			dispatch({
				type: MEMBER_TRANSACTIONS_ERROR,
			});
		}
	},

	setMemberDetails: (data) => async (dispatch) => {
		dispatch({
			type: SET_MEMBER,
			payload: {
				data
			}
		});
	},

	editMember: (data) => async (dispatch) => {
		try {
			await editMemberDetail(data).then((response) => {
				if (response.data.code === 200) {
					toast.success("Success")
					dispatch(actions.getMemberDetail({ id: data.id }))
				} else {
					toast.error(response.data.message)
				}
			});
		} catch (error) {
			dispatch({
				type: DETAIL_MEMBERS_ERROR,
				payload: {
					message: 'Error Editing Member detail',
				},
			});
			toast.error(error?.response?.data?.message)
		}
	},

	getKidDetails: (data) => async (dispatch) => {
		try {
			dispatch({
				type: DETAIL_KID_REQUEST,
			});
			await getKidDetail(data).then((response) => {
				if (response.data.code === 200) {
					dispatch({
						type: DETAIL_KID_SUCCESS,
						payload: {
							data: response.data.data,
						},
					});
				} else {
					dispatch({
						type: DETAIL_KID_ERROR,
						payload: {
							message: response.message,
						},
					});
				}
			});
		} catch (error) {
			dispatch({
				type: DETAIL_KID_ERROR,
				payload: {
					message: 'Error Loading Kid detail',
				},
			});
		}
	},

	getSchools: () => async (dispatch) => {
		try {

			await getAllSchools().then((response) => {
				if (response.data.code === 200) {
					dispatch({
						type: SCHOOLS_SUCCESS,
						payload: {
							data: response.data.data,
						},
					});
				} else {
					toast.error('Getting Schools Error, Please try again.')
				}
			});
		} catch (error) {
			toast.error('Getting Schools Error, Please try again.')
		}
	},

	setKidDetails: (data) => async (dispatch) => {
		dispatch({
			type: SET_KID,
			payload: {
				data
			}
		});
	},

	editKid: (data, history) => async (dispatch) => {
		try {
			await editKidDetail(data).then((response) => {
				if (response.data.code === 200) {
					toast.success("Saved")
				} else {
					toast.error(response.data.message)
				}
			});
		} catch (error) {
			dispatch({
				type: DETAIL_KID_ERROR,
				payload: {
					message: 'Error Editing Kid detail',
				},
			});
			toast.error(error?.response?.data?.message)
		}
	},


	generateVoucher: (data) => async (dispatch) => {
		try {
			dispatch({
				type: SEND_VOUCHER_REQUEST,
			});
			await generateVoucher(data).then((response) => {
				if (response.data.code === 200) {
					toast.success('Voucher Sent');
					dispatch({
						type: SEND_VOUCHER_SUCCESS,
						payload: {
							data: response.data.data.data,
						},
					});
				} else {
					toast.error('Error: Voucher not Sent' + response?.data?.message);
					dispatch({
						type: SEND_VOUCHER_ERROR,
						payload: {
							message: response.message,
						},
					});
				}
			});
		} catch (error) {
			toast.error('Error: Voucher not Sent' + error.message);
			dispatch({
				type: SEND_VOUCHER_ERROR,
				payload: {
					message: 'Error Voucher not Sent',
				},
			});
		}
	},


	deleteMembers: (data, history) => async (dispatch) => {
		try {
			dispatch({
				type: DELETE_MEMBERS_REQUEST,
			});
			let headers = {
				'Authorization': 'Bearer ' + localStorage.access_token,
				'Content-type': 'application/x-www-form-urlencoded',
			}
			await deleteMembers(data, headers).then((response) => {
				if (response.data.code === 200) {
					toast.success('Members deleted');
					dispatch({
						type: DELETE_MEMBERS_SUCCESS,
					});
					dispatch(postsActions.popupHandler({type:'CLOSE'}))
					history.push('/admin/members')
					dispatch(actions.listMembers(1))
				} else {
					toast.error('Error: Members not deleted' + response?.data?.message);
					dispatch({
						type: DELETE_MEMBERS_ERROR,
					});
				}
			});
		} catch (error) {
			toast.error('Error: Members not deleted' + error.response.message);
			dispatch({
				type: DELETE_MEMBERS_ERROR,
			});
		}
	},

	getInvoices: (data) => async (dispatch) => {
		try {
			dispatch({
				type: MEMBER_INVOICES_REQUEST,
			});
			await getMembersInvoices(data, 'Bearer ' + localStorage.access_token).then((response) => {
				if (response.data.code === 200) {
					dispatch({
						type: MEMBER_INVOICES_SUCCESS,
						payload: {
							data: response.data.data,
						},
					});
				} else {
					dispatch({
						type: MEMBER_INVOICES_ERROR,
						payload: {
							message: response.message,
						},
					});
				}
			});
		} catch (error) {
			dispatch({
				type: MEMBER_INVOICES_ERROR,
				payload: {
					message: 'Error Loading Invoices',
				},
			});
		}
	},

	deleteChildrens: (data) => async (dispatch) => {
		try {
			dispatch({
				type: DELETE_CHILDRENS_REQUEST,
			});
			let headers = {
				'Authorization': 'Bearer ' + localStorage.access_token,
				'Content-type': 'application/x-www-form-urlencoded',
			}
			await deleteChildrens(data, headers).then((response) => {
				if (response.data.code === 200) {
					toast.success('CHILDRENS deleted');
					dispatch({
						type: DELETE_CHILDRENS_SUCCESS,
					});
					let newData = {
						id: data.parent_id
					}
					dispatch(actions.getMemberDetail(newData))
				} else {
					toast.error('Error: CHILDRENS not deleted' + response?.data?.message);
					dispatch({
						type: DELETE_CHILDRENS_ERROR,
					});
				}
			});
		} catch (error) {
			toast.error('Error: CHILDRENS not deleted' + error.response.message);
			dispatch({
				type: DELETE_CHILDRENS_ERROR,
			});
		}
	},

	membersSearch: (data) => async (dispatch) => {
		try {
			dispatch({
				type: MEMBER_SEARCH_REQUEST,
			});
			await membersSearch(data).then((response) => {
				if (response.data.code === 200) {
					dispatch({
						type: MEMBER_SEARCH_SUCCESS,
						payload: {
							data: response.data.data,
						}
					});
				} else {
					dispatch({
						type: MEMBER_SEARCH_ERROR,
					});
				}
			});
		} catch (error) {
			dispatch({
				type: MEMBER_SEARCH_ERROR,
			});
		}
	},


	childrenSearch: (data) => async (dispatch) => {
		try {
			dispatch({
				type: CHILDREN_SEARCH_REQUEST,
			});
			await childrenSearch(data).then((response) => {
				if (response.data.code === 200) {
					dispatch({
						type: CHILDREN_SEARCH_SUCCESS,
						payload: {
							data: response.data.data,
						}
					});
				} else {
					dispatch({
						type: CHILDREN_SEARCH_ERROR,
					});
				}
			});
		} catch (error) {
			dispatch({
				type: MEMBER_SEARCH_ERROR,
			});
		}
	},

	resetMemberSearch: () => async (dispatch) => {
		dispatch({
			type: CLEAR_SEARCH,
		});
	},

	resetChildrenSearch: () => async (dispatch) => {
		dispatch({
			type: CLEAR_CHILDREN_SEARCH,
		});
	},

	changePageState: (pageOn) => (dispatch) => {
		dispatch({
		  type: MEMBER_PAGE_STATE,
		  pageOn: pageOn,
		})
	},
};

export default actions;
