import React , {useEffect} from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router';

import coachActions from '../../../redux/actions/coachActions'

import './schoolStyle.scss'

export const DeleteCoach = (props) => {
    const history = useHistory();
    const deleteCoach = () => {
        props.dispatch(coachActions.deleteCoach(props.selectedPost.id, history));
    }
    
    return (
        <div>
        <h3 className='blue bold'>Delete Coach</h3>
        <span className='font-20 blue mb-5'>Are you sure you want to delete {props.selectedPost.first_name}?</span>
        <div className='white  bold red-bg add_school_btn_popup' 
        onClick={() => deleteCoach()}
            >
                Delete
        </div>
    </div>
    )
}

const mapStateToProps = (state) => ({
    selectedPost: state.postReducer?.selectedPost,
})


export default connect(mapStateToProps)(DeleteCoach)
