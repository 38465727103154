import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from "../../history";
import NumberFormat from 'react-number-format';

//CSS
import './CouponsListRow.css';

//REDUX
import couponsActions from '../../redux/actions/couponsActions';
import postActions from "../../redux/actions/postsActions";

//Images
import arrowDown from '../../assets/images/arrow_blue_down.svg';
import arrowUp from '../../assets/images/arrow_blue_top.svg';
import Checked from '../../assets/images/CheckBoxChecked.png';
import Unchecked from '../../assets/images/CheckBoxUnchecked.png';
import xWhite from '../../assets/images/x-delete.svg'
import moment from 'moment';
import { CheckBox } from '@material-ui/icons';


function CouponsListRow(props) {

	const [isChecked, setIsChecked] = useState(false);
	const [max_uses, setMaxUses] = useState(false);

	useEffect(() => {
		setIsChecked(props.checked);
	}, [props.checked]);

	useEffect(() => {
		setMaxUses(props.max_uses);
	}, [props.max_uses]);

	const handleConfirmEdit = () => {
		let couponData = {id: props.id, data:{ max_uses: max_uses }};
		console.log(couponData);
		props.dispatch(couponsActions.editMaxUses(couponData))
	}

	return (
		<div className='coupons_row_table'>
			<div className={props.status === 'canceled' ? 'coupons_row_row_canceled coupons_row_row' : 'coupons_row_row'} onClick={() => { if(props.status === 'canceled'){ props.dispatch(postActions.popupHandler({type: 'INFO', data: props.notes })); } }}>
				<div className='coupons_row_col check_col wi-5'>
					<img
						src={!isChecked ? Unchecked : Checked}
						alt=''
						onClick={() => {
							props.handleChecked(props.code);
							setIsChecked(!isChecked);
						}}
						className='check_box_coupon'
						hidden={props.type==='AKV' || props.status === 'canceled'}
					/>
				</div>
				<div className='wi-10 coupons_row_col'>{props.type}</div>
				<div className='wi-10 coupons_row_col'>{props.code}</div>
				<div className='wi-10 coupons_row_col'><NumberFormat value={Number(props.value).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></div>
				<div className='wi-16 coupons_row_col'>{props.message}</div>
				<div className='wi-10 coupons_row_col'>
					<div className='max_uses' hidden={props.type == 'AKV' || props.status === 'canceled'}>
						<div className='arrows'>
							<input type='number' defaultValue={props.max_uses ?? 0} name='max_uses' onChange={e => setMaxUses(e.target.value)} ></input>
						</div>
						<div className='save' onClick={() => { handleConfirmEdit() }}>
							<span className="edit-class">save</span>
						</div>
					</div>
					<span hidden={props.type != 'AKV' && props.status != 'canceled'}>{props.max_uses}</span>
				</div>
				<div className='wi-10 coupons_row_col'>{props.is_used}</div>
				<div className='wi-10 coupons_row_col'>{(props.is_used == 'YES' && props.times_used == 0) ? 1 : props.times_used}</div>
				<div className='wi-16 coupons_row_col'>{props.created_at ? moment(props.created_at).format("DD/MM/YYYY") : ''}</div>
				<div className='wi-16 coupons_row_col'>{props.expiration_date ? moment(props.expiration_date).format("DD/MM/YYYY") : ''}</div>
			</div>
		</div>
	);
}

export default connect()(CouponsListRow);
