import {
  STORE_CATEGORY_LIST_SUCCESS,
  STORE_CATEGORY_LIST_LOADING,
  STORE_CATEGORY_LIST_FAILURE,
  STORE_PRODUCTS_LIST_SUCCESS,
  STORE_PRODUCTS_LIST_LOADING,
  STORE_PRODUCTS_LIST_FAILURE,
  CREATE_CATEGORIES_SUCCESS,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_LOADING,
  CATEGORY_LIST_FAILURE,
  RELATED_PRODUCT_LIST_SUCCESS,
  RELATED_PRODUCT_LIST_LOADING,
  RELATED_PRODUCT_LIST_FAILURE,
  STORE_PRODUCTS_DETAIL_SUCCESS,
  STORE_PRODUCTS_DETAIL_LOADING,
  STORE_PRODUCTS_DETAIL_FAILURE,
  STORE_PRODUCTS_ADD_SUCCESS,
  STORE_PRODUCTS_ADD_LOADING,
  STORE_PRODUCTS_ADD_FAILURE,
  STORE_PRODUCTS_EDIT_SUCCESS,
  STORE_PRODUCTS_EDIT_LOADING,
  STORE_PRODUCTS_EDIT_FAILURE,
  STORE_PRODUCTS_DELETE_SUCCESS,
  STORE_PRODUCTS_DELETE_LOADING,
  STORE_PRODUCTS_DELETE_FAILURE,
  CATEGORY_DETAIL,
} from "../actions/types";
const initialState = {
  storeCategories: [],
  storeProducts: {},
  categoryId: "",
  categories: [],
  relatedProducts: [],
  productDetail: {},
  categoryDetail: {},
  addProductLoading:false,
  editProductLoading:false,
  deleteProductLoading:false,
};

export default function (state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case STORE_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        storeCategories: payload,
        isFailed: false,
        loading: false,
      };
    case STORE_CATEGORY_LIST_LOADING:
      return {
        ...state,
        storeCategories: [],
        isFailed: false,
        loading: true,
      };
    case STORE_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        storeCategories: [],
        isFailed: true,
        loading: false,
      };
    case STORE_PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        storeProducts: payload,
        isFailed: false,
        loading: false,
      };
    case STORE_PRODUCTS_LIST_LOADING:
      return {
        ...state,
        storeProducts: {},
        isFailed: false,
        loading: true,
      };
    case STORE_PRODUCTS_LIST_FAILURE:
      return {
        ...state,
        storeProducts: {},
        isFailed: true,
        loading: false,
      };
    case CREATE_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoryId: payload,
      };
    case CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categories: payload,
        isFailed: false,
        loading: false,
      };
    case CATEGORY_LIST_LOADING:
      return {
        ...state,
        categories: [],
        isFailed: false,
        loading: true,
      };
    case CATEGORY_LIST_FAILURE:
      return {
        ...state,
        categories: [],
        isFailed: true,
        loading: false,
      };
    case RELATED_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        relatedProducts: payload,
        isFailed: false,
        loading: false,
      };
    case RELATED_PRODUCT_LIST_LOADING:
      return {
        ...state,
        relatedProducts: {},
        isFailed: false,
        loading: true,
      };
    case RELATED_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        relatedProducts: {},
        isFailed: true,
        loading: false,
      };
    case STORE_PRODUCTS_DETAIL_SUCCESS:
      return {
        ...state,
        productDetail: payload,
        isFailed: false,
        loading: false,
      };
    case STORE_PRODUCTS_DETAIL_LOADING:
      return {
        ...state,
        productDetail: {},
        isFailed: false,
        loading: true,
      };
    case STORE_PRODUCTS_DETAIL_FAILURE:
      return {
        ...state,
        productDetail: {},
        isFailed: true,
        loading: false,
      };
    case CATEGORY_DETAIL:
      return {
        ...state,
        categoryDetail: payload,
      };

    case STORE_PRODUCTS_ADD_LOADING:
      return Object.assign({}, state, {
        addProductLoading:true
      });
    case STORE_PRODUCTS_ADD_SUCCESS:
      return Object.assign({}, state, {
        addProductLoading:false
      });
    case STORE_PRODUCTS_ADD_FAILURE:
      return Object.assign({}, state, {
        addProductLoading:false
      });

    case STORE_PRODUCTS_EDIT_LOADING:
      return Object.assign({}, state, {
        editProductLoading:true
      });
    case STORE_PRODUCTS_EDIT_SUCCESS:
      return Object.assign({}, state, {
        editProductLoading:false
      });
    case STORE_PRODUCTS_EDIT_FAILURE:
      return Object.assign({}, state, {
        editProductLoading:false
      });

    case STORE_PRODUCTS_DELETE_LOADING:
      return Object.assign({}, state, {
        deleteProductLoading:true
      });
    case STORE_PRODUCTS_DELETE_SUCCESS:
      return Object.assign({}, state, {
        deleteProductLoading:false
      });
    case STORE_PRODUCTS_DELETE_FAILURE:
      return Object.assign({}, state, {
        deleteProductLoading:false
      });

    default:
      return state;
  }
}
