import React, { useState } from 'react';
import { connect } from 'react-redux'
import moment from 'moment'
// import { Link } from 'react-router-dom';
import { calculateAge } from '../../utils/app';
//Image
import greenCheck from '../../assets/images/checked-in-green.svg';
import unCheck from '../../assets/images/green-uncheck.svg';

//redux

import eventActions from "../../redux/actions/eventsActions"
import coachActions from "../../redux/actions/coachPortalActions"

function ProgramChildRow(props) {
	const [action, setAction] = useState(props.has_children_checked_in === 0 || !props.has_children_checked_in ? false : true);

	const handleChildAttendance = (action) => {

		let data = {
			children_id: props.bookingId,
			event_recurrence_id: props.programId,
			is_checked_in: action ? 1 : 0
		}
		if (props.user?.roles?.length > 0) {
			if (props.user?.roles[0]?.name === 'coach') {
				props.dispatch(coachActions.userCheckInCoach({ eventId: props.eventId, event_recurrence_id: props.programId, data: data }))
				console.log('COACH', data)
				setAction(!action)
			} else {
				props.dispatch(eventActions.setChildrenAttendanceAdmin({
					eventId: props.eventId,
					event_recurrence_id: props.programId,
					// children_id: props.bookingId,
					// attendance_date: props.date,
					data: data,
				}))
			}
			setAction(!action)
		}
	}
	return (
		<div className='event_purchaser_row'>
			<div className='event_purchaser_row_col wi-17'>{props.child_name}</div>
			<div className='event_purchaser_row_col wi-17'>{props.purchaser}</div>
			<div className='event_purchaser_row_col wi-17'>{props.mobile_number}</div>
			<div className='event_purchaser_row_col wi-17'>{moment(props.date).format('DD/MM/YYYY')}</div>
			<div className='event_purchaser_row_col wi-16'>
				{calculateAge(props?.dob)}
			</div>
			<div className='event_purchaser_row_col wi-16'>
				<div onClick={() => handleChildAttendance(action)} className='pointer'>
					<img src={action ? greenCheck : unCheck} alt='' className='mr-2' />
					{action ? 'checked in' : 'check in'}
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	childAttendanceAdminLoading: state.eventReducer.childAttendanceAdminLoading,
	user: state.register.user,
})

export default connect(mapStateToProps)(ProgramChildRow)
