import React from "react";
import { Link } from "react-router-dom";

import back from "../../assets/images/back.svg";
import logo from "../../assets/images/no1dtraft-img.svg";
import redLine from "../../assets/images/Line-red.svg";

const ForgotPasswordView = ({ formData, onChange, onSubmit }) => (
  <div className="forgot-password">
    <div className="d-sm-flex align-items-center">
      <div className="no1draft_logo">
        <div>
          <div className="login_logo">
            <div>
              <Link to="/login">
                <img src={back} />
              </Link>
            </div>
          </div>
          <img src={logo} />
        </div>
      </div>
      <div className="form_part">
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="form_style">
            <img src={redLine} />
          </div>
          <div className="form_title white font-36 bold">
            FORGOT YOUR PASSWORD?
          </div>
          <div className="form_title white font-13 regular padding-top-10">
            Enter your email address to send you a reset link
          </div>
          <div className="text_one_form white regular font-16 pt-40">
            <input
              type="email"
              value={formData.email}
              onChange={(e) => {
                onChange(e);
              }}
              name="email"
              className="white"
              placeholder="Email address"
              required
            ></input>
          </div>
          <input
            type="submit"
            className="d-inline-block text-center button_edit bold font-16 white red-bg border-red w-100 margin-top-20"
            value="Submit"
          />
        </form>
      </div>
    </div>
  </div>
);

export default ForgotPasswordView;
